import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { AuthenticateService } from "./services/authenticate.service";
import { GlobalDataSharingService } from "./services/globaldatasharing.service";
import { Observable, interval, Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  title = 'app';
  status = 'ONLINE'; //initializing as online by default
  isConnected = true;
  
  userModel: any=  {
    RoleID : "",
    IsOnline : "",
  }

  constructor(private authService: AuthenticateService,private dataSharingService: GlobalDataSharingService,private connectionService: ConnectionService,private userIdle: UserIdleService){
  
  //constructor(private connectionService: ConnectionService) {
   this.connectionService.monitor().subscribe(isConnected => {
     this.isConnected = isConnected;
     if (this.isConnected) {
       this.status = "ONLINE";
     } else {
       this.status = "OFFLINE"
     }
     console.log("console",this.status);
   });
  }
  ngOnInit() {
    console.log("************"+localStorage.getItem('loginUserRoleID'));
    var id=localStorage.getItem('loginUserRoleID');
    if(id!=null){
      this.logout();
    }
    let context = this;
    window.addEventListener("beforeunload", function (e) {
      context.logout();
    });
  }
  restart() {
    this.userIdle.resetTimer();
  }
  logout(){
    this.userModel.RoleID = localStorage.getItem('loginUserRoleID');
    this.userModel.IsOnline = "inactive";
    this.authService.UpdateLogin(this.userModel).subscribe((res1: any) => {});
  }
}
