import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from "../services/authenticate.service";
import { GlobalDataSharingService } from "../services/globaldatasharing.service";
import { Observable, interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-h-nav-menu',
  templateUrl: './h-nav-menu.component.html',
  styleUrls: ['./h-nav-menu.component.css']
})
export class HNavMenuComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  constructor(private authService: AuthenticateService,private dataSharingService: GlobalDataSharingService,) {
  }
  role = localStorage.getItem("loggedinUserRole");
  private updateSubscription: Subscription;
  userModel: any=  {
    RoleID : "",
    IsOnline : "",
  }
  ngOnInit() {
  
    this.isLoggedIn$ = this.authService.isLoggedIn;
    // Added below if condition to stop auto login. Sir wanted everytime the user has to login.
    if (this.isLoggedIn$) {
      this.authService.logout();
    }

    this.updateSubscription = interval(2000).subscribe(
      (val) => {
        this.role = localStorage.getItem("loggedinUserRole");
        //console.log("*****************"+this.role);
    });
   
  }
userdata : {};
  onLogout() {
    
    this.userModel.RoleID = localStorage.getItem('loginUserRoleID');
    this.userModel.IsOnline = "inactive";
  
    this.authService.UpdateLogin(this.userModel).subscribe((res1: any) => {});
    this.authService.logout();
    
    
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }
}
