import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ResponseViewModel } from '../models/responseviewmodel';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  constructor(private http: HttpClient, private router: Router, @Inject('BASE_URL') private baseUrl: string) { }

  getMembers(isMember: string, allowLocation: string, keyword: string) {
    var apiUrl = 'api/offer/getmembers?&isMember=' + isMember + '&allowLocation=' + allowLocation + '&keyword=' + keyword;
    return this.http.get(this.baseUrl + apiUrl);
  }

  getAllMembers() {
    var apiUrl = 'api/offer/getAllmembers';
    return this.http.get(this.baseUrl + apiUrl);
  }

  getAllMembersListing() {
    var apiUrl = 'api/offer/getAllMembersListing';
    return this.http.get(this.baseUrl + apiUrl);
  }

  getLastOfferingDetails(memberID) {
    var apiUrl = 'api/offer/getLastOfferingDetails?memberID=' + memberID;
    return this.http.get(this.baseUrl + apiUrl);
  }

  getLastOtherOfferingDetails(memberID) {
    var apiUrl = 'api/offer/getLastOtherOfferingDetails?memberID=' + memberID;
    return this.http.get(this.baseUrl + apiUrl);
  }

  Save(formData) {
    var apiUrl = 'api/offer/saveOffer';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8' );
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  SaveOtherOffering(formData) {
    var apiUrl = 'api/offer/saveOtherOffering';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  SaveAncestorsData(formData) {
    var apiUrl = 'api/offer/saveAncestorsData';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  updateMebo(formData) {
    var apiUrl = 'api/offer/updateMebo';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); 
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  getAncestorsData(formData) {
    var apiUrl = 'api/offer/getAncestorsData';
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, formData);
  }

  deleteAncestorEntry(ancestorsID) {
    var apiUrl = 'api/offer/deleteAncestorEntry?ancestorsID=' + ancestorsID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }

  getOffers(formData) {
    var apiUrl = 'api/offer/getOffers';
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, formData);
  }

  getOffer(offerID) {
    var apiUrl = 'api/offer/getOffer?offerid=' + offerID;
    return this.http.get<ResponseViewModel>(this.baseUrl + apiUrl);
  }
  getOtherOffering(otherOfferingID) {
    var apiUrl = 'api/offer/getOtherOffering?otherOfferingID=' + otherOfferingID;
    return this.http.get<ResponseViewModel>(this.baseUrl + apiUrl);
  }
  getAllOtherOfferingData() {
    var apiUrl = 'api/offer/getAllOtherOfferingData' ;
    return this.http.get<ResponseViewModel>(this.baseUrl + apiUrl);
  }

  deleteOffer(offerID) {
    var apiUrl = 'api/offer/deleteOffer?offerid=' + offerID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }
  deleteOtherOffering(otherOfferingID) {
    var apiUrl = 'api/offer/deleteOtherOffering?otherOfferingID=' + otherOfferingID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }

  getMainCentreDetails() {
    var apiUrl = 'api/offer/getMainCentreDetails';
    return this.http.get(this.baseUrl + apiUrl);
  }

  getChildCentreDetails() {
    var apiUrl = 'api/offer/getChildCentreDetails';
    return this.http.get(this.baseUrl + apiUrl);
  }

  getSubCentreDetails() {
    var apiUrl = 'api/offer/getSubCentreDetails';
    return this.http.get(this.baseUrl + apiUrl);
  }
  getStatusDetails() {
    var apiUrl = 'api/offer/getStatusDetails';
    return this.http.get(this.baseUrl + apiUrl);
  }

  getSysVarData() {
    console.log("api/offer/GetSysVarDataDetails");
    var apiUrl = 'api/offer/getSysVarDataDetails';
    return this.http.get(this.baseUrl + apiUrl);
  }

  saveMainCentreData(formData) {
    var apiUrl = 'api/offer/saveMainCentreData';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  deleteMainCentre(MainCentreID) {
    var apiUrl = 'api/offer/deleteMainCentre?mainCentreID=' + MainCentreID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }

  saveChildCentreData(formData) {
    var apiUrl = 'api/offer/saveChildCentreData';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  
  deleteChildCentre(childCentreID) {
    var apiUrl = 'api/offer/deleteChildCentre?childCentreID=' + childCentreID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }

  saveSubCentreData(formData) {
    var apiUrl = 'api/offer/saveSubCentreData';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  deleteSubCentre(subCentreID) {
    var apiUrl = 'api/offer/deleteSubCentre?subCentreID=' + subCentreID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }

  saveStatusData(formData) {
    var apiUrl = 'api/offer/saveStatusData';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  deleteStatus(statusID) {
    var apiUrl = 'api/offer/deleteStatus?statusID=' + statusID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }
  
  uploadMeboCSV(formData) {
    var apiUrl = 'api/offer/UploadMeboCSV';
    return this.http.post(this.baseUrl + apiUrl, formData);
  }
  uploadMainCentre(formData) {
    var apiUrl = 'api/offer/UploadMainCentreCSV';
    return this.http.post(this.baseUrl + apiUrl, formData);
  }

  uploadChildCentre(formData) {
    var apiUrl = 'api/offer/uploadChildCentreCSV';
    return this.http.post(this.baseUrl + apiUrl, formData);
  }

  uploadSubCentre(formData) {
    var apiUrl = 'api/offer/uploadSubCentreCSV';
    return this.http.post(this.baseUrl + apiUrl, formData);
  }

  uploadStatus(formData) {
    var apiUrl = 'api/offer/uploadStatusCSV';
    return this.http.post(this.baseUrl + apiUrl, formData);
  }
  uploadMember(formData) {
    var apiUrl = 'api/offer/uploadMemberCSV';
    return this.http.post(this.baseUrl + apiUrl, formData);
  }
  uploadOffering(formData) {
    var apiUrl = 'api/offer/uploadOfferingCSV';
    return this.http.post(this.baseUrl + apiUrl, formData);
  }
  saveUserData(formData) {
    var apiUrl = 'api/offer/saveUserData';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }
  saveSysVarData(formData) {
    var apiUrl = 'api/offer/saveSysVarData';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }

  deleteUser(roleID) {
    var apiUrl = 'api/offer/deleteUser?roleID=' + roleID;
    return this.http.delete<ResponseViewModel>(this.baseUrl + apiUrl);
  }

  getMembersForQueryM(formData) {
    var apiUrl = 'api/offer/getMembersForQueryM';
    let headers = new HttpHeaders();
    let bodyString = JSON.stringify(formData); // Stringify payload
    headers = headers.append('Content-Type', 'application/JSON;charset=utf-8');
    return this.http.post<ResponseViewModel>(this.baseUrl + apiUrl, bodyString, { headers: headers });
  }
  GetMemberInfoById(memberID) {
    var apiUrl = 'api/offer/GetMemberInfoById?Id=' + memberID;
    return this.http.get(this.baseUrl + apiUrl);
  }
  GetLastSlipNo(offerDate,mainCentre,childCentre,subCentre) {
    var apiUrl = 'api/offer/GetLastSlipNo?offerDate=' + offerDate+"&OfferMainCentre="+mainCentre +"&OfferChildCentre="+childCentre +"&OfferSubCentre="+subCentre;
    return this.http.get(this.baseUrl + apiUrl);
  }
  getSummaryReport(type){
    var apiUrl = 'api/offer/GetSummaryReport?reportType='+type;
    return this.http.get(this.baseUrl + apiUrl);
  }
}
