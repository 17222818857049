import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompleterService, CompleterData } from 'ng2-completer';
import { OfferService } from '../../services/offer.service';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';

@Component({
  selector: 'app-offerlist',
  templateUrl: './offerlist.component.html',
  styleUrls: ['./offerlist.component.css']
})
export class OfferlistComponent implements OnInit {
  formModel = {
    loggedinUserRole:"",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName: "",
    subCentreID: "",
    showReportOOption: "",
    showReportMOption: "",
    showReportSOption:"",
    offerDateStart: null,
    offerDateEnd: null,
    totalAmt: null,
    offerMainCentre: "",
    offerChildCentre: "",
    offerSubCentre: "",
    memberName:"",
    memberID : "",
    report: "",
    remark1:"",
    reportName:"",
    nextMonth:null,
    forMonth: null,
    lastMonth: null,
    last2Months: null,
    last6Months:null,
    last7Months:null,
    lastYear: null,
    prevYear: null,
    nextYear:null,
    status:"",
    tillDate : null,
  }
  
  offers = [];
  offersSCMO = [];
  offersMembers = [];
  otherOffering = [];
  chkListData = [];
  chkListDataCent = [];

  otherOfferingDailySCMO = [];
  SCMOHQ = [];
  divineProtection =[];
  datewiseOffering = [];
  datewiseShidobu = [];
  monthwiseShidobu = [];
  centrewiseShidobu = [];

  otherOfferingNew = [];
  otherOfferingMembers = [];
  scmologbook = [];
  loggedInUserData = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  allTextChildCentreObj = {};
  allTextSubCentreObj = {};
  selectedReportObj = {};
  selectedStatusObj ={};
  reportTypeDetails = [];
  selectedPaymentObj = {};
  paymentOpt = "";
  paymentOptionDetails = [];
  statusDetails = [];
  members: [];

  allMembersArrayData:  [];
  protected dataservice: CompleterData;

  constructor(private offerService: OfferService, private excelService: ExcelService, private dataSharingService: GlobalDataSharingService,
    private spinner: NgxSpinnerService,private completerService: CompleterService) { }
   
  ngOnInit() {
    //this.showSpinner();
    this.allMembersArrayData = this.dataSharingService.getAllMembersListing();
    this.dataservice = this.completerService.local(this.allMembersArrayData, 'name', 'name');
   
    var d = new Date();
    this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
    this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
    // console.log(this.formModel.offerDateStart);    
    // console.log(this.formModel.offerDateEnd);
    var a=this.formModel.offerDateStart.getFullYear()+"/"+(this.formModel.offerDateStart.getMonth()+1)+"/"+this.formModel.offerDateStart.getDate();
    var b=this.formModel.offerDateEnd.getFullYear()+"/"+(this.formModel.offerDateEnd.getMonth()+1)+"/"+this.formModel.offerDateEnd.getDate();
    this.formModel.offerDateStart=new Date(a);
    this.formModel.offerDateEnd=new Date(b);
    
    //console.log(b);
    let allowLocation = localStorage.getItem('allowLocation');
    let offData = allowLocation.split("/");
    this.formModel.offerMainCentre = offData[0];
    this.formModel.offerChildCentre = offData[1];
    this.formModel.offerSubCentre = offData[2];
    this.formModel.report = "OO";

    this.formModel.nextMonth = new Date(d.getFullYear(), d.getMonth()+1, 1);
    this.formModel.forMonth = new Date(d.getFullYear(), d.getMonth(), 1);
    this.formModel.lastMonth = new Date(d.getFullYear(), d.getMonth() - 1, 1);
    this.formModel.last2Months = new Date(d.getFullYear(), d.getMonth() - 2, 1);
    this.formModel.last6Months = new Date(d.getFullYear(), d.getMonth() - 6, 1);
    this.formModel.last7Months = new Date(d.getFullYear(), d.getMonth() - 7, 1);

    this.formModel.lastYear = new Date(d.getFullYear() - 1, d.getMonth(), 1);
    this.formModel.prevYear = new Date(d.getFullYear() - 1, d.getMonth(), 1);
    this.formModel.nextYear = new Date(d.getFullYear() +1, d.getMonth(), 1);
    this.formModel.tillDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.formModel.showReportOOption = 'N';
    this.formModel.showReportMOption = 'N';
    this.formModel.showReportSOption = 'N';
    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.loggedInUserData = this.dataSharingService.getLoggedInUserData();
    this.formModel.mainCentreName = this.loggedInUserData[0].mainCentreName;
    this.formModel.childCentreName = this.loggedInUserData[0].childCentreName;
    this.formModel.subCentreName = this.loggedInUserData[0].subCentreName;
    this.formModel.mainCentreID = this.loggedInUserData[0].mainCentreID;
    this.formModel.childCentreID = this.loggedInUserData[0].childCentreID;
    this.formModel.subCentreID = this.loggedInUserData[0].subCentreID;
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
    this.allTextChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };

    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedReportObj = {
      id: 0,
      type: 'Processing Centre Only'
    };
   
    this.reportTypeDetails = [];
    this.reportTypeDetails.push(this.selectedReportObj);

    var tmpArry = [
      {
        id: 1,
        type: "Processing Centre Actual"
      },
    ];

    for (i = 0; i < tmpArry.length; i++) {
      this.reportTypeDetails.push(tmpArry[i]);
    }

    this.paymentOptionDetails = [];
    this.selectedPaymentObj = {
      id: 0,
      type:"All"
    }
    this.paymentOptionDetails.push(this.selectedPaymentObj);
    var tmpArry3 = [
      {
        id: 1,
        type: "Cash"
      },
      {
        id: 2,
        type: "Cheque"
      }
    ];
    for (i = 0; i < tmpArry3.length; i++) {
      this.paymentOptionDetails.push(tmpArry3[i]);
    }

    this.selectedStatusObj = {
      statusID: 0,
      statusName: "All"
    };

    var tmpArry1 = [];
    var i = 0;
    tmpArry1 = this.dataSharingService.getStatusData();
    this.statusDetails = [];
    this.statusDetails.push(this.selectedStatusObj);
    for (i = 0; i < tmpArry1.length; i++) {
      this.statusDetails.push(tmpArry1[i]);
    }  

    var i = 0;
    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.mainCentreDetails.push(this.selectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.childCentreDetails.push(this.selectedChildCentreObj);
        this.subCentreDetails.push(this.selectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }      
    this.offers = this.dataSharingService.getSearchedOfferData();
    this.offersMembers = this.dataSharingService.getSearchedOfferMemberData();
    this.offersSCMO = this.dataSharingService.getSearchedOfferSCMOData();
    this.SCMOHQ = this.dataSharingService.getSearchedOfferSCMOHQ();
    this.otherOffering = this.dataSharingService.getSearchedOtherOfferingData();
    this.otherOfferingMembers = this.dataSharingService.getSearchedOtherOfferingMemberData();
    this.divineProtection =this.dataSharingService.getSearchedDivineProtectionData();
    this.datewiseOffering = this.dataSharingService.getSearchedNewOfferData();
    this.scmologbook = this.dataSharingService.getSearchedOfferSCMOLogbookData();
    this.otherOfferingDailySCMO = this.dataSharingService.getDailyOfferingSCMOData();
    this.formModel.reportName = "Daily Offering";
    let tmp = "";
    tmp = this.dataSharingService.getEditOfferOptionClicked();
    if (tmp == "OO") {
      this.formModel.report = "OO";
      this.formModel.reportName = "Daily Offering";
    } else if (tmp == "OM") {
      this.formModel.report = "OM";
      this.formModel.reportName = "Offering Member Wise";
    } else if (tmp == "DSCMO") {
      this.formModel.report = "DSCMO";
      this.formModel.reportName = "Daily SCMO";
    }
  }

  setToDefaults() {
    var d = new Date();
    this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
    this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
    this.formModel.forMonth = new Date(d.getFullYear(), d.getMonth(), 1);
    this.mainCentreDetails = [];
    this.childCentreDetails = [];
    this.subCentreDetails = [];
       
    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };

    var i =0;
    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.mainCentreDetails.push(this.selectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.childCentreDetails.push(this.selectedChildCentreObj);
        this.subCentreDetails.push(this.selectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }      

  }

  showSpinner() {
    return;  
    this.spinner.show();
    setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
    }, 3000);
  }

  onEditClicked(editOption) {
    this.dataSharingService.setEditOfferOptionClicked(editOption);
  }

  onChangeMainCentre(mainCentre) {
    do {
      this.childCentreDetails = [];
      this.childCentreDetails.push(this.allTextChildCentreObj);
      this.selectedChildCentreObj = this.allTextChildCentreObj;

      for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
    } while (false);

  }

  onChangeChildCentre(childCentre) {
    do {
      this.subCentreDetails = [];
      this.subCentreDetails.push(this.allTextSubCentreObj);
      this.selectedSubCentreObj = this.allTextSubCentreObj;
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
    } while (false);
  }

  popuplateDailySCMO(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    var MA = "";
    var MY = "";
    var FA = "";
    var FY = "";
    var adult = "";
    var gender = "";
    var iMaleAdultCount = 0
    var igMaleAdultCount = 0;
    var iMaleYouthCount = 0;
    var igMaleYouthCount = 0;
    var iFeMaleAdultCount = 0
    var igFeMaleAdultCount = 0;
    var iFeMaleYouthCount = 0;
    var igFeMaleYouthCount = 0;
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseTotal = 0;
    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotal = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotal = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    var rTotal = 0

    for (var i = 0; i < tmpList.length; i++) {
      var memberCentre = tmpList[i].mainCentre.substr(0, 3);
      if (tmpList[i].childCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
      }
      if (tmpList[i].subCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
      }
      var processing = tmpList[i].offerMainCentre.substr(0, 3);
      if (tmpList[i].offerChildCentre != "") {
        processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
      }
      if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
        processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
      }
      MA = "";
      MY = "";
      FA = "";
      FY = "";
      adult = tmpList[i].isAdultYN;
      gender = tmpList[i].gender;
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);
      rTotal = rScmo + rCentreM + rCountryM;
      var tmp = {
        SlipNo: tmpList[i].slipNo,
        SrNo: i + 1,
        otherOfferingID: tmpList[i].otherOfferingID,      
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        MA: MA,
        FA: FA,
        MY: MY,
        FY: FY,
        SCMO: rScmo,
        SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
        CountryM: rCountryM,
        CentreM: rCentreM,
        Total: rTotal,
        ChequeNumber: tmpList[i].chequeNo,
        ChequeDate: tmpList[i].chequeDate,
        BankName: tmpList[i].bankName,
        BankBranchName: tmpList[i].bankBranchName,
      }
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;

      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        dateWiseScmo = dateWiseScmo + rScmo;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;

        dateWiseTotal = dateWiseScmo + dateWiseCountryM + dateWiseCentreM;
        iCount = iCount + 1;
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      } else {
        var dateChangedObj = {
          SlipNo: "",
          SrNo: "",
          OfferingDate: "",
          MemberName: iCount,
          MA: iMaleAdultCount,
          FA: iFeMaleAdultCount,
          MY: iMaleYouthCount,
          FY: iFeMaleYouthCount,
          SCMO: dateWiseScmo,
          SCMOMonth: "",
          CountryM: dateWiseCountryM,
          CentreM: dateWiseCentreM,
          Total: dateWiseTotal,
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName: ""
        }

        tmpArray.push(dateChangedObj);
        var dateChangedObjCnt = {
          SlipNo: "",
          SrNo: "",
          otherOfferingID: "",      
          OfferingDate: "",
          MemberName: "",
          MA: "",
          FA: "",
          MY: "",
          FY: "",
          Status: "",
          SCMO: dateWiseScmoCnt,
          SCMOMonth: "",
          CountryM: dateWiseCountryMCnt,
          CentreM: dateWiseCentreMCnt,
          Total: "",
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName: "",
        }

        tmpArray.push(dateChangedObjCnt);

        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseTotal = parseInt(tmpList[i].total);
        dateWiseScmo = rScmo;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;

        iMaleAdultCount = 0;
        iFeMaleAdultCount = 0;
        iMaleYouthCount = 0;
        iFeMaleYouthCount = 0;
        iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (parseInt(tmpList[i].countryM) > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (parseInt(tmpList[i].centreM) > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (parseInt(tmpList[i].scmo) > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      }
    }
    var lastRecObj = {
      SlipNo: "",
      SrNo: "",
      otherOfferingID: "",      
      OfferingDate: "",
      MemberName: iCount,
      MA: iMaleAdultCount,
      FA: iFeMaleAdultCount,
      MY: iMaleYouthCount,
      FY: iFeMaleYouthCount,
      SCMO: dateWiseScmo,
      SCMOMonth: "",
      CountryM: dateWiseCountryM,
      CentreM: dateWiseCentreM,
      Total: dateWiseTotal,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: "",
    }

    tmpArray.push(lastRecObj);

    var lastRecObjCnt = {
      SlipNo: "",
      SrNo: "",
      otherOfferingID: "",      
      OfferingDate: "",
      MemberName: "",
      MA: "",
      FA: "",
      MY: "",
      FY: "",
      SCMO: dateWiseScmoCnt,
      SCMOMonth: "",
      CountryM: dateWiseCountryMCnt,
      CentreM: dateWiseCentreMCnt,
      Total: "",
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: "",
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SlipNo: "",
      SrNo: "",
      otherOfferingID: "",      
      OfferingDate: "",
      MemberName: "Grand Total",
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount,
      Status: "",
      SCMO: gTotalScmo,
      SCMOMonth: "",
      CountryM: gTotalCountryM,
      CentreM: gTotalCentreM,
      Total: gTotalScmo + gTotalCountryM + gTotalCentreM ,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: ""
    }

    tmpArray.push(grandTotalObj);
    var grandTotalCntObj = {
      SlipNo: "",
      SrNo: "",
      otherOfferingID: "",      
      OfferingDate: "",
      MemberName: "",
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount,
      Status: "",
      SCMO: gTotalScmoCnt,
      SCMOMonth:"",
      CountryM: gTotalCountryMCnt,
      CentreM: gTotalCentreMCnt,
      Total: gTotalScmoCnt + gTotalCountryMCnt + gTotalCentreMCnt ,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: ""
    }
    tmpArray.push(grandTotalCntObj);
    this.otherOfferingDailySCMO = tmpArray;
    this.dataSharingService.setDailyOfferingSCMOData(tmpSearched, tmpArray);
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  popuplateOtherOffering(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    var MA = "";
    var MY = "";
    var FA = "";
    var FY = "";
    var adult = "";
    var gender = "";
    var iMaleAdultCount = 0
    var igMaleAdultCount = 0;
    var iMaleYouthCount = 0;
    var igMaleYouthCount = 0;
    var iFeMaleAdultCount = 0
    var igFeMaleAdultCount = 0;
    var iFeMaleYouthCount = 0;
    var igFeMaleYouthCount = 0;
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt= 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var gTotal = 0;
    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    for (var i = 0; i < tmpList.length; i++) {

      if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash" ) {
          if (chqEmpty == false ) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }
/*
      var memberCentre = tmpList[i].mainCentre.substr(0, 3);
      if (tmpList[i].childCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
      }
      if (tmpList[i].subCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
      }
      var processing = tmpList[i].offerMainCentre.substr(0, 3);
      if (tmpList[i].offerChildCentre != "") {
        processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
      }
      if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
        processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
      }
*/
      var memberCentre = tmpList[i].mainCentre + "/" + tmpList[i].childCentre  + "/" + tmpList[i].subCentre;
      
      var processing = tmpList[i].offerMainCentre + "/" + tmpList[i].offerChildCentre + "/" + tmpList[i].offerSubCentre;

      MA = "";
      MY = "";
      FA = "";
      FY = "";
      adult = tmpList[i].isAdultYN;
      gender = tmpList[i].gender;
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      var tmp = {
        SrNo: i + 1,
        otherOfferingID: tmpList[i].otherOfferingID,
        ProcessingDate: tmpList[i].processingDate,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        Actual : memberCentre,
        Processing : processing,
        SlipNo: tmpList[i].slipNo,
        MA: MA,
        FA: FA,
        MY: MY,
        FY: FY,
        Status: tmpList[i].status,
        SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
        SCMO: rScmo,
        CountryM: rCountryM,
        CentreM: rCentreM,
        Otamagushi: rOtamagushi,
        Okiyome: rOkiyome,
        Goshintai: rGoshintai,
        Ancestors: rAncestors,
        Omitama: rOmitama,
        Goshugo: rGoshugo,
        Other: rOther,
        Total: tmpList[i].total,
        ChequeNumber: tmpList[i].chequeNo,
        ChequeDate: tmpList[i].chequeDate,
        BankName: tmpList[i].bankName,
        BankBranchName : tmpList[i].bankBranchName,
      }
      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi;
      gTotalOkiyome = gTotalOkiyome + rOkiyome;
      gTotalGoshintai = gTotalGoshintai + rGoshintai;
      gTotalAncestors = gTotalAncestors + rAncestors;
      gTotalOmitama = gTotalOmitama + rOmitama;
      gTotalGoshugo = gTotalGoshugo + rGoshugo;
      gTotalOther = gTotalOther + rOther;
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;
      gTotal = gTotal + parseInt(tmpList[i].total);

      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (rCountryM  > 0 ) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt +1;
        }
        if (rCentreM > 0){
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo  > 0 ) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi  > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome  > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai  > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors  > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt +1 ;
        }
        if (rOmitama  > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo  > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther  > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi ;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome ;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors ;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo ;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseScmo = dateWiseScmo + rScmo ;
        dateWiseCountryM = dateWiseCountryM + rCountryM ;
        dateWiseCentreM = dateWiseCentreM + rCentreM ;

        dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);
        iCount = iCount + 1;
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      } else {
        var dateChangedObj = {
          SrNo: "",
          otherOfferingID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: iCount,
          Actual : "",
          Processing : "",
          SlipNo: "",
          MA: iMaleAdultCount,
          FA: iFeMaleAdultCount,
          MY: iMaleYouthCount,
          FY: iFeMaleYouthCount,
          Status: "",
          SCMOMonth: "",
          SCMO: dateWiseScmo,
          CountryM: dateWiseCountryM,
          CentreM: dateWiseCentreM,
          Otamagushi: dateWiseOtamagushi,
          Okiyome: dateWiseOkiyome,
          Goshintai: dateWiseGoshintai,
          Ancestors: dateWiseAncestors,
          Omitama: dateWiseOmitama,
          Goshugo: dateWiseGoshugo,
          Other: dateWiseOther,
          Total: dateWiseTotal,
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName:""
        }

        tmpArray.push(dateChangedObj);
        var dateChangedObjCnt = {
          SrNo: "",
          otherOfferingID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: "",
          Actual : "",
          Processing : "",
          SlipNo: "",
          MA: "",
          FA: "",
          MY: "",
          FY: "",
          Status: "",
          SCMOMonth: "",
          SCMO: dateWiseScmoCnt,
          CountryM: dateWiseCountryMCnt,
          CentreM: dateWiseCentreMCnt,
          Otamagushi: dateWiseOtamagushiCnt,
          Okiyome: dateWiseOkiyomeCnt,
          Goshintai: dateWiseGoshintaiCnt,
          Ancestors: dateWiseAncestorsCnt,
          Omitama: dateWiseOmitamaCnt,
          Goshugo: dateWiseGoshugoCnt,
          Other: dateWiseOtherCnt,
          Total: "",
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName : "",
        }

        tmpArray.push(dateChangedObjCnt);

        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi ;
        dateWiseOkiyome = rOkiyome ;
        dateWiseGoshintai = rGoshintai ;
        dateWiseAncestors = rAncestors ;
        dateWiseOmitama = rOmitama ;
        dateWiseGoshugo = rGoshugo ;
        dateWiseOther = rOther ;
        dateWiseTotal = parseInt(tmpList[i].total);
        dateWiseScmo =  rScmo ;
        dateWiseCountryM =  rCountryM ;
        dateWiseCentreM =  rCentreM ;

        iMaleAdultCount = 0;
        iFeMaleAdultCount = 0;
        iMaleYouthCount = 0;
        iFeMaleYouthCount = 0;
        iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (parseInt(tmpList[i].countryM ) > 0 ) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt +1;
        }
        if (parseInt(tmpList[i].centreM) > 0){
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (parseInt(tmpList[i].scmo) > 0 ) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (parseInt(tmpList[i].otamagushi) > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (parseInt(tmpList[i].okiyome) > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (parseInt(tmpList[i].goshintai) > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (parseInt(tmpList[i].ancestors) > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt +1 ;
        }
        if (parseInt(tmpList[i].omitama) > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (parseInt(tmpList[i].goshugo) > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (parseInt(tmpList[i].other) > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      }
    }
    var lastRecObj = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: iCount,
      Actual : "",
      Processing : "",
      SlipNo: "",
      MA: iMaleAdultCount,
      FA: iFeMaleAdultCount,
      MY: iMaleYouthCount,
      FY: iFeMaleYouthCount,
      Status: "",
      SCMOMonth: "",
      SCMO: dateWiseScmo,
      CountryM: dateWiseCountryM,
      CentreM: dateWiseCentreM,
      Otamagushi: dateWiseOtamagushi,
      Okiyome: dateWiseOkiyome,
      Goshintai: dateWiseGoshintai,
      Ancestors: dateWiseAncestors,
      Omitama: dateWiseOmitama,
      Goshugo: dateWiseGoshugo,
      Other: dateWiseOther,
      Total: dateWiseTotal,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : "",
    }

    tmpArray.push(lastRecObj);

    var lastRecObjCnt = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "",
      Actual :"",
      Processing : "",
      SlipNo: "",
      MA: "",
      FA: "",
      MY: "",
      FY: "",
      Status: "",
      SCMOMonth: "",
      SCMO: dateWiseScmoCnt,
      CountryM: dateWiseCountryMCnt,
      CentreM: dateWiseCentreMCnt,
      Otamagushi: dateWiseOtamagushiCnt,
      Okiyome: dateWiseOkiyomeCnt,
      Goshintai: dateWiseGoshintaiCnt,
      Ancestors: dateWiseAncestorsCnt,
      Omitama: dateWiseOmitamaCnt,
      Goshugo: dateWiseGoshugoCnt,
      Other: dateWiseOtherCnt,
      Total: "",
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : "",
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      Actual : "",
      Processing : "",
      SlipNo: "",
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount,
      Status: "",
      SCMOMonth: "",
      SCMO: gTotalScmo,
      CountryM: gTotalCountryM,
      CentreM: gTotalCentreM,
      Otamagushi: gTotalOtamagushi,
      Okiyome: gTotalOkiyome,
      Goshintai: gTotalGoshintai,
      Ancestors: gTotalAncestors,
      Omitama: gTotalOmitama,
      Goshugo: gTotalGoshugo,
      Other: gTotalOther,
      Total: gTotal,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : ""
    }

    tmpArray.push(grandTotalObj);
    var grandTotalCntObj = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "",
      Actual : "",
      Processing : "",
      SlipNo: "",
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount,
      Status: "",
      SCMOMonth: "",
      SCMO: gTotalScmoCnt,
      CountryM: gTotalCountryMCnt,
      CentreM: gTotalCentreMCnt,
      Otamagushi: gTotalOtamagushiCnt,
      Okiyome: gTotalOkiyomeCnt,
      Goshintai: gTotalGoshintaiCnt,
      Ancestors: gTotalAncestorsCnt,
      Omitama: gTotalOmitamaCnt,
      Goshugo: gTotalGoshugoCnt,
      Other: gTotalOtherCnt,
      Total: gTotalScmoCnt + gTotalCountryMCnt +gTotalCentreMCnt + gTotalOtamagushiCnt +
             gTotalOkiyomeCnt + gTotalGoshintaiCnt + gTotalAncestorsCnt + gTotalOmitamaCnt + gTotalGoshugoCnt + gTotalOtherCnt,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : ""
    }
    tmpArray.push(grandTotalCntObj);
    this.otherOffering = tmpArray;
    this.dataSharingService.setSearchedOtherOfferingData(tmpSearched, tmpArray);
  }

  popuplateOtherOfferingMembers(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var totalAmt = 0;
    var memberName = tmpList[0].memberName;
    var dateWiseTot = 0;
    var dateWiseSCMO = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var totalOtamagushi = 0;
    var totalOkiyome = 0;
    var totalGoshintai = 0;
    var totalAncestors = 0;
    var totalOmitama = 0;
    var totalGoshugo = 0;
    var totalOther = 0;
    var totalSCMO = 0;
    var totalCountryM = 0;
    var totalCentreM = 0;
    var iCount = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;

    for (var i = 0; i < tmpList.length; i++) {
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      if (tmpList[i].memberName == memberName) {
        var memberCentre = tmpList[i].mainCentre.substr(0, 3);
        if (tmpList[i].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
        }
        if (tmpList[i].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
        }
        var processing = tmpList[i].offerMainCentre.substr(0, 3);
        if (tmpList[i].offerChildCentre != "") {
          processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
        }
        if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
          processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
        }
        dateWiseTot = dateWiseTot + parseInt(tmpList[i].total);
        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;
        dateWiseSCMO = dateWiseSCMO + rScmo; 
        var tmp = {
          SrNo: i + 1,
          otherOfferingID: tmpList[i].otherOfferingID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          Actual : memberCentre,
          Processing : processing,
          SlipNo: tmpList[i].slipNo,
          Status: tmpList[i].status,
          SCMO: rScmo, 
          CountryM :rCountryM,
          CentreM :rCentreM,
          Otamagushi: rOtamagushi,
          Okiyome: rOkiyome,
          Goshintai: rGoshintai,
          Ancestors: rAncestors,
          Omitama: rOmitama,
          Goshugo: rGoshugo,
          Other: rOther,
          Total: tmpList[i].total,
          ChequeNumber: tmpList[i].chequeNo,
          ChequeDate: tmpList[i].chequeDate,
          BankName: tmpList[i].bankName,
          BankBranchName : tmpList[i].bankBranchName
        }
        iCount = iCount + 1;
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      } else {
        var dummy = {
          SrNo: "",
          otherOfferingID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: iCount,
          Actual : "",
          Processing : "",
          SlipNo: "",
          Status: "",
          SCMO: dateWiseSCMO,
          CountryM: dateWiseCountryM,
          CentreM: dateWiseCentreM,
          Otamagushi: dateWiseOtamagushi,
          Okiyome: dateWiseOkiyome,
          Goshintai: dateWiseGoshintai,
          Ancestors: dateWiseAncestors,
          Omitama: dateWiseOmitama,
          Goshugo: dateWiseGoshugo,
          Other: dateWiseOther,
          Total: dateWiseTot,
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName : ""
        }
        tmpArray.push(dummy);
        iCount = 1;
         memberCentre = tmpList[i].mainCentre.substr(0, 3);
        if (tmpList[i].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
        }
        if (tmpList[i].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
        }
         processing = tmpList[i].offerMainCentre.substr(0, 3);
        if (tmpList[i].offerChildCentre != "") {
          processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
        }
        if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
          processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
        }
        memberName = tmpList[i].memberName;
        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo;
        dateWiseOther = rOther;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;
        dateWiseSCMO = rScmo; 
        var tmp1 = {
          SrNo: i + 1,
          otherOfferingID: tmpList[i].otherOfferingID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          Actual :memberCentre,
          Processing : processing,
          SlipNo: tmpList[i].slipNo,
          Status: tmpList[i].status,
          SCMO: rScmo,
          CountryM: rCountryM,
          CentreM: rCentreM,
          Otamagushi: rOtamagushi,
          Okiyome: rOkiyome,
          Goshintai: rGoshintai,
          Ancestors: rAncestors,
          Omitama: rOmitama,
          Goshugo: rGoshugo,
          Other: rOther,
          Total: tmpList[i].total,
          ChequeNumber: tmpList[i].chequeNo,
          ChequeDate: tmpList[i].chequeDate,
          BankName: tmpList[i].bankBranchName
        }
        tmpArray.push(tmp1);
        tmpSearched.push(tmp1);
      }
      totalAmt = totalAmt + parseInt(tmpList[i].total);
      totalOtamagushi = totalOtamagushi + rOtamagushi;
      totalOkiyome = totalOkiyome + rOkiyome;
      totalGoshintai = totalGoshintai + rGoshintai;
      totalAncestors = totalAncestors + rAncestors;
      totalOmitama = totalOmitama + rOmitama;
      totalGoshugo = totalGoshugo + rGoshugo;
      totalOther = totalOther + rOther;
      totalSCMO = totalSCMO + rScmo;
      totalCountryM = totalCountryM + rCountryM;
      totalCentreM = totalCentreM + rCentreM;
    }//end for
    var dummy1 = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: iCount,
      Actual : "",
      Processing : "",
      SlipNo: "",
      Status: "",
      SCMO: dateWiseSCMO,
      CountryM: dateWiseCountryM,
      CentreM: dateWiseCentreM,
      Otamagushi: dateWiseOtamagushi,
      Okiyome: dateWiseOkiyome,
      Goshintai: dateWiseGoshintai,
      Ancestors: dateWiseAncestors,
      Omitama: dateWiseOmitama,
      Goshugo: dateWiseGoshugo,
      Other: dateWiseOther,
      Total: dateWiseTot,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : ""
    }
    tmpArray.push(dummy1);
    this.formModel.totalAmt = totalAmt;
    var dummy2 = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      Actual : "",
      Processing : "",
      SlipNo: "",
      Status: "",
      SCMO: totalSCMO,
      CountryM: totalCountryM,
      CentreM: totalCentreM,
      Otamagushi: totalOtamagushi,
      Okiyome: totalOkiyome,
      Goshintai: totalGoshintai,
      Ancestors: totalAncestors,
      Omitama: totalOmitama,
      Goshugo: totalGoshugo,
      Other: totalOther,
      Total: totalAmt,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : ""
    }
    tmpArray.push(dummy2);
    this.otherOfferingMembers = tmpArray;
    this.dataSharingService.setSearchedOtherOfferingMemberData(tmpSearched, tmpArray);
  }

  popuplateMembers(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var totalAmt = 0;
    var memberName = tmpList[0].memberName;
    var dateWiseTot = 0;
    var dateWiseSCMO = 0;
    var dateWiseCountry = 0;
    var dateWiseCenter = 0;
    var totalSCMO = 0;
    var totalCountry = 0;
    var totalCenter = 0;
    var iCount = 0;
    for (var i = 0; i < tmpList.length; i++) {
      if ( tmpList[i].memberName == memberName) {
        dateWiseTot = dateWiseTot + parseInt(tmpList[i].total);
        dateWiseSCMO = dateWiseSCMO + parseInt(tmpList[i].scmo);
        dateWiseCountry = dateWiseCountry + parseInt(tmpList[i].countryM);
        dateWiseCenter = dateWiseCenter + parseInt(tmpList[i].centreM);
        var tmp = {
          SrNo: i + 1,
          offerID: tmpList[i].offerID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          SCMOAmount: tmpList[i].scmo,
          Status: tmpList[i].status,
          SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
          CountryM: tmpList[i].countryM,
          CenterM: tmpList[i].centreM,
          Total: tmpList[i].total,
          ChequeNumber: tmpList[i].chequeNo,
          ChequeDate: tmpList[i].chequeDate,
          BankName: tmpList[i].bankName,
          BankBranchName: tmpList[i].bankBranchName
        }
        iCount = iCount + 1;
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      } else {
        var dummy = {
          SrNo: "",
          offerID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: iCount,
          SCMOAmount: dateWiseSCMO,
          Status: "",
          SCMOMonth: "",
          CountryM: dateWiseCountry,
          CenterM: dateWiseCenter,
          Total: dateWiseTot,
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName : "",
        }
        tmpArray.push(dummy);
        iCount = 1;
        memberName = tmpList[i].memberName;
        dateWiseTot = parseInt(tmpList[i].total);
        dateWiseSCMO = parseInt(tmpList[i].scmo);
        dateWiseCountry = parseInt(tmpList[i].countryM);
        dateWiseCenter = parseInt(tmpList[i].centreM);
        var tmp1 = {
          SrNo: i + 1,
          offerID: tmpList[i].offerID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          SCMOAmount: tmpList[i].scmo,
          Status: tmpList[i].status,
          SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
          CountryM: tmpList[i].countryM,
          CenterM: tmpList[i].centreM,
          Total: tmpList[i].total,
          ChequeNumber: tmpList[i].chequeNo,
          ChequeDate: tmpList[i].chequeDate,
          BankName: tmpList[i].bankName,
          BankBranchName :tmpList[i].bankBranchName
        }
        tmpArray.push(tmp1);
        tmpSearched.push(tmp1);
      }
      totalAmt = totalAmt + parseInt(tmpList[i].total);
      totalSCMO = totalSCMO + parseInt(tmpList[i].scmo);
      totalCountry = totalCountry + parseInt(tmpList[i].countryM);
      totalCenter = totalCenter + parseInt(tmpList[i].centreM);
    }
    var dummy1 = {
      SrNo: "",
      offerID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: iCount,
      SCMOAmount: dateWiseSCMO,
      Status: "",
      SCMOMonth: "",
      CountryM: dateWiseCountry,
      CenterM: dateWiseCenter,
      Total: dateWiseTot,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName :""
    }
    tmpArray.push(dummy1);
    this.formModel.totalAmt = totalAmt;
    var dummy2 = {
      SrNo: "",
      offerID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      SCMOAmount: totalSCMO,
      Status: "",
      SCMOMonth: "",
      CountryM: totalCountry,
      CenterM: totalCenter,
      Total: totalAmt,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : "",
    }
    tmpArray.push(dummy2);
    this.offersMembers = tmpArray;
    this.dataSharingService.setSearchedOfferMemberData(tmpSearched, tmpArray);
  }

  popuplateOffering(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    var MA = "";
    var MY = "";
    var FA = "";
    var FY = "";
    var adult = "";
    var gender = "";
    var iMaleAdultCount = 0
    var igMaleAdultCount = 0;
    var iMaleYouthCount = 0;
    var igMaleYouthCount = 0;
    var iFeMaleAdultCount = 0
    var igFeMaleAdultCount = 0;
    var iFeMaleYouthCount = 0;
    var igFeMaleYouthCount = 0;
    var iCount = 0;
    var dateWiseSCMO = 0;
    var dateWiseCountry = 0;
    var dateWiseCentreM = 0;
    var dateWiseTotal = 0;
    var gTotalSCMO = 0;
    var gTotalCountry = 0;
    var gTotalCentreM = 0;
    var gTotal = 0;
    var dateWiseCountryCnt = 0;
    var dateWiseCentreCnt = 0;
    var dateWiseSCMOCnt = 0;

    for (var i = 0; i < tmpList.length; i++) {
      MA = "";
      MY = "";
      FA = "";
      FY = "";
      adult = tmpList[i].isAdultYN;
      gender = tmpList[i].gender;
      var tmp = {
        SrNo: i + 1,
        offerID: tmpList[i].offerID,
        ProcessingDate: tmpList[i].processingDate,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        MA: MA,
        FA: FA,
        MY: MY,
        FY: FY,
        SCMOAmount: tmpList[i].scmo,
        Status: tmpList[i].status,
        SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
        CountryM: tmpList[i].countryM,
        CenterM: tmpList[i].centreM,
        Total: tmpList[i].total,
        ChequeNumber: tmpList[i].chequeNo,
        ChequeDate: tmpList[i].chequeDate,
        BankName: tmpList[i].bankName,
        BankBranchName :tmpList[i].bankBranchName
      }
      gTotalSCMO = gTotalSCMO + parseInt(tmpList[i].scmo);
      gTotalCountry = gTotalCountry + parseInt(tmpList[i].countryM);
      gTotalCentreM = gTotalCentreM + parseInt(tmpList[i].centreM);
      gTotal = gTotal + parseInt(tmpList[i].total);

      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (parseInt(tmpList[i].countryM) > 0) {
          dateWiseCountryCnt = dateWiseCountryCnt + 1;
        }
        if (parseInt(tmpList[i].centreM) > 0) {
          dateWiseCentreCnt = dateWiseCentreCnt + 1;
        }
        if (parseInt(tmpList[i].scmo)> 0 ) {
          dateWiseSCMOCnt = dateWiseSCMOCnt + 1;
        }

        dateWiseSCMO = dateWiseSCMO + parseInt(tmpList[i].scmo);
        dateWiseCountry = dateWiseCountry + parseInt(tmpList[i].countryM);;
        dateWiseCentreM = dateWiseCentreM + parseInt(tmpList[i].centreM);
        dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);
        iCount = iCount + 1;
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      } else {
        var dateChangedObj = {
          SrNo: "",
          offerID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: "Date Wise Records : " + iCount,
          MA: iMaleAdultCount,
          FA: iFeMaleAdultCount,
          MY: iMaleYouthCount,
          FY: iFeMaleYouthCount,
          SCMOAmount: dateWiseSCMO,
          Status: "",
          SCMOMonth: "",
          CountryM: dateWiseCountry,
          CenterM: dateWiseCentreM,
          Total: dateWiseTotal,
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName : "",
        }

        tmpArray.push(dateChangedObj);
        var dateChangedObjCnt = {
          SrNo: "",
          offerID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: "Count : SCMO, CountryM and CentreM",
          MA: "",
          FA: "",
          MY: "",
          FY: "",
          SCMOAmount: dateWiseSCMOCnt,
          Status: "",
          SCMOMonth: "",
          CountryM: dateWiseCountryCnt,
          CenterM: dateWiseCentreCnt,
          Total: "",
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName : ""
        }
        tmpArray.push(dateChangedObjCnt);
        //Reset
        dateWiseCountryCnt = 0;
        dateWiseCentreCnt = 0;
        dateWiseSCMOCnt = 0;
        dateWiseSCMO =  parseInt(tmpList[i].scmo);
        dateWiseCountry = parseInt(tmpList[i].countryM);;
        dateWiseCentreM = parseInt(tmpList[i].centreM);
        dateWiseTotal = parseInt(tmpList[i].total);
        iMaleAdultCount = 0;
        iFeMaleAdultCount = 0;
        iMaleYouthCount = 0;
        iFeMaleYouthCount = 0;
        iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (parseInt(tmpList[i].countryM) > 0) {
          dateWiseCountryCnt = dateWiseCountryCnt + 1;
        }
        if (parseInt(tmpList[i].centreM) > 0) {
          dateWiseCentreCnt = dateWiseCentreCnt + 1;
        }
        if (parseInt(tmpList[i].scmo) > 0) {
          dateWiseSCMOCnt = dateWiseSCMOCnt + 1;
        }
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      }
    }//end for
    var lastRecObj = {
      SrNo: "",
      offerID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Date Wise Records : " + iCount,
      MA: iMaleAdultCount,
      FA: iFeMaleAdultCount,
      MY: iMaleYouthCount,
      FY: iFeMaleYouthCount,
      SCMOAmount: dateWiseSCMO,
      Status: "",
      SCMOMonth: "",
      CountryM: dateWiseCountry,
      CenterM: dateWiseCentreM,
      Total: dateWiseTotal,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : ""
    }
    tmpArray.push(lastRecObj);
    var lastRecObjCnt = {
      SrNo: "",
      offerID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Count : CountryM / CentreM",
      MA: "",
      FA: "",
      MY: "",
      FY: "",
      SCMOAmount: dateWiseSCMOCnt,
      Status: "",
      SCMOMonth: "",
      CountryM: dateWiseCountryCnt,
      CenterM: dateWiseCentreCnt,
      Total: "",
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : "",
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "",
      offerID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount,
      SCMOAmount: gTotalSCMO,
      Status: "",
      SCMOMonth: "",
      CountryM: gTotalCountry,
      CenterM: gTotalCentreM,
      Total: gTotal,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName : "",
    }

    tmpArray.push(grandTotalObj);
    this.offers = tmpArray;
    this.dataSharingService.setSearchedOfferData(tmpSearched, tmpArray);
  }

/************************************DIVINE PROTECTION REPORT ********************* */
  popuplateDivineProtection(data) {
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    var iCount = 0;
    var gTotalGoshugo = 0;
    var dateWiseGoshugo= 0;
    for (var i = 0; i < tmpList.length; i++) {
      iCount = iCount + 1;
      gTotalGoshugo = gTotalGoshugo + parseInt(tmpList[i].goshugo);
      var tmp = {
        SrNo: iCount,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        Goshugo: tmpList[i].goshugo,
        Total: ""
      }
      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        dateWiseGoshugo = dateWiseGoshugo + parseInt(tmpList[i].goshugo);
        tmpArray.push(tmp);
      } else {
        var dateChangedObj = {
          SrNo: "",
          OfferingDate: "",
          MemberName: "",// "Date Wise Records : " + iCount,
          Goshugo: "",//"Date Wise Total : "+ dateWiseGoshugo,
          Total: dateWiseGoshugo
        }
        tmpArray.push(dateChangedObj);
        tmpArray.push(tmp);
        //Reset
        dateWiseGoshugo = parseInt(tmpList[i].goshugo);
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
      }
    }
    var lastRecObj = {
      SrNo: "",
      OfferingDate: "",
      MemberName: "", 
      Goshugo: "",
      Total : dateWiseGoshugo,
    }
    tmpArray.push(lastRecObj);
    var grandTotalObj = {
      SrNo: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      Goshugo: "",
      Total: gTotalGoshugo,
    }
    tmpArray.push(grandTotalObj);
    this.divineProtection = tmpArray;
  }
 
  populateNewOfferingCountData(data) {
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
  
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;
    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    for (var i = 0; i < tmpList.length; i++) {
      if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash") {
          if (chqEmpty == false) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi ;
      gTotalOkiyome = gTotalOkiyome + rOkiyome ;
      gTotalGoshintai = gTotalGoshintai + rGoshintai ;
      gTotalAncestors = gTotalAncestors + rAncestors ;
      gTotalOmitama = gTotalOmitama + rOmitama ;
      gTotalGoshugo = gTotalGoshugo + rGoshugo ;
      gTotalOther = gTotalOther + rOther ;
      gTotalScmo = gTotalScmo + rScmo ;
      gTotalCentreM = gTotalCentreM + rCentreM ;
      gTotalCountryM = gTotalCountryM + rCountryM;
      gTotal = gTotal + parseInt(tmpList[i].total);

      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        if (rCountryM  > 0 ) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt +1;
        }
        if (rCentreM  > 0){
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt =gTotalCentreMCnt + 1;
        }
        if (rScmo  > 0 ) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt +1;
        }
        if (rOtamagushi  > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome  > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai  > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors  > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama  > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo  > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther  > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi ;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome ;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai ;
        dateWiseAncestors = dateWiseAncestors + rAncestors ;
        dateWiseOmitama = dateWiseOmitama + rOmitama ;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo ;
        dateWiseOther = dateWiseOther + rOther ;
        dateWiseScmo = dateWiseScmo + rScmo ;
        dateWiseCountryM = dateWiseCountryM + rCountryM ;
        dateWiseCentreM = dateWiseCentreM + rCentreM ;

        dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);
        //iCount = iCount + 1;
        //tmpArray.push(tmp);
      //  tmpSearched.push(tmp);
      } else {
        var dateChangedObj = {
          SrNo: iCount+1,
          OfferingDate: offerDate,
          SCMO: dateWiseScmo,
          SCMO_cnt: dateWiseScmoCnt,
          CountryM: dateWiseCountryM,
          CountryM_cnt: dateWiseCountryMCnt,
          CentreM: dateWiseCentreM,
          CentreM_cnt: dateWiseCentreMCnt,
          Otamagushi: dateWiseOtamagushi,
          Otamagushi_cnt: dateWiseOtamagushiCnt,
          Okiyome: dateWiseOkiyome,
          Okiyome_cnt: dateWiseOkiyomeCnt,
          Goshintai: dateWiseGoshintai,
          Goshintai_cnt: dateWiseGoshintaiCnt,
          Ancestors: dateWiseAncestors,
          Ancestors_cnt: dateWiseAncestorsCnt,
          Omitama: dateWiseOmitama,
          Omitama_cnt: dateWiseOmitamaCnt,
          Goshugo: dateWiseGoshugo,
          Goshugo_cnt: dateWiseGoshugoCnt,
          Other: dateWiseOther,
          Other_cnt: dateWiseOtherCnt,
          Total: dateWiseTotal,
          
        }

        
        tmpArray.push(dateChangedObj);
        iCount = iCount+1;
        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi ;
        dateWiseOkiyome = rOkiyome ;
        dateWiseGoshintai = rGoshintai ;
        dateWiseAncestors = rAncestors ;
        dateWiseOmitama = rOmitama ;
        dateWiseGoshugo = rGoshugo ;
        dateWiseOther = rOther ;
        dateWiseTotal = parseInt(tmpList[i].total);
        dateWiseScmo =  rScmo;
        dateWiseCountryM =  rCountryM;
        dateWiseCentreM =  rCentreM ;

       // iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
        
        if (rCountryM  > 0 ) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt +1;
        }
        if (rCentreM  > 0){
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt =gTotalCentreMCnt + 1;
        }
        if (rScmo  > 0 ) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt +1;
        }
        if (rOtamagushi  > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome  > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai  > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors  > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama  > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo  > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther  > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
      }
    }// for

    var lastRecObjCnt = {
      SrNo: iCount+1,
      OfferingDate: offerDate,
      SCMO: dateWiseScmo,
      SCMO_cnt: dateWiseScmoCnt,
      CountryM: dateWiseCountryM,
      CountryM_cnt: dateWiseCountryMCnt,
      CentreM: dateWiseCentreM,
      CentreM_cnt: dateWiseCentreMCnt,
      Otamagushi: dateWiseOtamagushi,
      Otamagushi_cnt: dateWiseOtamagushiCnt,
      Okiyome: dateWiseOkiyome,
      Okiyome_cnt: dateWiseOkiyomeCnt,
      Goshintai: dateWiseGoshintai,
      Goshintai_cnt: dateWiseGoshintaiCnt,
      Ancestors: dateWiseAncestors,
      Ancestors_cnt: dateWiseAncestorsCnt,
      Omitama: dateWiseOmitama,
      Omitama_cnt: dateWiseOmitamaCnt,
      Goshugo: dateWiseGoshugo,
      Goshugo_cnt: dateWiseGoshugoCnt,
      Other: dateWiseOther,
      Other_cnt: dateWiseOtherCnt,
      Total: dateWiseTotal,
    }
     tmpArray.push(lastRecObjCnt);

     var grandTotalObj = {
       SrNo: "",
       OfferingDate: "Grand Total",
       SCMO: gTotalScmo,
       SCMO_cnt: gTotalScmoCnt,
       CountryM: gTotalCountryM,
       CountryM_cnt: gTotalCountryMCnt,
       CentreM: gTotalCentreM,
       CentreM_cnt: gTotalCentreMCnt,
       Otamagushi: gTotalOtamagushi,
       Otamagushi_cnt: gTotalOtamagushiCnt,
       Okiyome: gTotalOkiyome,
       Okiyome_cnt: gTotalOkiyomeCnt,
       Goshintai: gTotalGoshintai,
       Goshintai_cnt: gTotalGoshintaiCnt,
       Ancestors: gTotalAncestors,
       Ancestors_cnt: gTotalAncestorsCnt,
       Omitama: gTotalOmitama,
       Omitama_cnt: gTotalOmitamaCnt,
       Goshugo: gTotalGoshugo,
       Goshugo_cnt: gTotalGoshugoCnt,
       Other: gTotalOther,
       Other_cnt: gTotalOtherCnt,
       Total: gTotal
     }

    tmpArray.push(grandTotalObj);
    this.datewiseOffering = tmpArray;
  }

/* SHIDOBU  populateSHIDOBU*/
  populateSHIDOBU(data) {
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var dummyTmpList = new Array();
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    var offerDateDD = pipe.transform(tmpList[0].offerDate, 'd');

    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var dateWiseCouCenTotal = 0;

    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var gTotal_1 = 0;
    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    var d = new Date(this.formModel.forMonth);
    var noOfDays = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    var mm = d.getMonth();
    var yy = d.getFullYear();
    for (var k = 1; k <= noOfDays; k++) {
      var bRecFound = false;
      var dateToCheck =""
      for (var i = 0; i < tmpList.length; i++) {
        dateToCheck  = pipe.transform(tmpList[i].offerDate, 'dd');
        if (k == parseInt(dateToCheck)) {
          bRecFound = true;
          dummyTmpList.push(tmpList[i]);
          continue;
        }
      }
      if (bRecFound == true) {
        bRecFound = false;
        continue;
      }
      var offDt = new Date(yy , mm , k) ;
      var tmpobj = {
        aadhaarNo: "",
        aadhaarNumber: "",
        addUser: "",
        addUserDate: "",
        addUserTime: "",
        ancestors: 0,
        bankBranchName: "",
        bankName: "",
        centreM: 0,
        chequeDate: "",
        chequeNo: "",
        childCentre: "",
        countryM: 0,
        forMonth: "",
        gender: "",
        goshintai: 0,
        goshugo: 0,
        groupLeader: "",
        groupUser: "",
        isAdultYN: "",
        isMember: "",
        mainCentre: "",
        memberID: "",
        memberName: "",
        offerChildCentre: "",
        offerDate: pipe.transform(offDt, 'MM/dd/yyyy') ,
        offerMainCentre: "",
        offerSubCentre: "",
        offeringFor: "",
        okiyome: 0,
        omitama: 0,
        otamagushi: 0,
        other: 0,
        otherOfferingID: "",
        panNo: "",
        panNumber: "",
        processingDate: "",
        remarks: "",
        scmo: 0,
        slipNo: 0,
        status: "",
        subCentre: "",
        total: 0,
        updateUser: "",
        updateUserDate: "",
        updateUserTime: ""
      }
      dummyTmpList.push(tmpobj);
    }
    tmpList = dummyTmpList;
    offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    offerDateDD = pipe.transform(tmpList[0].offerDate, 'd');
    for (var i = 0; i < tmpList.length; i++) {
      /*if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash") {
          if (chqEmpty == false) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }*/
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi;
      gTotalOkiyome = gTotalOkiyome + rOkiyome;
      gTotalGoshintai = gTotalGoshintai + rGoshintai;
      gTotalAncestors = gTotalAncestors + rAncestors;
      gTotalOmitama = gTotalOmitama + rOmitama;
      gTotalGoshugo = gTotalGoshugo + rGoshugo;
      gTotalOther = gTotalOther + rOther;
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;
      //gTotal = gTotal + parseInt(tmpList[i].total);
      gTotal = gTotalOtamagushi + gTotalOkiyome + gTotalGoshintai + gTotalAncestors + gTotalOmitama + gTotalGoshugo + gTotalOther + gTotalScmo;
      gTotal_1 = gTotalCentreM + gTotalCountryM;
      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseScmo = dateWiseScmo + rScmo;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;
        //dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);

      } else {
        var dateChangedObj = {
          SrNo: iCount + 1,
          OfferingDate: offerDateDD,
          SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
          SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
          CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
          CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
          CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
          CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
          Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
          Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
          Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
          Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
          Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
          Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
          Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
          Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
          Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
          Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
          Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
          Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
          Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
          Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
          Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
          Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
        }
        tmpArray.push(dateChangedObj);
        iCount = iCount + 1;
        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo; 
        dateWiseOther = rOther;
        dateWiseScmo = rScmo;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;

        // iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
        offerDateDD = pipe.transform(tmpList[i].offerDate, 'd');

        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
      }
    }// for

    var lastRecObjCnt = {
      SrNo: iCount + 1,
      OfferingDate: offerDateDD,
      SCMO: ( (dateWiseScmo == 0) ? "" : dateWiseScmo),
      SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
      CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
      CountryM_cnt: ((dateWiseCountryMCnt == 0 )? "" : dateWiseCountryMCnt),
      CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
      CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "":  dateWiseCentreMCnt),
      Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
      Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
      Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
      Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "": dateWiseOkiyomeCnt),
      Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
      Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
      Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
      Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
      Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
      Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
      Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
      Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
      Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
      Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
      Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
      Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "Grand Total",
      OfferingDate: "",
      SCMO: ((gTotalScmo == 0) ? "" : gTotalScmo),
      SCMO_cnt: ((gTotalScmoCnt == 0) ? "" : gTotalScmoCnt),
      CountryM: ((gTotalCountryM == 0) ? "" : gTotalCountryM),
      CountryM_cnt: ((gTotalCountryMCnt == 0) ? "" : gTotalCountryMCnt),
      CentreM: ((gTotalCentreM == 0) ? "" : gTotalCentreM),
      CentreM_cnt: ((gTotalCentreMCnt == 0) ? "" : gTotalCentreMCnt),
      Otamagushi: ((gTotalOtamagushi == 0) ? "" : gTotalOtamagushi),
      Otamagushi_cnt: ((gTotalOtamagushiCnt == 0) ? "" : gTotalOtamagushiCnt),
      Okiyome: ((gTotalOkiyome == 0) ? "" : gTotalOkiyome),
      Okiyome_cnt: ((gTotalOkiyomeCnt == 0) ? "" : gTotalOkiyomeCnt),
      Goshintai: ((gTotalGoshintai == 0) ? "" : gTotalGoshintai),
      Goshintai_cnt: ((gTotalGoshintaiCnt == 0) ? "" : gTotalGoshintaiCnt),
      Ancestors: ((gTotalAncestors == 0) ? "" : gTotalAncestors),
      Ancestors_cnt: ((gTotalAncestorsCnt == 0) ? "" : gTotalAncestorsCnt),
      Omitama: ((gTotalOmitama == 0) ? "" : gTotalOmitama),
      Omitama_cnt: ((gTotalOmitamaCnt == 0) ? "" : gTotalOmitamaCnt),
      Goshugo: ((gTotalGoshugo == 0) ? "" : gTotalGoshugo),
      Goshugo_cnt: ((gTotalGoshugoCnt == 0) ? "" : gTotalGoshugoCnt),
      Other: ((gTotalOther == 0) ? "" : gTotalOther),
      Other_cnt: ((gTotalOtherCnt == 0) ? "" : gTotalOtherCnt),
      Total: ((gTotal == 0) ? "" : gTotal),
      Total_1: ((gTotal_1 == 0) ? "" : gTotal_1)
    }

    tmpArray.push(grandTotalObj);
    this.datewiseShidobu = tmpArray;
  }

/* MONTHWISESHIDOBU  populateSHIDOBUMONTHWISE */
  populateSHIDOBUMonthWise(data) {
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'MMM-yyyy');
    var offerDateDD = pipe.transform(tmpList[0].offerDate, 'MMM-yyyy');
    var centre = "";
    if (this.selectedMainCentreObj['mainCentreName'] == "All") { 
      centre = "All/All";
    } else if (this.selectedChildCentreObj['childCentreName'] == "All") {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/All" ;
    } else {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/" + tmpList[0].childCentre.substr(0, 3);
    }
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var dateWiseCouCenTotal = 0;

    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var gTotal_1 = 0;
    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    console.log(tmpList[0]);
    for (var i = 0; i < tmpList.length; i++) {
      if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash") {
          if (chqEmpty == false) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi;
      gTotalOkiyome = gTotalOkiyome + rOkiyome;
      gTotalGoshintai = gTotalGoshintai + rGoshintai;
      gTotalAncestors = gTotalAncestors + rAncestors;
      gTotalOmitama = gTotalOmitama + rOmitama;
      gTotalGoshugo = gTotalGoshugo + rGoshugo;
      gTotalOther = gTotalOther + rOther;
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;
      //gTotal = gTotal + parseInt(tmpList[i].total);
      gTotal = gTotalOtamagushi + gTotalOkiyome + gTotalGoshintai + gTotalAncestors + gTotalOmitama + gTotalGoshugo + gTotalOther + gTotalScmo;
      gTotal_1 = gTotalCentreM + gTotalCountryM;
      if (pipe.transform(tmpList[i].offerDate, 'MMM-yyyy') == offerDate) {
        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseScmo = dateWiseScmo + rScmo;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;
        //dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);

      } else {
        var dateChangedObj = {
          SrNo: iCount + 1,
          Centre: centre,
          OfferingDate: offerDateDD,
          SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
          SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
          CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
          CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
          CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
          CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
          Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
          Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
          Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
          Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
          Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
          Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
          Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
          Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
          Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
          Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
          Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
          Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
          Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
          Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
          Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
          Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
        }
        tmpArray.push(dateChangedObj);
        iCount = iCount + 1;
        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo;
        dateWiseOther = rOther;
        dateWiseScmo = rScmo;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;

        // iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'MMM-yyyy');
        offerDateDD = pipe.transform(tmpList[i].offerDate, 'MMM-yyyy');

        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
      }
    }// for

    var lastRecObjCnt = {
      SrNo: iCount + 1,
      Centre: centre,
      OfferingDate: offerDateDD,
      SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
      SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
      CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
      CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
      CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
      CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
      Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
      Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
      Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
      Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
      Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
      Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
      Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
      Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
      Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
      Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
      Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
      Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
      Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
      Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
      Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
      Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "Grand Total",
      OfferingDate: "",
      Centre: "",
      SCMO: ((gTotalScmo == 0) ? "" : gTotalScmo),
      SCMO_cnt: ((gTotalScmoCnt == 0) ? "" : gTotalScmoCnt),
      CountryM: ((gTotalCountryM == 0) ? "" : gTotalCountryM),
      CountryM_cnt: ((gTotalCountryMCnt == 0) ? "" : gTotalCountryMCnt),
      CentreM: ((gTotalCentreM == 0) ? "" : gTotalCentreM),
      CentreM_cnt: ((gTotalCentreMCnt == 0) ? "" : gTotalCentreMCnt),
      Otamagushi: ((gTotalOtamagushi == 0) ? "" : gTotalOtamagushi),
      Otamagushi_cnt: ((gTotalOtamagushiCnt == 0) ? "" : gTotalOtamagushiCnt),
      Okiyome: ((gTotalOkiyome == 0) ? "" : gTotalOkiyome),
      Okiyome_cnt: ((gTotalOkiyomeCnt == 0) ? "" : gTotalOkiyomeCnt),
      Goshintai: ((gTotalGoshintai == 0) ? "" : gTotalGoshintai),
      Goshintai_cnt: ((gTotalGoshintaiCnt == 0) ? "" : gTotalGoshintaiCnt),
      Ancestors: ((gTotalAncestors == 0) ? "" : gTotalAncestors),
      Ancestors_cnt: ((gTotalAncestorsCnt == 0) ? "" : gTotalAncestorsCnt),
      Omitama: ((gTotalOmitama == 0) ? "" : gTotalOmitama),
      Omitama_cnt: ((gTotalOmitamaCnt == 0) ? "" : gTotalOmitamaCnt),
      Goshugo: ((gTotalGoshugo == 0) ? "" : gTotalGoshugo),
      Goshugo_cnt: ((gTotalGoshugoCnt == 0) ? "" : gTotalGoshugoCnt),
      Other: ((gTotalOther == 0) ? "" : gTotalOther),
      Other_cnt: ((gTotalOtherCnt==0) ? "" : gTotalOtherCnt),
      Total: ((gTotal == 0) ? "" : gTotal),
      Total_1: ((gTotal_1 == 0) ? "" : gTotal_1)
    }

    tmpArray.push(grandTotalObj);
    
    this.monthwiseShidobu = tmpArray;
  }

  /* CENTREWISESHIDOBU populateSHIDOBUCENTREWISE */
  populateSHIDOBUCentreWise(data) {
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = tmpList[0].offerChildCentre;
    var offerDateDD = pipe.transform(tmpList[0].offerDate, 'MMM-yyyy');
    var centre = tmpList[0].offerMainCentre.substr(0, 3) + "/" + tmpList[0].childCentre.substr(0, 3);
    /*if (this.selectedMainCentreObj['mainCentreName'] == "All") {
      centre = "All/All";
    } else if (this.selectedChildCentreObj['childCentreName'] == "All") {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/All";
    } else {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/" + tmpList[0].childCentre.substr(0, 3);
    }*/
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var dateWiseCouCenTotal = 0;

    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var gTotal_1 = 0;
    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    for (var i = 0; i < tmpList.length; i++) {
      if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash") {
          if (chqEmpty == false) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi;
      gTotalOkiyome = gTotalOkiyome + rOkiyome;
      gTotalGoshintai = gTotalGoshintai + rGoshintai;
      gTotalAncestors = gTotalAncestors + rAncestors;
      gTotalOmitama = gTotalOmitama + rOmitama;
      gTotalGoshugo = gTotalGoshugo + rGoshugo;
      gTotalOther = gTotalOther + rOther;
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;
      //gTotal = gTotal + parseInt(tmpList[i].total);
      gTotal = gTotalOtamagushi + gTotalOkiyome + gTotalGoshintai + gTotalAncestors + gTotalOmitama + gTotalGoshugo + gTotalOther + gTotalScmo;
      gTotal_1 = gTotalCentreM + gTotalCountryM;
      if (tmpList[i].offerChildCentre == offerDate) { 
        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseScmo = dateWiseScmo + rScmo;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;
        //dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);

      } else {
        var dateChangedObj = {
          SrNo: iCount + 1,
          Centre: centre,
          OfferingDate: offerDateDD,
          SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
          SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
          CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
          CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
          CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
          CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
          Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
          Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
          Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
          Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
          Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
          Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
          Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
          Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
          Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
          Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
          Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
          Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
          Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
          Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
          Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
          Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
        }
        tmpArray.push(dateChangedObj);
        iCount = iCount + 1;
        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo;
        dateWiseOther = rOther;
        dateWiseScmo = rScmo;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;
         
        // iCount = 1; 
        offerDate = tmpList[i].offerChildCentre;
        centre = tmpList[i].offerMainCentre.substr(0, 3) + "/" + tmpList[i].childCentre.substr(0, 3);;
        offerDateDD = pipe.transform(tmpList[i].offerDate, 'MMM-yyyy');

        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
      }
    }// for

    var lastRecObjCnt = {
      SrNo: iCount + 1,
      Centre: centre,
      OfferingDate: offerDateDD,
      SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
      SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
      CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
      CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
      CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
      CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
      Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
      Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
      Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
      Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
      Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
      Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
      Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
      Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
      Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
      Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
      Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
      Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
      Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
      Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
      Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
      Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "Grand Total",
      OfferingDate: "",
      Centre: "",
      SCMO: ((gTotalScmo == 0) ? "" : gTotalScmo),
      SCMO_cnt: ((gTotalScmoCnt == 0) ? "" : gTotalScmoCnt),
      CountryM: ((gTotalCountryM == 0) ? "" : gTotalCountryM),
      CountryM_cnt: ((gTotalCountryMCnt == 0) ? "" : gTotalCountryMCnt),
      CentreM: ((gTotalCentreM == 0) ? "" : gTotalCentreM),
      CentreM_cnt: ((gTotalCentreMCnt == 0) ? "" : gTotalCentreMCnt),
      Otamagushi: ((gTotalOtamagushi == 0) ? "" : gTotalOtamagushi),
      Otamagushi_cnt: ((gTotalOtamagushiCnt == 0) ? "" : gTotalOtamagushiCnt),
      Okiyome: ((gTotalOkiyome == 0) ? "" : gTotalOkiyome),
      Okiyome_cnt: ((gTotalOkiyomeCnt == 0) ? "" : gTotalOkiyomeCnt),
      Goshintai: ((gTotalGoshintai == 0) ? "" : gTotalGoshintai),
      Goshintai_cnt: ((gTotalGoshintaiCnt == 0) ? "" : gTotalGoshintaiCnt),
      Ancestors: ((gTotalAncestors == 0) ? "" : gTotalAncestors),
      Ancestors_cnt: ((gTotalAncestorsCnt == 0) ? "" : gTotalAncestorsCnt),
      Omitama: ((gTotalOmitama == 0) ? "" : gTotalOmitama),
      Omitama_cnt: ((gTotalOmitamaCnt == 0) ? "" : gTotalOmitamaCnt),
      Goshugo: ((gTotalGoshugo == 0) ? "" : gTotalGoshugo),
      Goshugo_cnt: ((gTotalGoshugoCnt == 0) ? "" : gTotalGoshugoCnt),
      Other: ((gTotalOther == 0) ? "" : gTotalOther),
      Other_cnt: ((gTotalOtherCnt == 0) ? "" : gTotalOtherCnt),
      Total: ((gTotal == 0) ? "" : gTotal),
      Total_1: ((gTotal_1 == 0) ? "" : gTotal_1)
    }

    tmpArray.push(grandTotalObj);

    this.centrewiseShidobu = tmpArray;
  }

  populateOtherOfferingNew(data){
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    var dateWiseTotal = 0;
    var gTotal = 0;
    var iCount = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rOther = 0;

    for (var i = 0; i < tmpList.length; i++) {
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      gTotal = gTotal + rGoshintai  + rAncestors  + rOmitama  + rOther ;
      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        dateWiseTotal = dateWiseTotal + rGoshintai  +  rAncestors  +  rOmitama  +  rOther  ;
        if (rGoshintai  > 0) {
          var tmp = {
            SrNo: iCount  + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Goshintai",
            Amount: rGoshintai 
          };
          tmpArray.push(tmp);
          iCount++;
        }
        if (rAncestors  > 0) {
          var tmp = {
            SrNo: iCount  + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Anc Alt",
            Amount: rAncestors 
          };
          tmpArray.push(tmp);
          iCount++;
        }
        if (rOmitama  > 0) {
          var tmp = {
            SrNo: iCount  + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Omitama",
            Amount: rOmitama 
          };
          tmpArray.push(tmp);
          iCount++;
        }
        if (rOther  > 0) {
          var tmp = {
            SrNo: iCount  + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Others",
            Amount: rOther 
          };
          tmpArray.push(tmp);
          iCount++;
        }

      } else {
        var dateChangedObj = {
          SrNo: "",
          OfferingDate: "",
          MemberName: "Date Wise Total",
          Type : "",
          Amount : dateWiseTotal,
        }
        tmpArray.push(dateChangedObj);
        dateWiseTotal = rGoshintai  + rAncestors  + rOmitama + rOther ;
        if (rGoshintai  > 0) {
          var tmp = {
            SrNo: iCount + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Goshintai",
            Amount: rGoshintai 
          };
          tmpArray.push(tmp);
          iCount++;
        }
        if (rAncestors  > 0) {
          var tmp = {
            SrNo: iCount + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Anc Alt",
            Amount: rAncestors 
          };
          tmpArray.push(tmp);
          iCount++;
        }
        if (rOmitama  > 0) {
          var tmp = {
            SrNo: iCount + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Omitama",
            Amount: rOmitama 
          };
          tmpArray.push(tmp);
          iCount++;
        }
        if (rOther > 0) {
          var tmp = {
            SrNo: iCount + 1,
            OfferingDate: tmpList[i].offerDate,
            MemberName: tmpList[i].memberName,
            Type: "Others",
            Amount: rOther
          };
          tmpArray.push(tmp);
          iCount++;
        }
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
      }
    }
    var lastRecObj = {
      SrNo: "",
      OfferingDate: "",
      MemberName: "Date Wise Total",
      Type : "",
      Amount: dateWiseTotal
    }
    tmpArray.push(lastRecObj);

    var grandTotalObj = {
      SrNo: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      Type: "",
      Amount: gTotal
    }

    tmpArray.push(grandTotalObj);
    this.otherOfferingNew = tmpArray;
  }

  sortData(tmpList) {
    return tmpList.sort((a, b) => {
      return <any>new Date(b.forMonth) - <any>new Date(a.forMonth);
    });
  }

  monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }

  popuplateSCMOHQ(data) {
    var tmpArray2 = [];
    tmpArray2 = this.dataSharingService.getAllMembersListing();
    var tmpArray = new Array();
    var sortArry = new Array();
    var distinctArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var memID = tmpList[0].memberID;
    var bStatus = false;
    var adult = "";
    var gender = "";
    var igMaleAdultCount = 0;
    var igMaleYouthCount = 0;
    var igFeMaleAdultCount = 0;
    var igFeMaleYouthCount = 0;
    var igSCMOTotal = 0;

    for (var k = 0; k < tmpList.length; k++) {
      if (tmpList[k].memberID != memID) {
        distinctArray.push(this.sortData(sortArry)[0]);
        memID = tmpList[k].memberID;
        sortArry = new Array();
        bStatus = false;
      }
      if (bStatus == false) {
        var tmpArray = [];
        tmpArray = tmpArray2.filter(function (item) {
          return item.memberID == memID;
        });
        //tmpList[k].status = tmpArray[0].status + " / " + tmpList[k].status;
        tmpList[k].status = (tmpArray.length > 0 ? tmpArray[0].status + " / " + tmpList[k].status : tmpList[k].status);
        bStatus = true;
      }
      sortArry.push(tmpList[k]);
    }
    distinctArray.push(this.sortData(sortArry)[0]);

    tmpList = distinctArray;
    var toCompareDate = new Date(this.formModel.forMonth);
    tmpArray = [];
    var iCnt = 0;
    for (var i = 0; i < tmpList.length; i++) {
      var memberCentre = tmpList[i].mainCentre.substr(0, 3);
      if (tmpList[i].childCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
      }
      if (tmpList[i].subCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
      }
      var processing = tmpList[i].offerMainCentre.substr(0, 3);
      if (tmpList[i].offerChildCentre != "") {
        processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
      }
      if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
        processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
      }

      var tmp = {
        SrNo: iCnt + 1,
        ProcessingDate: tmpList[i].processingDate,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        Centre: memberCentre,
        Processing: processing,
        SCMOMonth: "",
        SCMO: "",
        MA: "",
        FA: "",
        MY: "",
        FY:""
      }

      var bRecFnd = false;
      do {
        var offeringDate = new Date(tmpList[i].forMonth);
        if (offeringDate.getTime() > toCompareDate.getTime()) {
          tmp.SCMOMonth = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          bRecFnd = true;
          break;
        }
        if (offeringDate.getTime() == toCompareDate.getTime()) {
          tmp.SCMOMonth = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          bRecFnd = true;
          break;
        }
      } while (false);
      if (bRecFnd == false) {
        continue;
      }
      iCnt++;
      adult = tmpList[i].isAdultYN;
      gender = tmpList[i].gender;
      tmp.SCMO = tmpList[i].scmo;
      igSCMOTotal = igSCMOTotal + tmpList[i].scmo;
      if (gender == "M") {
        if (adult == "Y") {
          igMaleAdultCount = igMaleAdultCount + 1;
          tmp.MA = 'Y';
        } else if (adult == "N") {
          igMaleYouthCount = igMaleYouthCount + 1;
          tmp.MY = 'Y';
        }
      }
      if (gender == "F") {
        if (adult == "Y") {
          igFeMaleAdultCount = igFeMaleAdultCount + 1;
          tmp.FA = "Y"
        } else if (adult == "N") {
          igFeMaleYouthCount = igFeMaleYouthCount + 1;
          tmp.FY = "Y"
        }
      }

      tmpArray.push(tmp);
    }
    var tmp1 = {
      SrNo: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Total", 
      Centre: "",
      Processing: "",
      SCMOMonth: "",
      SCMO: igSCMOTotal,
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount
    }
    tmpArray.push(tmp1);
    this.SCMOHQ = tmpArray;
    this.dataSharingService.setSearchedOfferSCMOHQ(tmpArray);
  }

  popuplateSCMO(data,group) {
    var tmpArray2 = [];
    tmpArray2 = this.dataSharingService.getAllMembersListing();
    var tmpArray = new Array();
    var sortArry = new Array();
    var distinctArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var memID = tmpList[0].memberID;
    var bStatus = false;

    for (var k = 0; k < tmpList.length; k++) {
      if (tmpList[k].memberID != memID) {
        distinctArray.push(this.sortData(sortArry)[0]);
        memID = tmpList[k].memberID;
        sortArry = new Array();
        bStatus = false;
      }
      if (bStatus == false) {
        var tmpArray = [];
        tmpArray = tmpArray2.filter(function (item) {
          return item.memberID == memID;
        });
        tmpList[k].status = (tmpArray.length > 0 ? tmpArray[0].status + " / " + tmpList[k].status : tmpList[k].status) ;
        bStatus = true;
      }
      sortArry.push(tmpList[k]);
    }
    distinctArray.push(this.sortData(sortArry)[0]);
    if (this.isEmpty(group)) {
      tmpList = distinctArray;
    } else {
      var remark1Array = new Array();
      for (var z = 0; z < distinctArray.length;z++) {
        var tmpArray = [];
        tmpArray = tmpArray2.filter(function (item) {
          return item.memberID == distinctArray[z].memberID;
        });
        if (tmpArray.length > 0 ) {
          if (tmpArray[0].remark1 == group) {
            remark1Array.push(distinctArray[z]);
          }
        }
        
      }
      tmpList = remark1Array;
    }

    var toCompareDate = new Date(this.formModel.forMonth);
    var iCountCol1 = 0;
    var iCountCol2 = 0;
    var iCountCol3 = 0;
    var iCountCol4 = 0;
    var iCountCol5 = 0;

    tmpArray = [];
    for (var i = 0; i < tmpList.length; i++) {
      //console.log(tmpList[i]);
      var memberCentre = "";
      if (tmpList[i].mainCentre != "" && tmpList[i].mainCentre != null && tmpList[i].mainCentre != undefined) {
        memberCentre = tmpList[i].mainCentre.substr(0, 3);
      }
      if (tmpList[i].childCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
      }
      if (tmpList[i].subCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
      }
      var processing = tmpList[i].offerMainCentre.substr(0, 3);
      if (tmpList[i].offerChildCentre != "") {
        processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
      }
      if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
        processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
      }

      var tmp = {
        SrNo: i + 1,
        ProcessingDate: tmpList[i].processingDate,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        ChequeNo: tmpList[i].chequeNo,
        BankName: tmpList[i].bankName,
        Centre: memberCentre,
        Processing: processing,
        Status: tmpList[i].status,
        SCMOMonthLast7Months: "",
        SCMOMonthLast2_6Months: "",
        SCMOMonthLastMonth: "",
        SCMOMonthEqual: "",
        SCMOMonthGreater: "",
        showTotal: "N"
      }

      do {
        var offeringDate = new Date(tmpList[i].forMonth);
        if (offeringDate.getTime() > toCompareDate.getTime()) {
          tmp.SCMOMonthGreater = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          iCountCol5 = iCountCol5 + 1;
          break;
        }
        if (offeringDate.getTime() == toCompareDate.getTime()) {
          tmp.SCMOMonthEqual = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          iCountCol4 = iCountCol4 + 1;
          break;
        }
        var monthDifference = this.monthDiff(toCompareDate, offeringDate);
        if (monthDifference  == -1 ) {
          tmp.SCMOMonthLastMonth = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          iCountCol3 = iCountCol3 + 1;
          break;
        }
        /*
        if (monthDifference == -7) { we will do this later if 6 months is from from date of from date -1
          alert(pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'))
        }*/
        if (monthDifference == -6 || monthDifference == -5 || monthDifference == -4 || monthDifference == -3 || monthDifference == -2)  {
          tmp.SCMOMonthLast2_6Months = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          iCountCol2 = iCountCol2 + 1;
          break;
        }
        tmp.SCMOMonthLast7Months = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
        iCountCol1 = iCountCol1 + 1;

      } while (false);
      tmpArray.push(tmp);
    }
    var tmp1 = {
      SrNo: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "",
      ChequeNo: "",
      BankName: "",
      Centre: "",
      Processing: "",
      Status: "Grand Total",
      SCMOMonthLast7Months: iCountCol1,
      SCMOMonthLast2_6Months: iCountCol2,
      SCMOMonthLastMonth: iCountCol3,
      SCMOMonthEqual: iCountCol4,
      SCMOMonthGreater: iCountCol5,
      showTotal: "Y"
    }
    tmpArray.push(tmp1);

    this.offersSCMO = tmpArray;
    this.dataSharingService.setSearchedOfferSCMOData(tmpArray);

  }

  popuplateCheckList(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'dd/MM/yyyy');
    var MA = "";
    var MY = "";
    var FA = "";
    var FY = "";
    var adult = "";
    var gender = "";
    var iMaleAdultCount = 0
    var igMaleAdultCount = 0;
    var iMaleYouthCount = 0;
    var igMaleYouthCount = 0;
    var iFeMaleAdultCount = 0
    var igFeMaleAdultCount = 0;
    var iFeMaleYouthCount = 0;
    var igFeMaleYouthCount = 0;
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var gTotal = 0;
    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    var rTotal = 0;
    var iTotalMisMatch = 0;

    for (var i = 0; i < tmpList.length; i++) {
      offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
      if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash") {
          if (chqEmpty == false) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }

      var memberCentre = tmpList[i].mainCentre.substr(0, 3);
      if (tmpList[i].childCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
      }
      if (tmpList[i].subCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
      }
      var processing = tmpList[i].offerMainCentre.substr(0, 3);
      if (tmpList[i].offerChildCentre != "") {
        processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
      }
      if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
        processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
      }
      MA = "";
      MY = "";
      FA = "";
      FY = "";
      adult = tmpList[i].isAdultYN;
      gender = tmpList[i].gender;
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);
      rTotal = tmpList[i].total;
      var tmpTotal = 0;
      tmpTotal = rOtamagushi + rOkiyome + rGoshintai + rAncestors + rOmitama + rGoshugo + rOther + rCountryM + rCentreM + rScmo;
      if (tmpTotal == rTotal) {
        continue;
      }
      iTotalMisMatch++;

      var tmp = {
        SrNo: i + 1,
        otherOfferingID: tmpList[i].otherOfferingID,
        ProcessingDate: tmpList[i].processingDate,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        Actual: memberCentre,
        Processing: processing,
        SlipNo: tmpList[i].slipNo,
        MA: MA,
        FA: FA,
        MY: MY,
        FY: FY,
        Status: tmpList[i].status,
        SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
        SCMO: rScmo,
        CountryM: rCountryM,
        CentreM: rCentreM,
        Otamagushi: rOtamagushi,
        Okiyome: rOkiyome,
        Goshintai: rGoshintai,
        Ancestors: rAncestors,
        Omitama: rOmitama,
        Goshugo: rGoshugo,
        Other: rOther,
        Total: tmpList[i].total,
        ChequeNumber: tmpList[i].chequeNo,
        ChequeDate: tmpList[i].chequeDate,
        BankName: tmpList[i].bankName,
        BankBranchName: tmpList[i].bankBranchName,
      }
      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi;
      gTotalOkiyome = gTotalOkiyome + rOkiyome;
      gTotalGoshintai = gTotalGoshintai + rGoshintai;
      gTotalAncestors = gTotalAncestors + rAncestors;
      gTotalOmitama = gTotalOmitama + rOmitama;
      gTotalGoshugo = gTotalGoshugo + rGoshugo;
      gTotalOther = gTotalOther + rOther;
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;
      gTotal = gTotal + parseInt(tmpList[i].total);

      if (pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy') == offerDate) {
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseScmo = dateWiseScmo + rScmo;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;

        dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);
        iCount = iCount + 1;
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      } else {
        var dateChangedObj = {
          SrNo: "",
          otherOfferingID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: iCount,
          Actual: "",
          Processing: "",
          SlipNo: "",
          MA: iMaleAdultCount,
          FA: iFeMaleAdultCount,
          MY: iMaleYouthCount,
          FY: iFeMaleYouthCount,
          Status: "",
          SCMOMonth: "",
          SCMO: dateWiseScmo,
          CountryM: dateWiseCountryM,
          CentreM: dateWiseCentreM,
          Otamagushi: dateWiseOtamagushi,
          Okiyome: dateWiseOkiyome,
          Goshintai: dateWiseGoshintai,
          Ancestors: dateWiseAncestors,
          Omitama: dateWiseOmitama,
          Goshugo: dateWiseGoshugo,
          Other: dateWiseOther,
          Total: dateWiseTotal,
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName: ""
        }

        tmpArray.push(dateChangedObj);
        var dateChangedObjCnt = {
          SrNo: "",
          otherOfferingID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: "",
          Actual: "",
          Processing: "",
          SlipNo: "",
          MA: "",
          FA: "",
          MY: "",
          FY: "",
          Status: "",
          SCMOMonth: "",
          SCMO: dateWiseScmoCnt,
          CountryM: dateWiseCountryMCnt,
          CentreM: dateWiseCentreMCnt,
          Otamagushi: dateWiseOtamagushiCnt,
          Okiyome: dateWiseOkiyomeCnt,
          Goshintai: dateWiseGoshintaiCnt,
          Ancestors: dateWiseAncestorsCnt,
          Omitama: dateWiseOmitamaCnt,
          Goshugo: dateWiseGoshugoCnt,
          Other: dateWiseOtherCnt,
          Total: "",
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName: "",
        }

        tmpArray.push(dateChangedObjCnt);

        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo;
        dateWiseOther = rOther;
        dateWiseTotal = parseInt(tmpList[i].total);
        dateWiseScmo = rScmo;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;

        iMaleAdultCount = 0;
        iFeMaleAdultCount = 0;
        iMaleYouthCount = 0;
        iFeMaleYouthCount = 0;
        iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'dd/MM/yyyy');
        if (gender == "M") {
          if (adult == "Y") {
            iMaleAdultCount = iMaleAdultCount + 1;
            igMaleAdultCount = igMaleAdultCount + 1;
            tmp.MA = 'Y';
          } else if (adult == "N") {
            iMaleYouthCount = iMaleYouthCount + 1;
            igMaleYouthCount = igMaleYouthCount + 1;
            tmp.MY = 'Y';
          }
        }
        if (gender == "F") {
          if (adult == "Y") {
            iFeMaleAdultCount = iFeMaleAdultCount + 1;
            igFeMaleAdultCount = igFeMaleAdultCount + 1;
            tmp.FA = "Y"
          } else if (adult == "N") {
            iFeMaleYouthCount = iFeMaleYouthCount + 1;
            igFeMaleYouthCount = igFeMaleYouthCount + 1;
            tmp.FY = "Y"
          }
        }
        if (parseInt(tmpList[i].countryM) > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (parseInt(tmpList[i].centreM) > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (parseInt(tmpList[i].scmo) > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (parseInt(tmpList[i].otamagushi) > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (parseInt(tmpList[i].okiyome) > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (parseInt(tmpList[i].goshintai) > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (parseInt(tmpList[i].ancestors) > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (parseInt(tmpList[i].omitama) > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (parseInt(tmpList[i].goshugo) > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (parseInt(tmpList[i].other) > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      }
    }
    var lastRecObj = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: iCount,
      Actual: "",
      Processing: "",
      SlipNo: "",
      MA: iMaleAdultCount,
      FA: iFeMaleAdultCount,
      MY: iMaleYouthCount,
      FY: iFeMaleYouthCount,
      Status: "",
      SCMOMonth: "",
      SCMO: dateWiseScmo,
      CountryM: dateWiseCountryM,
      CentreM: dateWiseCentreM,
      Otamagushi: dateWiseOtamagushi,
      Okiyome: dateWiseOkiyome,
      Goshintai: dateWiseGoshintai,
      Ancestors: dateWiseAncestors,
      Omitama: dateWiseOmitama,
      Goshugo: dateWiseGoshugo,
      Other: dateWiseOther,
      Total: dateWiseTotal,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: "",
    }

    tmpArray.push(lastRecObj);

    var lastRecObjCnt = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "",
      Actual: "",
      Processing: "",
      SlipNo: "",
      MA: "",
      FA: "",
      MY: "",
      FY: "",
      Status: "",
      SCMOMonth: "",
      SCMO: dateWiseScmoCnt,
      CountryM: dateWiseCountryMCnt,
      CentreM: dateWiseCentreMCnt,
      Otamagushi: dateWiseOtamagushiCnt,
      Okiyome: dateWiseOkiyomeCnt,
      Goshintai: dateWiseGoshintaiCnt,
      Ancestors: dateWiseAncestorsCnt,
      Omitama: dateWiseOmitamaCnt,
      Goshugo: dateWiseGoshugoCnt,
      Other: dateWiseOtherCnt,
      Total: "",
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: "",
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      Actual: "",
      Processing: "",
      SlipNo: "",
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount,
      Status: "",
      SCMOMonth: "",
      SCMO: gTotalScmo,
      CountryM: gTotalCountryM,
      CentreM: gTotalCentreM,
      Otamagushi: gTotalOtamagushi,
      Okiyome: gTotalOkiyome,
      Goshintai: gTotalGoshintai,
      Ancestors: gTotalAncestors,
      Omitama: gTotalOmitama,
      Goshugo: gTotalGoshugo,
      Other: gTotalOther,
      Total: gTotal,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: ""
    }

    tmpArray.push(grandTotalObj);
    var grandTotalCntObj = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "",
      Actual: "",
      Processing: "",
      SlipNo: "",
      MA: igMaleAdultCount,
      FA: igFeMaleAdultCount,
      MY: igMaleYouthCount,
      FY: igFeMaleYouthCount,
      Status: "",
      SCMOMonth: "",
      SCMO: gTotalScmoCnt,
      CountryM: gTotalCountryMCnt,
      CentreM: gTotalCentreMCnt,
      Otamagushi: gTotalOtamagushiCnt,
      Okiyome: gTotalOkiyomeCnt,
      Goshintai: gTotalGoshintaiCnt,
      Ancestors: gTotalAncestorsCnt,
      Omitama: gTotalOmitamaCnt,
      Goshugo: gTotalGoshugoCnt,
      Other: gTotalOtherCnt,
      Total: gTotalScmoCnt + gTotalCountryMCnt + gTotalCentreMCnt + gTotalOtamagushiCnt +
        gTotalOkiyomeCnt + gTotalGoshintaiCnt + gTotalAncestorsCnt + gTotalOmitamaCnt + gTotalGoshugoCnt + gTotalOtherCnt,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: ""
    }
    tmpArray.push(grandTotalCntObj);
    if (iTotalMisMatch == 0) {
      alert("No Mismatch in Total for Search Criteria");
    } else {
      this.chkListData = tmpArray;
    }
  }


  popuplateCheckListCent(data) {
    this.chkListDataCent = data;
  }

  doPreProcess(form: NgForm) {
    this.showSpinner();
    this.onClear();
    if (this.formModel.memberName != "" && this.formModel.memberID == "") {
      alert("Please select member name");
      return;
    }
    var pipe = new DatePipe('en-US')
    if (form.value.offerDateStart != undefined) {
      form.value.offerDateStart = pipe.transform(form.value.offerDateStart, 'MM/dd/yyyy')
      form.value.offerDateEnd = pipe.transform(form.value.offerDateEnd, 'MM/dd/yyyy')
    }

    if (form.value.tillDate != undefined) {
      form.value.tillDate = pipe.transform(form.value.tillDate, 'MM/dd/yyyy');
    }

    if (form.value.childCentreDetails == undefined) {
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
    } else {
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
    }

    if (form.value.mainCentreDetails == undefined) {
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName']
    } else {
      form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if (form.value.subCentreDetails == undefined) {
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    } else {
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    }

    if (form.value.statusDetails == undefined) {
      form.value.status = this.selectedStatusObj['statusName']
    } else {
      form.value.status = form.value.statusDetails.statusName;
    }
    form.value.memberID = this.formModel.memberID;
    this.offerService.getOffers(form.value).subscribe((res: any) => {
      if (res.status == 1) {
        do {
          if (this.formModel.report == "OO") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.popuplateOtherOffering(res.result);
          } 
        } while (false);
      } else {
        alert(res.message);
        this.onClear();
      }
    });

  }

  onSearch(form: NgForm) {
    this.showSpinner();
    this.onClear();
    if(this.formModel.memberName!= "" && this.formModel.memberID == ""){
      alert("Please select member name");
      return;
    }
    var pipe = new DatePipe('en-US')
    if (form.value.offerDateStart != undefined ) {
      form.value.offerDateStart = pipe.transform(form.value.offerDateStart, 'MM/dd/yyyy')
      form.value.offerDateEnd = pipe.transform(form.value.offerDateEnd, 'MM/dd/yyyy')
    }

    if (form.value.tillDate != undefined ) {    
      form.value.tillDate = pipe.transform(form.value.tillDate, 'MM/dd/yyyy');     
    }

    if(form.value.childCentreDetails == undefined){
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
    }else{
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
    }
    
    if( form.value.mainCentreDetails == undefined){
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName']
    }else{
      form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if(form.value.subCentreDetails == undefined){
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    }else{
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    }

    if( form.value.statusDetails == undefined){
      form.value.status = this.selectedStatusObj['statusName']
    }else{
      form.value.status = form.value.statusDetails.statusName;
    }
    if (form.value.report == "S" && form.value.reportTypeDetails.id == 1) {
      form.value.report = "SA";
    }
    if (form.value.report == "SCMOHQ" && form.value.reportTypeDetails.id == 1) {
      form.value.report = "SCMOHQA";
    }
    if (this.formModel.report == "SHIDOBU") {
      var d = new Date(form.value.forMonth);
      form.value.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
      form.value.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
      this.formModel.offerDateStart = form.value.offerDateStart ;
      this.formModel.offerDateEnd = form.value.offerDateEnd;
    }
    //alert(this.formModel.report);

    if (this.formModel.report == "MONTHWISESHIDOBU") {
      var d = new Date(form.value.forMonth);
      form.value.offerDateStart = new Date(Date.UTC(d.getFullYear(), 3, 1));
      form.value.offerDateEnd = new Date(Date.UTC(d.getFullYear() + 1, 3, 0));
      this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), 3, 1));
      this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear() + 1, 3, 0));
    }

    if (this.formModel.report == "CENTREWISESHIDOBU") {
      var d = new Date(form.value.forMonth);
      form.value.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
      form.value.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth()+1, 0));
      this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
      this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
    }

    form.value.memberID = this.formModel.memberID;
    this.offerService.getOffers(form.value).subscribe((res: any) => {
      if (res.status == 1) {
        do {
          if (this.formModel.report == "O") {
            this.popuplateOffering(res.result);
          } else if (this.formModel.report == "S" ) {
            this.popuplateSCMO(res.result, form.value.remark1);
          } else if (this.formModel.report == "SCMO1") {
            this.popuplateSCMO(res.result, form.value.remark1);
          } else if (this.formModel.report == "M") {
            this.popuplateMembers(res.result);
          } else if (this.formModel.report == "OO") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.popuplateOtherOffering(res.result);
          } else if (this.formModel.report == "DSCMO") {
            this.popuplateDailySCMO(res.result);
          } else if (this.formModel.report == "OM") {
            this.popuplateOtherOfferingMembers(res.result);
          } else if (this.formModel.report == "DP") {
            this.popuplateDivineProtection(res.result);
          } else if (this.formModel.report == "DO") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.populateNewOfferingCountData(res.result);
          } else if (this.formModel.report == "SHIDOBU") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.populateSHIDOBU(res.result);
          } else if (this.formModel.report == "MONTHWISESHIDOBU") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.populateSHIDOBUMonthWise(res.result);
          } else if (this.formModel.report == "CENTREWISESHIDOBU") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.populateSHIDOBUCentreWise(res.result);
          } else if (this.formModel.report == "OOF") {
            this.populateOtherOfferingNew(res.result);
          } else if (this.formModel.report == "SCMOHQ") {
            this.popuplateSCMOHQ(res.result);
          } else if (this.formModel.report == "SCMOHQ1") {
            this.popuplateSCMOHQ(res.result);
          } else if (this.formModel.report == "CHKLIST" ) {
            this.popuplateCheckList(res.result);
          } else if (this.formModel.report == "CHKLISTCENT") {
            this.popuplateCheckListCent(res.result);
          }
          
        } while (false);
      } else {
        alert(res.message);
        this.onClear();
      }
    });

    //this.formModel.memberID = "";
  
  }

  onClear() {
    do {
      if (this.formModel.report == "O") {
        this.offers = [];
      } else if (this.formModel.report == "S") {
        this.offersSCMO = [];
      } else if (this.formModel.report == "SCMO1") {
        this.offersSCMO = [];
      } else if (this.formModel.report == "M") {
        this.offersMembers = [];
      } else if (this.formModel.report == "OO") {
        this.otherOffering = [];
      } else if (this.formModel.report == "OM") {
        this.otherOfferingMembers = [];
      } else if (this.formModel.report == "DP") {
        this.divineProtection = [];
      } else if (this.formModel.report == "DO") {
        this.datewiseOffering = [];
      } else if (this.formModel.report == "OOF") {
        this.otherOfferingNew = [];
      } else if (this.formModel.report == "SL") {
        this.scmologbook = [];
      } else if (this.formModel.report == "DSCMO") {
        this.otherOfferingDailySCMO = [];
      } else if (this.formModel.report == "CHKLIST") {
        this.chkListData = [];
      } else if (this.formModel.report == "CHKLISTCENT") {
        this.chkListDataCent = [];
      } else if (this.formModel.report == "SHIDOBU") {
        this.datewiseShidobu = [];
      } else if (this.formModel.report == "MONTHWISESHIDOBU") {
        this.monthwiseShidobu = [];
      } else if (this.formModel.report == "CENTREWISESHIDOBU") {
        this.centrewiseShidobu = [];
      }
    } while (false);
  }

  exportAsXLSX() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "O") {
      var tmpArray = new Array();
      for (var i = 0; i < this.offers.length; i++) {
        var tmp = {
          SrNo: this.offers[i].SrNo,
          OfferingDate: pipe.transform(this.offers[i].OfferingDate,'dd/MM/yyyy'),
          MemberName: this.offers[i].MemberName,
          SCMOAmount: this.offers[i].SCMOAmount,
          Status: this.offers[i].Status,
          SCMOMonth: pipe.transform(this.offers[i].SCMOMonth, 'MMM-yyyy'),
          CountryM: this.offers[i].CountryM,
          CenterM: this.offers[i].CenterM,
          Blank: "",
          Total: this.offers[i].Total,
          MA: this.offers[i].MA,
          FA: this.offers[i].FA,
          MY: this.offers[i].MY,
          FY: this.offers[i].FY,
          ChequeNumber: this.offers[i].ChequeNumber,
          ChequeDate: pipe.transform(this.offers[i].ChequeDate, 'dd/MM/yyyy'),
          BankName: this.offers[i].BankName,
          BankBranchName :this.offers[i].BankBranchName
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'Offering' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    } else if (this.formModel.report == "S") {
      var tmpArray = new Array();
      var SCMOMonthLast7Months = "<= " + pipe.transform(this.formModel.last7Months, 'MMM-yyyy');
      var SCMOMonthLast2_6Months = pipe.transform(this.formModel.last6Months, 'MMM-yyyy') + " to " + pipe.transform(this.formModel.last2Months, 'MMM-yyyy');
      var SCMOMonthLastMonth = pipe.transform(this.formModel.lastMonth, 'MMM-yyyy');
      var SCMOMonthEqual = pipe.transform(this.formModel.forMonth, 'MMM-yyyy');
      var SCMOMonthGreater = pipe.transform(this.formModel.nextMonth, 'MMM-yyyy');
      for (var i = 0; i < this.offersSCMO.length; i++) {
        var tmp1 = {
          SrNo: this.offersSCMO[i].SrNo,
          ProcessingDate: pipe.transform(this.offersSCMO[i].ProcessingDate, 'dd/MM/yyyy'),
          OfferingDate: pipe.transform(this.offersSCMO[i].OfferingDate, 'dd/MM/yyyy'),
          ChequeNo: this.offersSCMO[i].ChequeNo,
          BankName: this.offersSCMO[i].BankName,
          MemberName: this.offersSCMO[i].MemberName,
          Status: this.offersSCMO[i].Status,
          [SCMOMonthLast7Months] : this.offersSCMO[i].SCMOMonthLast7Months,
          [SCMOMonthLast2_6Months]: this.offersSCMO[i].SCMOMonthLast2_6Months,
          [SCMOMonthLastMonth]: this.offersSCMO[i].SCMOMonthLastMonth,
          [SCMOMonthEqual]: this.offersSCMO[i].SCMOMonthEqual,
          [SCMOMonthGreater]: this.offersSCMO[i].SCMOMonthGreater
        }
        tmpArray.push(tmp1);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'SCMO' + "_" + pipe.transform(this.formModel.forMonth, 'MMM/yyyy'));
    }
  }
  exportAsXLSXSCMO1() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "SCMO1") {
      var tmpArray = new Array();
      var SCMOMonthLast7Months = "<= " + pipe.transform(this.formModel.last7Months, 'MMM-yyyy');
      var SCMOMonthLast2_6Months = pipe.transform(this.formModel.last6Months, 'MMM-yyyy') + " to " + pipe.transform(this.formModel.last2Months, 'MMM-yyyy');
      var SCMOMonthLastMonth = pipe.transform(this.formModel.lastMonth, 'MMM-yyyy');
      var SCMOMonthEqual = pipe.transform(this.formModel.forMonth, 'MMM-yyyy');
      var SCMOMonthGreater = pipe.transform(this.formModel.nextMonth, 'MMM-yyyy');
      for (var i = 0; i < this.offersSCMO.length; i++) {
        var tmp1 = {
          SrNo: this.offersSCMO[i].SrNo,
          ChequeNo: this.offersSCMO[i].ChequeNo,
          BankName: this.offersSCMO[i].BankName,
          MemberName: this.offersSCMO[i].MemberName,
          Status: this.offersSCMO[i].Status,
          [SCMOMonthLast7Months]: this.offersSCMO[i].SCMOMonthLast7Months,
          [SCMOMonthLast2_6Months]: this.offersSCMO[i].SCMOMonthLast2_6Months,
          [SCMOMonthLastMonth]: this.offersSCMO[i].SCMOMonthLastMonth,
          [SCMOMonthEqual]: this.offersSCMO[i].SCMOMonthEqual,
          [SCMOMonthGreater]: this.offersSCMO[i].SCMOMonthGreater,
          ProcessingDate: pipe.transform(this.offersSCMO[i].ProcessingDate, 'dd/MM/yyyy'),
          OfferingDate: pipe.transform(this.offersSCMO[i].OfferingDate, 'dd/MM/yyyy')
        }
        tmpArray.push(tmp1);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'SCMO1' + "_" + pipe.transform(this.formModel.forMonth, 'MMM/yyyy'));
    }
  }

  exportAsXLSXOF() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "OOF") {
      var tmpArray = new Array();
      for (var i = 0; i < this.otherOfferingNew.length; i++) {
        var tmp = {
          SrNo: this.otherOfferingNew[i].SrNo,
          OfferingDate: pipe.transform(this.otherOfferingNew[i].OfferingDate, 'dd/MM/yyyy'),
          MemberName: this.otherOfferingNew[i].MemberName,
          TypeOfOffering: this.otherOfferingNew[i].Type,
          Amount: this.otherOfferingNew[i].Amount,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'OF' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSXDP() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "DP") {
      var tmpArray = new Array();
      for (var i = 0; i < this.divineProtection.length; i++) {
        var tmp = {
          SrNo: this.divineProtection[i].SrNo,
          OfferingDate: pipe.transform(this.divineProtection[i].OfferingDate, 'dd/MM/yyyy'),
          MemberName: this.divineProtection[i].MemberName,
          Goshugo: this.divineProtection[i].Goshugo,
          Total: this.divineProtection[i].Total,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'DP' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSXDS() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "DO") {
      var tmpArray = new Array();
      //OfferingDate: pipe.transform(this.datewiseOffering[i].OfferingDate, 'dd/MM/yyyy'),
      for (var i = 0; i < this.datewiseOffering.length; i++) {
        var tmp = {
          OfferingDate: this.datewiseOffering[i].OfferingDate,
          OTANo: this.datewiseOffering[i].Otamagushi_cnt,
          OTAAMT: this.datewiseOffering[i].Otamagushi,
          OKINo: this.datewiseOffering[i].Okiyome_cnt,
          OKIAMT: this.datewiseOffering[i].Okiyome,
          GhoshugoNo: this.datewiseOffering[i].Goshugo_cnt,
          GhoshugoAMT: this.datewiseOffering[i].Goshugo,
          SCMONo: this.datewiseOffering[i].SCMO_cnt,
          SCMOAMT: this.datewiseOffering[i].SCMO,
          CountryNo: this.datewiseOffering[i].CountryM_cnt,
          CountryAMT: this.datewiseOffering[i].CountryM,
          CentreNo: this.datewiseOffering[i].CentreM_cnt,
          CentreAMT: this.datewiseOffering[i].CentreM,
          GoshintaiNo: this.datewiseOffering[i].Goshintai_cnt,
          GoshintaiAMT: this.datewiseOffering[i].Goshintai,
          AncestorsNo: this.datewiseOffering[i].Ancestors_cnt,
          AncestorsAMT: this.datewiseOffering[i].Ancestors,
          OmitamaNo: this.datewiseOffering[i].Omitama_cnt,
          OmitamaAMT: this.datewiseOffering[i].Omitama,
          OtherNo: this.datewiseOffering[i].Other_cnt,
          OtherAMT: this.datewiseOffering[i].Other,
          Total: this.datewiseOffering[i].Total,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'DS' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSXSHIDOBU() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "SHIDOBU") {
      var tmpArray = new Array();
      //OfferingDate: pipe.transform(this.datewiseOffering[i].OfferingDate, 'dd/MM/yyyy'),
      for (var i = 0; i < this.datewiseShidobu.length; i++) {
        var tmp = {
          OtamagushiNo: this.datewiseShidobu[i].Otamagushi_cnt,
          OtamagushiAmt: this.datewiseShidobu[i].Otamagushi,
          PurificationNo: this.datewiseShidobu[i].Okiyome_cnt,
          PurificationAmt: this.datewiseShidobu[i].Okiyome,
          GoshintaiNo: this.datewiseShidobu[i].Goshintai_cnt,
          GoshintaiAmt: this.datewiseShidobu[i].Goshintai,

          AncestorsAltarNo: this.datewiseShidobu[i].Ancestors_cnt,
          AncestorsAltarAmt: this.datewiseShidobu[i].Ancestors,
          PrimaryCourseNo:"",
          PrimaryCourseAmt: "",
          OmitanaNo: this.datewiseShidobu[i].Omitama_cnt,
          OmitanaAmt: this.datewiseShidobu[i].Omitama,
          DivineProtectionNo: this.datewiseShidobu[i].Goshugo_cnt,
          DivineProtectionAmt: this.datewiseShidobu[i].Goshugo,

          Date: this.datewiseShidobu[i].OfferingDate,
          SpiritualCordMaintenanceNo: this.datewiseShidobu[i].SCMO_cnt,
          SpiritualCordMaintenanceAmt: this.datewiseShidobu[i].SCMO,
          ReviewersNo: "",
          ReviewersAmt: "",
          OtherNo: this.datewiseShidobu[i].Other_cnt,
          OtherAMT: this.datewiseShidobu[i].Other,
          Total: this.datewiseShidobu[i].Total,
          CountryNo: this.datewiseShidobu[i].CountryM_cnt,
          CountryAMT: this.datewiseShidobu[i].CountryM,
          CentreNo: this.datewiseShidobu[i].CentreM_cnt,
          CentreAMT: this.datewiseShidobu[i].CentreM,
          SuzaNo: "",
          SuzaAmt:"",
          Total_: this.datewiseShidobu[i].Total_1,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'Shidobu Sum' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSXSHIDOBUMonthWise() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "MONTHWISESHIDOBU") {
      var tmpArray = new Array();
      //OfferingDate: pipe.transform(this.datewiseOffering[i].OfferingDate, 'dd/MM/yyyy'),
      for (var i = 0; i < this.monthwiseShidobu.length; i++) {
        var tmp = {
          Centre: this.monthwiseShidobu[i].Centre,
          Month_Year: this.monthwiseShidobu[i].OfferingDate,
          OtamagushiNo: this.monthwiseShidobu[i].Otamagushi_cnt,
          OtamagushiAmt: this.monthwiseShidobu[i].Otamagushi,
          PurificationNo: this.monthwiseShidobu[i].Okiyome_cnt,
          PurificationAmt: this.monthwiseShidobu[i].Okiyome,
          GoshintaiNo: this.monthwiseShidobu[i].Goshugo_cnt,
          GoshintaiAmt: this.monthwiseShidobu[i].Goshugo,
          AncestorsAltarNo: this.monthwiseShidobu[i].Ancestors_cnt,
          AncestorsAltarAmt: this.monthwiseShidobu[i].Ancestors,
          PrimaryCourseNo: "",
          PrimaryCourseAmt: "",
          OmitanaNo: this.monthwiseShidobu[i].Omitama_cnt,
          OmitanaAmt: this.monthwiseShidobu[i].Omitama,
          DivineProtectionNo: this.monthwiseShidobu[i].Goshintai_cnt,
          DivineProtectionAmt: this.monthwiseShidobu[i].Goshintai,
          SpiritualCordMaintenanceNo: this.monthwiseShidobu[i].SCMO_cnt,
          SpiritualCordMaintenanceAmt: this.monthwiseShidobu[i].SCMO,
          ReviewersNo: "",
          ReviewersAmt: "",
          OtherNo: this.monthwiseShidobu[i].Other_cnt,
          OtherAMT: this.monthwiseShidobu[i].Other,
          Total: this.monthwiseShidobu[i].Total,
          CountryNo: this.monthwiseShidobu[i].CountryM_cnt,
          CountryAMT: this.monthwiseShidobu[i].CountryM,
          CentreNo: this.monthwiseShidobu[i].CentreM_cnt,
          CentreAMT: this.monthwiseShidobu[i].CentreM,
          SuzaNo: "",
          SuzaAmt: "",
          Total_: this.monthwiseShidobu[i].Total_1,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'M-wise_Sum' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSXSHIDOBUCentreWise() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "CENTREWISESHIDOBU") {
      var tmpArray = new Array();
      //OfferingDate: pipe.transform(this.datewiseOffering[i].OfferingDate, 'dd/MM/yyyy'),
      for (var i = 0; i < this.centrewiseShidobu.length; i++) {
        var tmp = {
          Centre: this.centrewiseShidobu[i].Centre,
          Month_Year: this.centrewiseShidobu[i].OfferingDate,
          OtamagushiNo: this.centrewiseShidobu[i].Otamagushi_cnt,
          OtamagushiAmt: this.centrewiseShidobu[i].Otamagushi,
          PurificationNo: this.centrewiseShidobu[i].Okiyome_cnt,
          PurificationAmt: this.centrewiseShidobu[i].Okiyome,
          GoshintaiNo: this.centrewiseShidobu[i].Goshugo_cnt,
          GoshintaiAmt: this.centrewiseShidobu[i].Goshugo,
          AncestorsAltarNo: this.centrewiseShidobu[i].Ancestors_cnt,
          AncestorsAltarAmt: this.centrewiseShidobu[i].Ancestors,
          PrimaryCourseNo: "",
          PrimaryCourseAmt: "",
          OmitanaNo: this.centrewiseShidobu[i].Omitama_cnt,
          OmitanaAmt: this.centrewiseShidobu[i].Omitama,
          DivineProtectionNo: this.centrewiseShidobu[i].Goshintai_cnt,
          DivineProtectionAmt: this.centrewiseShidobu[i].Goshintai,
          SpiritualCordMaintenanceNo: this.centrewiseShidobu[i].SCMO_cnt,
          SpiritualCordMaintenanceAmt: this.centrewiseShidobu[i].SCMO,
          ReviewersNo: "",
          ReviewersAmt: "",
          OtherNo: this.centrewiseShidobu[i].Other_cnt,
          OtherAMT: this.centrewiseShidobu[i].Other,
          Total: this.centrewiseShidobu[i].Total,
          CountryNo: this.centrewiseShidobu[i].CountryM_cnt,
          CountryAMT: this.centrewiseShidobu[i].CountryM,
          CentreNo: this.centrewiseShidobu[i].CentreM_cnt,
          CentreAMT: this.centrewiseShidobu[i].CentreM,
          SuzaNo: "",
          SuzaAmt: "",
          Total_: this.centrewiseShidobu[i].Total_1,
        }  
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'Ch Cen-wise Sum' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }  
  exportAsXLSXOM(){
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "OM") {
    var tmpArray = new Array();
      if (this.formModel.memberName == "") {
        for (var i = 0; i < this.otherOfferingMembers.length; i++) {
          var tmp = {
            SrNo: this.otherOfferingMembers[i].SrNo,
            ProcessingDate: pipe.transform(this.otherOfferingMembers[i].ProcessingDate, 'dd/MM/yyyy'),
            OfferingDate: pipe.transform(this.otherOfferingMembers[i].OfferingDate, 'dd/MM/yyyy'),
            MemberName: this.otherOfferingMembers[i].MemberName,
            SlipNo: this.otherOfferingMembers[i].SlipNo,
            Status: this.otherOfferingMembers[i].Status,
            OTA: this.otherOfferingMembers[i].Otamagushi,
            OKI: this.otherOfferingMembers[i].Okiyome,
            Ghoshugo: this.otherOfferingMembers[i].Goshugo,
            SCMO: this.otherOfferingMembers[i].SCMO,
            CountryM: this.otherOfferingMembers[i].CountryM,
            CentreM: this.otherOfferingMembers[i].CentreM,
            GoshApp: this.otherOfferingMembers[i].Goshintai,
            AncAlt: this.otherOfferingMembers[i].Ancestors,
            Omitama: this.otherOfferingMembers[i].Omitama,
            Others: this.otherOfferingMembers[i].Other,
            Total: this.otherOfferingMembers[i].Total,
            Chq: this.otherOfferingMembers[i].ChequeNumber,
            Date: pipe.transform(this.otherOfferingMembers[i].ChequeDate, 'dd/MM/yyyy'),
            BankName: this.otherOfferingMembers[i].BankName,
            BankBranchName: this.otherOfferingMembers[i].BankBranchName,
          }
          tmpArray.push(tmp);
        }
        this.excelService.exportAsExcelFile(tmpArray, 'OM_All' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
      }
      else {
        for (var i = 0; i < this.otherOfferingMembers.length; i++) {
          var tmp = {
            SrNo: this.otherOfferingMembers[i].SrNo,
            ProcessingDate: pipe.transform(this.otherOfferingMembers[i].ProcessingDate, 'dd/MM/yyyy'),
            OfferingDate: pipe.transform(this.otherOfferingMembers[i].OfferingDate, 'dd/MM/yyyy'),
            MemberName: this.otherOfferingMembers[i].MemberName,
            SlipNo: this.otherOfferingMembers[i].SlipNo,
            Status: this.otherOfferingMembers[i].Status,
            OTA: this.otherOfferingMembers[i].Otamagushi,
            OKI: this.otherOfferingMembers[i].Okiyome,
            Ghoshugo: this.otherOfferingMembers[i].Goshugo,
            SCMO: this.otherOfferingMembers[i].SCMO,
            CountryM: this.otherOfferingMembers[i].CountryM,
            CentreM: this.otherOfferingMembers[i].CentreM,
            GoshApp: this.otherOfferingMembers[i].Goshintai,
            AncAlt: this.otherOfferingMembers[i].Ancestors,
            Omitama: this.otherOfferingMembers[i].Omitama,
            Others: this.otherOfferingMembers[i].Other,
            Total: this.otherOfferingMembers[i].Total,
            Chq: this.otherOfferingMembers[i].ChequeNumber,
            Date: pipe.transform(this.otherOfferingMembers[i].ChequeDate, 'dd/MM/yyyy'),
            BankName: this.otherOfferingMembers[i].BankName,
            BankBranchName: this.otherOfferingMembers[i].BankBranchName,
          }
          tmpArray.push(tmp);
        }
        this.excelService.exportAsExcelFile(tmpArray, 'OM_MemberId' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
      }
  }
  }
  exportAsXLSXDO() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "OO") {
      var tmpArray = new Array();
      for (var i = 0; i < this.otherOffering.length; i++) {
        var tmp = {
          SrNo: this.otherOffering[i].SrNo,
          ProcessingDate: pipe.transform(this.otherOffering[i].ProcessingDate,'dd/MM/yyyy'),
          OfferingDate: pipe.transform(this.otherOffering[i].OfferingDate,'dd/MM/yyyy'),
          MemberName: this.otherOffering[i].MemberName,
          SlipNo: this.otherOffering[i].SlipNo,
          Status: this.otherOffering[i].Status,
          OTA: this.otherOffering[i].Otamagushi,
          OKI: this.otherOffering[i].Okiyome,
          Ghoshugo: this.otherOffering[i].Goshugo, 
          SCMOMonth: pipe.transform(this.otherOffering[i].SCMOMonth, 'MMM-yyyy'),
          SCMO: this.otherOffering[i].SCMO,
          CountryM: this.otherOffering[i].CountryM,
          CentreM: this.otherOffering[i].CentreM,
          GoshApp: this.otherOffering[i].Goshintai,
          AncAlt: this.otherOffering[i].Ancestors,
          Omitama: this.otherOffering[i].Omitama,
          Others: this.otherOffering[i].Other,
          Total: this.otherOffering[i].Total,
          Chq: this.otherOffering[i].ChequeNumber,
          Date: pipe.transform(this.otherOffering[i].ChequeDate,'dd/MM/yyyy'),
          BankName: this.otherOffering[i].BankName,
          BankBranchName: this.otherOffering[i].BankBranchName,
          MA: this.otherOffering[i].MA,
          FA: this.otherOffering[i].FA,
          MY: this.otherOffering[i].MY,
          FY: this.otherOffering[i].FY,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'DO' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSXDOSCMO() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "DSCMO") {
      var tmpArray = new Array();
      for (var i = 0; i < this.otherOfferingDailySCMO.length; i++) {
        var tmp = {
          SlipNo: this.otherOfferingDailySCMO[i].SlipNo,
          SrNo: this.otherOfferingDailySCMO[i].SrNo,
          OfferingDate: pipe.transform(this.otherOfferingDailySCMO[i].OfferingDate, 'dd'),
          MemberName: this.otherOfferingDailySCMO[i].MemberName,
          SCMO: this.otherOfferingDailySCMO[i].SCMO,
          SCMOMonth: pipe.transform(this.otherOfferingDailySCMO[i].SCMOMonth, 'MMM-yyyy'),
          CentreM: this.otherOfferingDailySCMO[i].CentreM,
          CountryM: this.otherOfferingDailySCMO[i].CountryM,
          BlankColumn: "",
          Total: this.otherOfferingDailySCMO[i].Total,
          Chq: this.otherOfferingDailySCMO[i].ChequeNumber,
          Date: pipe.transform(this.otherOfferingDailySCMO[i].ChequeDate, 'dd/MM/yyyy'),
          BankName: this.otherOfferingDailySCMO[i].BankName,
          BankBranchName: this.otherOfferingDailySCMO[i].BankBranchName,
          MA: this.otherOfferingDailySCMO[i].MA,
          FA: this.otherOfferingDailySCMO[i].FA,
          MY: this.otherOfferingDailySCMO[i].MY,
          FY: this.otherOfferingDailySCMO[i].FY
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'DOSCMO' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSSCMOHQ() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "SCMOHQ") {
      var tmpArray = new Array();
      for (var i = 0; i < this.SCMOHQ.length; i++) {
        var tmp = {
          SrNo: this.SCMOHQ[i].SrNo,
          ProcessingDate: pipe.transform(this.SCMOHQ[i].ProcessingDate, 'dd/MM/yyyy'),
          OfferingDate: pipe.transform(this.SCMOHQ[i].OfferingDate, 'dd/MM/yyyy'), 
          MemberName: this.SCMOHQ[i].MemberName,
          SCMOMonth: pipe.transform(this.SCMOHQ[i].SCMOMonth, 'MMM-yyyy'),
          SCMO: this.SCMOHQ[i].SCMO,
          MA: this.SCMOHQ[i].MA,
          FA: this.SCMOHQ[i].FA,
          MY: this.SCMOHQ[i].MY,
          FY: this.SCMOHQ[i].FY
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'SCMOHQ' + "_" + pipe.transform(this.formModel.forMonth, 'MMM/yyyy'));
    }
  }
  exportAsXLSSCMOHQ1() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "SCMOHQ") {
      var tmpArray = new Array();
      for (var i = 0; i < this.SCMOHQ.length; i++) {
        var tmp = {
          SrNo: this.SCMOHQ[i].SrNo,
          MemberName: this.SCMOHQ[i].MemberName,
          SCMOMonth: pipe.transform(this.SCMOHQ[i].SCMOMonth, 'MMM-yyyy'),
          SCMO: this.SCMOHQ[i].SCMO,
          MA: this.SCMOHQ[i].MA,
          FA: this.SCMOHQ[i].FA,
          MY: this.SCMOHQ[i].MY,
          FY: this.SCMOHQ[i].FY,
          ProcessingDate: pipe.transform(this.SCMOHQ[i].ProcessingDate, 'dd/MM/yyyy'),
          OfferingDate: pipe.transform(this.SCMOHQ[i].OfferingDate, 'dd/MM/yyyy')
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'SCMOHQ' + "_" + pipe.transform(this.formModel.forMonth, 'MMM/yyyy'));
    }
  }

  exportAsXLSXCheckListCent() {
    var pipe = new DatePipe('en-US');
    this.excelService.exportAsExcelFile(this.chkListDataCent, 'CHKLIST' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
  }

  deleteOffer(offerID, form: NgForm) {
    if (!confirm("Delete offering ?")) {
      return;
    }
    if(form.value.childCentreDetails == undefined){
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
    }else{
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
    }
    
    if( form.value.mainCentreDetails == undefined){
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName']
    }else{
    form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if(form.value.subCentreDetails == undefined){
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    }else{
    form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    }
    this.offerService.deleteOffer(offerID).subscribe((res: any) => {
      if (res.status == 1) {
        
        this.offerService.getOffers(form.value).subscribe((res: any) => {
          if (res.status == 1) {
            if (this.formModel.report == "O") {
              this.popuplateOffering(res.result);
            } else {
              this.popuplateMembers(res.result);
            }
          }
          else
      {
        alert(res.message);
        this.onClear();
      }
        });
        
      }
    });
  }
  deleteOtherOffering(otherOfferingID,form : NgForm) {
    if (!confirm("Delete Offering ?")) {
      return;
    }
    if(form.value.childCentreDetails == undefined){
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
    }else{
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
    }
    
    if( form.value.mainCentreDetails == undefined){
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName']
    }else{
    form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if(form.value.subCentreDetails == undefined){
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    }else{
    form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    }

    this.offerService.deleteOtherOffering(otherOfferingID).subscribe((res: any) => {
      if (res.status == 1) {

        this.offerService.getOffers(form.value).subscribe((res: any) => {
          if (res.status == 1) {
            if (this.formModel.report == "OO") {
              this.popuplateOtherOffering(res.result);
            } else if (this.formModel.report == "OM") {
              this.popuplateOtherOfferingMembers(res.result);
            } else if (this.formModel.report == "DP") {
              this.popuplateDivineProtection(res.result);
            } else if (this.formModel.report == "DO") {
              this.populateNewOfferingCountData(res.result);
            }else if (this.formModel.report == "OOF") {
              this.populateOtherOfferingNew(res.result);
            } else if (this.formModel.report == "DSCMO") {
              this.popuplateDailySCMO(res.result);
            } else if (this.formModel.report == "CHKLISTCENT") {
              this.popuplateCheckListCent(res.result);
            }
          }
          else{
            alert(res.message);
            this.onClear();
          }
          
        });

      }
    });
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  onOpenCalendarYear(container) {
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('year');
  }
  onOfferDateSelect(d: Date) {
    this.formModel.nextMonth = new Date(d.getFullYear(), d.getMonth()+1, 1);
    this.formModel.forMonth = new Date(d.getFullYear(), d.getMonth(), 1);
    this.formModel.lastMonth = new Date(d.getFullYear(), d.getMonth() - 1, 1);
    this.formModel.last2Months = new Date(d.getFullYear(), d.getMonth() - 2, 1);
    this.formModel.last6Months = new Date(d.getFullYear(), d.getMonth() - 6, 1);
    this.formModel.last7Months = new Date(d.getFullYear(), d.getMonth() - 7, 1);
    this.formModel.lastYear = new Date(d.getFullYear() - 1, 0, 1);
    this.formModel.prevYear = new Date(d.getFullYear() - 1, 0, 1);
    this.formModel.prevYear = new Date(d.getFullYear() + 1, 0, 1);
    //alert("1234");
  }
  onTillDateSelect(d: Date){

    this.formModel.tillDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }
  showHideReportOptions(queryOption) {
    do {
      if (queryOption == 'O') {
        if (this.formModel.showReportOOption == "Y") {
          this.formModel.showReportOOption = "N";
        } else {
          this.formModel.showReportOOption = "Y";
        }
        break;
      }
      if (queryOption == 'M') {
        if (this.formModel.showReportMOption == "Y") {
          this.formModel.showReportMOption = "N";
        } else {
          this.formModel.showReportMOption = "Y";
        }
        break;
      }
      if (queryOption == 'S') {
        if (this.formModel.showReportSOption == "Y") {
          this.formModel.showReportSOption = "N";
        } else {
          this.formModel.showReportSOption = "Y";
        }

        console.log(this.formModel.showReportSOption);
        break;
      }
      
    } while (false);
  }
  changeTab(){
    var element = document.getElementsByClassName("tab-head");
    if (element[0].className == 'tab-head active-tab') {
      element[0].className = 'tab-head';
      element[1].className = 'tab-head active-tab';
      return;
    }
    if (element[1].className == 'tab-head active-tab') {
      element[1].className = 'tab-head';
      element[0].className = 'tab-head active-tab';
    }
  }
  onMemberSearching(formModel) {
    //this.formModel.memberName = "";
    this.formModel.memberID ="";
    if (this.dataservice != null) {
      return;
    }
    if (formModel.memberName != null && (formModel.isMember == 'Y' || formModel.isMember == 'O')) {
      let allowLocation = localStorage.getItem('allowLocation');
      this.offerService.getMembers(formModel.isMember, allowLocation, formModel.memberName).subscribe((res: any) => {
        if (res != null) {
          this.dataservice = this.completerService.local(res, 'memberName', 'memberName');
        }
      })
    }
  }
  onMemberSelected(event) {
    if (event != null && event.originalObject != null) {
      var tmpArray = [];
      tmpArray = this.allMembersArrayData;
      tmpArray = tmpArray.filter(function (item) {
        return item.memberID == event.originalObject.memberID;
      });
      this.formModel.memberName = tmpArray[0].name;
      this.formModel.memberID = tmpArray[0].memberID;
      // this.formModel.mainCentre = tmpArray[0].mainCentre;
      // this.formModel.subCentre = tmpArray[0].subCentre;
      // this.formModel.childCentre = tmpArray[0].childCentre;
      let mainCentre = tmpArray[0].mainCentre;
      mainCentre = mainCentre.substring(0, 3);
      let childCentre = tmpArray[0].childCentre;
      if (childCentre != null) {
        childCentre = childCentre.substring(0, 3);
      }
      let subCentre = tmpArray[0].subCentre;
      if (subCentre != null) {
        subCentre = subCentre.substring(0, 3);
      }
      // this.formModel.MemberCentre = mainCentre + "/" + childCentre + "/" + subCentre;
      // this.formModel.status = tmpArray[0].status;
      // this.formModel.gender = tmpArray[0].gender;
      // this.formModel.aadhaarNo = tmpArray[0].aadhaarNo;
      // this.formModel.isAdultYN = tmpArray[0].isAdultYN;
      // this.formModel.panNo = tmpArray[0].panNo;
      // this.formModel.groupUser = tmpArray[0].groupUser;
      // this.formModel.groupLeader = tmpArray[0].groupLeader;
      // this.formModel.lastForMonth = "";
     // this.formModel.forMonth = "";
      //var pipe = new DatePipe('en-US');
      // if (tmpArray[0].forMonth != null) {
      //   var d = new Date(tmpArray[0].forMonth);
      //   var dd = new Date(d.getFullYear(), d.getMonth() + 1, d.getDate());
      //   //this.formModel.lastForMonth = pipe.transform(dd, 'MMM-yyyy');
      // } else {
      //   var d = new Date();
      //   //this.formModel.lastForMonth = pipe.transform( new Date(d.getFullYear(), d.getMonth() + 1, 1),"MMM-yyyy");
      // }
     // this.getLastOfferingDetails();
    }

  }
}
