import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompleterService, CompleterData } from 'ng2-completer';
import { OfferService } from '../../services/offer.service';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";

@Component({
  selector: 'app-additionalreports',
  templateUrl: './additionalreports.component.html',
  styleUrls: ['./additionalreports.component.css']
})
export class AdditionalreportsComponent implements OnInit {

  formModel = {
    loggedinUserRole: "",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName: "",
    subCentreID: "",
    showReportOOption: "",
    showReportMOption: "",
    showReportSOption: "",
    offerDateStart: null,
    offerDateEnd: null,
    totalAmt: null,
    offerMainCentre: "",
    offerChildCentre: "",
    offerSubCentre: "",
    memberName: "",
    memberID: "",
    report: "",
    reportName: "",
    memberDetails: "",
    forMonth: null,

    month1: null,
    month2: null,
    month3: null,
    month4: null,
    month5: null,
    month6: null,
    month7: null,
    month8: null,
    month9: null,
    month10: null,
    month11: null,
    month12: null,

    status: "",
    tillDate: null,
  }

  otherOfferingMembers = [];
  otherOfferingMembersWise = [];
  scmologbook = [];
  memwiseCashBankTotal = [];
  loggedInUserData = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  allTextChildCentreObj = {};
  allTextSubCentreObj = {};
  selectedReportObj = {};
  selectedStatusObj = {};
  reportTypeDetails = [];
  members: [];
  sortOrderByDetails = [];
  selectedSortOrder = {};
  selectedWithWithOutAddr = {};
  withWithOutAddr = [];
  kenshulist = [];
  allMembersArrayData: [];
  protected dataservice: CompleterData;
  protected dataservice1: CompleterData;

  constructor(private offerService: OfferService, private excelService: ExcelService, private dataSharingService: GlobalDataSharingService,
    private completerService: CompleterService, ) { }

  
  ngOnInit() {
    this.allMembersArrayData = this.dataSharingService.getAllMembersListing();
    let tmpArrayData = this.sortData(this.allMembersArrayData);
    let priKID = tmpArrayData[0].priKID;
    let iCnt = 0;
    let totalCnt = 0;
    let index = 0;
    var iActive = 0;
    var iAstral = 0;
    var iCheck = 0;
    var iDormantLt = 0;
    var iDormantGt = 0;
    var iORet = 0;
    var iTrfOut = 0;
    var iGActive = 0;
    var iGAstral = 0;
    var iGCheck = 0;
    var iGDormantLt = 0;
    var iGDormantGt = 0;
    var iGORet = 0;
    var iGTrfOut = 0;

    for (var j = 0; j < tmpArrayData.length; j++) {
      if (priKID == tmpArrayData[j].priKID) {
        if (tmpArrayData[j].status == 'Active') {
          iActive = iActive + 1;
          iGActive++;
        }
        if (tmpArrayData[j].status == 'Astral B') {
          iAstral = iAstral + 1;
          iGAstral++;
        }
        if (tmpArrayData[j].status == 'Check') {
          iCheck = iCheck + 1;
          iGCheck++
        }
        if (tmpArrayData[j].status == 'Dormant <5') {
          iDormantLt = iDormantLt + 1;
          iGDormantLt++
        }
        if (tmpArrayData[j].status == 'Dormant >5') {
          iDormantGt = iDormantGt + 1;
          iGDormantGt++
        }
        if (tmpArrayData[j].status == 'O Ret') {
          iORet = iORet + 1;
          iGORet++;
        }
        if (tmpArrayData[j].status == 'Trf Out') {
          iTrfOut = iTrfOut + 1;
          iGTrfOut++;
        }
      }

      if (priKID != tmpArrayData[j].priKID) {
        var tmp = {
          priKID: tmpArrayData[index].priKID,
          priKLecturer: tmpArrayData[index].priKLecturer,
          priKPlace: tmpArrayData[index].priKPlace,
          priKDate: tmpArrayData[index].priKDate,
          priKNo: tmpArrayData[index].priKNo,
          total: iCnt,
          Active:iActive,
          AstralB: iAstral,
          Check:iCheck,
          Dormantlt: iDormantLt,
          Dormantgt: iDormantGt,
          ORet: iORet,
          TrfOut: iTrfOut
        }
        this.kenshulist.push(tmp);
        index = j;
        priKID = tmpArrayData[j].priKID;
        iCnt = 0;
        iActive = 0;
        iAstral = 0;
        iCheck = 0;
        iDormantLt = 0;
        iDormantGt = 0;
        iORet = 0;
        iTrfOut = 0;
        if (tmpArrayData[j].status == 'Active') {
          iActive = iActive + 1;
          iGActive++;
        }
        if (tmpArrayData[j].status == 'Astral B') {
          iAstral = iAstral + 1;
          iGAstral++;
        }
        if (tmpArrayData[j].status == 'Check') {
          iCheck = iCheck + 1;
          iGCheck++
        }
        if (tmpArrayData[j].status == 'Dormant <5') {
          iDormantLt = iDormantLt + 1;
          iGDormantLt++
        }
        if (tmpArrayData[j].status == 'Dormant >5') {
          iDormantGt = iDormantGt + 1;
          iGDormantGt++
        }
        if (tmpArrayData[j].status == 'O Ret') {
          iORet = iORet + 1;
          iGORet++;
        }
        if (tmpArrayData[j].status == 'Trf Out') {
          iTrfOut = iTrfOut + 1;
          iGTrfOut++;
        }
      }
      
      iCnt++;
      totalCnt++;
    }
    var lastRec = {
      priKID: tmpArrayData[index].priKID,
      priKLecturer: "",
      priKPlace: "",
      priKDate: "",
      priKNo: "",
      total: iCnt,
      Active: iActive,
      AstralB: iAstral,
      Check: iCheck,
      Dormantlt: iDormantLt,
      Dormantgt: iDormantGt,
      ORet: iORet,
      TrfOut: iTrfOut
    }

    this.kenshulist.push(lastRec);
    var totalRec = {
      priKID: "",
      priKLecturer: "",
      priKPlace: "",
      priKDate: "",
      priKNo: "Grand Total",
      total: totalCnt,
      Active: iGActive,
      AstralB: iGAstral,
      Check: iGCheck,
      Dormantlt: iGDormantLt,
      Dormantgt: iGDormantGt,
      ORet: iGORet,
      TrfOut: iGTrfOut

    }
    this.kenshulist.push(totalRec);

     

    var d = new Date();
    this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
    this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
    var a = this.formModel.offerDateStart.getFullYear() + "/" + (this.formModel.offerDateStart.getMonth() + 1) + "/" + this.formModel.offerDateStart.getDate();
    var b = this.formModel.offerDateEnd.getFullYear() + "/" + (this.formModel.offerDateEnd.getMonth() + 1) + "/" + this.formModel.offerDateEnd.getDate();
    this.formModel.offerDateStart = new Date(a);
    this.formModel.offerDateEnd = new Date(b);

    let allowLocation = localStorage.getItem('allowLocation');
    let offData = allowLocation.split("/");
    this.formModel.offerMainCentre = offData[0];
    this.formModel.offerChildCentre = offData[1];
    this.formModel.offerSubCentre = offData[2];
    this.formModel.report = "OM";
    this.formModel.forMonth = new Date(d.getFullYear(), d.getMonth(), 1);

    this.formModel.month1 = new Date(d.getFullYear(), d.getMonth(), 1);
    this.formModel.month2 = new Date(d.getFullYear(), d.getMonth() + 1, 1);
    this.formModel.month3 = new Date(d.getFullYear(), d.getMonth() + 2, 1);
    this.formModel.month4 = new Date(d.getFullYear(), d.getMonth() + 3, 1);
    this.formModel.month5 = new Date(d.getFullYear(), d.getMonth() + 4, 1);
    this.formModel.month6 = new Date(d.getFullYear(), d.getMonth() + 5, 1);
    this.formModel.month7 = new Date(d.getFullYear(), d.getMonth() + 6, 1);
    this.formModel.month8 = new Date(d.getFullYear(), d.getMonth() + 7, 1);
    this.formModel.month9 = new Date(d.getFullYear(), d.getMonth() + 8, 1);
    this.formModel.month10 = new Date(d.getFullYear(), d.getMonth() + 9, 1);
    this.formModel.month11 = new Date(d.getFullYear(), d.getMonth() + 10, 1);
    this.formModel.month12 = new Date(d.getFullYear(), d.getMonth() + 11, 1);

    this.formModel.tillDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    this.formModel.showReportOOption = 'N';
    this.formModel.showReportMOption = 'N';
    this.formModel.showReportSOption = 'N';
    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.loggedInUserData = this.dataSharingService.getLoggedInUserData();
    this.formModel.mainCentreName = this.loggedInUserData[0].mainCentreName;
    this.formModel.childCentreName = this.loggedInUserData[0].childCentreName;
    this.formModel.subCentreName = this.loggedInUserData[0].subCentreName;
    this.formModel.mainCentreID = this.loggedInUserData[0].mainCentreID;
    this.formModel.childCentreID = this.loggedInUserData[0].childCentreID;
    this.formModel.subCentreID = this.loggedInUserData[0].subCentreID;
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
    this.allTextChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };

    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedReportObj = {
      id: 0,
      type: 'Processing Centre Only'
    };

    this.reportTypeDetails = [];
    this.reportTypeDetails.push(this.selectedReportObj);

    var tmpArry = [
      {
        id: 1,
        type: "Processing Centre Actual"
      },
    ];

    for (i = 0; i < tmpArry.length; i++) {
      this.reportTypeDetails.push(tmpArry[i]);
    }

    this.selectedStatusObj = {
      statusID: 0,
      statusName: "All"
    };

    this.selectedSortOrder = {
      id: "no",
      type: "No"
    };

    var tmpArry1 = [];
    this.sortOrderByDetails = [];
    this.sortOrderByDetails.push(this.selectedSortOrder);
    tmpArry1 = [
      {
        id: "nm",
        type: "Name"
      },
      {
        id: "st",
        type: "Status"
      },
      {
        id: "ct",
        type: "Centre"
      }
    ];
    for (i = 0; i < tmpArry1.length; i++) {
      this.sortOrderByDetails.push(tmpArry1[i]);
    }

    tmpArry1 = [];
    
    this.selectedWithWithOutAddr= {
      id: "w",
      type: "With Address"
    };
    this.withWithOutAddr = [];
    this.withWithOutAddr.push(this.selectedWithWithOutAddr);
    tmpArry1 = [
      {
        id: "o",
        type: "Without Address"
      }
    ]; 
    for (i = 0; i < tmpArry1.length; i++) {
      this.withWithOutAddr.push(tmpArry1[i]);
    }

    var i = 0;
    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.mainCentreDetails.push(this.selectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.childCentreDetails.push(this.selectedChildCentreObj);
        this.subCentreDetails.push(this.selectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }

      default: {
        break;
      }
    }
    this.otherOfferingMembers = this.dataSharingService.getSearchedOtherOfferingMemberData();
    this.scmologbook = this.dataSharingService.getSearchedOfferSCMOLogbookData();
    this.formModel.reportName = "Offering Member Wise";
    var testArry = [];
    testArry = this.allMembersArrayData;
    testArry = testArry.slice(0, 50);
    this.dataservice = this.completerService.local(testArry, 'name', 'name');
    this.dataservice1 = this.completerService.local(testArry, 'name', 'name');
  }

  onChangeMainCentre(mainCentre) {
    do {
      this.childCentreDetails = [];
      this.childCentreDetails.push(this.allTextChildCentreObj);
      this.selectedChildCentreObj = this.allTextChildCentreObj;

      for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
    } while (false);

  }

  onChangeChildCentre(childCentre) {
    do {
      this.subCentreDetails = [];
      this.subCentreDetails.push(this.allTextSubCentreObj);
      this.selectedSubCentreObj = this.allTextSubCentreObj;
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
    } while (false);
  }

  showHideReportOptions() {
    if (this.formModel.showReportOOption == "Y") {
      this.formModel.showReportOOption = "N";
    } else {
      this.formModel.showReportOOption = "Y";
    }
  }
  onEditClicked(editOption) {
    this.dataSharingService.setEditOfferOptionClicked(editOption);
  }

  onMemberSelected(event) {
    if (event != null && event.originalObject != null) {
      var tmpArray = [];
      tmpArray = this.allMembersArrayData;

      tmpArray = tmpArray.filter(function (item) {
        return item.memberID == event.originalObject.memberID;
      });
      this.formModel.memberName = tmpArray[0].name;
      this.formModel.memberID = tmpArray[0].memberID;
    }
  }
  onMemberSearching(searchMemberName) {
    this.formModel.memberID = "";
    var str = new String(searchMemberName);
    if (this.dataservice1 != null && str.length >=3 ) {
      var tmpArray = [];
      tmpArray = this.allMembersArrayData;
      tmpArray = tmpArray.filter(function (item) {
        return item.name.toLowerCase().includes(searchMemberName.toLowerCase());
      });
      this.dataservice1 = this.completerService.local(tmpArray, 'name', 'name');
      this.formModel.memberID = tmpArray[0].memberID;
      return;
    }
  }
  onMemberSearching1(searchMemberName) {
    this.formModel.memberID = "";
    var str = new String(searchMemberName);
    if (this.dataservice1 != null && str.length >= 3) {
      var tmpArray = [];
      tmpArray = this.allMembersArrayData;
      tmpArray = tmpArray.filter(function (item) {
        return item.name == item.name.toLowerCase().includes(searchMemberName.toLowerCase());
      });
      /*
      if (tmpArray == null || tmpArray == undefined || tmpArray[0] == undefined) {
        alert("Invalid Member Name / Member ID - 1");
        return;
      }*/

      this.dataservice = this.completerService.local(tmpArray, 'name', 'name');
      this.formModel.memberID = tmpArray[0].memberID;
      return;
    }
  }

  getSelectedMemberDetails() {
    //this.formModel.memberDetails = "";
    var searchMemberName = this.formModel.memberName;
    if (this.isEmpty(searchMemberName)) {
      return;
    }
    var tmpArray = [];
    tmpArray = this.allMembersArrayData;
    tmpArray = tmpArray.filter(function (item) {
      return item.name == searchMemberName;
    });
    if (tmpArray == null || tmpArray == undefined || tmpArray[0] == undefined) {
      //alert("Invalid Member Name / Member ID - 1");
      return;
    }

    for (var i = 0; i < tmpArray.length; i++) {
      if (tmpArray[i].name == searchMemberName) {
        this.formModel.memberID = tmpArray[0].memberID;
        this.formModel.memberDetails = tmpArray[0].memberID + " - " + tmpArray[0].name + " - " + tmpArray[0].mainCentre + "/" + tmpArray[0].childCentre + "/" + tmpArray[0].subCentre;
        //console.log(tmpArray);
        break;
      }
    }

  }

  memberName = "memberName";


  onSearch(form: NgForm) {
    this.onClear();
    /*
    if (this.formModel.memberName != "" && this.formModel.memberID == "") {
      alert("Please select member name");
      return;
    } */

    var pipe = new DatePipe('en-US')
    if (form.value.offerDateStart != undefined) {

      form.value.offerDateStart = pipe.transform(form.value.offerDateStart, 'MM/dd/yyyy')
      form.value.offerDateEnd = pipe.transform(form.value.offerDateEnd, 'MM/dd/yyyy')
    }
    if (form.value.tillDate != undefined) {
      form.value.tillDate = pipe.transform(form.value.tillDate, 'MM/dd/yyyy');
    }
    if (form.value.childCentreDetails == undefined) {
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
    } else {
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
    }

    if (form.value.mainCentreDetails == undefined) {
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName']
    } else {
      form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if (form.value.subCentreDetails == undefined) {
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    } else {
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    }

    if (form.value.statusDetails == undefined) {
      form.value.status = this.selectedStatusObj['statusName']
    } else {
      form.value.status = form.value.statusDetails.statusName;
    }
    form.value.memberID = this.formModel.memberID;

    if (this.formModel.report == "OMSUMMARY" || this.formModel.report == "OM" )
    {
      var searchMemberName = this.formModel.memberName;
      var tmpArray = [];
      tmpArray = this.allMembersArrayData;
      tmpArray = tmpArray.filter(function (item) {
        return item.name == searchMemberName;
        //item.name.toLowerCase().includes(searchMemberName.toLowerCase());
      });
      if (tmpArray == null || tmpArray == undefined || tmpArray[0] == undefined) {
        alert("Invalid Member Name / Member ID - 1");
        return;
      }

      for (var i = 0; i < tmpArray.length; i++) {
        if (tmpArray[i].name == searchMemberName) {
          this.formModel.memberID = tmpArray[0].memberID;
          form.value.memberID = tmpArray[0].memberID;
          console.log(tmpArray[0]);
          break;
        }
      }
    }

    //console.log(form.value.memberID);
    //alert(form.value.memberID);
    this.offerService.getOffers(form.value).subscribe((res: any) => {
      if (res.status == 1) {
        do {
          if (this.formModel.report == "OM") {
            this.popuplateOtherOfferingMembers(res.result);
          } else if (this.formModel.report == "SL") {
            this.populateSCMOLogbook(res.result, form.value);
          } else if (this.formModel.report == "OMSUMMARY") {
            this.popuplateOtherOfferingMembersSummary(res.result);
          } else if (this.formModel.report == "MWCBT") {
            this.popuplateMemberWiseCashBankTotal(res.result);
          } 
          form.value.memberID = "";
        } while (false);
      } else {
        alert(res.message);
        this.onClear();
      }
    });
  }

  onClear() {
    this.formModel.memberDetails = "";
    do {
      if (this.formModel.report == "OM") {
        this.otherOfferingMembers = [];
      } else if (this.formModel.report == "SL") {
        this.scmologbook = [];
      } else if (this.formModel.report == "MWCBT") {
        this.memwiseCashBankTotal = [];
      } else if (this.formModel.report == "OMSUMMARY") {
        this.otherOfferingMembersWise = [];
      }
    } while (false);
  }

  //  Offering Member Wise
  popuplateOtherOfferingMembers(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var totalAmt = 0;
    var memberName = tmpList[0].memberName;
    var dateWiseTot = 0;
    var dateWiseSCMO = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var totalOtamagushi = 0;
    var totalOkiyome = 0;
    var totalGoshintai = 0;
    var totalAncestors = 0;
    var totalOmitama = 0;
    var totalGoshugo = 0;
    var totalOther = 0;
    var totalSCMO = 0;
    var totalCountryM = 0;
    var totalCentreM = 0;
    var iCount = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    for (var i = 0; i < tmpList.length; i++) {
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      if (tmpList[i].memberName == memberName) {
        var memberCentre = tmpList[i].mainCentre.substr(0, 3);
        if (tmpList[i].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
        }
        if (tmpList[i].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
        }
        var processing = tmpList[i].offerMainCentre.substr(0, 3);
        if (tmpList[i].offerChildCentre != "") {
          processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
        }
        if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
          processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
        }
        dateWiseTot = dateWiseTot + parseInt(tmpList[i].total);
        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;
        dateWiseSCMO = dateWiseSCMO + rScmo;
        var tmp = {
          SrNo: i + 1,
          otherOfferingID: tmpList[i].otherOfferingID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          Actual: memberCentre,
          Processing: processing,
          SlipNo: tmpList[i].slipNo,
          Status: tmpList[i].status,
          SCMO: rScmo,
          SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
          CountryM: rCountryM,
          CentreM: rCentreM,
          Otamagushi: rOtamagushi,
          Okiyome: rOkiyome,
          Goshintai: rGoshintai,
          Ancestors: rAncestors,
          Omitama: rOmitama,
          Goshugo: rGoshugo,
          Other: rOther,
          Total: tmpList[i].total,
          ChequeNumber: tmpList[i].chequeNo,
          ChequeDate: tmpList[i].chequeDate,
          BankName: tmpList[i].bankName,
          BankBranchName: tmpList[i].bankBranchName
        }
        iCount = iCount + 1;
        tmpArray.push(tmp);
        tmpSearched.push(tmp);
      } else {
        var dummy = {
          SrNo: "",
          otherOfferingID: "",
          ProcessingDate: "",
          OfferingDate: "",
          MemberName: iCount,
          Actual: "",
          Processing: "",
          SlipNo: "",
          Status: "",
          SCMO: dateWiseSCMO,
          SCMOMonth: "",
          CountryM: dateWiseCountryM,
          CentreM: dateWiseCentreM,
          Otamagushi: dateWiseOtamagushi,
          Okiyome: dateWiseOkiyome,
          Goshintai: dateWiseGoshintai,
          Ancestors: dateWiseAncestors,
          Omitama: dateWiseOmitama,
          Goshugo: dateWiseGoshugo,
          Other: dateWiseOther,
          Total: dateWiseTot,
          ChequeNumber: "",
          ChequeDate: "",
          BankName: "",
          BankBranchName: ""
        }
        tmpArray.push(dummy);
        iCount = 1;
        memberCentre = tmpList[i].mainCentre.substr(0, 3);
        if (tmpList[i].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
        }
        if (tmpList[i].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
        }
        processing = tmpList[i].offerMainCentre.substr(0, 3);
        if (tmpList[i].offerChildCentre != "") {
          processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
        }
        if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
          processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
        }
        memberName = tmpList[i].memberName;
        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo;
        dateWiseOther = rOther;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;
        dateWiseSCMO = rScmo;
        var tmp1 = {
          SrNo: i + 1,
          otherOfferingID: tmpList[i].otherOfferingID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          Actual: memberCentre,
          Processing: processing,
          SlipNo: tmpList[i].slipNo,
          Status: tmpList[i].status,
          SCMO: rScmo,
          SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
          CountryM: rCountryM,
          CentreM: rCentreM,
          Otamagushi: rOtamagushi,
          Okiyome: rOkiyome,
          Goshintai: rGoshintai,
          Ancestors: rAncestors,
          Omitama: rOmitama,
          Goshugo: rGoshugo,
          Other: rOther,
          Total: tmpList[i].total,
          ChequeNumber: tmpList[i].chequeNo,
          ChequeDate: tmpList[i].chequeDate,
          BankName: tmpList[i].bankBranchName
        }
        tmpArray.push(tmp1);
        tmpSearched.push(tmp1);
      }
      totalAmt = totalAmt + parseInt(tmpList[i].total);
      totalOtamagushi = totalOtamagushi + rOtamagushi;
      totalOkiyome = totalOkiyome + rOkiyome;
      totalGoshintai = totalGoshintai + rGoshintai;
      totalAncestors = totalAncestors + rAncestors;
      totalOmitama = totalOmitama + rOmitama;
      totalGoshugo = totalGoshugo + rGoshugo;
      totalOther = totalOther + rOther;
      totalSCMO = totalSCMO + rScmo;
      totalCountryM = totalCountryM + rCountryM;
      totalCentreM = totalCentreM + rCentreM;
    }//end for
    var dummy1 = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: iCount,
      Actual: "",
      Processing: "",
      SlipNo: "",
      Status: "",
      SCMO: dateWiseSCMO,
      SCMOMonth: "",
      CountryM: dateWiseCountryM,
      CentreM: dateWiseCentreM,
      Otamagushi: dateWiseOtamagushi,
      Okiyome: dateWiseOkiyome,
      Goshintai: dateWiseGoshintai,
      Ancestors: dateWiseAncestors,
      Omitama: dateWiseOmitama,
      Goshugo: dateWiseGoshugo,
      Other: dateWiseOther,
      Total: dateWiseTot,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: ""
    }
    tmpArray.push(dummy1);
    this.formModel.totalAmt = totalAmt;
    var dummy2 = {
      SrNo: "",
      otherOfferingID: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "Grand Total",
      Actual: "",
      Processing: "",
      SlipNo: "",
      Status: "",
      SCMO: totalSCMO,
      CountryM: totalCountryM,
      CentreM: totalCentreM,
      Otamagushi: totalOtamagushi,
      Okiyome: totalOkiyome,
      Goshintai: totalGoshintai,
      Ancestors: totalAncestors,
      Omitama: totalOmitama,
      Goshugo: totalGoshugo,
      Other: totalOther,
      Total: totalAmt,
      ChequeNumber: "",
      ChequeDate: "",
      BankName: "",
      BankBranchName: ""
    }
    tmpArray.push(dummy2);
    this.otherOfferingMembers = tmpArray;
    this.dataSharingService.setSearchedOtherOfferingMemberData(tmpSearched, tmpArray);
  }

  exportAsXLSXOM() {
    var pipe = new DatePipe('en-US');
    var tmpArray = new Array();
    if (this.formModel.report == "OM") {
      if (this.formModel.memberName == "") {
        for (var i = 0; i < this.otherOfferingMembers.length; i++) {
          var tmp = {
            SrNo: this.otherOfferingMembers[i].SrNo,
            ProcessingDate: pipe.transform(this.otherOfferingMembers[i].ProcessingDate, 'dd/MM/yyyy'),
            OfferingDate: pipe.transform(this.otherOfferingMembers[i].OfferingDate, 'dd/MM/yyyy'),
            MemberName: this.otherOfferingMembers[i].MemberName,
            SlipNo: this.otherOfferingMembers[i].SlipNo,
            Status: this.otherOfferingMembers[i].Status,
            OTA: this.otherOfferingMembers[i].Otamagushi,
            OKI: this.otherOfferingMembers[i].Okiyome,
            Ghoshugo: this.otherOfferingMembers[i].Goshugo,
            SCMO: this.otherOfferingMembers[i].SCMO,
            CountryM: this.otherOfferingMembers[i].CountryM,
            CentreM: this.otherOfferingMembers[i].CentreM,
            GoshApp: this.otherOfferingMembers[i].Goshintai,
            AncAlt: this.otherOfferingMembers[i].Ancestors,
            Omitama: this.otherOfferingMembers[i].Omitama,
            Others: this.otherOfferingMembers[i].Other,
            Total: this.otherOfferingMembers[i].Total,
            Chq: this.otherOfferingMembers[i].ChequeNumber,
            Date: pipe.transform(this.otherOfferingMembers[i].ChequeDate, 'dd/MM/yyyy'),
            BankName: this.otherOfferingMembers[i].BankName,
            BankBranchName: this.otherOfferingMembers[i].BankBranchName,
          }
          tmpArray.push(tmp);
        }
        this.excelService.exportAsExcelFile(tmpArray, 'OM_All' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
      }
      else {
        for (var i = 0; i < this.otherOfferingMembers.length; i++) {
          var tmp = {
            SrNo: this.otherOfferingMembers[i].SrNo,
            ProcessingDate: pipe.transform(this.otherOfferingMembers[i].ProcessingDate, 'dd/MM/yyyy'),
            OfferingDate: pipe.transform(this.otherOfferingMembers[i].OfferingDate, 'dd/MM/yyyy'),
            MemberName: this.otherOfferingMembers[i].MemberName,
            SlipNo: this.otherOfferingMembers[i].SlipNo,
            Status: this.otherOfferingMembers[i].Status,
            OTA: this.otherOfferingMembers[i].Otamagushi,
            OKI: this.otherOfferingMembers[i].Okiyome,
            Ghoshugo: this.otherOfferingMembers[i].Goshugo,
            SCMO: this.otherOfferingMembers[i].SCMO,
            CountryM: this.otherOfferingMembers[i].CountryM,
            CentreM: this.otherOfferingMembers[i].CentreM,
            GoshApp: this.otherOfferingMembers[i].Goshintai,
            AncAlt: this.otherOfferingMembers[i].Ancestors,
            Omitama: this.otherOfferingMembers[i].Omitama,
            Others: this.otherOfferingMembers[i].Other,
            Total: this.otherOfferingMembers[i].Total,
            Chq: this.otherOfferingMembers[i].ChequeNumber,
            Date: pipe.transform(this.otherOfferingMembers[i].ChequeDate, 'dd/MM/yyyy'),
            BankName: this.otherOfferingMembers[i].BankName,
            BankBranchName: this.otherOfferingMembers[i].BankBranchName,
          }
          tmpArray.push(tmp);
        }
        this.excelService.exportAsExcelFile(tmpArray, 'OM_MemberId' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
      }
    }

    if (this.formModel.report == "OMSUMMARY") {
      for (var i = 0; i < this.otherOfferingMembersWise.length; i++) {
        var tmp1 = {
          SrNo: this.otherOfferingMembersWise[i].SrNo,
          ProcessingDate: pipe.transform(this.otherOfferingMembersWise[i].ProcessingDate, 'dd/MM/yyyy'),
          OfferingDate: pipe.transform(this.otherOfferingMembersWise[i].OfferingDate, 'dd/MM/yyyy'),
          MemberName: this.otherOfferingMembersWise[i].MemberName,
          SlipNo: this.otherOfferingMembersWise[i].SlipNo,
          Status: this.otherOfferingMembersWise[i].Status,
          OTA: this.otherOfferingMembersWise[i].Otamagushi,
          OKI: this.otherOfferingMembersWise[i].Okiyome,
          Ghoshugo: this.otherOfferingMembersWise[i].Goshugo,
          SCMO: this.otherOfferingMembersWise[i].SCMO,
          CountryM: this.otherOfferingMembersWise[i].CountryM,
          CentreM: this.otherOfferingMembersWise[i].CentreM,
          GoshApp: this.otherOfferingMembersWise[i].Goshintai,
          AncAlt: this.otherOfferingMembersWise[i].Ancestors,
          Omitama: this.otherOfferingMembersWise[i].Omitama,
          Others: this.otherOfferingMembersWise[i].Other,
          Total: this.otherOfferingMembersWise[i].Total,
          CashTotal: this.otherOfferingMembersWise[i].CashTotal,
          BankTotal: this.otherOfferingMembersWise[i].BankTotal,
          DiffTotal: this.otherOfferingMembersWise[i].DiffTotal,
        }
        tmpArray.push(tmp1);

      }

      if (this.formModel.memberName == "") {
        this.excelService.exportAsExcelFile(tmpArray, 'OMSummary_All' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));

      } else {
        this.excelService.exportAsExcelFile(tmpArray, 'OMSummary_MemberId' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));

      }
    }
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  // Offering Member Wise Summary
  popuplateOtherOfferingMembersSummary(data) {
    //alert(this.formModel.memberName);
    var tmpArray = new Array();
    var groupByArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var totalAmt = 0;
    var memberName = tmpList[0].memberName;

    var memWiseTot = 0;
    var memWiseSCMO = 0;
    var memWiseCountryM = 0;
    var memWiseCentreM = 0;
    var memWiseOtamagushi = 0;
    var memWiseOkiyome = 0;
    var memWiseGoshintai = 0;
    var memWiseAncestors = 0;
    var memWiseOmitama = 0;
    var memWiseGoshugo = 0;
    var memWiseOther = 0;

    var totalOtamagushi = 0;
    var totalOkiyome = 0;
    var totalGoshintai = 0;
    var totalAncestors = 0;
    var totalOmitama = 0;
    var totalGoshugo = 0;
    var totalOther = 0;
    var totalSCMO = 0;
    var totalCountryM = 0;
    var totalCentreM = 0;
    var iCount = 1;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;

    var cashTotal = 0;
    var bankTotal = 0;
    var diffTotal = 0;

    for (var i = 0; i < tmpList.length; i++) {
      cashTotal = 0;
      bankTotal = 0;
      diffTotal = 0;
      var memberCentre = tmpList[i].mainCentre.substr(0, 3);
      if (tmpList[i].childCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
      }
      if (tmpList[i].subCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
      }
      var processing = tmpList[i].offerMainCentre.substr(0, 3);
      if (tmpList[i].offerChildCentre != "") {
        processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
      }
      if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
        processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
      }

      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      if (this.isEmpty(tmpList[i].chequeNo)) {
        cashTotal = parseInt(tmpList[i].total);
      } else {
        bankTotal = parseInt(tmpList[i].total);
      }
      totalAmt = totalAmt + parseInt(tmpList[i].total);
      totalOtamagushi = totalOtamagushi + rOtamagushi;
      totalOkiyome = totalOkiyome + rOkiyome;
      totalGoshintai = totalGoshintai + rGoshintai;
      totalAncestors = totalAncestors + rAncestors;
      totalOmitama = totalOmitama + rOmitama;
      totalGoshugo = totalGoshugo + rGoshugo;
      totalOther = totalOther + rOther;
      totalSCMO = totalSCMO + rScmo;
      totalCountryM = totalCountryM + rCountryM;
      totalCentreM = totalCentreM + rCentreM;

      if (tmpList[i].memberName == memberName) {
        memWiseTot = memWiseTot + parseInt(tmpList[i].total);
        memWiseOtamagushi = memWiseOtamagushi + rOtamagushi;
        memWiseOkiyome = memWiseOkiyome + rOkiyome;
        memWiseGoshintai = memWiseGoshintai + rGoshintai;
        memWiseAncestors = memWiseAncestors + rAncestors;
        memWiseOmitama = memWiseOmitama + rOmitama;
        memWiseGoshugo = memWiseGoshugo + rGoshugo;
        memWiseOther = memWiseOther + rOther;
        memWiseCountryM = memWiseCountryM + rCountryM;
        memWiseCentreM = memWiseCentreM + rCentreM;
        memWiseSCMO = memWiseSCMO + rScmo;

        var tmp = {
          SrNo: iCount,
          otherOfferingID: tmpList[i].otherOfferingID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          Actual: memberCentre,
          Processing: processing,
          SlipNo: tmpList[i].slipNo,
          Status: tmpList[i].status,
          SCMO: rScmo,
          SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
          CountryM: rCountryM,
          CentreM: rCentreM,
          Otamagushi: rOtamagushi,
          Okiyome: rOkiyome,
          Goshintai: rGoshintai,
          Ancestors: rAncestors,
          Omitama: rOmitama,
          Goshugo: rGoshugo,
          Other: rOther,
          Total: tmpList[i].total,
          CashTotal: cashTotal,
          BankTotal: bankTotal
        }
        tmpArray.push(tmp);

      } else {
        var tmpArrayCashTotal = 0;
        var tmpArrayBankTotal = 0;
        var tmpArrayDiffTotal = 0;
        var tmpArrayMemTotal = 0;
        for (var k = 0; k < tmpArray.length; k++) {
          tmpArrayCashTotal = tmpArrayCashTotal + parseInt(tmpArray[k].CashTotal);
          tmpArrayBankTotal = tmpArrayBankTotal + parseInt(tmpArray[k].BankTotal);
          tmpArrayMemTotal = tmpArrayMemTotal + parseInt(tmpArray[k].Total);
        }
        tmpArrayDiffTotal = tmpArrayCashTotal + tmpArrayBankTotal;
        tmpArrayDiffTotal = tmpArrayDiffTotal - tmpArrayMemTotal;
        var memberObj = {
          SrNo: iCount,
          otherOfferingID: tmpArray[0].otherOfferingID,
          ProcessingDate: tmpArray[0].ProcessingDate,
          OfferingDate: tmpArray[0].OfferingDate,
          MemberName: tmpArray[0].MemberName,
          Actual: tmpArray[0].Actual,
          Processing: tmpArray[0].Processing,
          SlipNo: tmpArray[0].SlipNo,
          Status: tmpArray[0].Status,
          SCMO: memWiseSCMO,
          SCMOMonth: tmpArray[0].SCMOMonth,
          CountryM: memWiseCountryM,
          CentreM: memWiseCentreM,
          Otamagushi: memWiseOtamagushi,
          Okiyome: memWiseOkiyome,
          Goshintai: memWiseGoshintai,
          Ancestors: memWiseAncestors,
          Omitama: memWiseOmitama,
          Goshugo: memWiseGoshugo,
          Other: memWiseOther,
          Total: memWiseTot,
          CashTotal: tmpArrayCashTotal,
          BankTotal: tmpArrayBankTotal,
          DiffTotal: tmpArrayDiffTotal
        } 
        groupByArray.push(memberObj);
        tmpArray = new Array();
        iCount = iCount + 1;


        memberName = tmpList[i].memberName;
        memWiseTot = 0;
        memWiseSCMO = 0;
        memWiseCountryM = 0;
        memWiseCentreM = 0;
        memWiseOtamagushi = 0;
        memWiseOkiyome = 0;
        memWiseGoshintai = 0;
        memWiseAncestors = 0;
        memWiseOmitama = 0;
        memWiseGoshugo = 0;
        memWiseOther = 0;

        memWiseTot = memWiseTot + parseInt(tmpList[i].total);
        memWiseOtamagushi = memWiseOtamagushi + rOtamagushi;
        memWiseOkiyome = memWiseOkiyome + rOkiyome;
        memWiseGoshintai = memWiseGoshintai + rGoshintai;
        memWiseAncestors = memWiseAncestors + rAncestors;
        memWiseOmitama = memWiseOmitama + rOmitama;
        memWiseGoshugo = memWiseGoshugo + rGoshugo;
        memWiseOther = memWiseOther + rOther;
        memWiseCountryM = memWiseCountryM + rCountryM;
        memWiseCentreM = memWiseCentreM + rCentreM;
        memWiseSCMO = memWiseSCMO + rScmo;
        cashTotal = 0;
        bankTotal = 0;
        if (this.isEmpty(tmpList[i].chequeNo)) {
          cashTotal = parseInt(tmpList[i].total);
        } else {
          bankTotal = parseInt(tmpList[i].total);
        }

        var tmp = {
          SrNo: iCount,
          otherOfferingID: tmpList[i].otherOfferingID,
          ProcessingDate: tmpList[i].processingDate,
          OfferingDate: tmpList[i].offerDate,
          MemberName: tmpList[i].memberName,
          Actual: memberCentre,
          Processing: processing,
          SlipNo: tmpList[i].slipNo,
          Status: tmpList[i].status,
          SCMO: rScmo,
          SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
          CountryM: rCountryM,
          CentreM: rCentreM,
          Otamagushi: rOtamagushi,
          Okiyome: rOkiyome,
          Goshintai: rGoshintai,
          Ancestors: rAncestors,
          Omitama: rOmitama,
          Goshugo: rGoshugo,
          Other: rOther,
          Total: tmpList[i].total,
          CashTotal: cashTotal,
          BankTotal: bankTotal
        }
        tmpArray.push(tmp);
      }
    }
    var tmpArrayCashTotal = 0;
    var tmpArrayBankTotal = 0;
    var tmpArrayDiffTotal = 0;
    var tmpArrayMemTotal = 0;
    for (var k = 0; k < tmpArray.length; k++) {
      tmpArrayCashTotal = tmpArrayCashTotal + parseInt(tmpArray[k].CashTotal);
      tmpArrayBankTotal = tmpArrayBankTotal + parseInt(tmpArray[k].BankTotal);
      tmpArrayMemTotal = tmpArrayMemTotal + parseInt(tmpArray[k].Total);
    }
    tmpArrayDiffTotal = tmpArrayCashTotal + tmpArrayBankTotal;
    tmpArrayDiffTotal = tmpArrayDiffTotal - tmpArrayMemTotal;
    var memberObj = {
      SrNo: iCount,
      otherOfferingID: tmpArray[0].otherOfferingID,
      ProcessingDate: tmpArray[0].ProcessingDate,
      OfferingDate: tmpArray[0].OfferingDate,
      MemberName: tmpArray[0].MemberName,
      Actual: tmpArray[0].Actual,
      Processing: tmpArray[0].Processing,
      SlipNo: tmpArray[0].SlipNo,
      Status: tmpArray[0].Status,
      SCMO: memWiseSCMO,
      SCMOMonth: tmpArray[0].SCMOMonth,
      CountryM: memWiseCountryM,
      CentreM: memWiseCentreM,
      Otamagushi: memWiseOtamagushi,
      Okiyome: memWiseOkiyome,
      Goshintai: memWiseGoshintai,
      Ancestors: memWiseAncestors,
      Omitama: memWiseOmitama,
      Goshugo: memWiseGoshugo,
      Other: memWiseOther,
      Total: memWiseTot,
      CashTotal: tmpArrayCashTotal,
      BankTotal: tmpArrayBankTotal,
      DiffTotal: tmpArrayDiffTotal
    }
    groupByArray.push(memberObj);
    this.otherOfferingMembersWise = groupByArray;
    this.formModel.totalAmt = totalAmt; 
 }

  // End of Offering Member Wise Summary

  //MemberWise Cash Bank Total
  popuplateMemberWiseCashBankTotal(data) {
    var tmpArray = new Array();
    var groupByArray = new Array();
    var tmpList = data;
    var totalAmt = 0;
    var memberID = tmpList[0].memberID;
    var memWiseTot = 0;
    var iCount = 1;
    var cashTotal = 0;
    var bankTotal = 0;
    var gCashTotal = 0;
    var gBankTotal = 0;
    var gDiffTotal = 0;
    var gTotal = 0;

    for (var i = 0; i < tmpList.length; i++) {
      cashTotal = 0;
      bankTotal = 0;
      if (this.isEmpty(tmpList[i].chequeNo)) {
        cashTotal = parseInt(tmpList[i].total);
      } else {
        bankTotal = parseInt(tmpList[i].total);
      }
      totalAmt = totalAmt + parseInt(tmpList[i].total);
      gTotal = gTotal + parseInt(tmpList[i].total);

      if (tmpList[i].memberID == memberID) {
        memWiseTot = memWiseTot + parseInt(tmpList[i].total);
        var tmp = {
          SrNo: iCount,
          MemberID: tmpList[i].memberID,
          MemberName: tmpList[i].memberName,
          Total: tmpList[i].total,
          CashTotal: cashTotal,
          BankTotal: bankTotal
        }
        tmpArray.push(tmp);
      } else {
        var tmpArrayCashTotal = 0;
        var tmpArrayBankTotal = 0;
        var tmpArrayDiffTotal = 0;
        var tmpArrayMemTotal = 0;
        for (var k = 0; k < tmpArray.length; k++) {
          tmpArrayCashTotal = tmpArrayCashTotal + parseInt(tmpArray[k].CashTotal);
          tmpArrayBankTotal = tmpArrayBankTotal + parseInt(tmpArray[k].BankTotal);
          tmpArrayMemTotal = tmpArrayMemTotal + parseInt(tmpArray[k].Total);
          gCashTotal = gCashTotal + parseInt(tmpArray[k].CashTotal);
          gBankTotal = gBankTotal + parseInt(tmpArray[k].BankTotal);
        }
        tmpArrayDiffTotal = tmpArrayCashTotal + tmpArrayBankTotal;
        tmpArrayDiffTotal = tmpArrayDiffTotal - tmpArrayMemTotal;
        gDiffTotal = gDiffTotal + tmpArrayDiffTotal;
        var tmpMemberArray = [];
        tmpMemberArray = this.allMembersArrayData;
        tmpMemberArray = tmpMemberArray.filter(function (item) {
          return item.memberID == tmpArray[0].MemberID;
        });
        var memberCentre = tmpMemberArray[0].mainCentre.substr(0, 3);
        if (tmpMemberArray[0].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpMemberArray[0].childCentre.substr(0, 3);
        }
        if (tmpMemberArray[0].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpMemberArray[0].subCentre.substr(0, 3);
        }
        var memberObj = {
          SrNo: iCount,
          MemberID: tmpArray[0].MemberID,
          MemberName: tmpArray[0].MemberName,
          Total: memWiseTot,
          CashTotal: tmpArrayCashTotal,
          BankTotal: tmpArrayBankTotal,
          DiffTotal: tmpArrayDiffTotal,
          Gender: tmpMemberArray[0].gender,
          Status: tmpMemberArray[0].status,
          Centre: memberCentre,
          Phone: tmpMemberArray[0].mobileNo,
          Address1: tmpMemberArray[0].addressL1,
          Address2: tmpMemberArray[0].addressL2,
          Address3: tmpMemberArray[0].addressL3,
          City: tmpMemberArray[0].city,
          ZipCode: tmpMemberArray[0].zipCode,
          AadhaarNo: tmpMemberArray[0].aadhaarNo,
          PANNo: tmpMemberArray[0].panNo
        }

        groupByArray.push(memberObj);
        tmpArray = new Array();
        iCount = iCount + 1;


        memberID = tmpList[i].memberID;
        memWiseTot = 0;
        memWiseTot = memWiseTot + parseInt(tmpList[i].total);
        cashTotal = 0;
        bankTotal = 0;
        if (this.isEmpty(tmpList[i].chequeNo)) {
          cashTotal = parseInt(tmpList[i].total);
        } else {
          bankTotal = parseInt(tmpList[i].total);
        }

        var tmp = {
          SrNo: iCount,
          MemberID: tmpList[i].memberID,
          MemberName: tmpList[i].memberName,
          Total: tmpList[i].total,
          CashTotal: cashTotal,
          BankTotal: bankTotal
        }
        tmpArray.push(tmp);
      }
    }
    var tmpArrayCashTotal = 0;
    var tmpArrayBankTotal = 0;
    var tmpArrayDiffTotal = 0;
    var tmpArrayMemTotal = 0;
    for (var k = 0; k < tmpArray.length; k++) {
      tmpArrayCashTotal = tmpArrayCashTotal + parseInt(tmpArray[k].CashTotal);
      tmpArrayBankTotal = tmpArrayBankTotal + parseInt(tmpArray[k].BankTotal);
      tmpArrayMemTotal = tmpArrayMemTotal + parseInt(tmpArray[k].Total);
      gCashTotal = gCashTotal + parseInt(tmpArray[k].CashTotal);
      gBankTotal = gBankTotal + parseInt(tmpArray[k].BankTotal);
    }
    tmpArrayDiffTotal = tmpArrayCashTotal + tmpArrayBankTotal;
    tmpArrayDiffTotal = tmpArrayDiffTotal - tmpArrayMemTotal;
    gDiffTotal = gDiffTotal + tmpArrayDiffTotal;
    var tmpMemberArray = [];
    tmpMemberArray = this.allMembersArrayData;
    tmpMemberArray = tmpMemberArray.filter(function (item) {
      return item.memberID == tmpArray[0].MemberID;
    });
    var memberCentre = tmpMemberArray[0].mainCentre.substr(0, 3);
    if (tmpMemberArray[0].childCentre != "") {
      memberCentre = memberCentre + "/" + tmpMemberArray[0].childCentre.substr(0, 3);
    }
    if (tmpMemberArray[0].subCentre != "") {
      memberCentre = memberCentre + "/" + tmpMemberArray[0].subCentre.substr(0, 3);
    }

    var memberObj1 = {
      SrNo: iCount,
      MemberID: tmpArray[0].MemberID,
      MemberName: tmpArray[0].MemberName,
      Total: memWiseTot,
      CashTotal: tmpArrayCashTotal,
      BankTotal: tmpArrayBankTotal,
      DiffTotal: tmpArrayDiffTotal,
      Gender: tmpMemberArray[0].gender,
      Status: tmpMemberArray[0].status,
      Centre: memberCentre,
      Phone: tmpMemberArray[0].mobileNo,
      Address1: tmpMemberArray[0].addressL1,
      Address2: tmpMemberArray[0].addressL2,
      Address3: tmpMemberArray[0].addressL3,
      City: tmpMemberArray[0].city,
      ZipCode: tmpMemberArray[0].zipCode,
      AadhaarNo: tmpMemberArray[0].aadhaarNo,
      PANNo: tmpMemberArray[0].panNo
    }
    groupByArray.push(memberObj1);
    var grandTotalObj = {
      SrNo: "",
      MemberID: "",
      MemberName: "",
      Total: gTotal,
      CashTotal: gCashTotal, 
      BankTotal: gBankTotal,
      DiffTotal: gDiffTotal,
      Gender: "",
      Status: "",
      Centre: "",
      Phone: "",
      Address1: "",
      Address2: "",
      Address3: "",
      City: "",
      ZipCode: "",
      AadhaarNo: "",
      PANNo: ""
    }
    groupByArray.push(grandTotalObj);
    this.memwiseCashBankTotal = groupByArray;
  }
  exportAsMemWiseBankCash() {
    var pipe = new DatePipe('en-US');
    this.excelService.exportAsExcelFile(this.memwiseCashBankTotal, 'MemWiseCashBankTotal' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
  }
  //MemberWise Cash Bank Total Completed
//Export KenshuList
  exportKensuList() {
    var pipe = new DatePipe('en-US');
    this.excelService.exportAsExcelFile(this.kenshulist, 'Kenshu List' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  onOfferDateSelect(d: Date) {

    this.formModel.month1 = new Date(d.getFullYear(), d.getMonth(), 1);
    this.formModel.month2 = new Date(d.getFullYear(), d.getMonth() + 1, 1);
    this.formModel.month3 = new Date(d.getFullYear(), d.getMonth() + 2, 1);
    this.formModel.month4 = new Date(d.getFullYear(), d.getMonth() + 3, 1);
    this.formModel.month5 = new Date(d.getFullYear(), d.getMonth() + 4, 1);
    this.formModel.month6 = new Date(d.getFullYear(), d.getMonth() + 5, 1);
    this.formModel.month7 = new Date(d.getFullYear(), d.getMonth() + 6, 1);
    this.formModel.month8 = new Date(d.getFullYear(), d.getMonth() + 7, 1);
    this.formModel.month9 = new Date(d.getFullYear(), d.getMonth() + 8, 1);
    this.formModel.month10 = new Date(d.getFullYear(), d.getMonth() + 9, 1);
    this.formModel.month11 = new Date(d.getFullYear(), d.getMonth() + 10, 1);
    this.formModel.month12 = new Date(d.getFullYear(), d.getMonth() + 11, 1);
  }

  populateSCMOLogbook(data, searchCriteria) {

    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var memberName = tmpList[0].memberName;
    var count = 0;

    var scmo_month1 = "";
    var scmo_month2 = "";
    var scmo_month3 = "";
    var scmo_month4 = "";
    var scmo_month5 = "";
    var scmo_month6 = "";
    var scmo_month7 = "";
    var scmo_month8 = "";
    var scmo_month9 = "";
    var scmo_month10 = "";
    var scmo_month11 = "";
    var scmo_month12 = "";

    var gscmo_month1 = 0;
    var gscmo_month2 = 0;
    var gscmo_month3 = 0;
    var gscmo_month4 = 0;
    var gscmo_month5 = 0;
    var gscmo_month6 = 0;
    var gscmo_month7 = 0;
    var gscmo_month8 = 0;
    var gscmo_month9 = 0;
    var gscmo_month10 = 0;
    var gscmo_month11 = 0;
    var gscmo_month12 = 0;

    for (var i = 0; i < tmpList.length; i++) {


      if (tmpList[i].memberName == memberName) {
        var id = tmpList[i].otherOfferingID;
        var status = tmpList[i].status;
        var memberCentre = tmpList[i].mainCentre.substr(0, 3);
        if (tmpList[i].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
        }
        if (tmpList[i].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
        }
        var processing = tmpList[i].offerMainCentre.substr(0, 3);
        if (tmpList[i].offerChildCentre != "") {
          processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
        }
        if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
          processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
        }

        var offeringDate = new Date(tmpList[i].forMonth);
        if (offeringDate.getTime() == this.formModel.month1.getTime()) {
          scmo_month1 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month2.getTime()) {
          scmo_month2 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month3.getTime()) {
          scmo_month3 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month4.getTime()) {
          scmo_month4 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month5.getTime()) {
          scmo_month5 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month6.getTime()) {
          scmo_month6 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month7.getTime()) {
          scmo_month7 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month8.getTime()) {
          scmo_month8 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month9.getTime()) {
          scmo_month9 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month10.getTime()) {
          scmo_month10 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month11.getTime()) {
          scmo_month11 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month12.getTime()) {
          scmo_month12 = "Y";
        }


      } else {

        if (scmo_month1 == "" && scmo_month2 == "" && scmo_month3 == "" && scmo_month4 == "" && scmo_month5 == ""
          && scmo_month6 == "" && scmo_month7 == "" && scmo_month8 == "" && scmo_month9 == "" && scmo_month10 == ""
          && scmo_month11 == "" && scmo_month12 == "") {
          console.log("noscmo");
        } else {
          count = count + 1;
          var total = 0;
          if (scmo_month1 == 'Y') {
            total = total + 1;
            gscmo_month1 = gscmo_month1 + 1;
          }
          if (scmo_month2 == 'Y') {
            total = total + 1;
            gscmo_month2 = gscmo_month2 + 1;
          }
          if (scmo_month3 == 'Y') {
            total = total + 1;
            gscmo_month3 = gscmo_month3 + 1;
          }
          if (scmo_month4 == 'Y') {
            total = total + 1;
            gscmo_month4 = gscmo_month4 + 1;
          }
          if (scmo_month5 == 'Y') {
            total = total + 1;
            gscmo_month5 = gscmo_month5 + 1;
          }
          if (scmo_month6 == 'Y') {
            total = total + 1;
            gscmo_month6 = gscmo_month6 + 1;
          }
          if (scmo_month7 == 'Y') {
            total = total + 1;
            gscmo_month7 = gscmo_month7 + 1;
          }
          if (scmo_month8 == 'Y') {
            total = total + 1;
            gscmo_month8 = gscmo_month8 + 1;
          }
          if (scmo_month9 == 'Y') {
            total = total + 1;
            gscmo_month9 = gscmo_month9 + 1;
          }
          if (scmo_month10 == 'Y') {
            total = total + 1;
            gscmo_month10 = gscmo_month10 + 1;
          }
          if (scmo_month11 == 'Y') {
            total = total + 1;
            gscmo_month11 = gscmo_month11 + 1;
          }
          if (scmo_month12 == 'Y') {
            total = total + 1;
            gscmo_month12 = gscmo_month12 + 1;
          }

          var tmp = {
            id: id,
            SrNo: count,
            MemberName: memberName,
            Status: status,
            Actual: memberCentre,
            Processing: processing,
            SCMO_Month1: scmo_month1,
            SCMO_Month2: scmo_month2,
            SCMO_Month3: scmo_month3,
            SCMO_Month4: scmo_month4,
            SCMO_Month5: scmo_month5,
            SCMO_Month6: scmo_month6,
            SCMO_Month7: scmo_month7,
            SCMO_Month8: scmo_month8,
            SCMO_Month9: scmo_month9,
            SCMO_Month10: scmo_month10,
            SCMO_Month11: scmo_month11,
            SCMO_Month12: scmo_month12,
            //Total : total,
          }
          tmpArray.push(tmp);
        }

        memberName = tmpList[i].memberName;
        status = tmpList[i].status;
        memberCentre = tmpList[i].mainCentre.substr(0, 3);
        if (tmpList[i].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
        }
        if (tmpList[i].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
        }
        processing = tmpList[i].offerMainCentre.substr(0, 3);
        if (tmpList[i].offerChildCentre != "") {
          processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3);
        }
        if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
          processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
        }
        scmo_month1 = "";
        scmo_month2 = "";
        scmo_month3 = "";
        scmo_month4 = "";
        scmo_month5 = "";
        scmo_month6 = "";
        scmo_month7 = "";
        scmo_month8 = "";
        scmo_month9 = "";
        scmo_month10 = "";
        scmo_month11 = "";
        scmo_month12 = "";

        var offeringDate = new Date(tmpList[i].forMonth);
        if (offeringDate.getTime() == this.formModel.month1.getTime()) {
          scmo_month1 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month2.getTime()) {
          scmo_month2 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month3.getTime()) {
          scmo_month3 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month4.getTime()) {
          scmo_month4 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month5.getTime()) {
          scmo_month5 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month6.getTime()) {
          scmo_month6 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month7.getTime()) {
          scmo_month7 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month8.getTime()) {
          scmo_month8 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month9.getTime()) {
          scmo_month9 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month10.getTime()) {
          scmo_month10 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month11.getTime()) {
          scmo_month11 = "Y";
        } else if (offeringDate.getTime() == this.formModel.month12.getTime()) {
          scmo_month12 = "Y";
        }
      }
    }//end for
    var tmp2 = {
      id: id,
      SrNo: count + 1,
      MemberName: memberName,
      Status: status,
      Actual: memberCentre,
      Processing: processing,
      SCMO_Month1: scmo_month1,
      SCMO_Month2: scmo_month2,
      SCMO_Month3: scmo_month3,
      SCMO_Month4: scmo_month4,
      SCMO_Month5: scmo_month5,
      SCMO_Month6: scmo_month6,
      SCMO_Month7: scmo_month7,
      SCMO_Month8: scmo_month8,
      SCMO_Month9: scmo_month9,
      SCMO_Month10: scmo_month10,
      SCMO_Month11: scmo_month11,
      SCMO_Month12: scmo_month12,
    }

    if (scmo_month1 == 'Y') {
      total = total + 1;
      gscmo_month1 = gscmo_month1 + 1;
    }
    if (scmo_month2 == 'Y') {
      total = total + 1;
      gscmo_month2 = gscmo_month2 + 1;
    }
    if (scmo_month3 == 'Y') {
      total = total + 1;
      gscmo_month3 = gscmo_month3 + 1;
    }
    if (scmo_month4 == 'Y') {
      total = total + 1;
      gscmo_month4 = gscmo_month4 + 1;
    }
    if (scmo_month5 == 'Y') {
      total = total + 1;
      gscmo_month5 = gscmo_month5 + 1;
    }
    if (scmo_month6 == 'Y') {
      total = total + 1;
      gscmo_month6 = gscmo_month6 + 1;
    }
    if (scmo_month7 == 'Y') {
      total = total + 1;
      gscmo_month7 = gscmo_month7 + 1;
    }
    if (scmo_month8 == 'Y') {
      total = total + 1;
      gscmo_month8 = gscmo_month8 + 1;
    }
    if (scmo_month9 == 'Y') {
      total = total + 1;
      gscmo_month9 = gscmo_month9 + 1;
    }
    if (scmo_month10 == 'Y') {
      total = total + 1;
      gscmo_month10 = gscmo_month10 + 1;
    }
    if (scmo_month11 == 'Y') {
      total = total + 1;
      gscmo_month11 = gscmo_month11 + 1;
    }
    if (scmo_month12 == 'Y') {
      total = total + 1;
      gscmo_month12 = gscmo_month12 + 1;
    }

    if (scmo_month1 == "" && scmo_month2 == "" && scmo_month3 == "" && scmo_month4 == "" && scmo_month5 == ""
      && scmo_month6 == "" && scmo_month7 == "" && scmo_month8 == "" && scmo_month9 == "" && scmo_month10 == ""
      && scmo_month11 == "" && scmo_month12 == "") {
      console.log("noscmo");
    } else {
      tmpArray.push(tmp2);
    }
    var tmp1 = {
      id: "",
      SrNo: "",
      MemberName: "",
      Status: "Grand Total",
      Actual: "",
      Processing: "",
      SCMO_Month1: gscmo_month1,
      SCMO_Month2: gscmo_month2,
      SCMO_Month3: gscmo_month3,
      SCMO_Month4: gscmo_month4,
      SCMO_Month5: gscmo_month5,
      SCMO_Month6: gscmo_month6,
      SCMO_Month7: gscmo_month7,
      SCMO_Month8: gscmo_month8,
      SCMO_Month9: gscmo_month9,
      SCMO_Month10: gscmo_month10,
      SCMO_Month11: gscmo_month11,
      SCMO_Month12: gscmo_month12,
      //Total : gscmo_month1+gscmo_month2+gscmo_month3+gscmo_month4+gscmo_month5+gscmo_month6+gscmo_month7+gscmo_month8+gscmo_month9+gscmo_month10+gscmo_month11+gscmo_month12,
    }
    //      tmpArray.push(tmp1);

    do {
      // console.log(searchCriteria.sortOrderByDetails.type+"   searchCriteria.sortOrderByDetails.type");

      if (searchCriteria.sortOrderByDetails.id == "no") {
        tmpArray.sort(function (a, b) {
          return a.id - b.id
        })
      }
      if (searchCriteria.sortOrderByDetails.id == "nm") {
        tmpArray.sort(function (a, b) {
          var nameA = a.MemberName.toLowerCase(), nameB = b.MemberName.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "st") {
        tmpArray.sort(function (a, b) {
          var nameA = a.Status, nameB = b.Status
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "ct") {
        tmpArray.sort(function (a, b) {

          var nameA = a.Actual;
          var nameB = b.Actual;
          // console.log(nameA);
          // console.log(nameB);
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
    } while (false);

    tmpArray.push(tmp1);

    this.scmologbook = tmpArray;
    this.dataSharingService.setSearchedOfferSCMOLogbookData(tmpArray);
  }

  exportAsXLSXSCMOLog() {

    var pipe = new DatePipe('en-US');
    var tmpArray = new Array();

    var month1 = pipe.transform(this.formModel.month1, 'MMM-yy');
    var month2 = pipe.transform(this.formModel.month2, 'MMM-yy')
    var month3 = pipe.transform(this.formModel.month3, 'MMM-yy')
    var month4 = pipe.transform(this.formModel.month4, 'MMM-yy')
    var month5 = pipe.transform(this.formModel.month5, 'MMM-yy')
    var month6 = pipe.transform(this.formModel.month6, 'MMM-yy')
    var month7 = pipe.transform(this.formModel.month7, 'MMM-yy')
    var month8 = pipe.transform(this.formModel.month8, 'MMM-yy')
    var month9 = pipe.transform(this.formModel.month9, 'MMM-yy')
    var month10 = pipe.transform(this.formModel.month10, 'MMM-yy')
    var month11 = pipe.transform(this.formModel.month11, 'MMM-yy')
    var month12 = pipe.transform(this.formModel.month12, 'MMM-yy')
    for (var i = 0; i < this.scmologbook.length; i++) {
      if (this.scmologbook.length != (i + 1)) {
        // console.log((i+1) + " "+this.scmologbook[i].length);
        var tmp = {
          SrNo: i + 1,
          MemberName: this.scmologbook[i].MemberName,
          Status: this.scmologbook[i].Status,
          Actual: this.scmologbook[i].Actual,
          [month1]: this.scmologbook[i].SCMO_Month1,
          [month2]: this.scmologbook[i].SCMO_Month2,
          [month3]: this.scmologbook[i].SCMO_Month3,
          [month4]: this.scmologbook[i].SCMO_Month4,
          [month5]: this.scmologbook[i].SCMO_Month5,
          [month6]: this.scmologbook[i].SCMO_Month6,
          [month7]: this.scmologbook[i].SCMO_Month7,
          [month8]: this.scmologbook[i].SCMO_Month8,
          [month9]: this.scmologbook[i].SCMO_Month9,
          [month10]: this.scmologbook[i].SCMO_Month10,
          [month11]: this.scmologbook[i].SCMO_Month11,
          [month12]: this.scmologbook[i].SCMO_Month12,
          //Total : this.scmologbook[i].Total,
        }
        tmpArray.push(tmp);
      } else {
        var tmp1 = {
          SrNo: "",
          MemberName: this.scmologbook[i].MemberName,
          Status: this.scmologbook[i].Status,
          Actual: this.scmologbook[i].Actual,
          [month1]: this.scmologbook[i].SCMO_Month1,
          [month2]: this.scmologbook[i].SCMO_Month2,
          [month3]: this.scmologbook[i].SCMO_Month3,
          [month4]: this.scmologbook[i].SCMO_Month4,
          [month5]: this.scmologbook[i].SCMO_Month5,
          [month6]: this.scmologbook[i].SCMO_Month6,
          [month7]: this.scmologbook[i].SCMO_Month7,
          [month8]: this.scmologbook[i].SCMO_Month8,
          [month9]: this.scmologbook[i].SCMO_Month9,
          [month10]: this.scmologbook[i].SCMO_Month10,
          [month11]: this.scmologbook[i].SCMO_Month11,
          [month12]: this.scmologbook[i].SCMO_Month12,
          //Total : this.scmologbook[i].Total,
        }
        tmpArray.push(tmp1);
      }

    }
    //console.log(tmpArray);
    this.excelService.exportAsExcelFile(tmpArray, "SCMO_Logbook_" + pipe.transform(this.formModel.forMonth, 'MMM/yyyy'));
  }

  // Kenshu List
  sortData(tmpList) {
    return tmpList.sort((a, b) => {
      return a.priKID - b.priKID;
    });
  }

}
