import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private router: Router, @Inject('BASE_URL') private baseUrl: string) { }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  GetAuthenticated() {
    if (localStorage.getItem('token') != null) {
      this.loggedIn.next(true);
      return true;
    }
    this.loggedIn.next(false);
    return false;
  }

  login(formData)
  {
    return this.http.post(this.baseUrl + 'api/authentication/loginNew', formData);
   
  }

  logout() {
    localStorage.clear();
    this.loggedIn.next(false);
    this.router.navigateByUrl("/login");
  }

  getAllowLocation() {
    return this.http.get(this.baseUrl + 'api/location/getallowlocations');
  }

  getAllUserDetails() {
    return this.http.get(this.baseUrl + 'api/authentication/getAllUserDetails');
  }

  UpdateLogin(formData){
    //console.log("updating",formData)
    return this.http.post(this.baseUrl + 'api/authentication/UpdateLogin',formData);
  }
  GetUserInfoById(RoleID) {
    var apiUrl = 'api/authentication/GetUserInfoById?RoleID='+RoleID;
    return this.http.get(this.baseUrl + apiUrl);
  }
  UpdateUser(formData){
    return this.http.post(this.baseUrl + 'api/authentication/UpdateUser',formData);
  }
}
