import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompleterService, CompleterData } from 'ng2-completer';
import { OfferService } from '../../services/offer.service';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-summary-reports',
  templateUrl: './summary-reports.component.html',
  styleUrls: ['./summary-reports.component.css']
})
export class SummaryReportsComponent implements OnInit {

  constructor(private offerService: OfferService, private excelService: ExcelService, private dataSharingService: GlobalDataSharingService,
    private spinner: NgxSpinnerService,private completerService: CompleterService) { }


    summaryreport1 =[];
    SCMOSummaryReport=[];
    report = '';
    selectedReportObj = {};
    reportTypeDetails = [];
    forMonth = null;


  ngOnInit() {
    this.report='DO';
    var d = new Date();
    this.forMonth = new Date(d.getFullYear(), d.getMonth(), 1);
    this.selectedReportObj = {
      id: 0,
      type: 'Processing Centre Only'
    };
    this.reportTypeDetails = [];
    this.reportTypeDetails.push(this.selectedReportObj);
    var i = 0;
    var tmpArry = [
      {
        id: 1,
        type: "Processing Centre Actual"
      },
    ];

    for (i = 0; i < tmpArry.length; i++) {
      this.reportTypeDetails.push(tmpArry[i]);
    }

  }
  
  getSummryReport(reportType){
    this.report = reportType;
   this.offerService.getSummaryReport(this.report).subscribe((res: any) => {
    if (res.status == 1) {
      do {
              var tmplist = res.result;
              if(reportType == 'DO'){
                this.populateReport1(tmplist);
              }else if(reportType == 'SC'){
                this.populateSCMO(tmplist);
              }
                
      } while (false);
    } else {
      alert(res.message);
      //this.onClear();
      this.summaryreport1 = [];
    }
  });
  }

  populateReport1(data){

    var tmpArray = new Array();
    var tmpList = data;

    var centerWiseScmo = 0;
    var centerWiseCountryM = 0;
    var centerWiseCentreM = 0;
    var centerWiseOtamagushi = 0;
    var centerWiseOkiyome = 0;
    var centerWiseGoshintai = 0;
    var centerWiseAncestors = 0;
    var centerWiseOmitama = 0;
    var centerWiseGoshugo = 0;
    var centerWiseOther = 0;
    var centerWiseTotal = 0;

    var centerWiseOtamagushiCnt = 0;
    var centerWiseOtamagushiCnt = 0;
    var centerWiseOkiyomeCnt = 0;
    var centerWiseGoshintaiCnt = 0;
    var centerWiseAncestorsCnt = 0;
    var centerWiseOmitamaCnt = 0;
    var centerWiseGoshugoCnt = 0;
    var centerWiseOtherCnt = 0;
    var centerWiseScmoCnt = 0;
    var centerWiseCountryMCnt = 0;
    var centerWiseCentreMCnt = 0;


    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;

    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;

    var iCount =0;
    var mc= tmpList[0].mainCentre;
    var cc =tmpList[0].childCentre;
    var sc =tmpList[0].subCentre;

    for (var i = 0; i < tmpList.length; i++) {
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi ;
      gTotalOkiyome = gTotalOkiyome + rOkiyome ;
      gTotalGoshintai = gTotalGoshintai + rGoshintai ;
      gTotalAncestors = gTotalAncestors + rAncestors ;
      gTotalOmitama = gTotalOmitama + rOmitama ;
      gTotalGoshugo = gTotalGoshugo + rGoshugo ;
      gTotalOther = gTotalOther + rOther ;
      gTotalScmo = gTotalScmo + rScmo ;
      gTotalCentreM = gTotalCentreM + rCentreM ;
      gTotalCountryM = gTotalCountryM + rCountryM;
      gTotal = gTotal + parseInt(tmpList[i].total);


      if (tmpList[i].mainCentre == mc && tmpList[i].childCentre == cc && tmpList[i].subCentre == sc ) {
        if (rCountryM  > 0 ) {
          centerWiseCountryMCnt = centerWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt +1;
        }
        if (rCentreM  > 0){
          centerWiseCentreMCnt = centerWiseCentreMCnt + 1;
          gTotalCentreMCnt =gTotalCentreMCnt + 1;
        }
        if (rScmo  > 0 ) {
          centerWiseScmoCnt = centerWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt +1;
        }
        if (rOtamagushi  > 0) {
          centerWiseOtamagushiCnt = centerWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome  > 0) {
          centerWiseOkiyomeCnt = centerWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai  > 0) {
          centerWiseGoshintaiCnt = centerWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors  > 0) {
          centerWiseAncestorsCnt = centerWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama  > 0) {
          centerWiseOmitamaCnt = centerWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo  > 0) {
          centerWiseGoshugoCnt = centerWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther  > 0) {
          centerWiseOtherCnt = centerWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        centerWiseOtamagushi = centerWiseOtamagushi + rOtamagushi ;
        centerWiseOkiyome = centerWiseOkiyome + rOkiyome ;
        centerWiseGoshintai = centerWiseGoshintai + rGoshintai ;
        centerWiseAncestors = centerWiseAncestors + rAncestors ;
        centerWiseOmitama = centerWiseOmitama + rOmitama ;
        centerWiseGoshugo = centerWiseGoshugo + rGoshugo ;
        centerWiseOther = centerWiseOther + rOther ;
        centerWiseScmo = centerWiseScmo + rScmo ;
        centerWiseCountryM = centerWiseCountryM + rCountryM ;
        centerWiseCentreM = centerWiseCentreM + rCentreM ;

        centerWiseTotal = centerWiseTotal + parseInt(tmpList[i].total);
      }else{
      var dateChangedObj = {
        SrNo: iCount+1,
        mainCentre: mc,
        childCentre : cc,
        subCentre : sc,
        SCMO: centerWiseScmo,
        SCMO_cnt: centerWiseScmoCnt,
        CountryM: centerWiseCountryM,
        CountryM_cnt: centerWiseCountryMCnt,
        CentreM: centerWiseCentreM,
        CentreM_cnt: centerWiseCentreMCnt,
        Otamagushi: centerWiseOtamagushi,
        Otamagushi_cnt: centerWiseOtamagushiCnt,
        Okiyome: centerWiseOkiyome,
        Okiyome_cnt: centerWiseOkiyomeCnt,
        Goshintai: centerWiseGoshintai,
        Goshintai_cnt: centerWiseGoshintaiCnt,
        Ancestors: centerWiseAncestors,
        Ancestors_cnt: centerWiseAncestorsCnt,
        Omitama: centerWiseOmitama,
        Omitama_cnt: centerWiseOmitamaCnt,
        Goshugo: centerWiseGoshugo,
        Goshugo_cnt: centerWiseGoshugoCnt,
        Other: centerWiseOther,
        Other_cnt: centerWiseOtherCnt,
        Total: centerWiseTotal,
        
      }

      
      tmpArray.push(dateChangedObj);
      iCount = iCount+1;


       //Reset
       centerWiseScmoCnt = 0;
       centerWiseCountryMCnt = 0;
       centerWiseCentreMCnt = 0;
       centerWiseOtamagushiCnt = 0;
       centerWiseOtamagushiCnt = 0;
       centerWiseOkiyomeCnt = 0;
       centerWiseGoshintaiCnt = 0;
       centerWiseAncestorsCnt = 0;
       centerWiseOmitamaCnt = 0;
       centerWiseGoshugoCnt = 0;
       centerWiseOtherCnt = 0;

       centerWiseOtamagushi = rOtamagushi ;
       centerWiseOkiyome = rOkiyome ;
       centerWiseGoshintai = rGoshintai ;
       centerWiseAncestors = rAncestors ;
       centerWiseOmitama = rOmitama ;
       centerWiseGoshugo = rGoshugo ;
       centerWiseOther = rOther ;
       centerWiseTotal = parseInt(tmpList[i].total);
       centerWiseScmo =  rScmo;
       centerWiseCountryM =  rCountryM;
       centerWiseCentreM =  rCentreM ;

       var mc= tmpList[i].mainCentre;
       var cc =tmpList[i].childCentre;
       var sc =tmpList[i].subCentre;

       if (rCountryM  > 0 ) {
        centerWiseCountryMCnt = centerWiseCountryMCnt + 1;
        gTotalCountryMCnt = gTotalCountryMCnt +1;
      }
      if (rCentreM  > 0){
        centerWiseCentreMCnt = centerWiseCentreMCnt + 1;
        gTotalCentreMCnt =gTotalCentreMCnt + 1;
      }
      if (rScmo  > 0 ) {
        centerWiseScmoCnt = centerWiseScmoCnt + 1;
        gTotalScmoCnt = gTotalScmoCnt +1;
      }
      if (rOtamagushi  > 0) {
        centerWiseOtamagushiCnt = centerWiseOtamagushiCnt + 1;
        gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
      }
      if (rOkiyome  > 0) {
        centerWiseOkiyomeCnt = centerWiseOkiyomeCnt + 1;
        gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
      }
      if (rGoshintai  > 0) {
        centerWiseGoshintaiCnt = centerWiseGoshintaiCnt + 1;
        gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
      }
      if (rAncestors  > 0) {
        centerWiseAncestorsCnt = centerWiseAncestorsCnt + 1;
        gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
      }
      if (rOmitama  > 0) {
        centerWiseOmitamaCnt = centerWiseOmitamaCnt + 1;
        gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
      }
      if (rGoshugo  > 0) {
        centerWiseGoshugoCnt = centerWiseGoshugoCnt + 1;
        gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
      }
      if (rOther  > 0) {
        centerWiseOtherCnt = centerWiseOtherCnt + 1;
        gTotalOtherCnt = gTotalOtherCnt + 1;
      }
    }
    }

    var lastRecObjCnt = {
      SrNo: iCount+1,
      mainCentre: mc,
      childCentre : cc,
      subCentre : sc,
      SCMO: centerWiseScmo,
      SCMO_cnt: centerWiseScmoCnt,
      CountryM: centerWiseCountryM,
      CountryM_cnt: centerWiseCountryMCnt,
      CentreM: centerWiseCentreM,
      CentreM_cnt: centerWiseCentreMCnt,
      Otamagushi: centerWiseOtamagushi,
      Otamagushi_cnt: centerWiseOtamagushiCnt,
      Okiyome: centerWiseOkiyome,
      Okiyome_cnt: centerWiseOkiyomeCnt,
      Goshintai: centerWiseGoshintai,
      Goshintai_cnt: centerWiseGoshintaiCnt,
      Ancestors: centerWiseAncestors,
      Ancestors_cnt: centerWiseAncestorsCnt,
      Omitama: centerWiseOmitama,
      Omitama_cnt: centerWiseOmitamaCnt,
      Goshugo: centerWiseGoshugo,
      Goshugo_cnt: centerWiseGoshugoCnt,
      Other: centerWiseOther,
      Other_cnt: centerWiseOtherCnt,
      Total: centerWiseTotal,
    }
     tmpArray.push(lastRecObjCnt);

     var grandTotalObj = {
       SrNo: "",
       mainCentre: "Grand Total",
       childCentre:"",
       subCentre: "",
       SCMO: gTotalScmo,
       SCMO_cnt: gTotalScmoCnt,
       CountryM: gTotalCountryM,
       CountryM_cnt: gTotalCountryMCnt,
       CentreM: gTotalCentreM,
       CentreM_cnt: gTotalCentreMCnt,
       Otamagushi: gTotalOtamagushi,
       Otamagushi_cnt: gTotalOtamagushiCnt,
       Okiyome: gTotalOkiyome,
       Okiyome_cnt: gTotalOkiyomeCnt,
       Goshintai: gTotalGoshintai,
       Goshintai_cnt: gTotalGoshintaiCnt,
       Ancestors: gTotalAncestors,
       Ancestors_cnt: gTotalAncestorsCnt,
       Omitama: gTotalOmitama,
       Omitama_cnt: gTotalOmitamaCnt,
       Goshugo: gTotalGoshugo,
       Goshugo_cnt: gTotalGoshugoCnt,
       Other: gTotalOther,
       Other_cnt: gTotalOtherCnt,
       Total: gTotal
     }

    tmpArray.push(grandTotalObj);
    this.summaryreport1 = tmpArray;
  }
  populateSCMO(data){
    var tmpArray = new Array();
    var sortArry = new Array();
    var distinctArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var memID = tmpList[0].memberID;
    for (var k = 0; k < tmpList.length; k++) {
      if (tmpList[k].memberID != memID) {
        distinctArray.push(this.sortData(sortArry)[0]);
        memID = tmpList[k].memberID;
        sortArry = new Array();
      }
      sortArry.push(tmpList[k]);
    }
    distinctArray.push(this.sortData(sortArry)[0]);
    tmpList = distinctArray;
    var d = new Date(this.forMonth);
    var compMonth = d.getMonth();
    var compLastMonth = d.getMonth() - 1;
    var compLast2Months = d.getMonth() - 2;var compLastYear = d.getFullYear() - 1;
    var compYear = d.getFullYear();


    var d1 = new Date(this.forMonth);
    d1.setMonth(d1.getMonth()-1);
    if (compLastMonth < 0) {
      compLastMonth = d1.getMonth();
    }
    if (compLast2Months < 0) {
      d1.setMonth(d1.getMonth() - 1);
      compLast2Months = d1.getMonth();
    }

    var iCountCol1 = 0;
    var iCountCol2 = 0;
    var iCountCol3 = 0;
    var iCountCol4 = 0;
    var iCountCol5 = 0;
    for (var i = 0; i < tmpList.length; i++) {
      d = new Date(tmpList[i].forMonth);
      var forMonth = d.getMonth();
      var forYear = d.getFullYear();
      var memberCentre = tmpList[i].mainCentre.substr(0, 3);
      if (tmpList[i].childCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].childCentre.substr(0, 3);
      }
      if (tmpList[i].subCentre != "") {
        memberCentre = memberCentre + "/" + tmpList[i].subCentre.substr(0, 3);
      }
      var processing = tmpList[i].offerMainCentre.substr(0, 3);
      if (tmpList[i].offerChildCentre != "") {
        processing = processing + "/" + tmpList[i].offerChildCentre.substr(0, 3); 
      }
      if (tmpList[i].offerSubCentre != "" && tmpList[i].offerSubCentre != null && tmpList[i].offerSubCentre != undefined) {
        processing = processing + "/" + tmpList[i].offerSubCentre.substr(0, 3);
      }

      var tmp = {
        SrNo: i + 1,
        ProcessingDate: tmpList[i].processingDate,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        Centre: memberCentre,
        Processing: processing,
        Status: tmpList[i].status,
        SCMOMonthLast2Months:"",
        SCMOMonthLastMonth: "",
        SCMOMonthEqual: "",
        SCMOMonthGreater: "",
        showTotal: "N"
      }
      do {
        console.log(forYear  + "  "+forMonth);
        //console.log(forYear  == compLastYear)
        if (forYear == compYear) {
          //console.log("***** forYear == compYear");
          if (forMonth > compMonth) {
            //console.log("forMonth > compMonth");
            tmp.SCMOMonthGreater = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
            iCountCol5 = iCountCol5 + 1;
            break;
          }
          if (forMonth == compMonth) {
            //console.log("forMonth== compMonth")
            tmp.SCMOMonthEqual = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
            iCountCol4 = iCountCol4 + 1;
            break;  
          }
          if (forMonth == compLastMonth) {
           // console.log("forMonth == compLstMonth")
            tmp.SCMOMonthLastMonth = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
            iCountCol3 = iCountCol3 + 1;
            break;
          }
          if(forMonth <= compLast2Months){
            //console.log("forMonth <= compLast2Month")
            tmp.SCMOMonthLast2Months = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
            iCountCol2 = iCountCol2 + 1;
            break;
          }
        }
        if (forYear == compLastYear) {
        //  console.log("***** forYear == compLastYear");
          if (forMonth == compLastMonth) {
            tmp.SCMOMonthLastMonth = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
            iCountCol3 = iCountCol3 + 1;
            break;
          }
          if (forMonth <= compLast2Months) {
            tmp.SCMOMonthLast2Months = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
            iCountCol2 = iCountCol2 + 1;
            break;
          }else{
            tmp.SCMOMonthLast2Months = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
            iCountCol2 = iCountCol2 + 1;
            break;
          }


         
          break;
        }
        if (forYear < compLastYear) {
          //console.log("***** forYear < compLastYear");
          tmp.SCMOMonthLast2Months = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          iCountCol2 = iCountCol2 + 1;
          break;

        }
        if (forYear > compYear) {
         // console.log("***** forYear > compYear");
          tmp.SCMOMonthLastMonth = "";
          tmp.SCMOMonthLast2Months = "";
          tmp.SCMOMonthEqual = "";
          tmp.SCMOMonthGreater = pipe.transform(tmpList[i].forMonth, 'MMM-yyyy');
          iCountCol5 = iCountCol5 + 1;
          break;
        }

      } while (false);

      tmpArray.push(tmp);
    }
    var tmp1 = {
      SrNo: "",
      ProcessingDate: "",
      OfferingDate: "",
      MemberName: "",
      Centre: "",
      Processing: "",
      Status: "",
      SCMOMonthLast2Months: iCountCol2,
      SCMOMonthLastMonth: iCountCol3,
      SCMOMonthEqual: iCountCol4,
      SCMOMonthGreater: iCountCol5,
      showTotal: "Y"
    }
    tmpArray.push(tmp1);
    this.SCMOSummaryReport = tmpArray;
    this.dataSharingService.setSearchedOfferSCMOData(tmpArray);
  }
  sortData(tmpList) {
    return tmpList.sort((a, b) => {
      return <any>new Date(b.forMonth) - <any>new Date(a.forMonth);
    });
  }
}
