import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { OfferService } from "../../services/offer.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-edit-kenshu',
  templateUrl: './edit-kenshu.component.html',
  styleUrls: ['./edit-kenshu.component.css']
})
export class EditKenshuComponent implements OnInit {

  
  constructor(  private spinner: NgxSpinnerService,private router: Router, private route: ActivatedRoute,  private offerService: OfferService,private dataSharingService: GlobalDataSharingService) { }

  allMembersArrayData = [];
  formModel: any = {
    loggedinUserRole: "",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName:"",
    subCentreID: "",
    memberID: "",
    name:"",
    pkyn: "",
    priKNo: "",
    priKDate: "",
    priKPlace: "",
    ikyn :"",
    intKNo : "",
    intKDate : "",
    intKPlace : "",
    akyn : "",
    advKNo : "",
    advKDate : "",
    advKPlace : ""

  };
  queryMDetails = [];
  loggedInUserData = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  mainCentreObjEditMode = {};
  childCentreObjEditMode = {};
  subCentreObjEditMode = {};
  statusObjEditMode = {};
  statusDetailsEditMode = [];
  mainCentreDetailsEditMode = [];
  childCentreDetailsEditMode = [];
  subCentreDetailsEditMode = [];
  genderList = [];
  isAdultList=[];
  isPKenshuList = [];
  isIKenshuList=[];
  isAKenshuList = [];
  ngOnInit() {
    this.isPKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.isIKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.isAKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    
    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.loggedInUserData = this.dataSharingService.getLoggedInUserData();
    this.formModel.mainCentreName = this.loggedInUserData[0].mainCentreName;
    this.formModel.childCentreName = this.loggedInUserData[0].childCentreName;
    this.formModel.subCentreName = this.loggedInUserData[0].subCentreName;
    this.formModel.mainCentreID = this.loggedInUserData[0].mainCentreID;
    this.formModel.childCentreID = this.loggedInUserData[0].childCentreID;
    this.formModel.subCentreID = this.loggedInUserData[0].subCentreID;
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
    this.statusDetailsEditMode = this.dataSharingService.getStatusData();
    this.route.queryParams.subscribe(params => {
      if (params.id == null || params.id == undefined) {
        return;
      }
      this.getMemberbyId(params.id);
    })
  }

  getMemberbyId(id){
    this.mainCentreDetailsEditMode = this.dataSharingService.getMainCentreData();
    this.childCentreDetailsEditMode = this.dataSharingService.getChildCentreData();
    this.subCentreDetailsEditMode = this.dataSharingService.getSubCentreData();
    this.statusDetailsEditMode = this.dataSharingService.getStatusData();
    this.offerService.GetMemberInfoById(id).subscribe((res: any) => {
      if (res.status == 1) {
        var memberObj = res['result'];
        this.formModel.memberID = memberObj.memberID;

        var i = 0;
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (memberObj.mainCentre == this.glbmainCentreDetails[i].mainCentreName) {
            this.mainCentreObjEditMode = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (memberObj.childCentre == this.glbchildCentreDetails[i].childCentreName) {
            this.childCentreObjEditMode = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (memberObj.subCentre == this.glbsubCentreDetails[i].subCentreName) {
            this.subCentreObjEditMode = this.glbsubCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.statusDetailsEditMode.length; i++) {
          if (memberObj.status == this.statusDetailsEditMode[i].statusName) {
            this.statusObjEditMode = this.statusDetailsEditMode[i];
            break;
          }
        }
       
        var pipe = new DatePipe('en-US');
        this.formModel.name = memberObj.name;
        this.formModel.pkyn = memberObj.pkyn;
        this.formModel.priKNo = memberObj.priKNo;
        this.formModel.priKDate = memberObj.priKDate;
        this.formModel.priKPlace = memberObj.priKPlace;
        if (this.formModel.priKDate != null && this.formModel.priKDate != "") {
          this.formModel.priKDate = new Date(memberObj.priKDate);
        }

        this.formModel.ikyn = memberObj.ikyn;
        this.formModel.intKNo = memberObj.intKNo;
        this.formModel.intKDate = memberObj.intKDate;
        if(this.formModel.intKDate != null && this.formModel.intKDate != ""){
          this.formModel.intKDate =new Date( memberObj.intKDate);
        }
       this.formModel.intKPlace = memberObj.intKPlace;
        this.formModel.akyn = memberObj.akyn;
        this.formModel.advKNo = memberObj.advKNo;
        this.formModel.advKDate = memberObj.advKDate;
        if(this.formModel.advKDate != null && this.formModel.advKDate != ""){
          this.formModel.advKDate = new Date( memberObj.advKDate);
        }
        this.formModel.advKPlace = memberObj.advKPlace;
        // if(this.formModel.advKDate != null && this.formModel.advKDate != ""){
        //   var date = new Date(memberObj.advKDate);
        //   this.formModel.advKDate =  pipe.transform(date, 'dd/MM/yyyy');
        //   console.log(this.formModel.advKDate);
        // }
        
      }else{
        alert(res.message);
      }
      
    })
  }
 
  UpdateKenshu(form: NgForm) {
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    tmpArray = tmpArray.filter(function (item) {
      return item.memberID == form.value.memberID;
    });

    var pipe = new DatePipe('en-US');
    tmpArray[0].name = form.value.name;
    tmpArray[0].pkyn = form.value.pkyn;
    tmpArray[0].priKNo = form.value.priKNo;
    tmpArray[0].priKDate = form.value.priKDate;
    tmpArray[0].priKPlace = form.value.priKPlace;
    if (form.value.priKDate != null && form.value.priKDate != "") {
      tmpArray[0].priKDate = pipe.transform(form.value.priKDate, 'MM/dd/yyyy');
    }

    tmpArray[0].ikyn = form.value.ikyn;
    tmpArray[0].intKNo = form.value.intKNo;
    tmpArray[0].intKDate = form.value.intKDate;
    if(form.value.intKDate != null && form.value.intKDate != ""){
      tmpArray[0].intKDate =  pipe.transform(form.value.intKDate, 'MM/dd/yyyy');
    }
   
    tmpArray[0].intKPlace = form.value.intKPlace;
    tmpArray[0].akyn = form.value.akyn;
    tmpArray[0].advKNo = form.value.advKNo;
    tmpArray[0].advKDate = form.value.advKDate;
    if(form.value.advKDate != null && form.value.advKDate != ""){
      tmpArray[0].advKDate =  pipe.transform(form.value.advKDate, 'MM/dd/yyyy');
    }
    tmpArray[0].advKPlace = form.value.advKPlace; 

    this.offerService.updateMebo(tmpArray[0]).subscribe((res: any) => {
      if (res.status == 0) {
        alert(res.message);
        return;
      }
      var index = this.queryMDetails.findIndex((e) => e.id === tmpArray[0].id);
      if (index === -1) {
        return;
      } else {
        this.queryMDetails[index] = tmpArray[0];
      }
     // this.formModel.inEditMode = 'N';
      index = -1;
      var tmpArray2 = [];
      tmpArray2 = this.dataSharingService.getAllMembersListing();
      index = tmpArray2.findIndex((e) => e.id === tmpArray[0].id);
      if (index === -1) {
        return;
      } else {
        tmpArray2[index] = tmpArray[0];
      }
     
      this.dataSharingService.setAllMembersListing(tmpArray2);
      
      })
  }
 cancelClick(){
  
   this.router.navigateByUrl("offer/kenshu");
 }
}
