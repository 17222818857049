import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './user/login/login.component';
import { HNavMenuComponent } from './h-nav-menu/h-nav-menu.component';
import { OfferlistComponent } from './offer/offerlist/offerlist.component';
import { AuthGuard } from './guards/AuthGuard';
import { ExcelService } from './services/excel.service';
import { BsDatepickerModule, TypeaheadModule } from 'ngx-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2CompleterModule } from "ng2-completer";
import { NumericDirective } from './services/onlynumber.directive';
import { GlobalDataSharingService } from './services/globaldatasharing.service';
import { ManagemastersComponent } from './offer/managemasters/managemasters.component';
import { MeboComponent } from './offer/mebo/mebo.component';
import { UsermanagementComponent } from './offer/usermanagement/usermanagement.component';
import { AdduserComponent } from './offer/adduser/adduser.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NewofferingComponent } from './offer/newoffering/newoffering.component';
import { EditmeboComponent } from './offer/editmebo/editmebo.component';
import { KenshuComponent } from './offer/kenshu/kenshu.component';
import { EditKenshuComponent } from './offer/edit-kenshu/edit-kenshu.component';
import { SummaryReportsComponent } from './summary_reports/summary-reports/summary-reports.component';
import { UserIdleModule } from 'angular-user-idle';
import { AdditionalreportsComponent } from './offer/additionalreports/additionalreports.component';
import { AncestorsComponent } from './offer/ancestors/ancestors.component';
import { AdminfunctionsComponent } from './offer/adminfunctions/adminfunctions.component';
 
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    HNavMenuComponent,
    OfferlistComponent,
    NumericDirective,
    ManagemastersComponent,
    MeboComponent,
    UsermanagementComponent,
    AdduserComponent,
    NewofferingComponent,
    EditmeboComponent,
    KenshuComponent,
    EditKenshuComponent,
    SummaryReportsComponent,
    AdditionalreportsComponent,
    AncestorsComponent,
    AdminfunctionsComponent,
  ],
  providers: [AuthGuard, ExcelService, GlobalDataSharingService],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    Ng2CompleterModule,
    NgxSpinnerModule ,
    UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 120}),
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'offer/mebo', component: MeboComponent, canActivate: [AuthGuard] },
      { path: '', component: MeboComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'offer/user', component: UsermanagementComponent, canActivate: [AuthGuard] },
      { path: 'offer/managemasters', component: ManagemastersComponent, canActivate: [AuthGuard] },
      { path: 'offer/list', component: OfferlistComponent, canActivate: [AuthGuard] },
      { path: 'offer/addreports', component: AdditionalreportsComponent, canActivate: [AuthGuard] },
      { path: 'offer/newoffering', component: NewofferingComponent, canActivate: [AuthGuard] },
      { path: 'offer/editmebo', component: EditmeboComponent, canActivate: [AuthGuard] },
      { path: 'offer/kenshu', component: KenshuComponent, canActivate: [AuthGuard] },
      { path: 'offer/edit-kenshu', component: EditKenshuComponent, canActivate: [AuthGuard] },
      { path: 'summary-reports', component: SummaryReportsComponent, canActivate: [AuthGuard] },
      { path: 'offer/ancestors', component: AncestorsComponent, canActivate: [AuthGuard] },
      { path: 'offer/adminfunctions', component: AdminfunctionsComponent, canActivate: [AuthGuard] },

    ]),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
