import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticateService } from "../../services/authenticate.service";
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { OfferService } from "../../services/offer.service";
import { when } from 'q';
import { Observable, interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.css']
})

export class UsermanagementComponent implements OnInit {
  formModel: any = {
    loggedinUserRole: "",
    isAdminUser:"",
    inEditMode:"",
    loginID: "",
    roleID:"",
    password: "",
    contactNo: "",
    email: "",
    roleType: "",
    roleName:""
  };
  allUserDetails = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  RoleTypes = [];
  mainCenterChildCenterData = [];
  childCenterSubCenterData = [];
  private updateSubscription: Subscription;
  constructor(private authService: AuthenticateService,private dataSharingService: GlobalDataSharingService, private offerService: OfferService) { }

  ngOnInit() {
    this.allUserDetails = null;
    this.formModel.roleName = "";
    this.formModel.loggedinUserRole = "";
    this.formModel.loginUserRoleID = "";
    this.formModel.isAdminUser = "";
    this.formModel.inEditMode = "";
    this.formModel.loginID = "";
    this.formModel.password = "";
    this.formModel.contactNo = "";
    this.formModel.email = "";
    this.formModel.roleType = "";
    this.formModel.inEditMode = "N";
    this.formModel.isAdminUser = "N";

    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.formModel.loginUserRoleID = localStorage.getItem("loginUserRoleID");
    this.updateSubscription = interval(20000).subscribe(
      (val) => {
        if (this.formModel.loggedinUserRole == 'Adm') {
        this.authService.getAllUserDetails().subscribe((res1: any) => { //this is used for User Management Screen
          if (res1 != null) {
            if (res1.status == 1) {
              this.dataSharingService.setAllUsersData(res1.result);
            }
          }
        })
        this.allUserDetails = this.dataSharingService.getAllUsersData();
      }
    
    });
    
    if (this.formModel.loggedinUserRole == 'Adm') {
      this.formModel.isAdminUser = "Y";
      this.authService.getAllUserDetails().subscribe((res1: any) => { //this is used for User Management Screen
        if (res1 != null) {
          if (res1.status == 1) {
            this.dataSharingService.setAllUsersData(res1.result);
          }
        }
      })
      this.allUserDetails = this.dataSharingService.getAllUsersData();
      this.mainCentreDetails = this.dataSharingService.getMainCentreData();
      this.childCentreDetails = this.dataSharingService.getChildCentreData();
      this.subCentreDetails = this.dataSharingService.getSubCentreData();
      this.RoleTypes = [
        {
          name: 'Super User',
          value: 'Adm'
        },
        {
          name: 'Main Center User',
          value: 'MCU'
        },
        {
          name: 'Child Center User',
          value: 'CCU'
        },
        {
          name: 'Sub Center User',
          value: 'SCU'
        }
      ];
    }
else{
    this.authService.GetUserInfoById(this.formModel.loginUserRoleID).subscribe((res: any) => {
      if (res.status = 1) {
        console.log(res.result);
        this.formModel.loginID = res.result['loginID'];
        this.formModel.roleID=res.result['roleID'];
       // this.formModel.roleName=res.result['roleName'];
        this.formModel.email=res.result['email'];
        this.formModel.contactNo=res.result['contactNo'];
        this.formModel.password=res.result['password'];
      }
    })
  }

  }

  addNewUser() {
    this.formModel.inEditMode = 'Y';
    this.formModel.loginID = "";
    this.formModel.password = "";
    this.formModel.contactNo = "";
    this.formModel.email = "";
    this.formModel.roleType = "";
  }

  onSubmit(form: NgForm) {
    if (form.value.loginID == "" || form.value.loginID == null || form.value.loginID == undefined) {
      alert("Enter Login Name");
      return;
    }
    if (form.value.password == "" || form.value.password == null || form.value.password == undefined) {
      alert("Enter Password");
      return;
    }
    if (form.value.roleType == "" || form.value.roleType == null || form.value.roleType == undefined) {
      alert("Select Role for the User");
      return;
    }

    var dataGood = false; 


    var obj = {};
    switch (form.value.roleType){
      case 'SCU': {
        if (form.value.subCentreDetails == null) {
          alert('Select Child Centre');
          break;
        }
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: form.value.subCentreDetails.subCentreName,
          subCentreID: form.value.subCentreDetails.subCentreID,
          childCentreID: form.value.childCentreDetails.childCentreID,
          childCentreName: form.value.childCentreDetails.childCentreName,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName,
          roleName: 'Sub Centre User'
        };
        dataGood = true;
        break;
      }
      case 'CCU': {
        if (form.value.childCentreDetails == null) {
          alert('Select Child Centre');
          break;
        }
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: '',
          subCentreID: 0,
          childCentreID: form.value.childCentreDetails.childCentreID,
          childCentreName: form.value.childCentreDetails.childCentreName,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName,
          roleName: 'Child Centre User'
        };
        dataGood = true;
        break;
      }
      case 'MCU': {
        if (form.value.mainCentreDetails == null) {
          alert('Select Main Centre');
          break;
        }
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: '',
          subCentreID: 0,
          childCentreID: 0,
          childCentreName: '',
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName,
          roleName: 'Main Centre User'
        };
        dataGood = true;
        break;
      }
      case 'Adm': {
        dataGood = true;
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: '',
          subCentreID: 0,
          childCentreID: 0,
          childCentreName: '',
          mainCentreID: 0,
          mainCentreName: '',
          roleName: 'Super User'
        };
        break;
      }
      default:
        break;
    }
    if (dataGood == false) {
      return;
    }
    this.offerService.saveUserData(obj).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setAllUsersData(res.result);
        this.allUserDetails = res.result;
        this.formModel.inEditMode = 'N';
      }
    })

  }

  deleteUser(roleID, roleType) {
    if (roleType == 'Adm' ) {
      alert("Super User cannot be deleted");
      return;
    }
    if (!confirm("Delete User ?")) {
      return;
    }

    this.offerService.deleteUser(roleID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setAllUsersData(res.result);
        this.allUserDetails = res.result;
      }
    })

  }

  onChangeMainCentre(mainCentre) {
    var tmpArry = [];
    for (var i = 0; i < this.childCentreDetails.length; i++) {
      if (this.childCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
        tmpArry.push(this.childCentreDetails[i]);
      }
    }
    this.mainCenterChildCenterData = tmpArry;
  }

  onChangeChildCentre(childCentre) {
    var tmpArry = [];
    for (var i = 0; i < this.subCentreDetails.length; i++) {
      if (this.subCentreDetails[i].childCentreID == childCentre.childCentreID) {
        tmpArry.push(this.subCentreDetails[i]);
      }
    }
    this.childCenterSubCenterData = tmpArry;
  }

  EditUser(roleID){
    this.formModel.inEditMode = "Y";
    this.authService.GetUserInfoById(roleID).subscribe((res: any) => {
      if (res.status = 1) {
        console.log(res.result);
        this.formModel.loginID = res.result['loginID'];
        this.formModel.roleID=res.result['roleID'];
       // this.formModel.roleName=res.result['roleName'];
        this.formModel.email=res.result['email'];
        this.formModel.contactNo=res.result['contactNo'];
        this.formModel.password=res.result['password'];
      }
    })
  }
  userModel: any=  {
    RoleID : "",
    Email : "",
    ContactNo:"",
    Password:"",
  }
  UpdateUser(form: NgForm){
    if (form.value.password == "" || form.value.password == null || form.value.password == undefined) {
      alert("Enter Password");
      return;
    }
    this.userModel.RoleID = form.value.roleID;
    this.userModel.Email = form.value.email;
    this.userModel.ContactNo = form.value.contactNo;
    this.userModel.Password = form.value.password;

    this.authService.UpdateUser(this.userModel).subscribe((res: any) => {
      if (res.status = 1) {
        if(  this.formModel.loggedinUserRole == 'Adm'){
        this.authService.getAllUserDetails().subscribe((res1: any) => { //this is used for User Management Screen
          if (res1 != null) {
            if (res1.status == 1) {
              this.dataSharingService.setAllUsersData(res1.result);
            }
          }
        })
        this.allUserDetails = this.dataSharingService.getAllUsersData();
        this.formModel.inEditMode = 'N';
      }else{
        this.authService.GetUserInfoById(this.formModel.loginUserRoleID).subscribe((res: any) => {
          if (res.status = 1) {
            console.log(res.result);
            this.formModel.loginID = res.result['loginID'];
            this.formModel.roleID=res.result['roleID'];
           // this.formModel.roleName=res.result['roleName'];
            this.formModel.email=res.result['email'];
            this.formModel.contactNo=res.result['contactNo'];
            this.formModel.password=res.result['password'];
          }
        })
        this.formModel.inEditMode = 'N';
      }
        
      }
      else{
        alert(res.message);
        this.formModel.inEditMode = 'N';
      }
    });
  }


}
