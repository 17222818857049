import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { OfferService } from "../../services/offer.service";
import { ExcelService } from '../../services/excel.service';
import { HttpClient } from '@angular/common/http';
import { ResponseViewModel } from '../../models/responseviewmodel';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CompleterService, CompleterData } from 'ng2-completer';
import { DatePipe } from '@angular/common';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ancestors',
  templateUrl: './ancestors.component.html',
  styleUrls: ['./ancestors.component.css']
})
export class AncestorsComponent implements OnInit {


  formModel: any = {
    action: "",
    actionType:"",
    loggedinUserRole: "",
    loginMainCentre: "",
    loginChildCentre: "",
    loginSubCentre: "",
    loginMainCentreID: "",
    loginChildCentreID: "",
    loginSubCentreID: "",
    ancestorsID: 0,
    memberID: "",
    memberName: "",
    qMemberName: "",
    status: "",
    description: "",
    altarActive:"",
    forFamily: "",
    tabletNo: "",
    tabletName: "",
    dateOfRemoval: null,
    relationship: "",
    removedDate: null,
    remarks: "",
    mainCentre: "",
    subCentre: "",
    childCentre: "",
    addUser: "",
    addUserDate: "",
    addUserTime: "",
    updtUser: "",
    updtUserDate: "",
    updtUserTime: "",
    offerDateStart: null,
    offerDateEnd: null,
    tabletsToBeRemoved: ""
  };
  glbAncestorsID:0;
  message:"";
  isAllMember: false;
  inEditMode = 'N';
  dataSource: Observable<any>;
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  qmainCentreDetails = [];
  qchildCentreDetails = [];
  qsubCentreDetails = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  qselectedMainCentreObj = {};
  qselectedChildCentreObj = {};
  qselectedSubCentreObj = {};
  allTextChildCentreObj = {};
  allTextSubCentreObj = {};

  allMembersArrayData: [];
  memberArray = [];
  centerWiseMemberArray = [];
  selectedAlterActive = {};
  alterActiveDetails = [];
  selectedTablet = {};
  tabletsOptionDetails = [];
  ancestorsDetails = [];



  protected dataservice: CompleterData;
  protected dataservice1: CompleterData;
  //protected dataservice2: CompleterData;

  constructor(private offerService: OfferService, private http: HttpClient, private excelService: ExcelService, 
    private router: Router, private route: ActivatedRoute, private completerService: CompleterService,
    private dataSharingService: GlobalDataSharingService, private spinner: NgxSpinnerService) {
  }

  populateDefaults() {
    this.selectedAlterActive = {
      id: 1,
      type: 'Yes'
    };
    this.alterActiveDetails = [];
    this.alterActiveDetails.push(this.selectedAlterActive);
    var tmpArry = [
      {
        id: 2,
        type: "No"
      }
    ];

    var i = 0;
    for (i = 0; i < tmpArry.length; i++) {
      this.alterActiveDetails.push(tmpArry[i]);
    }

    this.mainCentreDetails = [];
    this.childCentreDetails = [];
    this.subCentreDetails = [];
    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.formModel.actionType = "Ancestor (Entry)";
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
    let allowLocation = localStorage.getItem('allowLocation');
    let offData = allowLocation.split("/");
    this.formModel.loginMainCentre = offData[0];
    this.formModel.loginChildCentre = offData[1];
    this.formModel.loginSubCentre = offData[2];

    var i = 0;
    do {
      for (i = 0; i < this.glbmainCentreDetails.length; i++) {
        if (this.formModel.loginMainCentre == this.glbmainCentreDetails[i].mainCentreName) {
          this.selectedMainCentreObj = this.glbmainCentreDetails[i];
          this.formModel.loginMainCentreID = this.glbmainCentreDetails[i].mainCentreID;
          this.mainCentreDetails.push(this.selectedMainCentreObj);
          continue;
        }
        this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
      }
      if (this.formModel.loginChildCentre == "All" && this.formModel.loginSubCentre == "All") {
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      for (i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == this.formModel.loginMainCentreID) {
          if (this.glbchildCentreDetails[i].childCentreName == this.formModel.loginChildCentre) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            this.formModel.loginChildCentreID = this.glbchildCentreDetails[i].childCentreID;
          }
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
      if (this.formModel.loginSubCentre == "All") {
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == this.formModel.loginChildCentreID) {
          if (this.glbsubCentreDetails[i].subCentreName == this.formModel.loginSubCentre) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            this.formModel.loginSubCentreID = this.glbsubCentreDetails[i].subCentreID;
          }
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
    } while (false);
  }

  populateAncestorsQueryDefaults() {
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
    let allowLocation = localStorage.getItem('allowLocation');
    let offData = allowLocation.split("/");
    this.formModel.loginMainCentre = offData[0];
    this.formModel.loginChildCentre = offData[1];
    this.formModel.loginSubCentre = offData[2];

    var date = new Date();
    this.formModel.offerDateStart = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
    this.formModel.offerDateEnd = new Date(Date.UTC(date.getFullYear(), date.getMonth()+1, 0));
    this.selectedTablet = {
      id: 0,
      type: 'Present'
    };
    this.tabletsOptionDetails = [];
    this.tabletsOptionDetails.push(this.selectedTablet);
    var tmpArry = [
      {
        id: 1,
        type: "Removed"
      },
      {
        id: 2,
        type: "All"
      },
    ];
    var i = 0;
    for (i = 0; i < tmpArry.length; i++) {
      this.tabletsOptionDetails.push(tmpArry[i]);
    }

    this.allTextChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };

    this.qselectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.qselectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.qselectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    i = 0;
    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.qmainCentreDetails.push(this.qselectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          this.qmainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.qchildCentreDetails.push(this.qselectedChildCentreObj);
        this.qsubCentreDetails.push(this.qselectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.loginMainCentre == this.glbmainCentreDetails[i].mainCentreName) {
            this.qselectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.qonChangeMainCentre(this.qselectedMainCentreObj);
        this.qonChangeChildCentre(this.qselectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.loginMainCentre == this.glbmainCentreDetails[i].mainCentreName) {
            this.qselectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.loginChildCentre == this.glbchildCentreDetails[i].childCentreName) {
            this.qselectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.qonChangeChildCentre(this.qselectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.loginMainCentre == this.glbmainCentreDetails[i].mainCentreName) {
            this.qselectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.loginChildCentre == this.glbchildCentreDetails[i].childCentreName) {
            this.qselectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.loginSubCentre == this.glbsubCentreDetails[i].subCentreName) {
            this.qselectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
    this.populateQMemberArray(this.qselectedMainCentreObj['mainCentreName'], this.qselectedChildCentreObj['childCentreName'], this.qselectedSubCentreObj['subCentreName']);
  }

  populateQMemberArray(mainCentreName, childCentreName, subCentreName) {
    let tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    if (mainCentreName != "All") {
      tmpArray = tmpArray.filter(function (item) {
        item.mainCentre = item.mainCentre.trim();
        return item.mainCentre == mainCentreName;
      });
    }
    if (childCentreName != "All") {
      tmpArray = tmpArray.filter(function (item) {
        item.childCentre = item.childCentre;
        return item.childCentre == childCentreName;
      });
    }
    if (subCentreName != 'All') {
      tmpArray = tmpArray.filter(function (item) {
        item.subCentre = item.subCentre;
        return item.subCentre == subCentreName;
      });
    }
    this.dataservice1 = this.completerService.local(tmpArray, 'name', 'name');
  }

  populateMemberArrayCenterWise(mainCentreName, childCentreName, subCentreName) {
    this.memberArray = [];
    do {
      if (this.isAllMember) {
        this.memberArray = this.dataSharingService.getAllMembersListing();
        break;
      }
      let tmpArray = [];
      tmpArray = this.dataSharingService.getAllMembersListing();

      if (mainCentreName != "") {
        tmpArray = tmpArray.filter(function (item) {
          item.mainCentre = item.mainCentre.trim();
          return item.mainCentre == mainCentreName;
        });
      }
      if (childCentreName != undefined || childCentreName != "" || childCentreName != null) {
        tmpArray = tmpArray.filter(function (item) {
          item.childCentre = item.childCentre;
          return item.childCentre == childCentreName;
        });
      }
      if (subCentreName != undefined || subCentreName != null || subCentreName != "") {
        tmpArray = tmpArray.filter(function (item) {
          item.subCentre = item.subCentre;
          return item.subCentre == subCentreName;
        });
      }
      this.memberArray = tmpArray;

    } while (false);
    this.centerWiseMemberArray = this.memberArray;
    this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
  }

  ngOnInit() {
    this.inEditMode = "N";
    this.formModel.action = "EN";
    this.formModel.ancestorsID = 0;
    this.populateDefaults();
    this.isAllMember = false;
    let loginuser = [];
    loginuser = this.dataSharingService.getLoggedInUserData();

    this.isAllMember = false;
    this.formModel.offerDate = new Date();

    var date = new Date();
    var dd, mm, yyyy, dateStr;
    dd = date.getDate();
    mm = date.getMonth() + 1;
    yyyy = date.getFullYear();
    dateStr = mm + "/" + dd + "/" + yyyy;

    this.formModel.MemberCentre = "";
    this.formModel.message = "";
    this.glbAncestorsID = 0;
    this.allMembersArrayData = this.dataSharingService.getAllMembersListing();
    this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);
    //this.isAllMembrSelected(this.isAllMember);
    //this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
    //this.dataservice1 = this.completerService.local(this.allMembersArrayData, 'name', 'name');
    //this.dataservice2 = this.completerService.local(this.allMembersArrayData, 'status', 'status');
    var date = new Date();
    var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(); // Local time
    var pipe = new DatePipe('en-US');
    this.formModel.addUser = loginuser[0]['loginID'];
    this.formModel.updtUser = loginuser[0]['loginID'];
    this.formModel.addUserDate = pipe.transform(date, 'dd-MM-yyyy')
    this.formModel.updtUserDate = pipe.transform(date, 'dd-MM-yyyy')
    this.formModel.addUsertime = time;
    this.formModel.updtUserTime = time;
    this.populateAncestorsQueryDefaults();
  }

  qonChangeMainCentre(mainCentre) {
    do {
      this.qchildCentreDetails = [];
      this.qchildCentreDetails.push(this.allTextChildCentreObj);
      this.qselectedChildCentreObj = this.allTextChildCentreObj;
      this.qsubCentreDetails = [];
      this.qsubCentreDetails.push(this.allTextSubCentreObj);
      this.qselectedSubCentreObj = this.allTextSubCentreObj;

      for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
          this.qchildCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
    } while (false);
    this.populateQMemberArray(this.qselectedMainCentreObj['mainCentreName'], this.qselectedChildCentreObj['childCentreName'], this.qselectedSubCentreObj['subCentreName']);
  }

  qonChangeChildCentre(childCentre) {
    do {
      this.qsubCentreDetails = [];
      this.qsubCentreDetails.push(this.allTextSubCentreObj);
      this.qselectedSubCentreObj = this.allTextSubCentreObj;
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.qsubCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
    } while (false);
    this.populateQMemberArray(this.qselectedMainCentreObj['mainCentreName'], this.qselectedChildCentreObj['childCentreName'], this.qselectedSubCentreObj['subCentreName']);
  }

  qonChangeSubCentre(subCentre) {
    this.populateQMemberArray(this.qselectedMainCentreObj['mainCentreName'], this.qselectedChildCentreObj['childCentreName'], this.qselectedSubCentreObj['subCentreName']);
  }

  onChangeMainCentre(mainCentre) {
    do {
      this.childCentreDetails = [];
      this.selectedChildCentreObj = {};
      for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
      this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);
    } while (false);
  }

  onChangeChildCentre(childCentre) {
    do {
      this.subCentreDetails = [];
      this.selectedSubCentreObj = {};
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
      this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);
    } while (false);
  }
  onChangeSubCentre(subCentre) {
    this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);
  }
  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
  }
  isAllMembrSelected(value) {
    if (value == true) {
      this.memberArray = [];
      this.memberArray = this.allMembersArrayData;
      this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
    } else {
      this.memberArray = [];
      this.memberArray = this.centerWiseMemberArray;
      this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
    }
  }

  onMemberSelected(event) {
    if (this.inEditMode == 'Y') {
      return;
    }
    if (event != null && event.originalObject != null) {
      var tmpArray = [];
      tmpArray = this.allMembersArrayData;
      tmpArray = tmpArray.filter(function (item) {
        return item.memberID == event.originalObject.memberID;
      });

      this.formModel.memberName = tmpArray[0].name;
      this.formModel.memberID = tmpArray[0].memberID;
      this.formModel.status = tmpArray[0].status;
    }
  }
  alterActiveChanged() {
    if (this.selectedAlterActive['id'] == 0 || this.selectedAlterActive['id'] == 2) {
      this.formModel.forFamily = "";
      this.formModel.tabletNo = "";
      this.formModel.tabletName = "";
      this.formModel.dateOfRemoval = null;
      this.formModel.relationship = "";
      this.formModel.removedDate = null;
      this.formModel.remarks = "";
    }
  }

  onCancel() {
    this.formModel.ancestorsID = 0;
    this.formModel.memberID = "";
    this.formModel.memberName = "";
    this.formModel.status = "";
    this.formModel.description = "";
    this.formModel.altarActive = "";
    this.formModel.forFamily = "";
    this.formModel.tabletNo = "";
    this.formModel.tabletName = "";
    this.formModel.dateOfRemoval = null;
    this.formModel.relationship = "";
    this.formModel.removedDate = null;
    this.formModel.remarks = "";
    this.alterActiveDetails = [];
    this.selectedAlterActive = {
      id: 1,
      type: 'Yes'
    };
    this.alterActiveDetails.push(this.selectedAlterActive);
    var tmpArry = [
      {
        id: 2,
        type: "No"
      }
    ];
    for (var i = 0; i < tmpArry.length; i++) {
      this.alterActiveDetails.push(tmpArry[i]);
    }
    this.inEditMode = 'N';
  }

  onEditSubmit(form: NgForm) {
    var date = new Date();
    var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(); // Local time
    var pipe = new DatePipe('en-US');
    form.value.addUserDate = null;
    form.value.addUserTime = "";
    form.value.updtUserDate = pipe.transform(date, 'MM-dd-yyyy');
    form.value.updtUserTime = time;
    var altarActiveType = form.value.alterActiveDetails['type'];
    if (altarActiveType == "Yes") {
      form.value.altarActive = "Y";
    } else {
      form.value.altarActive = "N";
    }
    var dd;
    var mm;
    var yyyy;
    var d;
    var dateStr;
    var removedDate = form.value.removedDate;
    if (removedDate == "" || removedDate == null || removedDate == NaN) {
      form.value.removedDate = "";
    } else {
      dd = form.value.removedDate.getDate();
      mm = form.value.removedDate.getMonth() + 1;
      yyyy = form.value.removedDate.getFullYear();
      dateStr = yyyy + "-" + mm + "-" + dd;
      d = new Date(dateStr);
      form.value.removedDate = pipe.transform(d, 'MM-dd-yyyy');
    }
    var dateOfRemoval = form.value.dateOfRemoval;
    if (dateOfRemoval == "" || dateOfRemoval == null || dateOfRemoval == NaN) {
      form.value.dateOfRemoval = "";
    } else {
      dd = form.value.dateOfRemoval.getDate();
      mm = form.value.dateOfRemoval.getMonth() + 1;
      yyyy = form.value.dateOfRemoval.getFullYear();
      dateStr = yyyy + "-" + mm + "-" + dd;
      d = new Date(dateStr);
      form.value.dateOfRemoval = pipe.transform(d, 'MM-dd-yyyy');
    }
    this.offerService.SaveAncestorsData(form.value).subscribe((res: any) => {
      if (res.status == 0) {
        alert(res.message);
      } else {
        this.playAudio();
        this.formModel.message = "RECORD SAVED";
        setTimeout(() => {
          this.formModel.message = "";
        }, 3000);
        this.onClear();
      }
    })

  }
  onSubmit(form: NgForm) {
    do {
      if (this.formModel.ancestorsID > 0) {
        this.onEditSubmit(form);
        break;
      }
      var date = new Date();
      var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(); // Local time
      var pipe = new DatePipe('en-US');
      this.formModel.ancestorsID = 0;
      form.value.ancestorsID = 0;
      form.value.addUserDate = pipe.transform(date, 'MM-dd-yyyy');
      form.value.addUserTime = time;
      form.value.updtUserDate = null;
      form.value.updtUserTime = "";
      form.value.updtUser = "";

      if (this.formModel.loggedinUserRole == 'Adm') {
        if (form.value.subCentreDetails == null || form.value.subCentreDetails.subCentreName == null) {
          alert("Please select Sub Centre");
          return;
        }
        if (form.value.childCentreDetails == null || form.value.childCentreDetails.childCentreName == null) {
          alert("Please select Child Centre");
          return;
        }
        if (form.value.mainCentreDetails == null || form.value.mainCentreDetails.mainCentreName == null) {
          alert("Please select Main Centre");
          return;
        }
        form.value.mainCentre = form.value.mainCentreDetails.mainCentreName;
        form.value.childCentre = form.value.childCentreDetails.childCentreName;
        form.value.subCentre = form.value.subCentreDetails.subCentreName;
      } else if (this.formModel.loggedinUserRole == 'CCU') {
        form.value.mainCentre = this.selectedMainCentreObj['mainCentreName'];
        form.value.childCentre = this.selectedChildCentreObj['childCentreName'];
        if (form.value.subCentreDetails == null || form.value.subCentreDetails.subCentreName == null) {
          alert("Please select Sub Centre");
          return;
        }
        form.value.subCentre = form.value.subCentreDetails.subCentreName;
      } else if (this.formModel.loggedinUserRole == 'MCU') {
        form.value.mainCentre = this.selectedMainCentreObj['mainCentreName'];
        if (form.value.childCentreDetails == null || form.value.childCentreDetails.childCentreName == null) {
          alert("Please select Child Centre");
          return;
        }
        if (form.value.subCentreDetails == null || form.value.subCentreDetails.subCentreName == null) {
          alert("Please select Sub Centre");
          return;
        }
        form.value.childCentre = form.value.childCentreDetails.childCentreName;
        form.value.subCentre = form.value.subCentreDetails.subCentreName;
      } else if (this.formModel.loggedinUserRole == 'SCU') {
        form.value.mainCentre = this.selectedMainCentreObj['mainCentreName'];
        form.value.childCentre = this.selectedChildCentreObj['childCentreName'];
        form.value.subCentre = this.selectedSubCentreObj['subCentreName'];
      }
      if (form.value.memberName == "" || form.value.memberName == null || form.value.memberName == undefined) {
        alert("Please select / enter Member details");
        return;
      }
      var altarActiveType = form.value.alterActiveDetails['type'];
      if (altarActiveType == "Yes") {
        form.value.altarActive = "Y";
      } else {
        form.value.altarActive = "N";
      }
      var dd;
      var mm;
      var yyyy;
      var d;
      var dateStr;
      console.log(form.value);
      var removedDate = form.value.removedDate;
      if (removedDate == "" || removedDate == null || removedDate == NaN) {
        form.value.removedDate = "";
      } else {
        dd = form.value.removedDate.getDate();
        mm = form.value.removedDate.getMonth() + 1;
        yyyy = form.value.removedDate.getFullYear();
        dateStr = yyyy + "-" + mm + "-" + dd;
        d = new Date(dateStr);
        form.value.removedDate = pipe.transform(d, 'MM-dd-yyyy');
      }
      var dateOfRemoval = form.value.dateOfRemoval;
      if (dateOfRemoval == "" || dateOfRemoval == null || dateOfRemoval == NaN) {
        form.value.dateOfRemoval = "";
      } else {
        dd = form.value.dateOfRemoval.getDate();
        mm = form.value.dateOfRemoval.getMonth() + 1;
        yyyy = form.value.dateOfRemoval.getFullYear();
        dateStr = yyyy + "-" + mm + "-" + dd;
        d = new Date(dateStr);
        form.value.dateOfRemoval = pipe.transform(d, 'MM-dd-yyyy');
      }
      var tmpArray = [];
      tmpArray = this.dataSharingService.getAllMembersListing();
      tmpArray = tmpArray.filter(function (item) {
        return item.name == form.value.memberName;
      });

      if (tmpArray == null || tmpArray == undefined || tmpArray[0] == undefined) {
        alert("Invalid Member Name / Member ID - 1");
        return;
      }
      tmpArray = this.dataSharingService.getAllMembersListing();
      tmpArray = tmpArray.filter(function (item) {
        return item.memberID == form.value.memberID;
      });
      if (tmpArray == null || tmpArray == undefined || tmpArray[0] == undefined) {
        alert("Invalid Member Name / Member ID -2");
        return;
      }

      this.offerService.SaveAncestorsData(form.value).subscribe((res: any) => {
        if (res.status == 0) {
          alert(res.message);
        } else {
          this.playAudio();
          this.formModel.message = "RECORD SAVED";
          setTimeout(() => {
            this.formModel.message = "";
          }, 3000);
        }
      })

    } while (false);
  }

  onMemberSearching(formModel) {
    if (this.inEditMode == 'Y') {
      return;
    }
    if (this.dataservice != null) {
      return;
    }
  }
  memberName = "memberName";
  onKey(event) {
    document.getElementById("memberName").focus();
  }
  playAudio() {
    let audio = new Audio();
    audio.src = "../assets/audio/beep2.wav";
    audio.load();
    audio.play();
  }

/*Ancestors Query */
  onSearchAncestors(form: NgForm) {
    var pipe = new DatePipe('en-US')
    if (form.value.offerDateStart != undefined) {
      form.value.offerDateStart = pipe.transform(form.value.offerDateStart, 'MM/dd/yyyy')
      form.value.offerDateEnd = pipe.transform(form.value.offerDateEnd, 'MM/dd/yyyy')
    }
    if (form.value.childCentreDetails == undefined) {
      form.value.childCentre = this.qselectedChildCentreObj['childCentreName'];
    } else {
      form.value.childCentre = form.value.childCentreDetails.childCentreName;
    }

    if (form.value.mainCentreDetails == undefined) {
      form.value.mainCentre = this.qselectedMainCentreObj['mainCentreName']
    } else {
      form.value.mainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if (form.value.subCentreDetails == undefined) {
      form.value.subCentre = this.qselectedSubCentreObj['subCentreName'];
    } else {
      form.value.subCentre = form.value.subCentreDetails.subCentreName;
    }
    form.value.tablet = form.value.tabletsOptionDetails.type;
    form.value.report = "N";
    if (form.value.tabletsToBeRemoved == true) {
      form.value.report = "Y";
    } 
    this.offerService.getAncestorsData(form.value).subscribe((res: any) => {
      if (res.status == 1) {
        console.log(res.result);
        this.populateAncestorsDetails(res.result);
      } else {
        alert("No records found for given search criteria");
        this.ancestorsDetails = [];
      }
    });
  }

  onClear() {
    this.ancestorsDetails = [];
  }

  
  populateAncestorsDetails(data) {
    var tmpList = data;
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    this.ancestorsDetails = [];
    var i = 0;
    var tmpMemName = tmpList[i].memberName;
    var sameMbCount = 0;
    for (i = 0; i < tmpList.length; i++) {
      var ancestorsID = tmpList[i].ancestorsID;
      var memberName = tmpList[i].memberName;
      var eMemID = tmpList[i].memberID;
      var eMemName = tmpList[i].memberName;
      var eMain = tmpList[i].mainCentre;
      var eChild = tmpList[i].childCentre;
      var eSub = tmpList[i].subCentre;
      var eAlter = tmpList[i].altarActive;
      var eDecr = tmpList[i].description;
      var eFor = tmpList[i].forFamily;
      var eStatus = tmpList[i].status;
      var edateOfRemoval = tmpList[i].dateOfRemoval;
      var eremovedDate = tmpList[i].removedDate;

      var centre = tmpList[i].mainCentre.substr(0, 3) + "/" + tmpList[i].childCentre.substr(0, 3) + "/" + tmpList[i].subCentre.substr(0, 3);
      var status = tmpList[i].status;
      var description = tmpList[i].description;
      var altarActive = tmpList[i].altarActive;
      var forFamily = tmpList[i].forFamily;
      var tabletNo = tmpList[i].tabletNo;
      var name = tmpList[i].tabletName;
      var dateOfRemoval = pipe.transform(tmpList[i].dateOfRemoval, 'dd/MM/yyyy');
      var relation = tmpList[i].relationship;
      var removedDate = pipe.transform(tmpList[i].removedDate, 'dd/MM/yyyy');
      var remark = tmpList[i].remarks; 
      if (tmpMemName == tmpList[i].memberName) {
        if (sameMbCount == 0) {
          var tmp = {
            ancestorsID: ancestorsID,
            MemberName: memberName,
            eMemName: eMemName,
            eMemID: eMemID,
            eMain: eMain,
            eChild: eChild,
            eSub: eSub,
            eAlter: eAlter,
            eDecr: eDecr,
            eFor: eFor,
            eStatus: eStatus,
            edateOfRemoval: edateOfRemoval,
            eremovedDate: eremovedDate,
            Centre: centre,
            Status: status,
            Description: description,
            AltarActive: altarActive,
            ForFamily: forFamily,
            TabletNo: tabletNo,
            Name: name,
            DateOfRemoval: dateOfRemoval,
            Relation: relation,
            RemovedDate: removedDate,
            Remark: remark
          };
          tmpArray.push(tmp);
        } else {
          var tmp1 = {
            ancestorsID: ancestorsID,
            MemberName: "",
            eMemName: eMemName,
            eMemID: eMemID,
            eMain: eMain,
            eChild: eChild,
            eSub: eSub,
            eAlter: eAlter,
            eDecr: eDecr,
            eFor: eFor,
            eStatus: eStatus,
            edateOfRemoval: edateOfRemoval,
            eremovedDate: eremovedDate,
            Centre: "",
            Status: "",
            Description: "",
            AltarActive: "",
            ForFamily: forFamily,
            TabletNo: tabletNo,
            Name: name,
            DateOfRemoval: dateOfRemoval,
            Relation: relation,
            RemovedDate: removedDate,
            Remark: remark
          };
          tmpArray.push(tmp1);
        }
        sameMbCount = sameMbCount + 1;
      } else {
        sameMbCount = 0;
        tmpMemName = tmpList[i].memberName;
        var tmp2 = {
          ancestorsID: ancestorsID,
          MemberName: memberName,
          eMemName: eMemName,
          eMemID: eMemID,
          eMain: eMain,
          eChild: eChild,
          eSub: eSub,
          eAlter: eAlter,
          eDecr: eDecr,
          eFor: eFor,
          eStatus: eStatus,
          edateOfRemoval: edateOfRemoval,
          eremovedDate: eremovedDate,
          Centre: centre,
          Status: status,
          Description: description,
          AltarActive: altarActive,
          ForFamily: forFamily,
          TabletNo: tabletNo,
          Name: name,
          DateOfRemoval: dateOfRemoval,
          Relation: relation,
          RemovedDate: removedDate,
          Remark: remark
        };
        tmpArray.push(tmp2);
        sameMbCount = sameMbCount + 1;
      }

    }
    this.ancestorsDetails = tmpArray;
  }

   
  onEditAncestorClicked(ancestorsData) {
    this.formModel.action = 'EN';
    this.formModel.actionType = 'Ancestor (Entry)';
    this.inEditMode = 'Y';
    this.formModel.ancestorsID = ancestorsData.ancestorsID;
    this.formModel.memberID = ancestorsData.eMemID;
    this.formModel.memberName = ancestorsData.eMemName;
    this.formModel.status = ancestorsData.eStatus;
    this.formModel.description = ancestorsData.eDecr;
    this.formModel.altarActive = ancestorsData.eAlter;
    this.formModel.forFamily = ancestorsData.eFor;
    this.formModel.tabletNo = ancestorsData.TabletNo;
    this.formModel.tabletName = ancestorsData.Name;
    this.formModel.dateOfRemoval = null;
    if (ancestorsData.DateOfRemoval != null) {
      var removalDate = new Date(ancestorsData.edateOfRemoval);
      this.formModel.dateOfRemoval = null;
      this.formModel.dateOfRemoval = removalDate;
    }
    this.formModel.relationship = ancestorsData.Relation;
    this.formModel.removedDate = null;
    if (ancestorsData.RemovedDate != null) {
      var rDate = new Date(ancestorsData.eremovedDate);
      this.formModel.removedDate = null;
      this.formModel.removedDate = rDate;
    }
    this.formModel.remarks = ancestorsData.Remark;
    if (ancestorsData.eAlter == "Y") {
      ancestorsData.eAlter = "Yes";
    } else {
      ancestorsData.eAlter = "No";
    } 
    
    this.selectedAlterActive = {};
    this.alterActiveDetails = [];
    var tmpArry = [
      {
        id: 1,
        type: "Yes"
      },
      {
        id: 2,
        type: "No"
      },
    ];
    for (var i = 0; i < tmpArry.length; i++) {
      if (tmpArry[i].type == ancestorsData.eAlter) {
        this.selectedAlterActive = tmpArry[i];
      }
      this.alterActiveDetails.push(tmpArry[i]);
    }

    var i = 0;
    do {
      this.mainCentreDetails = [];
      this.childCentreDetails = [];
      this.subCentreDetails = [];

      for (i = 0; i < this.glbmainCentreDetails.length; i++) {
        if (ancestorsData.eMain == this.glbmainCentreDetails[i].mainCentreName) {
          this.selectedMainCentreObj = this.glbmainCentreDetails[i];
          this.formModel.loginMainCentreID = this.glbmainCentreDetails[i].mainCentreID;
          this.mainCentreDetails.push(this.selectedMainCentreObj);
          continue;
        }
        this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
      }

      for (i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == this.formModel.loginMainCentreID) {
          if (this.glbchildCentreDetails[i].childCentreName == ancestorsData.eChild) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            this.formModel.loginChildCentreID = this.glbchildCentreDetails[i].childCentreID;
            this.childCentreDetails.push(this.glbchildCentreDetails[i]);
            continue;
          }
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == this.formModel.loginChildCentreID) {
          if (this.glbsubCentreDetails[i].subCentreName == ancestorsData.eSub) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            this.formModel.loginSubCentreID = this.glbsubCentreDetails[i].subCentreID;
            this.subCentreDetails.push(this.glbsubCentreDetails[i]);
            continue;
          }
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
    } while (false);

  }

  deleteAncestorEntry(ancestorsID, form: NgForm) {
    if (!confirm("Delete Ancestors Data ?")) {
      return;
    }
    this.offerService.deleteAncestorEntry(ancestorsID).subscribe((res: any) => {
      if (res.status = 1) {
        this.onSearchAncestors(form);
      }
    });
  }

  exportAncestors() {
    var tmpArray = new Array();
    for (var i = 0; i < this.ancestorsDetails.length; i++) {
      var tmp = {
        MemberName: this.ancestorsDetails[i].MemberName,
        Centre: this.ancestorsDetails[i].Centre,
        Status: this.ancestorsDetails[i].Status,
        Description: this.ancestorsDetails[i].Description,
        AltarActive: this.ancestorsDetails[i].AltarActive,
        ForFamily: this.ancestorsDetails[i].ForFamily,
        TabletNo: this.ancestorsDetails[i].TabletNo,
        RealNameRelationship: this.ancestorsDetails[i].Name,
        Kamiyo: this.ancestorsDetails[i].Relation,
        DateOfRemoval: this.ancestorsDetails[i].DateOfRemoval,
        RemovedDate: this.ancestorsDetails[i].RemovedDate,
        Remark: this.ancestorsDetails[i].Remark
      }
      tmpArray.push(tmp);
    }
    this.excelService.exportAsExcelFile(tmpArray, 'Ancestors');
  }

}
