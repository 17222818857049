import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticateService } from "../../services/authenticate.service";
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { OfferService } from "../../services/offer.service";
import { CompleterService, CompleterData } from 'ng2-completer';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  IsAuthenticated: boolean;
  locations: [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  formModel = {
    loginId: "",
    password: "",
    allowLocation: "",
    isUserSignedIn: "",
    loggedinUserRole: "",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName: "",
    subCentreID: "",
    allRecordsFetched: ""
  };
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  allTextChildCentreObj = {};
  allTextSubCentreObj = {};

  constructor(private authService: AuthenticateService,
    private offerService: OfferService,
    private router: Router,
    private completerService: CompleterService,
    private dataSharingService: GlobalDataSharingService,
    private route: ActivatedRoute) {

    /*this.authService.getAllowLocation().subscribe((res: any) => {
      this.locations = res;
    }
      , err => {
        if (err.status = 400) {

        }
      })
      */
    this.formModel.allRecordsFetched = "N";

    this.offerService.getMainCentreDetails().subscribe((res: any) => {
      if (res != null) {
        if (res.status == 1) {
          this.dataSharingService.setMainCentreData(res.result);
        }
      }
      this.offerService.getChildCentreDetails().subscribe((res: any) => {
        if (res != null) {
          if (res.status == 1) {
            this.dataSharingService.setChildCentreData(res.result);
          }
        }
        this.offerService.getSubCentreDetails().subscribe((res: any) => {
          if (res != null) {
            if (res.status == 1) {
              this.dataSharingService.setSubCentreData(res.result);
            }
          }
        })
      })
    })
    /*
    this.offerService.getAllMembers().subscribe((res: any) => {
      if (res != null) {
        this.dataSharingService.setAllMembersDetails(this.completerService.local(res, 'memberName', 'memberName'));
      }
    })
    */
    this.offerService.getAllMembersListing().subscribe((res: any) => {
      if (res != null) {
        this.dataSharingService.setAllMembersListing(res.result);
      }
      this.formModel.allRecordsFetched = "Y";
    })

    this.offerService.getStatusDetails().subscribe((res: any) => {
      if (res != null) {
        if (res.status == 1) {
          this.dataSharingService.setStatusData(res.result);
        }
      }
    })
    
    this.offerService.getSysVarData().subscribe((res: any) => {
      console.log(res);
      if (res.status == 1) {
        this.dataSharingService.setSysVarData(res.result);
      }
    })

//    console.log("Getting getAllOtherOfferingData - Prakash");
    this.dataSharingService.bOfferListingDataDownloaded = false;
    /*
    this.offerService.getAllOtherOfferingData().subscribe((res: any) => {
      if (res.status == 1) {
        console.log("Data downloaded");
        this.dataSharingService.bOfferListingDataDownloaded = true;
        this.dataSharingService.setOfferListingData(res.result);
      }
    })
    */
    
  }

  ngOnInit() {
    this.formModel.isUserSignedIn = "N";
    this.formModel.loggedinUserRole = "";
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.IsAuthenticated = this.authService.GetAuthenticated();
    if (this.IsAuthenticated) {
      //  this.router.navigateByUrl(this.returnUrl);
      this.router.navigateByUrl("offer/mebo");
    }

    // get return url from route parameters or default to '/'
    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: "",
    };

  }

  onChangeMainCentre(mainCentre) {
    do {
      this.childCentreDetails = [];
      this.childCentreDetails.push(this.allTextChildCentreObj);
      this.selectedChildCentreObj = this.allTextChildCentreObj;

      for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
    } while (false);

  }

  onChangeChildCentre(childCentre) {
    do {
      this.subCentreDetails = [];
      this.subCentreDetails.push(this.allTextSubCentreObj);
      this.selectedSubCentreObj = this.allTextSubCentreObj;
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
    } while (false);
  }

  selectLocation(form: NgForm) {
    if (this.formModel.allRecordsFetched == "N" || this.formModel.allRecordsFetched == "") {
      alert("Please wait. Getting records from Server");
      return;
    }
    var location = form.value.mainCentreDetails.mainCentreName;
    location = location + "/" + form.value.childCentreDetails.childCentreName;
    location = location + "/" + form.value.subCentreDetails.subCentreName;
    localStorage.setItem("allowLocation", location);
    // console.log(this.returnUrl);
    this.router.navigateByUrl("offer/mebo");
  }

  onSubmit(form: NgForm) {
    this.authService.login(form.value).subscribe((res: any) => {
      if (res.status == 0) {
        alert(res.message);
        return;
      }
      /*
      if (res.result[0]['isOnline'] == "active") {
        alert("Already Logined In");
        return;
      }
      else */{
        this.formModel.isUserSignedIn = "Y";
        localStorage.setItem("token", res.message);
        this.dataSharingService.setLoggedInUserData(res.result);

        form.value.IsOnline = "active";
        form.value.roleID = res.result[0]['roleID'];
        this.authService.UpdateLogin(form.value).subscribe((res1: any) => {
          ;//console.log(res.result[0]);
          //this.dataSharingService.setLoggedInUserData(res1.result);
        })

        if (res.result[0].roleType == 'Adm') {
          localStorage.setItem("isAdminUser", 'Y');

          this.authService.getAllUserDetails().subscribe((res1: any) => { //this is used for User Management Screen
            if (res1 != null) {
              if (res1.status == 1) {
                this.dataSharingService.setAllUsersData(res1.result);
              }
            }
          })
        }
        else {
          localStorage.setItem("isAdminUser", 'N');
        }
        localStorage.setItem("loggedinUserRole", res.result[0].roleType);
        localStorage.setItem("loginUserRoleID", res.result[0].roleID);
        // localStorage.setItem("roleType",res.result[0].roleType);

        this.formModel.loggedinUserRole = res.result[0].roleType;
        this.formModel.mainCentreName = res.result[0].mainCentreName;
        this.formModel.childCentreName = res.result[0].childCentreName;
        this.formModel.subCentreName = res.result[0].subCentreName;
        this.formModel.mainCentreID = res.result[0].mainCentreID;
        this.formModel.childCentreID = res.result[0].childCentreID;
        this.formModel.subCentreID = res.result[0].subCentreID;
        this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
        this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
        this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
        this.mainCentreDetails = [];
        this.childCentreDetails = [];
        this.subCentreDetails = [];
        var i = 0;
        switch (this.formModel.loggedinUserRole) {
          case 'Adm': {
            //this.mainCentreDetails.push(this.selectedMainCentreObj);
            for (i = 0; i < this.glbmainCentreDetails.length; i++) {
              this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
            }
            this.selectedMainCentreObj = this.glbmainCentreDetails[0];
            this.childCentreDetails.push(this.selectedChildCentreObj);
            this.subCentreDetails.push(this.selectedSubCentreObj);
            this.onChangeMainCentre(this.selectedMainCentreObj);
            this.onChangeChildCentre(this.selectedChildCentreObj);
            break;
          }
          case 'MCU': {
            for (i = 0; i < this.glbmainCentreDetails.length; i++) {
              if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
                this.selectedMainCentreObj = this.glbmainCentreDetails[i];
                break;
              }
            }
            this.onChangeMainCentre(this.selectedMainCentreObj);
            this.onChangeChildCentre(this.selectedChildCentreObj);
            break;
          }
          case 'CCU': {
            for (i = 0; i < this.glbmainCentreDetails.length; i++) {
              if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
                this.selectedMainCentreObj = this.glbmainCentreDetails[i];
                break;
              }
            }
            for (i = 0; i < this.glbchildCentreDetails.length; i++) {
              if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
                this.selectedChildCentreObj = this.glbchildCentreDetails[i];
                break;
              }
            }
            this.onChangeChildCentre(this.selectedChildCentreObj);
            break;
          }
          case 'SCU': {
            for (i = 0; i < this.glbmainCentreDetails.length; i++) {
              if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
                this.selectedMainCentreObj = this.glbmainCentreDetails[i];
                break;
              }
            }
            for (i = 0; i < this.glbchildCentreDetails.length; i++) {
              if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
                this.selectedChildCentreObj = this.glbchildCentreDetails[i];
                break;
              }
            }
            for (i = 0; i < this.glbsubCentreDetails.length; i++) {
              if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
                this.selectedSubCentreObj = this.glbsubCentreDetails[i];
                break;
              }
            }
            break;
          }

          default: {
            break;
          }
        }

        //this.router.navigateByUrl("offer/list");
      }
    }
      , err => {
        if (err.status = 400) {
          console.log("Auth failed", "Login Name and Password is incorrect.")
        }
      })
  
}
}
