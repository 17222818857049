import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { OfferService } from "../../services/offer.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-editmebo',
  templateUrl: './editmebo.component.html',
  styleUrls: ['./editmebo.component.css']
})
export class EditmeboComponent implements OnInit {

  constructor(  private spinner: NgxSpinnerService,private router: Router, private route: ActivatedRoute,  private offerService: OfferService,private dataSharingService: GlobalDataSharingService) { }

  allMembersArrayData = [];
  formModel: any = {
    loggedinUserRole: "",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName:"",
    subCentreID: "",
    gender: "",
    isAdultYN: "",
    memberID: "",
    name: "",
    birthDate: "",
    introducerName:"",
    aadhaarNo :"",
    panNo :"",
    addressL1 :"",
    addressL2 :"",
    addressL3 : "",
    city : "",
    state : "",
    country : "",
    zipCode : "",
    mobileNo : "",
    alternateNo : "",
    previousName: "",
    remark1: "",
    remark2: "",
    remark3: "",
    // ikyn :"",
    // intKNo : "",
    // intKDate : "",
    // intKPlace : "",
    // akyn : "",
    // advKNo : "",
    // advKDate : "",
    // advKPlace : ""

  };
  queryMDetails = [];
  loggedInUserData = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  mainCentreObjEditMode = {};
  childCentreObjEditMode = {};
  subCentreObjEditMode = {};
  statusObjEditMode = {};
  statusDetailsEditMode = [];
  mainCentreDetailsEditMode = [];
  childCentreDetailsEditMode = [];
  subCentreDetailsEditMode = [];
  genderList = [];
  isAdultList=[];
  isIKenshuList=[];
  isAKenshuList = [];
  ngOnInit() {
    this.genderList = [{ name: "Male", value: "M" }, { name: "Female", value: "F" }];
    this.isAdultList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.isIKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.isAKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    
    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.loggedInUserData = this.dataSharingService.getLoggedInUserData();
    this.formModel.mainCentreName = this.loggedInUserData[0].mainCentreName;
    this.formModel.childCentreName = this.loggedInUserData[0].childCentreName;
    this.formModel.subCentreName = this.loggedInUserData[0].subCentreName;
    this.formModel.mainCentreID = this.loggedInUserData[0].mainCentreID;
    this.formModel.childCentreID = this.loggedInUserData[0].childCentreID;
    this.formModel.subCentreID = this.loggedInUserData[0].subCentreID;
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
    this.statusDetailsEditMode = this.dataSharingService.getStatusData();
    this.route.queryParams.subscribe(params => {

      if (params.id == null || params.id == undefined) {
        return;
      }

      
      this.getMemberbyId(params.id);
    })


  
  }

  getMemberbyId(id){
    this.mainCentreDetailsEditMode = this.dataSharingService.getMainCentreData();
    //this.childCentreDetailsEditMode = this.dataSharingService.getChildCentreData();
    //this.subCentreDetailsEditMode = this.dataSharingService.getSubCentreData();
    this.statusDetailsEditMode = this.dataSharingService.getStatusData();
    this.offerService.GetMemberInfoById(id).subscribe((res: any) => {
      if (res.status == 1) {
        var memberObj = res['result'];
        this.formModel.memberID = memberObj.memberID;

        var i = 0;
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (memberObj.mainCentre == this.glbmainCentreDetails[i].mainCentreName) {
            this.mainCentreObjEditMode = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.childCentreDetailsEditMode = [];
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.glbchildCentreDetails[i].mainCentreName == this.mainCentreObjEditMode['mainCentreName']) {
            this.childCentreDetailsEditMode.push(this.glbchildCentreDetails[i]);
          }

          if (memberObj.childCentre == this.glbchildCentreDetails[i].childCentreName) {
            this.childCentreObjEditMode = this.glbchildCentreDetails[i];
            //break;
          }
        }
        this.subCentreDetailsEditMode = [];
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.glbsubCentreDetails[i].childCentreName == this.childCentreObjEditMode['childCentreName']) {
            this.subCentreDetailsEditMode.push(this.glbsubCentreDetails[i]);
          }
          if (memberObj.subCentre == this.glbsubCentreDetails[i].subCentreName) {
            this.subCentreObjEditMode = this.glbsubCentreDetails[i];
            //break;
          }
        }

        for (i = 0; i < this.statusDetailsEditMode.length; i++) {
          if (memberObj.status == this.statusDetailsEditMode[i].statusName) {
            this.statusObjEditMode = this.statusDetailsEditMode[i];
            break;
          }
        }
       
        var pipe = new DatePipe('en-US');
        this.formModel.gender = memberObj.gender;
        this.formModel.isAdultYN = memberObj.isAdultYN;
        this.formModel.name = memberObj.name;
        this.formModel.previousName = memberObj.previousName;
        this.formModel.aadhaarNo = memberObj.aadhaarNo;
        this.formModel.panNo = memberObj.panNo;
        this.formModel.addressL1 = memberObj.addressL1;
        this.formModel.addressL2 = memberObj.addressL2;
        this.formModel.addressL3 = memberObj.addressL3;
        this.formModel.city = memberObj.city;
        this.formModel.state = memberObj.state;
        this.formModel.country = memberObj.country;
        this.formModel.zipCode = memberObj.zipCode;
        this.formModel.mobileNo = memberObj.mobileNo;
        this.formModel.alternateNo = memberObj.alternateNo;
        this.formModel.remark1 = memberObj.remark1;
        this.formModel.remark2 = memberObj.remark2;
        this.formModel.remark3 = memberObj.remark3;
        this.formModel.introducerName = memberObj.introducerName; 
        this.formModel.birthDate = memberObj.birthDate;
        if (this.formModel.birthDate != null && this.formModel.birthDate!= "") {
          this.formModel.birthDate = new Date(memberObj.birthDate);
        }


        // this.formModel.ikyn = memberObj.ikyn;
        // this.formModel.intKNo = memberObj.intKNo;
       // this.formModel.intKDate = memberObj.intKDate;
        // var date = new Date(memberObj.intKDate);
        // this.formModel.intKDate =  pipe.transform(date, 'dd-MM-yyyy');
        // this.formModel.intKPlace = memberObj.intKPlace;

        // this.formModel.akyn = memberObj.akyn;
        // this.formModel.advKNo = memberObj.advKNo;
        // //this.formModel.advKDate = memberObj.advKDate;
        // var date = new Date(memberObj.advKDate);
        // this.formModel.advKDate =  pipe.transform(date, 'dd-MM-yyyy');
        // this.formModel.advKPlace = memberObj.advKPlace;
      
        
      }else{
        alert(res.message);
      }
      
    })
  }


  onChangeMainCentre(mainCentre) {
    do {
        this.childCentreDetailsEditMode = [];
        for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
            this.childCentreDetailsEditMode.push(this.glbchildCentreDetails[i]);
          }
      }
    } while (false);

  }

  onChangeChildCentre(childCentre) {
    do {
      this.subCentreDetailsEditMode = [];
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.subCentreDetailsEditMode.push(this.glbsubCentreDetails[i]);
        }
      }
      this.subCentreObjEditMode = this.subCentreDetailsEditMode[0];
    } while (false);
  }

  UpdateMebo(form: NgForm) {
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    tmpArray = tmpArray.filter(function (item) {
      return item.memberID == form.value.memberID;
    });
    var pipe = new DatePipe('en-US');

    tmpArray[0].status = form.value.statusDetails.statusName;
    tmpArray[0].mainCentre = form.value.mainCentreDetails.mainCentreName;
    tmpArray[0].childCentre = form.value.childCentreDetails.childCentreName;
    tmpArray[0].subCentre = form.value.subCentreDetails.subCentreName;
    tmpArray[0].isAdultYN = form.value.isAdultYN;
    tmpArray[0].name = form.value.name;
    if (form.value.birthDate != null || form.value.birthDate != "" ) {
      tmpArray[0].birthDate = pipe.transform(form.value.birthDate, 'MM/dd/yyyy');
    }
    tmpArray[0].previousName = form.value.previousName;
    tmpArray[0].gender = form.value.gender;
    tmpArray[0].panNo = form.value.panNo;
    tmpArray[0].aadhaarNo = form.value.aadhaarNo;
    tmpArray[0].addressL1 = form.value.addressL1;
    tmpArray[0].addressL2 = form.value.addressL2;
    tmpArray[0].addressL3 = form.value.addressL3;
    tmpArray[0].city = form.value.city;
    tmpArray[0].state = form.value.state;
    tmpArray[0].country = form.value.country;
    tmpArray[0].zipCode = form.value.zipCode;
    tmpArray[0].mobileNo = form.value.mobileNo;
    tmpArray[0].alternateNo = form.value.alternateNo;
    tmpArray[0].introducerName = form.value.introducerName;
    tmpArray[0].remark1 = form.value.remark1;
    tmpArray[0].remark2 = form.value.remark2;
    tmpArray[0].remark3 = form.value.remark3;


    // tmpArray[0].ikyn = form.value.ikyn;
    // tmpArray[0].intKNo = form.value.intKNo;
    // tmpArray[0].intKDate = form.value.intKDate;
    // tmpArray[0].intKPlace = form.value.intKPlace;

    // tmpArray[0].akyn = form.value.akyn;
    // tmpArray[0].advKNo = form.value.advKNo;
    // tmpArray[0].advKDate = form.value.advKDate;
    // tmpArray[0].advKPlace = form.value.advKPlace; 

    this.offerService.updateMebo(tmpArray[0]).subscribe((res: any) => {
      if (res.status == 0) {
        alert(res.message);
        return;
      }
      var index = this.queryMDetails.findIndex((e) => e.id === tmpArray[0].id);
      if (index === -1) {
        return;
      } else {
        this.queryMDetails[index] = tmpArray[0];
      }
     // this.formModel.inEditMode = 'N';
      index = -1;
      var tmpArray2 = [];
      tmpArray2 = this.dataSharingService.getAllMembersListing();
      index = tmpArray2.findIndex((e) => e.id === tmpArray[0].id);
      if (index === -1) {
        return;
      } else {
        tmpArray2[index] = tmpArray[0];
      }
     
      this.dataSharingService.setAllMembersListing(tmpArray2);
      
      })
  }
 cancelClick(){
  
   this.router.navigateByUrl("offer/mebo");
 }

}
