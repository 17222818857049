import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { OfferService } from "../../services/offer.service";

@Component({
  selector: 'app-managemasters',
  templateUrl: './managemasters.component.html',
  styleUrls: ['./managemasters.component.css']
})
export class ManagemastersComponent implements OnInit {
  formModel: any = {
    manageMaster: "",
    mainCentreID: null,
    mainCentreName: "",
    childCentreID: null,
    childCentreName: "",
    subCentreID: null,
    subCentreName: "",
    statusID: null,
    statusName: ""
  };
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  statusDetails = [];

  constructor(private dataSharingService: GlobalDataSharingService, private offerService: OfferService) { }

  ngOnInit() {
    this.formModel.manageMaster = "M";
    this.mainCentreDetails = this.dataSharingService.getMainCentreData();
    this.childCentreDetails = this.dataSharingService.getChildCentreData();
    this.subCentreDetails = this.dataSharingService.getSubCentreData();
    this.statusDetails = this.dataSharingService.getStatusData();
  }

  onCancel() {
    switch (this.formModel.manageMaster) {
      case 'AEM': {
        this.formModel.manageMaster = 'M'
        break;
      }
      case 'AEC': {
        this.formModel.manageMaster = 'C'
        break;
      }
      case 'AES': {
        this.formModel.manageMaster = 'S'
        break;
      }
      case 'AET': {
        this.formModel.manageMaster = 'T'
        break;
      }
      default:{
        break;   
      }
    }
  }

  onSubmit(form: NgForm) {
    console.log(form.value);
    switch (this.formModel.manageMaster) {
      case 'AEM': {
        if (form.value.mainCentreName == "" || form.value.mainCentreName == null || form.value.mainCentreName == undefined) {
          alert("Main Centre Name cannot be blank");
          return;
        }
        this.offerService.saveMainCentreData(form.value).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setMainCentreData(res.result);
            this.mainCentreDetails = res.result;
            this.onCancel();
          }
        })
        break;
      }
      case 'AEC': {
        if (form.value.childCentreName == "" || form.value.childCentreName == null || form.value.childCentreName == undefined) {
          alert("Child Centre Name cannot be blank");
          return;
        }
        var obj = {
          childCentreName:form.value.childCentreName,
          childCentreID:0,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName:form.value.mainCentreDetails.mainCentreName
        };
        this.offerService.saveChildCentreData(obj).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setChildCentreData(res.result);
            this.childCentreDetails = res.result;
            this.onCancel();
          }
        })
        break;
      }
      case 'AES': {
        if (form.value.subCentreName == "" || form.value.subCentreName == null || form.value.subCentreName == undefined) {
          alert("Sub Centre Name cannot be blank");
          return;
        }
        var obj1 = {
          subCentreName: form.value.subCentreName,
          subCentreID: form.value.subCentreID,
          childCentreID: form.value.childCentreDetails.childCentreID,
          childCentreName: form.value.childCentreDetails.childCentreName,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName
        };
        this.offerService.saveSubCentreData(obj1).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setSubCentreData(res.result);
            this.subCentreDetails = res.result;
            this.onCancel();
          }
        })
        break;
      }
      case 'AET': {
        if (form.value.statusName == "" || form.value.statusName == null || form.value.statusName == undefined) {
          alert("Status cannot be blank");
          return;
        }
        this.offerService.saveStatusData(form.value).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setStatusData(res.result);
            this.statusDetails = res.result;
            this.onCancel();
          }
        })
        break;
      }

      default: {
        break;
      }

    }

  }

  deleteMainCentre(mainCentreID) {
    if (!confirm("Delete Main Centre ?")) {
      return;
    }
    this.offerService.deleteMainCentre(mainCentreID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setMainCentreData(res.result);
        this.mainCentreDetails = res.result;
      }
    });
  }

  deleteChildCentre(childCentreID) {
    if (!confirm("Delete Child Centre ?")) {
      return;
    }
    this.offerService.deleteChildCentre(childCentreID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setChildCentreData(res.result);
        this.childCentreDetails = res.result;
      }
    });
  }

  addEditMainCentre(mainCentreID, mainCentreName) {
    this.formModel.manageMaster = 'AEM';
    this.formModel.mainCentreID = mainCentreID;
    this.formModel.mainCentreName = mainCentreName;
  }

  addEditChildCentre(childCentreID, childCentreName) {
    this.formModel.manageMaster = 'AEC';
    this.formModel.childCentreID = childCentreID;
    this.formModel.childCentreName = childCentreName;
  }
  addEditSubCentre(subCentreID, subCentreName) {
    this.formModel.manageMaster = 'AES';
    this.formModel.subCentreID = subCentreID;
    this.formModel.subCentreName = subCentreName;
  }
  
  addEditStatus(statusID, statusName) {
    this.formModel.manageMaster = 'AET';
    this.formModel.statusID = statusID;
    this.formModel.statusName = statusName;
  }

  deleteSubCentre(subCentreID) {
    if (!confirm("Delete Sub Centre ?")) {
      return;
    }
    this.offerService.deleteSubCentre(subCentreID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setSubCentreData(res.result);
        this.subCentreDetails = res.result;
      }
    });
  }

  deleteStatus(statusID) {
    if (!confirm("Delete Status?")) {
      return;
    }
    this.offerService.deleteStatus(statusID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setStatusData(res.result);
        this.statusDetails = res.result;
      }
    });
  }

}
