import { Injectable, Directive } from '@angular/core';
import { CompleterService, CompleterData } from 'ng2-completer';


@Injectable()
export class GlobalDataSharingService {
  private memberDetails: CompleterData;
  private searchedOtherOfferingData: [];
  private searchedOtherOfferingDataEdit: [];
  private searchedOfferData: [];
  private searchedOfferDataEdit: [];
  private searchedOfferMemberData: [];
  private searchedOfferMemberDataEdit: [];
  private searchedDivineProtectionData : [];
  private searchedDailyOfferData : [];
  private searchedOtherOfferingMemberDataEdit: [];
  private searchedOtherOfferingMemberData: [];
  private searchedOfferSCMOData: [];
  private mainCentreData: [];
  private searchedNewOfferData: [];
  private childCentreData: [];
  private subCentreData: [];
  private statusData: [];
  private allUsersData: [];
  private loggedInUserData: [];
  private allMembersListing: [];
  private searchedOtherOfferingNewDataEdit : [];
  private editOfferOptionClicked: string;
  private dailyOfferingSCMOData: [];
  private dailyOfferingSCMODataEdit: [];
  private searchedSCMOLogbookData: [];
  private searchedSCMOHQ: [];
  private sysVarData: [];
  private allOfferListingData: [];
  public bOfferListingDataDownloaded: boolean = false;


  private queryMDetailsData = {
    queryMSearchCriteria: "",
    totalRecords: 0,
    queryMDetails:[]
  }

  constructor() { }

  setQueryMDetailsData(val) {
    this.queryMDetailsData.queryMSearchCriteria = val.queryMSearchCriteria;
    this.queryMDetailsData.totalRecords = val.totalRecords;
    this.queryMDetailsData.queryMDetails = val.queryMDetails;
  }

  getQueryMDetailsData() {
    return this.queryMDetailsData;
  }

  setAllMembersDetails(val) {
    this.memberDetails = val;
  }

  setAllMembersListing(val) {
    this.allMembersListing = val;
  }

  getAllMembersListing() {
    console.log("Get all Member Listing");
    return this.allMembersListing;
  }

  setOfferListingData(val) {
    console.log("SET Offer Listing Data");
    this.allOfferListingData = val;
  }

  getOfferListingData() {
    console.log("GET Offer Listing Data");
    return this.allOfferListingData;
  }

  getAllMembersDetails() {
    return this.memberDetails;
  }

  setSearchedOtherOfferingData(val, val1) {
    this.searchedOtherOfferingDataEdit = val;
    this.searchedOtherOfferingData = val1;
  }
  getSearchedOtherOfferingDataEdit() {
    return this.searchedOtherOfferingDataEdit;
  }
  setSearchedOtherOfferingNewData(val) {
    this.searchedOtherOfferingNewDataEdit = val;
   
  }
  getSearchedOtherOfferingNewDataEdit() {
    return this.searchedOtherOfferingDataEdit;
  }

  getSearchedOtherOfferingData() {
    return this.searchedOtherOfferingData;
  }

  setSearchedOfferData(val,val1) {
    this.searchedOfferDataEdit = val;
    this.searchedOfferData = val1;
  }

  getSearchedOfferDataEdit() {
    return this.searchedOfferDataEdit;
  }

  getSearchedOfferData() {
    return this.searchedOfferData;
  }

  setSearchedOfferMemberData(val,val1) {
    this.searchedOfferMemberDataEdit = val;
    this.searchedOfferMemberData = val1;
  }
  getSearchedDivineProtectionData() {
    return this.searchedOfferData;
  }

  setSearchedDivineProtectionData(val) {
    this.searchedDivineProtectionData = val;
  }
  getSearchedDailyOfferData() {
    return this.searchedDailyOfferData;
  }

  setSearchedDailyOfferDataData(val) {
    this.searchedDailyOfferData = val;
  }

  getSearchedNewOfferData() {
    return this.searchedNewOfferData;
  }

  setSearchedNewOfferData(val) {
    this.searchedNewOfferData = val;
  }
  getSearchedOfferMemberDataEdit() {
    return this.searchedOfferMemberDataEdit;
  }

  getSearchedOfferMemberData() {
    return this.searchedOfferMemberData ;
  }

  setSearchedOtherOfferingMemberData(val, val1) {
    this.searchedOtherOfferingMemberDataEdit = val;
    this.searchedOtherOfferingMemberData = val1;
  }

  getSearchedOtherOfferingMemberDataEdit() {
    return this.searchedOtherOfferingMemberDataEdit;
  }

  getSearchedOtherOfferingMemberData() {
    return this.searchedOtherOfferingMemberData;
  }


  setSearchedOfferSCMOData(val) {
    this.searchedOfferSCMOData = val;
  }

  getSearchedOfferSCMOData() {
    return this.searchedOfferSCMOData;
  }
  setSearchedOfferSCMOLogbookData(val) {
    this.searchedSCMOLogbookData = val;
  }

  getSearchedOfferSCMOLogbookData() {
    return this.searchedSCMOLogbookData;
  }

  setSearchedOfferSCMOHQ(val) {
    this.searchedSCMOHQ = val;
  }

  getSearchedOfferSCMOHQ() {
    return this.searchedSCMOHQ;
  }


  setEditOfferOptionClicked(editOption) {
    this.editOfferOptionClicked = editOption;
  }

  getEditOfferOptionClicked() {
    return this.editOfferOptionClicked;
  }

  setMainCentreData(val) {
    this.mainCentreData = val;
  }

  getMainCentreData() {
    return this.mainCentreData;
  }

  setChildCentreData(val) {
    this.childCentreData = val;
  }

  getChildCentreData() {
    return this.childCentreData;
  }

  setSubCentreData(val) {
    this.subCentreData = val;
  }

  getSubCentreData() {
    return this.subCentreData;
  }

  setStatusData(val) {
    this.statusData = val;
  }

  getStatusData() {
    return this.statusData;
  }
  

  setSysVarData(val) {
    this.sysVarData = val;
  }

  getSysVarData() {
    return this.sysVarData;
  }


  setAllUsersData(val) {
    this.allUsersData = val;
  }

  setLoggedInUserData(val) {
    //console.log(val)
    this.loggedInUserData = val; 
  }

  getAllUsersData() {
    return this.allUsersData;
  }

  getLoggedInUserData() {
    //console.log(this.loggedInUserData);
    return this.loggedInUserData;
  }

  setDailyOfferingSCMOData(val,val1) {
    this.dailyOfferingSCMOData = val1;
    this.dailyOfferingSCMODataEdit = val;
  }

  getDailyOfferingSCMOData() {
    return this.dailyOfferingSCMOData;
  }
  getDailyOfferingSCMODataEdit() {
    return this.dailyOfferingSCMODataEdit;
  }

}
