import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { OfferService } from "../../services/offer.service";
import { HttpClient } from '@angular/common/http';
import { ResponseViewModel } from '../../models/responseviewmodel';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CompleterService, CompleterData } from 'ng2-completer';
import { DatePipe } from '@angular/common';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-newoffering',
  templateUrl: './newoffering.component.html',
  styleUrls: ['./newoffering.component.css']
})
export class NewofferingComponent implements OnInit {
  public onlineOffline: boolean = navigator.onLine;

  isMemberList: any[];
  genderList: any[];
  isAdultList: any[];
  formModel: any = {
    otherOfferingID: 0,
    loggedinUserRole: "",
    offerDate: null,
    processingDate: null,
    isMember: "",
    memberID: "",
    memberName: "",
    forMonth: "",
    slipNo: "",
    lastForMonth: "",
    lastForMonthEmpty:"",
    ProcessingCentre: "",
    MemberCentre: "",
    otamagushi: "",
    okiyome: "",
    goshintai: "",
    ancestors: "",
    omitama: "",
    goshugo: "",
    scmo: "",
    countryM: "",
    centreM:"",
    mscmo: "",
    mcountryM: "",
    mcentreM:"",
    other: "",
    total: 0,
    offeringFor:"",
    chequeNo: "",
    chequeDate: null,
    bankBranchName: "",
    isAdultYN: "",
    panNo: "",
    aadhaarNo: "",
    gender: "",
    groupUser: "",
    groupLeader: "",
    mainCentre: "",
    subCentre: "",
    childCentre: "",
    status: "",
    offerMainCentre: "",
    offerChildCentre: "",
    offerSubCentre: "",
    offerMainCentreID: "",
    offerChildCentreID: "",
    offerSubCentreID: "",
    message: "",
    remarks : "",
    bankName: "",
    addUser: "",
    addUserDate: "",
    addUserTime: "",
    updateUser: "",
    updateUserDate: "",
    updateUserTime:""
  };
  
  isAllMember : false;
  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSource: Observable<any>;
  glbOtherOfferingID: 0;
  glbSearchedDataArray: [];
  loggedInUserData = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  inEditMode = 'N';
  allMembersArrayData:  [];
  memberArray = [];
  centerWiseMemberArray = [];
 
  protected dataservice: CompleterData;
  protected dataservice1: CompleterData;
  protected dataservice2: CompleterData;


  constructor(private offerService: OfferService, private http: HttpClient,
    private router: Router, private route: ActivatedRoute, private completerService: CompleterService,
    private dataSharingService: GlobalDataSharingService, private spinner: NgxSpinnerService) {

    this.isMemberList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }, { name: "Other", value: "O" }];
    this.genderList = [{ name: "Male", value: "M" }, { name: "Female", value: "F" }];
    this.isAdultList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
    }, 3000);
  }
  populateDefaults(offerMainCentre, offerChildCentre, offerSubCentre) {
    this.mainCentreDetails = [];
    this.childCentreDetails = [];
    this.subCentreDetails = [];
    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    let allowLocation = localStorage.getItem('allowLocation');
    let offData = allowLocation.split("/");
    if (offerMainCentre == "" && offerChildCentre == "" && offerSubCentre == "") {
      this.formModel.offerMainCentre = offData[0];
      this.formModel.offerChildCentre = offData[1];
      this.formModel.offerSubCentre = offData[2];
      this.formModel.ProcessingCentre = allowLocation;
    } else {
      this.formModel.offerMainCentre = offerMainCentre;
      this.formModel.offerChildCentre = offerChildCentre;
      this.formModel.offerSubCentre = offerSubCentre;

    }
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
    var i = 0;

    do {
      for (i = 0; i < this.glbmainCentreDetails.length; i++) {
        if (this.formModel.offerMainCentre == this.glbmainCentreDetails[i].mainCentreName) {
          this.selectedMainCentreObj = this.glbmainCentreDetails[i];
          this.formModel.offerMainCentreID = this.glbmainCentreDetails[i].mainCentreID;
          this.mainCentreDetails.push(this.selectedMainCentreObj);
          continue;
        }
        this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
      }
      if (this.formModel.offerChildCentre == "All" && this.formModel.offerSubCentre == "All") {
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      for (i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == this.formModel.offerMainCentreID) {
          if (this.glbchildCentreDetails[i].childCentreName == this.formModel.offerChildCentre) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            this.formModel.offerChildCentreID = this.glbchildCentreDetails[i].childCentreID;
          }
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }

      if (this.formModel.offerSubCentre == "All") {
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }

      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == this.formModel.offerChildCentreID) {
          if (this.glbsubCentreDetails[i].subCentreName == this.formModel.offerSubCentre) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            this.formModel.offerSubCentreID = this.glbsubCentreDetails[i].subCentreID;
          }
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }

    } while (false);

  }

  populateMemberArrayCenterWise(mainCentreName, childCentreName, subCentreName) {
    if (this.inEditMode == 'Y') {
      return;
    }
    this.memberArray = [];
    do {
      if (this.isAllMember) {
        this.memberArray = this.dataSharingService.getAllMembersListing();
        break;
      }
      let tmpArray = [];
      tmpArray = this.dataSharingService.getAllMembersListing();

      if (mainCentreName != "") {
        tmpArray = tmpArray.filter(function (item) {
          item.mainCentre = item.mainCentre.trim();
          return item.mainCentre == mainCentreName;
        });
      }
      if (childCentreName != undefined || childCentreName != "" || childCentreName != null) {
        tmpArray = tmpArray.filter(function (item) {
          item.childCentre = item.childCentre;
          return item.childCentre == childCentreName;
        });
      }
      if (subCentreName != undefined || subCentreName != null || subCentreName != "") {
        tmpArray = tmpArray.filter(function (item) {
          item.subCentre = item.subCentre;
          return item.subCentre == subCentreName;
        });
      }
      this.memberArray = tmpArray;

    } while (false);
    this.centerWiseMemberArray = this.memberArray ;

    this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
  }

  ngOnInit() {
    this.showSpinner();
    this.formModel.otherOfferingID = 0;
    this.populateDefaults("", "", "");

    this.formModel.isMember = 'Y';
    this.formModel.processingDate = new Date();
    let loginuser = [];
    loginuser = this.dataSharingService.getLoggedInUserData();

    this.isAllMember = false;
    this.formModel.offerDate = new Date();

    var dd,mm,yyyy,dateStr;
    var date = new Date();
    dd = date.getDate();
    mm = date.getMonth() + 1;
    yyyy = date.getFullYear();
    dateStr = mm + "/" + dd + "/" + yyyy;
  

    this.formModel.chequeDate = null;
    this.formModel.forMonth = null;//new Date(d.getFullYear(), d.getMonth() + 1, 1);
    this.formModel.MemberCentre = "";
    this.formModel.message = "";
    this.glbOtherOfferingID = 0;
    this.allMembersArrayData = this.dataSharingService.getAllMembersListing();
    this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);
    //this.isAllMembrSelected(this.isAllMember);
    //this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
    this.dataservice1 = this.completerService.local(this.allMembersArrayData, 'memberID', 'memberID');
    this.dataservice2 = this.completerService.local(this.allMembersArrayData, 'status', 'status');
    var tmp = this.dataSharingService.getEditOfferOptionClicked();
    if (tmp == "OO") {
      this.glbSearchedDataArray = this.dataSharingService.getSearchedOtherOfferingDataEdit();
    } else if (tmp == "OM") {
      this.glbSearchedDataArray = this.dataSharingService.getSearchedOtherOfferingMemberDataEdit();
    } else if (tmp == "DSCMO") {
      this.glbSearchedDataArray = this.dataSharingService.getDailyOfferingSCMODataEdit();
    }

    var pipe = new DatePipe('en-US')
    this.route.queryParams.subscribe(params => {
      if (params.otherOfferingID == null || params.otherOfferingID == undefined) {

        this.getSlipNo(dateStr,this.selectedMainCentreObj['mainCentreName'],this.selectedChildCentreObj['childCentreName'],this.selectedSubCentreObj['subCentreName']);
        this.formModel.addUser = loginuser[0]['loginID'];
        this.formModel.addUserDate = new Date();
        var date = new Date();
        this.formModel.addUserDate = pipe.transform(date, 'dd-MM-yyyy')
        var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(); // Local time
        this.formModel.addUserTime = time;
       // document.getElementById('memberName').focus();
      } else {
        this.getOfferRecord(params.otherOfferingID);
        this.formModel.otherOfferingID = params.otherOfferingID;
        this.dataservice = null;
        this.inEditMode = 'Y';
      }
    })

  }

  getSlipNo(date, mainCentre, childCentre, subCentre) {
    return;
    if (parseInt(this.formModel.otherOfferingID) == 0 ) {
      this.offerService.GetLastSlipNo(date, mainCentre, childCentre, subCentre).subscribe((res: any) => { //,mainCentre,childCentre,subCentre
        if (res['result'].length > 0) {
          this.formModel.slipNo = parseInt(res['result'][0]['slipNo']) + 1;
        } else {
          this.formModel.slipNo = 1;
        }
      })
    }
  }
  onChangeMainCentre(mainCentre) {
    do {
      this.childCentreDetails = [];
      this.selectedChildCentreObj = {};
      for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
      this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);

      /*
      this.formModel.offerDate = new Date();
      var dd,mm,yyyy,dateStr;
      var date = new Date();
      dd = date.getDate();
      mm = date.getMonth() + 1;
      yyyy = date.getFullYear();
      dateStr = mm + "/" + dd + "/" + yyyy;
      if(this.childCentreDetails.length>0){
        this.getSlipNo(dateStr,mainCentre.mainCentreName,this.selectedChildCentreObj['childCentreName'],'');
      }else
      {
        this.getSlipNo(dateStr,mainCentre.mainCentreName,'','');
      }*/

    } while (false);
  }

  onChangeChildCentre(childCentre) {
    do {
      this.subCentreDetails = [];
      this.selectedSubCentreObj ={};
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
      this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);

      /*
      var dd,mm,yyyy,dateStr;
      var date = new Date();
      dd = date.getDate();
      mm = date.getMonth() + 1;
      yyyy = date.getFullYear();
      dateStr = mm + "/" + dd + "/" + yyyy;
      if(this.subCentreDetails.length>0){
        this.getSlipNo(dateStr,this.selectedMainCentreObj['mainCentreName'],this.selectedChildCentreObj['childCentreName'],this.selectedSubCentreObj['subCentreName']);
      }else{
        this.getSlipNo(dateStr,this.selectedMainCentreObj['mainCentreName'],this.selectedChildCentreObj['childCentreName'],'');
      }*/

    } while (false);
  }
  onChangeSubCentre(subCentre) {
    this.populateMemberArrayCenterWise(this.selectedMainCentreObj['mainCentreName'], this.selectedChildCentreObj['childCentreName'], this.selectedSubCentreObj['subCentreName']);

   /* 
    do {
      var dd,mm,yyyy,dateStr;
      var date = new Date();
      dd = date.getDate();
      mm = date.getMonth() + 1;
      yyyy = date.getFullYear();
      dateStr = mm + "/" + dd + "/" + yyyy;
      this.getSlipNo(dateStr,this.selectedMainCentreObj['mainCentreName'],this.selectedChildCentreObj['childCentreName'],this.selectedSubCentreObj['subCentreName']);
    } while (false);
    */
  }
  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    
    container.setViewMode('month');
    document.getElementById('scmoamount').focus();  
  //alert("hi");  
    this.getLastOfferingDetails();
  }

  onMemberSearching(formModel) {
    if (this.inEditMode == 'Y') {
      return;
    }
    if (this.dataservice != null) {
      return;
    }
  }

  onOfferDateSelect(value: Date) {
    return;
    var dd,mm,yyyy,dateStr;
    dd = value.getDate();
    mm = value.getMonth() + 1;
    yyyy = value.getFullYear();
    dateStr = mm + "/" + dd + "/" + yyyy;

    this.getSlipNo(dateStr,this.formModel.offerMainCentre,this.formModel.offerChildCentre,this.formModel.offerSubCentre);
    do {
      
      if (this.formModel.otherOfferingID != null) {
        break
      }
      this.formModel.forMonth = new Date(value.getFullYear(), value.getMonth() + 1, 1);
      this.getSlipNo(value,this.formModel.offerMainCentre,this.formModel.offerChildCentre,this.formModel.offerSubCentre);
    } while (false);
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  onMemberSelected(event) {
    console.log(event);
    if (this.inEditMode == 'Y') {
      return;
    }
    if (event != null && event.originalObject != null) {
      var tmpArray = [];
      tmpArray = this.allMembersArrayData;
      tmpArray = tmpArray.filter(function (item) {
        return item.memberID == event.originalObject.memberID;
      });

      this.formModel.memberName = tmpArray[0].name;
      this.formModel.memberID = tmpArray[0].memberID;
      this.formModel.mainCentre = tmpArray[0].mainCentre;
      this.formModel.subCentre = tmpArray[0].subCentre;
      this.formModel.childCentre = tmpArray[0].childCentre;
      let mainCentre = tmpArray[0].mainCentre;
      mainCentre = mainCentre.substring(0, 3);
      let childCentre = tmpArray[0].childCentre;
      if (childCentre != null) {
        childCentre = childCentre.substring(0, 3);
      }
      let subCentre = tmpArray[0].subCentre;
      if (subCentre != null) {
        subCentre = subCentre.substring(0, 3);
      }
      this.formModel.MemberCentre = mainCentre + "/" + childCentre + "/" + subCentre;
      this.formModel.status = tmpArray[0].status;
      this.formModel.gender = tmpArray[0].gender;
      this.formModel.aadhaarNo = tmpArray[0].aadhaarNo;
      this.formModel.isAdultYN = tmpArray[0].isAdultYN;
      this.formModel.panNo = tmpArray[0].panNo;
      this.formModel.groupUser = tmpArray[0].groupUser;
      this.formModel.groupLeader = tmpArray[0].groupLeader;
      this.formModel.lastForMonth = "";
      this.formModel.lastForMonthEmpty = "";
      this.formModel.forMonth = "";
      this.formModel.mscmo = "";
      this.formModel.mcentreM = "";
      this.formModel.mcountryM = "";
      this.formModel.scmo = "";
      this.formModel.countryM = "";
      this.formModel.centreM = "";
      this.formModel.otamagushi = "";
      this.formModel.okiyome = "";
      this.formModel.goshugo = "";
      this.formModel.goshintai = "";
      this.formModel.ancestors = "";
      this.formModel.omitama = "";
      this.formModel.other = "";
      this.formModel.total = "";

      var d = new Date();
      var pipe = new DatePipe('en-US');
      this.formModel.lastForMonth = pipe.transform(new Date(d.getFullYear(), d.getMonth() + 1, 1), "MMM-yyyy");
      this.formModel.lastForMonthEmpty = "Y";
      var tmp = tmpArray[0].forMonth;
      if (!this.isEmpty(tmp)) {
        var d = new Date(tmpArray[0].forMonth);
        var dd = new Date(d.getFullYear(), d.getMonth() + 1, d.getDate());
        this.formModel.lastForMonth = pipe.transform(dd, 'MMM-yyyy');
        this.formModel.mscmo = parseInt(tmpArray[0].scmo);
        this.formModel.mcentreM = parseInt(tmpArray[0].centreM);
        this.formModel.mcountryM = parseInt(tmpArray[0].countryM);
        this.formModel.lastForMonthEmpty = "N";
      } 
      document.getElementById('otamagushi').focus();
     // this.getLastOfferingDetails();
    }

  }

  setDate(){
    //alert('hi' + event);
    document.getElementById('scmoamount').focus();
  }
  setfocustoName() {
    document.getElementById('memberName').focus();
  }
  getLastOfferingDetails() {

    if (!this.isEmpty(this.formModel.mscmo)) {
      this.formModel.scmo = parseInt(this.formModel.mscmo);
    }
    if (!this.isEmpty(this.formModel.mcountryM)) {
      this.formModel.countryM = parseInt(this.formModel.mcountryM);
    }
    if (!this.isEmpty(this.formModel.mcentreM)) {
      this.formModel.centreM = parseInt(this.formModel.mcentreM);
    }
    this.addTotal(this.formModel); 
    //document.getElementById('scmoamount').focus();
    return;
    //this.formModel.lastForMonth = "";
    this.offerService.getLastOtherOfferingDetails(this.formModel.memberID).subscribe((res: any) => {
      if (res.result.length > 0) {
        /*
        var pipe = new DatePipe('en-US');
        var d = new Date(res.result[0].forMonth);
        var dd = new Date(d.getFullYear(), d.getMonth() + 1, d.getDate());
        this.formModel.forMonth = pipe.transform(dd, 'MMM-yyyy');
        this.formModel.lastForMonth = "(" + pipe.transform(res.result[0].forMonth, 'MMM-yyyy') + ")";
        this.formModel.status = res.result[0].status;
        this.formModel.otamagushi = parseInt(res.result[0].otamagushi);
        this.formModel.okiyome = parseInt(res.result[0].okiyome);
        this.formModel.goshintai = parseInt(res.result[0].goshintai);
        this.formModel.ancestors = parseInt(res.result[0].ancestors);
        this.formModel.omitama = parseInt(res.result[0].omitama);
        this.formModel.goshugo = parseInt(res.result[0].goshugo);
        this.formModel.other = parseInt(res.result[0].other);*/
        this.formModel.total = parseInt(res.result[0].total);
        this.formModel.scmo = parseInt(res.result[0].scmo);
        this.formModel.countryM = parseInt(res.result[0].countryM);
        this.formModel.centreM = parseInt(res.result[0].centreM);
        this.addTotal(this.formModel);
      }
    })
  }


  addTotal(formModel) {
    this.formModel.total = 0;
    if(this.formModel.otamagushi != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.otamagushi);
    }
    if(this.formModel.okiyome != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.okiyome);
    }
    if(this.formModel.goshintai != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.goshintai);
    }
    if(this.formModel.ancestors != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.ancestors);
    }
    if(this.formModel.omitama != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.omitama);
    }
    if(this.formModel.goshugo != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.goshugo);
    }
    if(this.formModel.scmo != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.scmo);
    }
    if(this.formModel.other != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.other);
    }
    if(this.formModel.centreM != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.centreM);
    }
    if(this.formModel.countryM != ""){
      this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.countryM);
    }

    // this.formModel.total = parseInt(this.formModel.otamagushi) + parseInt(this.formModel.okiyome) + parseInt(this.formModel.goshintai);
    // this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.ancestors) + parseInt(this.formModel.omitama) + parseInt(this.formModel.goshugo);
    // this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.other) +  parseInt(this.formModel.centreM);
    // this.formModel.total = parseInt(this.formModel.total) + parseInt(this.formModel.scmo) + parseInt(this.formModel.countryM) ;
  }

  onSubmit(form: NgForm) {
    let loginuser = [];
    loginuser = this.dataSharingService.getLoggedInUserData();
    var date = new Date();
    var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(); // Local time

    this.route.queryParams.subscribe(params => {
      if (params.otherOfferingID == null || params.otherOfferingID == undefined) {
        this.formModel.otherOfferingID = 0;
        form.value.addUser = loginuser[0]['loginID'];
        form.value.addUserDate = date;
        form.value.addUserTime = time;

        form.value.updateUser = "";
        form.value.updateUserDate = null;
        form.value.updateUserTime = "";
      } else {
        form.value.memberName = this.formModel.memberName;
        form.value.updateUser = loginuser[0]['loginID'];
        form.value.updateUserDate = date; //pipe.transform(date, 'MM-dd-yyyy');
        form.value.updateUserTime = time;
      }
    })
    
    if (this.formModel.loggedinUserRole == 'Adm') {
      if (form.value.subCentreDetails == null || form.value.subCentreDetails.subCentreName == null) {
        alert("Please select Sub Centre");
        return;
      }
      if (form.value.childCentreDetails == null || form.value.childCentreDetails.childCentreName == null) {
        alert("Please select Child Centre");
        return;
      }
      if (form.value.mainCentreDetails == null || form.value.mainCentreDetails.mainCentreName == null) {
        alert("Please select Main Centre");
        return;
      }
      form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    } else if (this.formModel.loggedinUserRole == 'CCU') {
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName'];
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
      if (form.value.subCentreDetails == null || form.value.subCentreDetails.subCentreName == null) {
        alert("Please select Sub Centre");
        return;
      }
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    } else if (this.formModel.loggedinUserRole == 'MCU') {
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName'];
      if (form.value.childCentreDetails == null || form.value.childCentreDetails.childCentreName == null) {
        alert("Please select Child Centre");
        return;
      }
      if (form.value.subCentreDetails == null || form.value.subCentreDetails.subCentreName == null) {
        alert("Please select Sub Centre");
        return;
      }

      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    } else if (this.formModel.loggedinUserRole == 'SCU') {
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName'];
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    }
    if (form.value.memberName == "" || form.value.memberName == null || form.value.memberName == undefined) {
      alert("Please select / enter Member details");
      return;
    }
    var t = parseInt(form.value.total);
    if (t == NaN || t <= 0) {
      alert("Total amount cannot be 0");
      return;
    }
    if (form.value.slipNo == "") {
      alert("Enter Slip No");
      return;
    }
    console.log(form.value.forMonth + "---" + form.value.scmo);
    if((form.value.forMonth == '' || form.value.forMonth == null) && form.value.scmo != ''){
      alert("Please select SCMO month");
      return;
    }
    if (form.value.forMonth != '' && form.value.forMonth != null && form.value.scmo == ''){
      alert("Please enter SCMO Amount");
      return;
    }


    var dd;
    var mm;
    var yyyy;
    var d;
    var pipe = new DatePipe('en-US');
    var dateStr;
    //console.log(form.value.processingDate);
    if (form.value.processingDate != "" || form.value.processingDate != null || form.value.processingDate != NaN) {
      dd = form.value.processingDate.getDate();
      mm = form.value.processingDate.getMonth() + 1;
      yyyy = form.value.processingDate.getFullYear();
      dateStr = yyyy + "-" + mm + "-" + dd;
      d = new Date(dateStr);
      form.value.processingDate = pipe.transform(d, 'MM/dd/yyyy');
      //form.value.processingDate = mm/dd/yyyy;
    }
    if (form.value.offerDate != null) {
      dd = form.value.offerDate.getDate();
      mm = form.value.offerDate.getMonth() + 1;
      yyyy = form.value.offerDate.getFullYear();
      dateStr = yyyy + "-" + mm + "-" + dd;
      d = new Date(dateStr);
      form.value.offerDate = pipe.transform(d, 'MM/dd/yyyy');
      var tmpData = [];
      tmpData = this.dataSharingService.getSysVarData();
      var cuttOffDate = new Date(tmpData[0].cuttOffDate);
      if ( d <= cuttOffDate ) {
        alert("Cut Off Date: " + cuttOffDate +"\nBack date entry is not allowed in the system");
        return;
      }
     // form.value.offerDate = mm/dd/yyyy;
    }
    if (parseInt(form.value.scmo) == 0) {
      form.value.forMonth = null;
    }

    if (!this.isEmpty(form.value.forMonth)) {
      var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
      var tmpforMonth = form.value.forMonth;
      if (typeof tmpforMonth == "string") {
        var tmp = tmpforMonth.split("-");
        dd = 1;
        yyyy = tmp[1];
        var month1 = tmp[0].toLowerCase();
        mm = months.indexOf(month1) + 1;
      } else {
        dd = 1;
        mm = form.value.forMonth.getMonth() + 1;
        yyyy = form.value.forMonth.getFullYear();
      }
      dateStr = yyyy + "-" + mm + "-" + dd;
      form.value.forMonth = dateStr;
      var d2 = new Date(yyyy, mm, dd);
      var memberSCMO = this.formModel.lastForMonth;
      if (typeof memberSCMO == "string" && this.formModel.lastForMonthEmpty == "N") {
        var tmp2 = memberSCMO.split("-");
        dd = 1;
        yyyy = tmp2[1];
        var month2 = tmp2[0].toLowerCase();
        mm = months.indexOf(month2) + 1;
        var memberLastSCMO = new Date(yyyy, mm, dd);
        if (memberLastSCMO.getTime() > d2.getTime()) {
          //alert("Not to update DB. Since ForMonth in DB is greater than entered ForMonth ");
          form.value.forMonth = dateStr + " Not To Update";
        } 
      }
    }

    // if (form.value.adduserDate != "" || form.value.adduserDate != null || form.value.adduserDate != NaN) {
    //   dd = form.value.adduserDate.getDate();
    //   mm = form.value.adduserDate.getMonth() + 1;
    //   yyyy = form.value.adduserDate.getFullYear();
    //   dateStr = yyyy + "-" + mm + "-" + dd;
    //   d = new Date(dateStr);
    //   console.log("d",d);
    //   form.value.adduserDate = pipe.transform(d, 'MM/dd/yyyy');
    // }
     var date = new Date();
    form.value.addUserDate = pipe.transform(date, 'MM/dd/yyyy');

    if (form.value.chequeDate != null) {
      dd = form.value.chequeDate.getDate();
      mm = form.value.chequeDate.getMonth() + 1;
      yyyy = form.value.chequeDate.getFullYear();
      dateStr = yyyy + "-" + mm + "-" + dd;
      d = new Date(dateStr);
      form.value.chequeDate = pipe.transform(d, 'MM/dd/yyyy');
    }
    if (form.value.offerChildCentre == "All") {
      form.value.offerChildCentre = "";
    }
    if (form.value.offerMainCentre == "All") {
      form.value.offerMainCentre = "";
    }
    if (form.value.offerSubCentre == "All") {
      form.value.offerSubCentre = "";
    }
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    tmpArray = tmpArray.filter(function (item) {
      return item.name == form.value.memberName;
    });

    if (tmpArray == null || tmpArray == undefined || tmpArray[0] == undefined) {
      alert("Invalid Member Name / Member ID - 1");
      return;
    }
    tmpArray = this.dataSharingService.getAllMembersListing();
    tmpArray = tmpArray.filter(function (item) {
      return item.memberID == form.value.memberID;
    });
    if (tmpArray == null || tmpArray == undefined || tmpArray[0] == undefined) {
      alert("Invalid Member Name / Member ID -2");
      return;
    }

    if(this.formModel.otamagushi == ""){
      form.value.otamagushi=0;
    }
    if(this.formModel.okiyome == ""){
      form.value.okiyome=0;
    }
    if(this.formModel.goshintai == ""){
      form.value.goshintai=0;
    }
    if(this.formModel.ancestors == ""){
      form.value.ancestors=0;
    }
    if(this.formModel.omitama == ""){
      form.value.omitama=0;
    }
    if(this.formModel.goshugo == ""){
      form.value.goshugo=0;
    }
    if(this.formModel.scmo == ""){
      form.value.scmo=0;
    }
    if(this.formModel.other == ""){
      form.value.other=0;
    }
    if(this.formModel.centreM == ""){
      form.value.centreM=0;
    }
    if(this.formModel.countryM == ""){
      form.value.countryM=0;
    }
    console.log("in Save");
    console.log(form.value);
    this.offerService.SaveOtherOffering(form.value).subscribe((res: any) => {
      if (res.status == 0) {
        alert(res.message);
      } else {
        this.playAudio();
        this.formModel.message = "RECORD SAVED";
        setTimeout(() => {
          this.formModel.message = "";
        }, 3000);

        this.formModel.MemberCentre = "";
        do {
          if (!this.isEmpty(form.value.forMonth)) {
            if (form.value.forMonth.indexOf("Not To Update") > 0) {
              //alert("not to update");
              break;
            }
            var index = -1;
            var tmpArray = [];
            tmpArray = this.dataSharingService.getAllMembersListing();
            tmpArray = tmpArray.filter(function (item) {
              return item.memberID == form.value.memberID;
            });

            var tmpArray2 = [];
            tmpArray2 = this.dataSharingService.getAllMembersListing();
            index = tmpArray2.findIndex((e) => e.id === tmpArray[0].id);
            if (index === -1) {
              ;
            } else {
              tmpArray[0].forMonth = form.value.forMonth;
              tmpArray[0].scmo = form.value.scmo;
              tmpArray[0].centreM = form.value.centreM;
              tmpArray[0].countryM = form.value.countryM;
              tmpArray2[index] = tmpArray[0];
            }

            this.dataSharingService.setAllMembersListing(tmpArray2);

          }

        } while (false);


        //this.formModel.processingDate = new Date();
        //this.formModel.offerDate = new Date();
        //var d = new Date();
        //this.formModel.forMonth = new Date(d.getFullYear(), d.getMonth() + 1, 1);
        this.formModel.offeringFor = "";
        this.formModel.bankBranchName = "";
        this.formModel.chequeDate = null;
        this.formModel.chequeNo = "";
        this.formModel.total = 0;
        this.formModel.otamagushi = 0;
        this.formModel.okiyome = 0;
        this.formModel.goshintai = 0;
        this.formModel.ancestors = 0;
        this.formModel.omitama = 0;
        this.formModel.goshugo = 0;
        this.formModel.other = 0;
        this.formModel.scmo = 0;
        this.formModel.countryM = 0;
        this.formModel.centreM = 0;
        this.formModel.aadhaarNo = "";
        this.formModel.panNo = "";
        this.formModel.isAdultYN = "";
        this.formModel.status = "";
        this.formModel.groupLeader = "";
        this.formModel.groupUser = "";
        this.formModel.slipNo = "";//parseInt(form.value.slipNo) + 1;
        this.formModel.gender = "";
        this.formModel.memberName = "";
        this.formModel.memberID = "";
        this.formModel.mainCentre = "";
        this.formModel.subCentre = "";
        this.formModel.childCentre = "";
        this.formModel.lastForMonth = "";
        this.formModel.lastForMonthEmpty = "";
        this.formModel.remarks="";
        this.formModel.bankName = "";
        this.formModel.forMonth = "";
        this.formModel.bankBranchName = "";
        this.formModel.addUser = "";
        this.formModel.addUserDate = "";
        this.formModel.addUserTime = "";
        this.formModel.updateUser = "";
        this.formModel.updateUserDate = "";
        this.formModel.updateUserTime = "";
      }
    })
  }

  onCancel() {
    let tmp = "";
    tmp = this.dataSharingService.getEditOfferOptionClicked();
    if (tmp == "OM") {
      this.router.navigateByUrl("offer/addreports");
    } else {
      this.router.navigateByUrl("offer/list");
    }
  }
  getOfferRecord(recordNo) {
    this.offerService.getOtherOffering(recordNo).subscribe((res: any) => {
      if (res != null && res.status == 1 && res.result != null) {
        this.formModel = res.result;
        this.glbOtherOfferingID = this.formModel.otherOfferingID;
        var date = new Date(res.result.offerDate);
        this.formModel.offerDate = null;
        this.formModel.offerDate = date;
        this.formModel.otherOfferingID = recordNo;
        if (res.result.forMonth != "" && res.result.forMonth != null) {
          date = new Date(res.result.forMonth);
          this.formModel.forMonth = null;
          this.formModel.forMonth = date;
        }
        date = new Date(res.result.processingDate);
        this.formModel.processingDate = null;
        this.formModel.processingDate = date;

        var pipe = new DatePipe('en-US')
        this.formModel.addUser = res.result.addUser;
        if(res.result.addUserDate != null){
          date = new Date(res.result.addUserDate);
          this.formModel.addUserDate =  pipe.transform(date, 'dd-MM-yyyy');
        }
        if (res.result.addUserTime != null){
          this.formModel.addUserTime = res.result.addUserTime;
        }

        let loginuser = [];
        loginuser = this.dataSharingService.getLoggedInUserData();
        this.formModel.updateUser = loginuser[0]['loginID'];
        if (res.result.updateUserDate != null) {
          date = new Date(res.result.updateUserDate);
          this.formModel.updateUserDate = pipe.transform(date, 'dd-MM-yyyy');
        }
        if (res.result.updateUserTime != null) {
          this.formModel.updateUserTime = res.result.updateUserTime;
        }

        if (res.result.chequeDate != null) {
          date = new Date(res.result.chequeDate);
          this.formModel.chequeDate = null;
          this.formModel.chequeDate = date;
        }
        let mainCentre = res.result.mainCentre;
        mainCentre = mainCentre.substring(0, 3);
        let childCentre = res.result.childCentre;
        if (childCentre != null) {
          childCentre = childCentre.substring(0, 3);
        }
        let subCentre = res.result.subCentre;
        if (subCentre != null) {
          subCentre = subCentre.substring(0, 3);
        }
        this.formModel.MemberCentre = mainCentre + "/" + childCentre + "/" + subCentre;
        this.populateDefaults(res.result.offerMainCentre, res.result.offerChildCentre, res.result.offerSubCentre);
      }
    })
  }
  gotoRecordOffer(searchDirection) {
    if (this.glbOtherOfferingID <= 0 || this.glbOtherOfferingID == undefined) {
      alert("This Functionality only available from Report Search");
      return;
    }
    let currRecord = 0;
    currRecord = this.glbOtherOfferingID;
    do {
      var tmpArray = [];
      tmpArray = this.glbSearchedDataArray;

      switch (searchDirection) {
        case 'F':
          {
            currRecord = tmpArray[0].otherOfferingID;
          }
          break;
        case 'P':
        case 'N':
          {
            var i = 0;
            for (i = 0; i < tmpArray.length; i++) {
              if (tmpArray[i].otherOfferingID == currRecord) {
                break;
              }
            }
            if (searchDirection == 'P') {
              i = i - 1;
              if (i <= 0) {
                currRecord = tmpArray[0].otherOfferingID;
                break;
              }
            }
            if (searchDirection == "N") {
              i = i + 1;
              if (i >= tmpArray.length - 1) {
                currRecord = tmpArray[tmpArray.length - 1].otherOfferingID;
                break;
              }
            }
            currRecord = tmpArray[i].otherOfferingID;
          }
          break;
        case 'L':
          {
            currRecord = tmpArray[tmpArray.length - 1].otherOfferingID;
          }
          break;
        default:
          break;
      }

    } while (false);
    this.getOfferRecord(currRecord);
  }

  isAllMembrSelected(value) {
    //alert(value);
    if (value == true) {
      this.memberArray = [];
      this.memberArray = this.allMembersArrayData;
      this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
    } else {
      this.memberArray = [];
      this.memberArray = this.centerWiseMemberArray;
      this.dataservice = this.completerService.local(this.memberArray, 'name', 'name');
    }
  }
  memberName="memberName";
  onKey(event){
 //   alert('hi')
   document.getElementById("memberName").focus();
  }
  playAudio(){
    let audio = new Audio();
    audio.src = "../assets/audio/beep2.wav";
    audio.load();
    audio.play();
  }
}
