import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { OfferService } from "../../services/offer.service";
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { NgForm } from '@angular/forms';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-kenshu',
  templateUrl: './kenshu.component.html',
  styleUrls: ['./kenshu.component.css']
})
export class KenshuComponent implements OnInit {

  formModel: any = {
    loggedinUserRole: "",
    showQueryMOption:"N",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName:"",
    subCentreID: "",
    manageMebo: "",
    message: "",
    progress: "",
    inEditMode: "",
    gender: "",
    isAdultYN: "",
    memberID: "",
    name:""
  };
  totalRecords = 0;
  ErrMessage = "";
  loggedInUserData = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  mainCentreDetailsEditMode = [];
  childCentreDetailsEditMode = [];
  subCentreDetailsEditMode = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  statusDetails = [];
  statusDetailsEditMode = [];
  kenshuDetails = [];
  genderDetails = [];
  ageDetails = [];
  kenshuYearDetails = [];
  sortOrderByDetails = [];
  queryMDetails = [];
  genderList = [];
  isAdultList=[];

  queryMSearchCriteria = "";
  fileNameMebo = "";
  meboUploadErrors = [];
  meboUploadDetails = [];
  meboUploadMessage = "";
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  mainCentreObjEditMode = {};
  childCentreObjEditMode = {};
  subCentreObjEditMode = {};
  statusObjEditMode = {};

  allTextChildCentreObj = {};
  allTextSubCentreObj = {};
  selectedStatusObj = {};
  selectedKenshuObj = {};
  selectedGenderObj = {};
  selectedAgeObj = {};
  selectKenshuYearObj = {};
  selectedSortOrder = {}
  isConnected = true;
  status = 'ONLINE';
 // public isOnline: boolean = navigator.onLine;

  @Output() public onUploadFinished = new EventEmitter();

  constructor( private router: Router,private dataSharingService: GlobalDataSharingService, private http: HttpClient, private offerService: OfferService,
    private spinner: NgxSpinnerService, private connectionService: ConnectionService)
  {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
      }
    })
  }


  ngOnInit() {
     
    this.formModel.inEditMode = "N";
    this.formModel.showQueryMOption = "N";
    this.formModel.isAdultYN = "";
    this.formModel.gender = "";
    this.formModel.memberID = "";
    this.formModel.name = "";

    this.genderList = [{ name: "Male", value: "M" }, { name: "Female", value: "F" }];
    this.isAdultList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];

    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.loggedInUserData = this.dataSharingService.getLoggedInUserData();
    this.formModel.mainCentreName = this.loggedInUserData[0].mainCentreName;
    this.formModel.childCentreName = this.loggedInUserData[0].childCentreName;
    this.formModel.subCentreName = this.loggedInUserData[0].subCentreName;
    this.formModel.mainCentreID = this.loggedInUserData[0].mainCentreID;
    this.formModel.childCentreID = this.loggedInUserData[0].childCentreID;
    this.formModel.subCentreID = this.loggedInUserData[0].subCentreID;
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
   
    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedStatusObj = {
      statusID: 0,
      statusName: "All"
    };
    this.selectedKenshuObj = {
      kenshuID: 0,
      kenshuType: "All"
    };
    this.selectedGenderObj = {
      genderID: "A",
      genderType: "All"
    };

    this.selectedAgeObj = {
      ageID: "A",
      ageType:"All"
    }

    this.selectKenshuYearObj = {
      kenshuYear: "A",
      kenshuYearType: "All"
    };

    this.selectedSortOrder = {
      id: "no",
      type: "No"
    };

    this.initializeTabs(1);
    this.queryMDetails = [];
    var tmpQueryMData = this.dataSharingService.getQueryMDetailsData();
    if (tmpQueryMData.queryMSearchCriteria != "") {
      this.totalRecords = tmpQueryMData.totalRecords;
      this.queryMSearchCriteria = tmpQueryMData.queryMSearchCriteria;
      this.queryMDetails = tmpQueryMData.queryMDetails;
    }
  }

  initializeTabs(tab) {
    var tmpArry = [];
    var i = 0;
    tmpArry = this.dataSharingService.getStatusData();
    this.statusDetails = [];
    this.statusDetails.push(this.selectedStatusObj);
    for (i = 0; i < tmpArry.length; i++) {
      this.statusDetails.push(tmpArry[i]);
    }
    this.kenshuDetails = [];
    tmpArry = [];
    this.kenshuDetails.push(this.selectedKenshuObj);
    tmpArry =  [
      {
        kenshuID: 1,
        kenshuType: "Primary",
      },
      {
        kenshuID: 2,
        kenshuType: "Intermediate",
      },
      {
        kenshuID: 3,
        kenshuType: "Advanced",
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.kenshuDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.genderDetails = [];
    this.genderDetails.push(this.selectedGenderObj);
    tmpArry = [
      {
        genderID: "M",
        genderType: "Male"
      },
      {
        genderID: "F",
        genderType: "Female"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.genderDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.ageDetails = [];
    this.ageDetails.push(this.selectedAgeObj);
    tmpArry = [
      {
        ageID: "Y",
        ageType: "Youth Age <= 35"
      },
      {
        ageID: "E",
        ageType: "Adult Age > 35"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.ageDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.kenshuYearDetails = [];
    this.kenshuYearDetails.push(this.selectKenshuYearObj);
    tmpArry = [
      {
        kenshuYear: "B",
        kenshuYearType: "Before 2000"
      },
      {
        kenshuYear: "C",
        kenshuYearType: "2001 To 2010"
      },
      {
        kenshuYear: "D",
        kenshuYearType: "After 2010"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.kenshuYearDetails.push(tmpArry[i]);
    }


    this.selectedSortOrder = {
      id: "no",
      type: "No"
    };

    tmpArry = [];
    this.sortOrderByDetails = [];
    this.sortOrderByDetails.push(this.selectedSortOrder);
    tmpArry = [
      {
        id: "nm",
        type: "Name"
      },
      {
        id: "st",
        type: "Status"
      },
      {
        id: "ct",
        type: "Centre"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.sortOrderByDetails.push(tmpArry[i]);
    }


    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.mainCentreDetails.push(this.selectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length;i++) {
          this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.childCentreDetails.push(this.selectedChildCentreObj);
        this.subCentreDetails.push(this.selectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  onChangeMainCentre(mainCentre) {
    do {
      if (this.formModel.inEditMode == "N") {
        this.childCentreDetails = [];
        this.childCentreDetails.push(this.allTextChildCentreObj);
        this.selectedChildCentreObj = this.allTextChildCentreObj;

        for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
            this.childCentreDetails.push(this.glbchildCentreDetails[i]);
          }
        }
      } else {
        this.childCentreDetailsEditMode = [];
        for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
            this.childCentreDetailsEditMode.push(this.glbchildCentreDetails[i]);
          }
        }
      }
    } while (false);

  }

  onChangeChildCentre(childCentre) {
    do {
      if (this.formModel.inEditMode == "N") {
        this.subCentreDetails = [];
        this.subCentreDetails.push(this.allTextSubCentreObj);
        this.selectedSubCentreObj = this.allTextSubCentreObj;
        for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
            this.subCentreDetails.push(this.glbsubCentreDetails[i]);
          }
        }
      } else {
        this.subCentreDetailsEditMode = [];
        for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
            this.subCentreDetailsEditMode.push(this.glbsubCentreDetails[i]);
          }
        }
      }
    } while (false);
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
    }, 5000);
  }

  GetMembersForQueryM(form: NgForm) {
    //this.showSpinner();
    this.queryMSearchCriteria = "Processing Records....";
    this.populateQueryMResult(form.value);
  }


  populateQueryMResult(searchCriteria) {
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu : " + searchCriteria.kenshuDetails.kenshuType;
      if (searchCriteria.kenshuDetails.kenshuType == 'Primary') {
        break;
      }
      if (searchCriteria.kenshuDetails.kenshuType == 'Intermediate') {
        tmpArray = tmpArray.filter(function (item) {
          return item.ikyn == 'Y';
        });
      }
      if (searchCriteria.kenshuDetails.kenshuType == 'Advanced') {
        tmpArray = tmpArray.filter(function (item) {
          return item.akyn == 'Y';
        });
      }
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu ID: " + searchCriteria.kenshuID;
      if (searchCriteria.kenshuID == null || searchCriteria.kenshuID == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.priKID == searchCriteria.kenshuID;
      });
    } while (false)

    do {
      if (searchCriteria.memberName == null || searchCriteria.memberName == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.name.toLowerCase().includes(searchCriteria.memberName.toLowerCase());
      });
    } while (false);
    do {
      if (searchCriteria.introName == null || searchCriteria.introName == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.introducerName.toLowerCase().includes(searchCriteria.introName.toLowerCase());
      });
    } while (false);

    do {
      this.queryMSearchCriteria = "Search Criteria Main Centre : " + searchCriteria.mainCentreDetails.mainCentreName;
      if (searchCriteria.mainCentreDetails.mainCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.mainCentre = item.mainCentre.trim();
        return item.mainCentre == searchCriteria.mainCentreDetails.mainCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria +  ", Child Centre : " + searchCriteria.childCentreDetails.childCentreName;
      if (searchCriteria.childCentreDetails.childCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.childCentre = item.childCentre.trim();
        return item.childCentre == searchCriteria.childCentreDetails.childCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Sub Centre :" + searchCriteria.subCentreDetails.subCentreName;
      if (searchCriteria.subCentreDetails.subCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.subCentre = item.subCentre.trim();
        return item.subCentre == searchCriteria.subCentreDetails.subCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Status : " + searchCriteria.statusDetails.statusName; 
      if (searchCriteria.statusDetails.statusName== 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.status == searchCriteria.statusDetails.statusName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Gender : " + searchCriteria.genderDetails.genderType;
      if (searchCriteria.genderDetails.genderType == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.gender == searchCriteria.genderDetails.genderID;
      });
    } while (false);

    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu Year : " + searchCriteria.kenshuYearDetails.kenshuYearType;
      if (searchCriteria.kenshuYearDetails.kenshuYear == 'A') {
        break;
      }
      var kenshuArray = [];
      for (var i = 0; i < tmpArray.length; i++) {
        var priLevelDate = tmpArray[i].priKDate;
        if (priLevelDate == null || priLevelDate == "") {
          continue;
        }
        var prilevel = priLevelDate.split("-");
        var pri = prilevel[0];
        console.log(pri);
        if (searchCriteria.kenshuYearDetails.kenshuYear == 'B' && pri <= 2000) {
          kenshuArray.push(tmpArray[i]);
        }
        if (searchCriteria.kenshuYearDetails.kenshuYear== 'C' && (pri >= 2001 && pri <= 2010)) {
          kenshuArray.push(tmpArray[i]);
        }
        if (searchCriteria.kenshuYearDetails.kenshuYear == 'D' && pri > 2010) {
          kenshuArray.push(tmpArray[i]);
        }
      }
      tmpArray = kenshuArray;
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Age : " + searchCriteria.ageDetails.ageType;
      if (searchCriteria.ageDetails.ageID == 'A') {
        break;
      }
      var dummyArray = [];
      var yy = new Date().getFullYear();
      var bError = false;
      for (var i = 0; i < tmpArray.length; i++) {
        var birthDate = tmpArray[i].birthDate;
        if (birthDate == null || birthDate == "") {
          bError = true;
          this.ErrMessage = this.ErrMessage + tmpArray[i].memberId + ","
          continue;
        }
        var dbDArr = birthDate.split("-");
        var bdyy = dbDArr[0];
        var years = yy - bdyy;
        if (searchCriteria.ageDetails.ageID == 'Y' && years <= 35) {
          dummyArray.push(tmpArray[i]);
        }
        if (searchCriteria.ageDetails.ageID == 'E' && years > 35) {
          dummyArray.push(tmpArray[i]);
        }
      }
      tmpArray = dummyArray;
    } while (false);


    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Order By : " + searchCriteria.sortOrderByDetails.type;
      if (searchCriteria.sortOrderByDetails.id == "no") {
        tmpArray.sort(function (a, b) {
          return a.id - b.id
        })      }
      if (searchCriteria.sortOrderByDetails.id == "nm") {
        tmpArray.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "st") {
        tmpArray.sort(function (a, b) {
          var nameA = a.status, nameB = b.status
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "ct") {
        tmpArray.sort(function (a, b) {

          var nameA = a.mainCentre.substr(0,3) +"/"+a.childCentre.substr(0,3)+"/"+a.subCentre.substr(0,3);
          var nameB = b.mainCentre.substr(0,3) +"/"+b.childCentre.substr(0,3)+"/"+b.subCentre.substr(0,3);
         // console.log(nameA);
         // console.log(nameB);
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
    } while (false);
    this.queryMDetails = [];
    this.queryMDetails = tmpArray;
    this.totalRecords = tmpArray.length;
    if (this.ErrMessage != "") {
      this.ErrMessage = "Invalid DOB for Members ( " + this.ErrMessage.substr(0, this.ErrMessage.length - 1) + " )";
    }
    var tmpObj = {
      totalRecords: this.totalRecords,
      queryMSearchCriteria: this.queryMSearchCriteria,
      queryMDetails: this.queryMDetails
    };
    this.dataSharingService.setQueryMDetailsData(tmpObj);
  }

  
  showHideQueryOptions(queryOption) {
    do {
      if (queryOption == 'M') {
        if (this.formModel.showQueryMOption == "Y") {
          this.formModel.showQueryMOption = "N";
        } else {
          this.formModel.showQueryMOption = "Y";
        }
        break;
      }
    } while (false);
  }

  editMember(){
    this.router.navigateByUrl('offer/edit-kenshu');
  }
}
