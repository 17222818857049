import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompleterService, CompleterData } from 'ng2-completer';
import { OfferService } from '../../services/offer.service';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { AuthenticateService } from "../../services/authenticate.service";

@Component({
  selector: 'app-adminfunctions',
  templateUrl: './adminfunctions.component.html',
  styleUrls: ['./adminfunctions.component.css']
})
export class AdminfunctionsComponent implements OnInit {

  formModel: any = {
    loggedinUserRole: "",
    showQueryMOption: "N",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName: "",
    subCentreID: "",
    manageMebo: "",
    message: "",
    displayMessage:"",
    progress: "",
    inEditMode: "",
    gender: "",
    isAdultYN: "",
    memberID: "",
    name: "",
    manageMaster: "",
    statusID: null,
    statusName: "",
    isAdminUser: "",
    loginID: "",
    roleID: "",
    password: "",
    contactNo: "",
    email: "",
    roleType: "",
    roleName: "",
    cuttOffDate:""
  };
  totalRecords = 0;
  ErrMessage = "";
  loggedInUserData = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  mainCentreDetailsEditMode = [];
  childCentreDetailsEditMode = [];
  subCentreDetailsEditMode = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  statusDetails = [];
  statusDetailsEditMode = [];
  kenshuDetails = [];
  genderDetails = [];
  ageDetails = [];
  kenshuYearDetails = [];
  sortOrderByDetails = [];
  queryMDetails = [];
  genderList = [];
  isAdultList = [];
  monthwiseShidobu = [];
  centrewiseShidobu = [];
  selectedPaymentObj = {};
  paymentOpt = "";
  paymentOptionDetails = [];
  selectedWithWithOutAddr = {};
  withWithOutAddr = [];
  allMembersArrayData: [];
  memwiseCashBankTotal = [];
  otherOffering = [];


  queryMSearchCriteria = "";
  fileNameMebo = "";
  fileNameMain = "";
  fileNameChild = "";
  fileNameSub = "";
  fileNameStatus = "";
  fileNameOffering = "";
  fileNameMember = "";
  mainCentreUploadDetails = [];
  childCentreUploadDetails = [];
  subCentreUploadDetails = [];
  statusUploadDetails = [];
  membersUploadDetails = [];
  offeringsUploadDetails = [];

  meboUploadErrors = [];
  meboUploadDetails = [];
  meboUploadMessage = "";
  fileTypeToUpload = "";
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  mainCentreObjEditMode = {};
  childCentreObjEditMode = {};
  subCentreObjEditMode = {};
  statusObjEditMode = {};

  allTextChildCentreObj = {};
  allTextSubCentreObj = {};
  selectedStatusObj = {};
  selectedKenshuObj = {};
  selectedGenderObj = {};
  selectedAgeObj = {};
  selectKenshuYearObj = {};
  selectedSortOrder = {}
  isConnected = true;
  status = 'ONLINE';

  allUserDetails = [];
  RoleTypes = [];
  mainCenterChildCenterData = [];
  childCenterSubCenterData = [];

  protected dataservice: CompleterData;

  constructor(private offerService: OfferService, private excelService: ExcelService, private dataSharingService: GlobalDataSharingService,
    private completerService: CompleterService, private authService: AuthenticateService) { }

  isPKenshuList = [];
  isIKenshuList = [];
  isAKenshuList = [];

  ngOnInit() {
    this.allMembersArrayData = this.dataSharingService.getAllMembersListing();
    this.allUserDetails = null;
    this.formModel.roleName = "";
    this.formModel.loggedinUserRole = "";
    this.formModel.loginUserRoleID = "";
    this.formModel.isAdminUser = "";
    this.formModel.inEditMode = "";
    this.formModel.loginID = "";
    this.formModel.password = "";
    this.formModel.contactNo = "";
    this.formModel.email = "";
    this.formModel.roleType = "";
    this.formModel.isAdminUser = "N";

    this.formModel.inEditMode = "N";
    this.formModel.showQueryMOption = "N";
    this.formModel.isAdultYN = "";
    this.formModel.gender = "";
    this.formModel.memberID = "";
    this.formModel.name = "";
    this.formModel.manageMaster = 'M';

    this.genderList = [{ name: "Male", value: "M" }, { name: "Female", value: "F" }];
    this.isAdultList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.isPKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.isIKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.isAKenshuList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];

    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.formModel.loginUserRoleID = localStorage.getItem("loginUserRoleID");
    this.loggedInUserData = this.dataSharingService.getLoggedInUserData();
    this.formModel.mainCentreName = this.loggedInUserData[0].mainCentreName;
    this.formModel.childCentreName = this.loggedInUserData[0].childCentreName;
    this.formModel.subCentreName = this.loggedInUserData[0].subCentreName;
    this.formModel.mainCentreID = this.loggedInUserData[0].mainCentreID;
    this.formModel.childCentreID = this.loggedInUserData[0].childCentreID;
    this.formModel.subCentreID = this.loggedInUserData[0].subCentreID;
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();

    this.mainCentreDetails = this.dataSharingService.getMainCentreData();
    this.childCentreDetails = this.dataSharingService.getChildCentreData();
    this.subCentreDetails = this.dataSharingService.getSubCentreData();
    this.statusDetails = this.dataSharingService.getStatusData();
    this.allUserDetails = this.dataSharingService.getAllUsersData();

    this.RoleTypes = [
      {
        name: 'Super User',
        value: 'Adm'
      },
      {
        name: 'Main Center User',
        value: 'MCU'
      },
      {
        name: 'Child Center User',
        value: 'CCU'
      },
      {
        name: 'Sub Center User',
        value: 'SCU'
      }
    ];

    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedStatusObj = {
      statusID: 0,
      statusName: "All"
    };
    this.selectedKenshuObj = {
      kenshuID: 0,
      kenshuType: "All"
    };
    this.selectedGenderObj = {
      genderID: "A",
      genderType: "All"
    };

    this.selectedAgeObj = {
      ageID: "A",
      ageType: "All"
    }

    this.selectKenshuYearObj = {
      kenshuYear: "A",
      kenshuYearType: "All"
    };

    this.selectedSortOrder = {
      id: "no",
      type: "No"
    };

    this.selectedWithWithOutAddr = {
      id: "w",
      type: "With Address"
    };
    var tmpArry1 = [];
    this.withWithOutAddr = [];
    this.withWithOutAddr.push(this.selectedWithWithOutAddr);
    tmpArry1 = [
      {
        id: "o",
        type: "Without Address"
      }
    ];
    for (i = 0; i < tmpArry1.length; i++) {
      this.withWithOutAddr.push(tmpArry1[i]);
    }

    //this.initializeTabs(1);
    this.queryMDetails = [];
    var tmpQueryMData = this.dataSharingService.getQueryMDetailsData();
    if (tmpQueryMData.queryMSearchCriteria != "") {
      this.totalRecords = tmpQueryMData.totalRecords;
      this.queryMSearchCriteria = tmpQueryMData.queryMSearchCriteria;
      this.queryMDetails = tmpQueryMData.queryMDetails;
    }
    
    this.paymentOptionDetails = [];
    this.selectedPaymentObj = {
      id: 0,
      type: "All"
    }
    this.paymentOptionDetails.push(this.selectedPaymentObj);
    var tmpArry3 = [
      {
        id: 1,
        type: "Cash"
      },
      {
        id: 2,
        type: "Cheque"
      }
    ];
    for (i = 0; i < tmpArry3.length; i++) {
      this.paymentOptionDetails.push(tmpArry3[i]);
    }

    var tmpArry = [];
    var i = 0;
    tmpArry = this.dataSharingService.getStatusData();
    this.statusDetails = [];
    this.statusDetails.push(this.selectedStatusObj);
    for (i = 0; i < tmpArry.length; i++) {
      this.statusDetails.push(tmpArry[i]);
    }
    this.kenshuDetails = [];
    tmpArry = [];
    this.kenshuDetails.push(this.selectedKenshuObj);
    tmpArry = [
      {
        kenshuID: 1,
        kenshuType: "Primary",
      },
      {
        kenshuID: 2,
        kenshuType: "Intermediate",
      },
      {
        kenshuID: 3,
        kenshuType: "Advanced",
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.kenshuDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.genderDetails = [];
    this.genderDetails.push(this.selectedGenderObj);
    tmpArry = [
      {
        genderID: "M",
        genderType: "Male"
      },
      {
        genderID: "F",
        genderType: "Female"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.genderDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.ageDetails = [];
    this.ageDetails.push(this.selectedAgeObj);
    tmpArry = [
      {
        ageID: "Y",
        ageType: "Youth Age <= 35"
      },
      {
        ageID: "E",
        ageType: "Adult Age > 35"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.ageDetails.push(tmpArry[i]);
    } 

    tmpArry = [];
    this.kenshuYearDetails = [];
    this.kenshuYearDetails.push(this.selectKenshuYearObj);
    tmpArry = [
      {
        kenshuYear: "B",
        kenshuYearType: "Before 2000"
      },
      {
        kenshuYear: "C",
        kenshuYearType: "2001 To 2010"
      },
      {
        kenshuYear: "D",
        kenshuYearType: "After 2010"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.kenshuYearDetails.push(tmpArry[i]);
    }
    this.selectedSortOrder = {
      id: "no",
      type: "No"
    };

    tmpArry = [];
    this.sortOrderByDetails = [];
    this.sortOrderByDetails.push(this.selectedSortOrder);
    tmpArry = [
      {
        id: "nm",
        type: "Name"
      },
      {
        id: "st",
        type: "Status"
      },
      {
        id: "ct",
        type: "Centre"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.sortOrderByDetails.push(tmpArry[i]);
    }

  }

  editKenshu(id) {
    this.getMemberbyId(id);
  }

  getMemberbyId(id) {
    this.mainCentreDetailsEditMode = this.dataSharingService.getMainCentreData();
    this.childCentreDetailsEditMode = this.dataSharingService.getChildCentreData();
    this.subCentreDetailsEditMode = this.dataSharingService.getSubCentreData();
    this.statusDetailsEditMode = this.dataSharingService.getStatusData();
    this.offerService.GetMemberInfoById(id).subscribe((res: any) => {
      if (res.status == 1) {
        var memberObj = res['result'];
        this.formModel.memberID = memberObj.memberID;

        var i = 0;
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (memberObj.mainCentre == this.glbmainCentreDetails[i].mainCentreName) {
            this.mainCentreObjEditMode = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (memberObj.childCentre == this.glbchildCentreDetails[i].childCentreName) {
            this.childCentreObjEditMode = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (memberObj.subCentre == this.glbsubCentreDetails[i].subCentreName) {
            this.subCentreObjEditMode = this.glbsubCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.statusDetailsEditMode.length; i++) {
          if (memberObj.status == this.statusDetailsEditMode[i].statusName) {
            this.statusObjEditMode = this.statusDetailsEditMode[i];
            break;
          }
        }

        var pipe = new DatePipe('en-US');
        this.formModel.name = memberObj.name;
        this.formModel.pkyn = memberObj.pkyn;
        this.formModel.priKNo = memberObj.priKNo;
        this.formModel.priKDate = memberObj.priKDate;
        this.formModel.priKPlace = memberObj.priKPlace;
        if (this.formModel.priKDate != null && this.formModel.priKDate != "") {
          this.formModel.priKDate = new Date(memberObj.priKDate);
        }

        this.formModel.ikyn = memberObj.ikyn;
        this.formModel.intKNo = memberObj.intKNo;
        this.formModel.intKDate = memberObj.intKDate;
        if (this.formModel.intKDate != null && this.formModel.intKDate != "") {
          this.formModel.intKDate = new Date(memberObj.intKDate);
        }
        this.formModel.intKPlace = memberObj.intKPlace;
        this.formModel.akyn = memberObj.akyn;
        this.formModel.advKNo = memberObj.advKNo;
        this.formModel.advKDate = memberObj.advKDate;
        if (this.formModel.advKDate != null && this.formModel.advKDate != "") {
          this.formModel.advKDate = new Date(memberObj.advKDate);
        }
        this.formModel.advKPlace = memberObj.advKPlace;
        // if(this.formModel.advKDate != null && this.formModel.advKDate != ""){
        //   var date = new Date(memberObj.advKDate);
        //   this.formModel.advKDate =  pipe.transform(date, 'dd/MM/yyyy');
        //   console.log(this.formModel.advKDate);
        // }

      } else {
        alert(res.message);
      }

    })
  }

  UpdateKenshu(form: NgForm) {
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    tmpArray = tmpArray.filter(function (item) {
      return item.memberID == form.value.memberID;
    });

    var pipe = new DatePipe('en-US');
    tmpArray[0].name = form.value.name;
    tmpArray[0].pkyn = form.value.pkyn;
    tmpArray[0].priKNo = form.value.priKNo;
    tmpArray[0].priKDate = form.value.priKDate;
    tmpArray[0].priKPlace = form.value.priKPlace;
    if (form.value.priKDate != null && form.value.priKDate != "") {
      tmpArray[0].priKDate = pipe.transform(form.value.priKDate, 'MM/dd/yyyy');
    }

    tmpArray[0].ikyn = form.value.ikyn;
    tmpArray[0].intKNo = form.value.intKNo;
    tmpArray[0].intKDate = form.value.intKDate;
    if (form.value.intKDate != null && form.value.intKDate != "") {
      tmpArray[0].intKDate = pipe.transform(form.value.intKDate, 'MM/dd/yyyy');
    }

    tmpArray[0].intKPlace = form.value.intKPlace;
    tmpArray[0].akyn = form.value.akyn;
    tmpArray[0].advKNo = form.value.advKNo;
    tmpArray[0].advKDate = form.value.advKDate;
    if (form.value.advKDate != null && form.value.advKDate != "") {
      tmpArray[0].advKDate = pipe.transform(form.value.advKDate, 'MM/dd/yyyy');
    }
    tmpArray[0].advKPlace = form.value.advKPlace;

    this.offerService.updateMebo(tmpArray[0]).subscribe((res: any) => {
      if (res.status == 0) {
        alert(res.message);
        return;
      }
      var index = this.queryMDetails.findIndex((e) => e.id === tmpArray[0].id);
      if (index === -1) {
        return;
      } else {
        this.queryMDetails[index] = tmpArray[0];
      }
      // this.formModel.inEditMode = 'N';
      index = -1;
      var tmpArray2 = [];
      tmpArray2 = this.dataSharingService.getAllMembersListing();
      index = tmpArray2.findIndex((e) => e.id === tmpArray[0].id);
      if (index === -1) {
        return;
      } else {
        tmpArray2[index] = tmpArray[0];
      }
      this.dataSharingService.setAllMembersListing(tmpArray2);
      this.playAudio();
      this.formModel.displayMessage = "RECORD SAVED";
      setTimeout(() => {
        this.formModel.displayMessage = "";
      }, 3000);
    })
  }
  cancelEditKenshu() {
    this.playAudio();
    this.formModel.displayMessage = "Re-directing to Search Kenshu";
    setTimeout(() => {
      this.formModel.displayMessage = "";
    }, 3000);
    this.formModel.report = 'SEARCHKENSHU';
    this.formModel.reportName = 'Search Kenshu'
  }
  playAudio() {
    let audio = new Audio();
    audio.src = "../assets/audio/beep2.wav";
    audio.load();
    audio.play();
  }

  initializeTabs() {
    var i = 0;
    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.mainCentreDetails.push(this.selectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.childCentreDetails.push(this.selectedChildCentreObj);
        this.subCentreDetails.push(this.selectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  setToDefaults() {
    var d = new Date();
    this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
    this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
    this.formModel.forMonth = new Date(d.getFullYear(), d.getMonth(), 1);
    var tmpData = [];
    tmpData = this.dataSharingService.getSysVarData();
    this.formModel.cuttOffDate = new Date(tmpData[0].cuttOffDate);

    this.mainCentreDetails = [];
    this.childCentreDetails = [];
    this.subCentreDetails = [];

    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };

    var i = 0;
    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.mainCentreDetails.push(this.selectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.childCentreDetails.push(this.selectedChildCentreObj);
        this.subCentreDetails.push(this.selectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }

  }

  onChangeMainCentre(mainCentre) {
    do {
      this.childCentreDetails = [];
      this.childCentreDetails.push(this.allTextChildCentreObj);
      this.selectedChildCentreObj = this.allTextChildCentreObj;

      for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
        if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
          this.childCentreDetails.push(this.glbchildCentreDetails[i]);
        }
      }
    } while (false);

  }

  onChangeChildCentre(childCentre) {
    do {
      this.subCentreDetails = [];
      this.subCentreDetails.push(this.allTextSubCentreObj);
      this.selectedSubCentreObj = this.allTextSubCentreObj;
      for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
        if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
          this.subCentreDetails.push(this.glbsubCentreDetails[i]);
        }
      }
    } while (false);
  }

  onChangeMainCentreUser(mainCentre) {
    var tmpArry = [];
    for (var i = 0; i < this.childCentreDetails.length; i++) {
      if (this.childCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
        tmpArry.push(this.childCentreDetails[i]);
      }
    }
    this.mainCenterChildCenterData = tmpArry;
  }

  onChangeChildCentreUser(childCentre) {
    var tmpArry = [];
    for (var i = 0; i < this.subCentreDetails.length; i++) {
      if (this.subCentreDetails[i].childCentreID == childCentre.childCentreID) {
        tmpArry.push(this.subCentreDetails[i]);
      }
    }
    this.childCenterSubCenterData = tmpArry;
  }

  GetMembersForQueryM(form: NgForm) {
    //this.showSpinner();
    this.queryMSearchCriteria = "Processing Records....";
    this.populateQueryMResult(form.value);
  }

  populateQueryMResult(searchCriteria) {
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu : " + searchCriteria.kenshuDetails.kenshuType;
      if (searchCriteria.kenshuDetails.kenshuType == 'Primary') {
        break;
      }
      if (searchCriteria.kenshuDetails.kenshuType == 'Intermediate') {
        tmpArray = tmpArray.filter(function (item) {
          return item.ikyn == 'Y';
        });
      }
      if (searchCriteria.kenshuDetails.kenshuType == 'Advanced') {
        tmpArray = tmpArray.filter(function (item) {
          return item.akyn == 'Y';
        });
      }
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu ID: " + searchCriteria.kenshuID;
      if (searchCriteria.kenshuID == null || searchCriteria.kenshuID == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.priKID == searchCriteria.kenshuID;
      });
    } while (false)

    do {
      if (searchCriteria.memberName == null || searchCriteria.memberName == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.name.toLowerCase().includes(searchCriteria.memberName.toLowerCase());
      });
    } while (false);
    do {
      if (searchCriteria.introName == null || searchCriteria.introName == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.introducerName.toLowerCase().includes(searchCriteria.introName.toLowerCase());
      });
    } while (false);

    do {
      this.queryMSearchCriteria = "Search Criteria Main Centre : " + searchCriteria.mainCentreDetails.mainCentreName;
      if (searchCriteria.mainCentreDetails.mainCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.mainCentre = item.mainCentre.trim();
        return item.mainCentre == searchCriteria.mainCentreDetails.mainCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Child Centre : " + searchCriteria.childCentreDetails.childCentreName;
      if (searchCriteria.childCentreDetails.childCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.childCentre = item.childCentre.trim();
        return item.childCentre == searchCriteria.childCentreDetails.childCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Sub Centre :" + searchCriteria.subCentreDetails.subCentreName;
      if (searchCriteria.subCentreDetails.subCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.subCentre = item.subCentre.trim();
        return item.subCentre == searchCriteria.subCentreDetails.subCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Status : " + searchCriteria.statusDetails.statusName;
      if (searchCriteria.statusDetails.statusName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.status == searchCriteria.statusDetails.statusName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Gender : " + searchCriteria.genderDetails.genderType;
      if (searchCriteria.genderDetails.genderType == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.gender == searchCriteria.genderDetails.genderID;
      });
    } while (false);

    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu Year : " + searchCriteria.kenshuYearDetails.kenshuYearType;
      if (searchCriteria.kenshuYearDetails.kenshuYear == 'A') {
        break;
      }
      var kenshuArray = [];
      for (var i = 0; i < tmpArray.length; i++) {
        var priLevelDate = tmpArray[i].priKDate;
        if (priLevelDate == null || priLevelDate == "") {
          continue;
        }
        var prilevel = priLevelDate.split("-");
        var pri = prilevel[0];
        console.log(pri);
        if (searchCriteria.kenshuYearDetails.kenshuYear == 'B' && pri <= 2000) {
          kenshuArray.push(tmpArray[i]);
        }
        if (searchCriteria.kenshuYearDetails.kenshuYear == 'C' && (pri >= 2001 && pri <= 2010)) {
          kenshuArray.push(tmpArray[i]);
        }
        if (searchCriteria.kenshuYearDetails.kenshuYear == 'D' && pri > 2010) {
          kenshuArray.push(tmpArray[i]);
        }
      }
      tmpArray = kenshuArray;
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Age : " + searchCriteria.ageDetails.ageType;
      if (searchCriteria.ageDetails.ageID == 'A') {
        break;
      }
      var dummyArray = [];
      var yy = new Date().getFullYear();
      var bError = false;
      for (var i = 0; i < tmpArray.length; i++) {
        var birthDate = tmpArray[i].birthDate;
        if (birthDate == null || birthDate == "") {
          bError = true;
          this.ErrMessage = this.ErrMessage + tmpArray[i].memberId + ","
          continue;
        }
        var dbDArr = birthDate.split("-");
        var bdyy = dbDArr[0];
        var years = yy - bdyy;
        if (searchCriteria.ageDetails.ageID == 'Y' && years <= 35) {
          dummyArray.push(tmpArray[i]);
        }
        if (searchCriteria.ageDetails.ageID == 'E' && years > 35) {
          dummyArray.push(tmpArray[i]);
        }
      }
      tmpArray = dummyArray;
    } while (false);


    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Order By : " + searchCriteria.sortOrderByDetails.type;
      if (searchCriteria.sortOrderByDetails.id == "no") {
        tmpArray.sort(function (a, b) {
          return a.id - b.id
        })
      }
      if (searchCriteria.sortOrderByDetails.id == "nm") {
        tmpArray.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "st") {
        tmpArray.sort(function (a, b) {
          var nameA = a.status, nameB = b.status
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "ct") {
        tmpArray.sort(function (a, b) {

          var nameA = a.mainCentre.substr(0, 3) + "/" + a.childCentre.substr(0, 3) + "/" + a.subCentre.substr(0, 3);
          var nameB = b.mainCentre.substr(0, 3) + "/" + b.childCentre.substr(0, 3) + "/" + b.subCentre.substr(0, 3);
          // console.log(nameA);
          // console.log(nameB);
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
    } while (false);
    this.queryMDetails = [];
    this.queryMDetails = tmpArray;
    this.totalRecords = tmpArray.length;
    if (this.ErrMessage != "") {
      this.ErrMessage = "Invalid DOB for Members ( " + this.ErrMessage.substr(0, this.ErrMessage.length - 1) + " )";
    }

    var tmpObj = {
      totalRecords: this.totalRecords,
      queryMSearchCriteria: this.queryMSearchCriteria,
      queryMDetails: this.queryMDetails
    };
    this.dataSharingService.setQueryMDetailsData(tmpObj);
  }

  showHideQueryOptions(queryOption) {
    do {
      if (queryOption == 'M') {
        if (this.formModel.showQueryMOption == "Y") {
          this.formModel.showQueryMOption = "N";
        } else {
          this.formModel.showQueryMOption = "Y";
        }
        break;
      }
    } while (false);
  }

  onCancelManageMaster() {
    switch (this.formModel.manageMaster) {
      case 'AEM': {
        this.formModel.manageMaster = 'M'
        break;
      }
      case 'AEC': {
        this.formModel.manageMaster = 'C'
        break;
      }
      case 'AES': {
        this.formModel.manageMaster = 'S'
        break;
      }
      case 'AET': {
        this.formModel.manageMaster = 'T'
        break;
      }
      default: {
        break;
      }
    }
  }

  onSubmitManageMaster(form: NgForm) {
    console.log(form.value);
    switch (this.formModel.manageMaster) {
      case 'AEM': {
        if (form.value.mainCentreName == "" || form.value.mainCentreName == null || form.value.mainCentreName == undefined) {
          alert("Main Centre Name cannot be blank");
          return;
        }
        this.offerService.saveMainCentreData(form.value).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setMainCentreData(res.result);
            this.mainCentreDetails = res.result;
            this.onCancelManageMaster();
          }
        })
        break;
      }
      case 'AEC': {
        if (form.value.childCentreName == "" || form.value.childCentreName == null || form.value.childCentreName == undefined) {
          alert("Child Centre Name cannot be blank");
          return;
        }
        var obj = {
          childCentreName: form.value.childCentreName,
          childCentreID: 0,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName
        };
        this.offerService.saveChildCentreData(obj).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setChildCentreData(res.result);
            this.childCentreDetails = res.result;
            this.onCancelManageMaster();
          }
        })
        break;
      }
      case 'AES': {
        if (form.value.subCentreName == "" || form.value.subCentreName == null || form.value.subCentreName == undefined) {
          alert("Sub Centre Name cannot be blank");
          return;
        }
        var obj1 = {
          subCentreName: form.value.subCentreName,
          subCentreID: form.value.subCentreID,
          childCentreID: form.value.childCentreDetails.childCentreID,
          childCentreName: form.value.childCentreDetails.childCentreName,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName
        };
        this.offerService.saveSubCentreData(obj1).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setSubCentreData(res.result);
            this.subCentreDetails = res.result;
            this.onCancelManageMaster();
          }
        })
        break;
      }
      case 'AET': {
        if (form.value.statusName == "" || form.value.statusName == null || form.value.statusName == undefined) {
          alert("Status cannot be blank");
          return;
        }
        this.offerService.saveStatusData(form.value).subscribe((res: any) => {
          if (res.status = 1) {
            this.dataSharingService.setStatusData(res.result);
            this.statusDetails = res.result;
            this.onCancelManageMaster();
          }
        })
        break;
      }

      default: {
        break;
      }

    }

  }

  deleteMainCentre(mainCentreID) {
    if (!confirm("Delete Main Centre ?")) {
      return;
    }
    this.offerService.deleteMainCentre(mainCentreID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setMainCentreData(res.result);
        this.mainCentreDetails = res.result;
      }
    });
  }

  deleteChildCentre(childCentreID) {
    if (!confirm("Delete Child Centre ?")) {
      return;
    }
    this.offerService.deleteChildCentre(childCentreID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setChildCentreData(res.result);
        this.childCentreDetails = res.result;
      }
    });
  }

  addEditMainCentre(mainCentreID, mainCentreName) {
    this.formModel.manageMaster = 'AEM';
    this.formModel.mainCentreID = mainCentreID;
    this.formModel.mainCentreName = mainCentreName;
  }

  addEditChildCentre(childCentreID, childCentreName) {
    this.formModel.manageMaster = 'AEC';
    this.formModel.childCentreID = childCentreID;
    this.formModel.childCentreName = childCentreName;
  }
  addEditSubCentre(subCentreID, subCentreName) {
    this.formModel.manageMaster = 'AES';
    this.formModel.subCentreID = subCentreID;
    this.formModel.subCentreName = subCentreName;
  }

  addEditStatus(statusID, statusName) {
    this.formModel.manageMaster = 'AET';
    this.formModel.statusID = statusID;
    this.formModel.statusName = statusName;
  }

  deleteSubCentre(subCentreID) {
    if (!confirm("Delete Sub Centre ?")) {
      return;
    }
    this.offerService.deleteSubCentre(subCentreID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setSubCentreData(res.result);
        this.subCentreDetails = res.result;
      }
    });
  }

  deleteStatus(statusID) {
    if (!confirm("Delete Status?")) {
      return;
    }
    this.offerService.deleteStatus(statusID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setStatusData(res.result);
        this.statusDetails = res.result;
      }
    });
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  /* MONTHWISESHIDOBU  populateSHIDOBUMONTHWISE */
  populateSHIDOBUMonthWise(data) {
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerDate = pipe.transform(tmpList[0].offerDate, 'MMM-yyyy');
    var offerDateDD = pipe.transform(tmpList[0].offerDate, 'MMM-yyyy');
    var centre = "";
    if (this.selectedMainCentreObj['mainCentreName'] == "All") {
      centre = "All/All";
    } else if (this.selectedChildCentreObj['childCentreName'] == "All") {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/All";
    } else {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/" + tmpList[0].childCentre.substr(0, 3);
    }
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var dateWiseCouCenTotal = 0;

    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var gTotal_1 = 0;
    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    console.log(tmpList[0]);
    for (var i = 0; i < tmpList.length; i++) {
      if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash") {
          if (chqEmpty == false) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi;
      gTotalOkiyome = gTotalOkiyome + rOkiyome;
      gTotalGoshintai = gTotalGoshintai + rGoshintai;
      gTotalAncestors = gTotalAncestors + rAncestors;
      gTotalOmitama = gTotalOmitama + rOmitama;
      gTotalGoshugo = gTotalGoshugo + rGoshugo;
      gTotalOther = gTotalOther + rOther;
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;
      //gTotal = gTotal + parseInt(tmpList[i].total);
      gTotal = gTotalOtamagushi + gTotalOkiyome + gTotalGoshintai + gTotalAncestors + gTotalOmitama + gTotalGoshugo + gTotalOther + gTotalScmo;
      gTotal_1 = gTotalCentreM + gTotalCountryM;
      if (pipe.transform(tmpList[i].offerDate, 'MMM-yyyy') == offerDate) {
        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseScmo = dateWiseScmo + rScmo;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;
        //dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);

      } else {
        var dateChangedObj = {
          SrNo: iCount + 1,
          Centre: centre,
          OfferingDate: offerDateDD,
          SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
          SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
          CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
          CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
          CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
          CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
          Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
          Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
          Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
          Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
          Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
          Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
          Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
          Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
          Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
          Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
          Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
          Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
          Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
          Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
          Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
          Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
        }
        tmpArray.push(dateChangedObj);
        iCount = iCount + 1;
        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo;
        dateWiseOther = rOther;
        dateWiseScmo = rScmo;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;

        // iCount = 1;
        offerDate = pipe.transform(tmpList[i].offerDate, 'MMM-yyyy');
        offerDateDD = pipe.transform(tmpList[i].offerDate, 'MMM-yyyy');

        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
      }
    }// for

    var lastRecObjCnt = {
      SrNo: iCount + 1,
      Centre: centre,
      OfferingDate: offerDateDD,
      SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
      SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
      CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
      CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
      CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
      CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
      Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
      Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
      Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
      Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
      Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
      Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
      Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
      Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
      Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
      Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
      Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
      Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
      Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
      Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
      Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
      Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "Grand Total",
      OfferingDate: "",
      Centre: "",
      SCMO: ((gTotalScmo == 0) ? "" : gTotalScmo),
      SCMO_cnt: ((gTotalScmoCnt == 0) ? "" : gTotalScmoCnt),
      CountryM: ((gTotalCountryM == 0) ? "" : gTotalCountryM),
      CountryM_cnt: ((gTotalCountryMCnt == 0) ? "" : gTotalCountryMCnt),
      CentreM: ((gTotalCentreM == 0) ? "" : gTotalCentreM),
      CentreM_cnt: ((gTotalCentreMCnt == 0) ? "" : gTotalCentreMCnt),
      Otamagushi: ((gTotalOtamagushi == 0) ? "" : gTotalOtamagushi),
      Otamagushi_cnt: ((gTotalOtamagushiCnt == 0) ? "" : gTotalOtamagushiCnt),
      Okiyome: ((gTotalOkiyome == 0) ? "" : gTotalOkiyome),
      Okiyome_cnt: ((gTotalOkiyomeCnt == 0) ? "" : gTotalOkiyomeCnt),
      Goshintai: ((gTotalGoshintai == 0) ? "" : gTotalGoshintai),
      Goshintai_cnt: ((gTotalGoshintaiCnt == 0) ? "" : gTotalGoshintaiCnt),
      Ancestors: ((gTotalAncestors == 0) ? "" : gTotalAncestors),
      Ancestors_cnt: ((gTotalAncestorsCnt == 0) ? "" : gTotalAncestorsCnt),
      Omitama: ((gTotalOmitama == 0) ? "" : gTotalOmitama),
      Omitama_cnt: ((gTotalOmitamaCnt == 0) ? "" : gTotalOmitamaCnt),
      Goshugo: ((gTotalGoshugo == 0) ? "" : gTotalGoshugo),
      Goshugo_cnt: ((gTotalGoshugoCnt == 0) ? "" : gTotalGoshugoCnt),
      Other: ((gTotalOther == 0) ? "" : gTotalOther),
      Other_cnt: ((gTotalOtherCnt == 0) ? "" : gTotalOtherCnt),
      Total: ((gTotal == 0) ? "" : gTotal),
      Total_1: ((gTotal_1 == 0) ? "" : gTotal_1)
    }

    tmpArray.push(grandTotalObj);

    this.monthwiseShidobu = tmpArray;
  }

  /* CENTREWISESHIDOBU populateSHIDOBUCENTREWISE */
  populateSHIDOBUCentreWise(data) {
    var tmpArray = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var offerChildCenter = tmpList[0].offerChildCentre.trim();
    var offerDateDD = pipe.transform(tmpList[0].offerDate, 'MMM-yyyy');
    var centre = tmpList[0].offerMainCentre.substr(0, 3) + "/" + tmpList[0].offerChildCentre.substr(0, 3);
    /*if (this.selectedMainCentreObj['mainCentreName'] == "All") {
      centre = "All/All";
    } else if (this.selectedChildCentreObj['childCentreName'] == "All") {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/All";
    } else {
      centre = tmpList[0].offerMainCentre.substr(0, 3) + "/" + tmpList[0].childCentre.substr(0, 3);
    }*/
    var iCount = 0;
    var dateWiseScmo = 0;
    var dateWiseCountryM = 0;
    var dateWiseCentreM = 0;
    var dateWiseOtamagushi = 0;
    var dateWiseOkiyome = 0;
    var dateWiseGoshintai = 0;
    var dateWiseAncestors = 0;
    var dateWiseOmitama = 0;
    var dateWiseGoshugo = 0;
    var dateWiseOther = 0;
    var dateWiseTotal = 0;
    var dateWiseCouCenTotal = 0;

    var gTotalScmo = 0;
    var gTotalCountryM = 0;
    var gTotalCentreM = 0;
    var gTotalOtamagushi = 0;
    var gTotalOkiyome = 0;
    var gTotalGoshintai = 0;
    var gTotalAncestors = 0;
    var gTotalOmitama = 0;
    var gTotalGoshugo = 0;
    var gTotalOther = 0;
    var gTotal = 0;
    var gTotal_1 = 0;
    var gTotalScmoCnt = 0;
    var gTotalCountryMCnt = 0;
    var gTotalCentreMCnt = 0;
    var gTotalOtamagushiCnt = 0;
    var gTotalOkiyomeCnt = 0;
    var gTotalGoshintaiCnt = 0;
    var gTotalAncestorsCnt = 0;
    var gTotalOmitamaCnt = 0;
    var gTotalGoshugoCnt = 0;
    var gTotalOtherCnt = 0;
    var gTotalCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOtamagushiCnt = 0;
    var dateWiseOkiyomeCnt = 0;
    var dateWiseGoshintaiCnt = 0;
    var dateWiseAncestorsCnt = 0;
    var dateWiseOmitamaCnt = 0;
    var dateWiseGoshugoCnt = 0;
    var dateWiseOtherCnt = 0;
    var dateWiseScmoCnt = 0;
    var dateWiseCountryMCnt = 0;
    var dateWiseCentreMCnt = 0;

    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    for (var i = 0; i < tmpList.length; i++) {
      if (this.paymentOpt == "Cash" || this.paymentOpt == "Cheque") {
        var chqNo = tmpList[i].chequeNo;
        var chqEmpty = this.isEmpty(chqNo);
        if (this.paymentOpt == "Cash") {
          if (chqEmpty == false) {
            continue;
          }
        } else {
          if (chqEmpty) {
            continue;
          }
        }
      }
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      gTotalOtamagushi = gTotalOtamagushi + rOtamagushi;
      gTotalOkiyome = gTotalOkiyome + rOkiyome;
      gTotalGoshintai = gTotalGoshintai + rGoshintai;
      gTotalAncestors = gTotalAncestors + rAncestors;
      gTotalOmitama = gTotalOmitama + rOmitama;
      gTotalGoshugo = gTotalGoshugo + rGoshugo;
      gTotalOther = gTotalOther + rOther;
      gTotalScmo = gTotalScmo + rScmo;
      gTotalCentreM = gTotalCentreM + rCentreM;
      gTotalCountryM = gTotalCountryM + rCountryM;
      //gTotal = gTotal + parseInt(tmpList[i].total);
      gTotal = gTotalOtamagushi + gTotalOkiyome + gTotalGoshintai + gTotalAncestors + gTotalOmitama + gTotalGoshugo + gTotalOther + gTotalScmo;
      gTotal_1 = gTotalCentreM + gTotalCountryM;
      if (tmpList[i].offerChildCentre == offerChildCenter) {
        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }

        dateWiseOtamagushi = dateWiseOtamagushi + rOtamagushi;
        dateWiseOkiyome = dateWiseOkiyome + rOkiyome;
        dateWiseGoshintai = dateWiseGoshintai + rGoshintai;
        dateWiseAncestors = dateWiseAncestors + rAncestors;
        dateWiseOmitama = dateWiseOmitama + rOmitama;
        dateWiseGoshugo = dateWiseGoshugo + rGoshugo;
        dateWiseOther = dateWiseOther + rOther;
        dateWiseScmo = dateWiseScmo + rScmo;
        dateWiseCountryM = dateWiseCountryM + rCountryM;
        dateWiseCentreM = dateWiseCentreM + rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;
        //dateWiseTotal = dateWiseTotal + parseInt(tmpList[i].total);

      } else {
        var dateChangedObj = {
          SrNo: iCount + 1,
          Centre: centre,
          OfferingDate: offerDateDD,
          SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
          SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
          CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
          CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
          CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
          CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
          Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
          Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
          Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
          Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
          Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
          Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
          Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
          Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
          Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
          Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
          Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
          Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
          Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
          Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
          Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
          Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
        }
        tmpArray.push(dateChangedObj);
        iCount = iCount + 1;
        //Reset
        dateWiseScmoCnt = 0;
        dateWiseCountryMCnt = 0;
        dateWiseCentreMCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOtamagushiCnt = 0;
        dateWiseOkiyomeCnt = 0;
        dateWiseGoshintaiCnt = 0;
        dateWiseAncestorsCnt = 0;
        dateWiseOmitamaCnt = 0;
        dateWiseGoshugoCnt = 0;
        dateWiseOtherCnt = 0;

        dateWiseOtamagushi = rOtamagushi;
        dateWiseOkiyome = rOkiyome;
        dateWiseGoshintai = rGoshintai;
        dateWiseAncestors = rAncestors;
        dateWiseOmitama = rOmitama;
        dateWiseGoshugo = rGoshugo;
        dateWiseOther = rOther;
        dateWiseScmo = rScmo;
        dateWiseCountryM = rCountryM;
        dateWiseCentreM = rCentreM;
        dateWiseCouCenTotal = dateWiseCountryM + dateWiseCentreM;
        dateWiseTotal = dateWiseOtamagushi + dateWiseOkiyome + dateWiseGoshintai + dateWiseAncestors + dateWiseOmitama + dateWiseGoshugo + dateWiseOther + dateWiseScmo;
        // iCount = 1; 
        offerChildCenter = "";
        offerChildCenter = tmpList[i].offerChildCentre.trim();

        centre = tmpList[i].offerMainCentre.substr(0, 3) + "/" + tmpList[i].offerChildCentre.substr(0, 3);
        //centre = tmpList[i].offerMainCentre + "/" + tmpList[i].offerChildCentre;
        offerDateDD = pipe.transform(tmpList[i].offerDate, 'MMM-yyyy');

        if (rCountryM > 0) {
          dateWiseCountryMCnt = dateWiseCountryMCnt + 1;
          gTotalCountryMCnt = gTotalCountryMCnt + 1;
        }
        if (rCentreM > 0) {
          dateWiseCentreMCnt = dateWiseCentreMCnt + 1;
          gTotalCentreMCnt = gTotalCentreMCnt + 1;
        }
        if (rScmo > 0) {
          dateWiseScmoCnt = dateWiseScmoCnt + 1;
          gTotalScmoCnt = gTotalScmoCnt + 1;
        }
        if (rOtamagushi > 0) {
          dateWiseOtamagushiCnt = dateWiseOtamagushiCnt + 1;
          gTotalOtamagushiCnt = gTotalOtamagushiCnt + 1;
        }
        if (rOkiyome > 0) {
          dateWiseOkiyomeCnt = dateWiseOkiyomeCnt + 1;
          gTotalOkiyomeCnt = gTotalOkiyomeCnt + 1;
        }
        if (rGoshintai > 0) {
          dateWiseGoshintaiCnt = dateWiseGoshintaiCnt + 1;
          gTotalGoshintaiCnt = gTotalGoshintaiCnt + 1;
        }
        if (rAncestors > 0) {
          dateWiseAncestorsCnt = dateWiseAncestorsCnt + 1;
          gTotalAncestorsCnt = gTotalAncestorsCnt + 1;
        }
        if (rOmitama > 0) {
          dateWiseOmitamaCnt = dateWiseOmitamaCnt + 1;
          gTotalOmitamaCnt = gTotalOmitamaCnt + 1;
        }
        if (rGoshugo > 0) {
          dateWiseGoshugoCnt = dateWiseGoshugoCnt + 1;
          gTotalGoshugoCnt = gTotalGoshugoCnt + 1;
        }
        if (rOther > 0) {
          dateWiseOtherCnt = dateWiseOtherCnt + 1;
          gTotalOtherCnt = gTotalOtherCnt + 1;
        }
      }
    }// for

    var lastRecObjCnt = {
      SrNo: iCount + 1,
      Centre: centre,
      OfferingDate: offerDateDD,
      SCMO: ((dateWiseScmo == 0) ? "" : dateWiseScmo),
      SCMO_cnt: ((dateWiseScmoCnt == 0) ? "" : dateWiseScmoCnt),
      CountryM: ((dateWiseCountryM == 0) ? "" : dateWiseCountryM),
      CountryM_cnt: ((dateWiseCountryMCnt == 0) ? "" : dateWiseCountryMCnt),
      CentreM: ((dateWiseCentreM == 0) ? "" : dateWiseCentreM),
      CentreM_cnt: ((dateWiseCentreMCnt == 0) ? "" : dateWiseCentreMCnt),
      Otamagushi: ((dateWiseOtamagushi == 0) ? "" : dateWiseOtamagushi),
      Otamagushi_cnt: ((dateWiseOtamagushiCnt == 0) ? "" : dateWiseOtamagushiCnt),
      Okiyome: ((dateWiseOkiyome == 0) ? "" : dateWiseOkiyome),
      Okiyome_cnt: ((dateWiseOkiyomeCnt == 0) ? "" : dateWiseOkiyomeCnt),
      Goshintai: ((dateWiseGoshintai == 0) ? "" : dateWiseGoshintai),
      Goshintai_cnt: ((dateWiseGoshintaiCnt == 0) ? "" : dateWiseGoshintaiCnt),
      Ancestors: ((dateWiseAncestors == 0) ? "" : dateWiseAncestors),
      Ancestors_cnt: ((dateWiseAncestorsCnt == 0) ? "" : dateWiseAncestorsCnt),
      Omitama: ((dateWiseOmitama == 0) ? "" : dateWiseOmitama),
      Omitama_cnt: ((dateWiseOmitamaCnt == 0) ? "" : dateWiseOmitamaCnt),
      Goshugo: ((dateWiseGoshugo == 0) ? "" : dateWiseGoshugo),
      Goshugo_cnt: ((dateWiseGoshugoCnt == 0) ? "" : dateWiseGoshugoCnt),
      Other: ((dateWiseOther == 0) ? "" : dateWiseOther),
      Other_cnt: ((dateWiseOtherCnt == 0) ? "" : dateWiseOtherCnt),
      Total: ((dateWiseTotal == 0) ? "" : dateWiseTotal),
      Total_1: ((dateWiseCouCenTotal == 0) ? "" : dateWiseCouCenTotal),
    }
    tmpArray.push(lastRecObjCnt);

    var grandTotalObj = {
      SrNo: "Grand Total",
      OfferingDate: "",
      Centre: "",
      SCMO: ((gTotalScmo == 0) ? "" : gTotalScmo),
      SCMO_cnt: ((gTotalScmoCnt == 0) ? "" : gTotalScmoCnt),
      CountryM: ((gTotalCountryM == 0) ? "" : gTotalCountryM),
      CountryM_cnt: ((gTotalCountryMCnt == 0) ? "" : gTotalCountryMCnt),
      CentreM: ((gTotalCentreM == 0) ? "" : gTotalCentreM),
      CentreM_cnt: ((gTotalCentreMCnt == 0) ? "" : gTotalCentreMCnt),
      Otamagushi: ((gTotalOtamagushi == 0) ? "" : gTotalOtamagushi),
      Otamagushi_cnt: ((gTotalOtamagushiCnt == 0) ? "" : gTotalOtamagushiCnt),
      Okiyome: ((gTotalOkiyome == 0) ? "" : gTotalOkiyome),
      Okiyome_cnt: ((gTotalOkiyomeCnt == 0) ? "" : gTotalOkiyomeCnt),
      Goshintai: ((gTotalGoshintai == 0) ? "" : gTotalGoshintai),
      Goshintai_cnt: ((gTotalGoshintaiCnt == 0) ? "" : gTotalGoshintaiCnt),
      Ancestors: ((gTotalAncestors == 0) ? "" : gTotalAncestors),
      Ancestors_cnt: ((gTotalAncestorsCnt == 0) ? "" : gTotalAncestorsCnt),
      Omitama: ((gTotalOmitama == 0) ? "" : gTotalOmitama),
      Omitama_cnt: ((gTotalOmitamaCnt == 0) ? "" : gTotalOmitamaCnt),
      Goshugo: ((gTotalGoshugo == 0) ? "" : gTotalGoshugo),
      Goshugo_cnt: ((gTotalGoshugoCnt == 0) ? "" : gTotalGoshugoCnt),
      Other: ((gTotalOther == 0) ? "" : gTotalOther),
      Other_cnt: ((gTotalOtherCnt == 0) ? "" : gTotalOtherCnt),
      Total: ((gTotal == 0) ? "" : gTotal),
      Total_1: ((gTotal_1 == 0) ? "" : gTotal_1)
    }

    tmpArray.push(grandTotalObj);

    this.centrewiseShidobu = tmpArray;
    //console.log(this.centrewiseShidobu);
  }

  popuplateMemberWiseCashBankTotal(data) {
    var tmpArray = new Array();
    var groupByArray = new Array();
    var tmpList = data;
    var totalAmt = 0;
    var memberID = tmpList[0].memberID;
    var memWiseTot = 0;
    var iCount = 1;
    var cashTotal = 0;
    var bankTotal = 0;
    var gCashTotal = 0;
    var gBankTotal = 0;
    var gDiffTotal = 0;
    var gTotal = 0;

    for (var i = 0; i < tmpList.length; i++) {
      cashTotal = 0;
      bankTotal = 0;
      if (this.isEmpty(tmpList[i].chequeNo)) {
        cashTotal = parseInt(tmpList[i].total);
      } else {
        bankTotal = parseInt(tmpList[i].total);
      }
      totalAmt = totalAmt + parseInt(tmpList[i].total);
      gTotal = gTotal + parseInt(tmpList[i].total);

      if (tmpList[i].memberID == memberID) {
        memWiseTot = memWiseTot + parseInt(tmpList[i].total);
        var tmp = {
          SrNo: iCount,
          MemberID: tmpList[i].memberID,
          MemberName: tmpList[i].memberName,
          Total: tmpList[i].total,
          CashTotal: cashTotal,
          BankTotal: bankTotal
        }
        tmpArray.push(tmp);
      } else {
        var tmpArrayCashTotal = 0;
        var tmpArrayBankTotal = 0;
        var tmpArrayDiffTotal = 0;
        var tmpArrayMemTotal = 0;
        for (var k = 0; k < tmpArray.length; k++) {
          tmpArrayCashTotal = tmpArrayCashTotal + parseInt(tmpArray[k].CashTotal);
          tmpArrayBankTotal = tmpArrayBankTotal + parseInt(tmpArray[k].BankTotal);
          tmpArrayMemTotal = tmpArrayMemTotal + parseInt(tmpArray[k].Total);
          gCashTotal = gCashTotal + parseInt(tmpArray[k].CashTotal);
          gBankTotal = gBankTotal + parseInt(tmpArray[k].BankTotal);
        }
        tmpArrayDiffTotal = tmpArrayCashTotal + tmpArrayBankTotal;
        tmpArrayDiffTotal = tmpArrayDiffTotal - tmpArrayMemTotal;
        gDiffTotal = gDiffTotal + tmpArrayDiffTotal;
        var tmpMemberArray = [];
        tmpMemberArray = this.allMembersArrayData;
        tmpMemberArray = tmpMemberArray.filter(function (item) {
          return item.memberID == tmpArray[0].MemberID;
        });
        var memberCentre = tmpMemberArray[0].mainCentre.substr(0, 3);
        if (tmpMemberArray[0].childCentre != "") {
          memberCentre = memberCentre + "/" + tmpMemberArray[0].childCentre.substr(0, 3);
        }
        if (tmpMemberArray[0].subCentre != "") {
          memberCentre = memberCentre + "/" + tmpMemberArray[0].subCentre.substr(0, 3);
        }
        var memberObj = {
          SrNo: iCount,
          MemberID: tmpArray[0].MemberID,
          MemberName: tmpArray[0].MemberName,
          Total: memWiseTot,
          CashTotal: tmpArrayCashTotal,
          BankTotal: tmpArrayBankTotal,
          DiffTotal: tmpArrayDiffTotal,
          Gender: tmpMemberArray[0].gender,
          Status: tmpMemberArray[0].status,
          Centre: memberCentre,
          Phone: tmpMemberArray[0].mobileNo,
          Address1: tmpMemberArray[0].addressL1,
          Address2: tmpMemberArray[0].addressL2,
          Address3: tmpMemberArray[0].addressL3,
          City: tmpMemberArray[0].city,
          ZipCode: tmpMemberArray[0].zipCode,
          AadhaarNo: tmpMemberArray[0].aadhaarNo,
          PANNo: tmpMemberArray[0].panNo
        }

        groupByArray.push(memberObj);
        tmpArray = new Array();
        iCount = iCount + 1;


        memberID = tmpList[i].memberID;
        memWiseTot = 0;
        memWiseTot = memWiseTot + parseInt(tmpList[i].total);
        cashTotal = 0;
        bankTotal = 0;
        if (this.isEmpty(tmpList[i].chequeNo)) {
          cashTotal = parseInt(tmpList[i].total);
        } else {
          bankTotal = parseInt(tmpList[i].total);
        }

        var tmp = {
          SrNo: iCount,
          MemberID: tmpList[i].memberID,
          MemberName: tmpList[i].memberName,
          Total: tmpList[i].total,
          CashTotal: cashTotal,
          BankTotal: bankTotal
        }
        tmpArray.push(tmp);
      }
    }
    var tmpArrayCashTotal = 0;
    var tmpArrayBankTotal = 0;
    var tmpArrayDiffTotal = 0;
    var tmpArrayMemTotal = 0;
    for (var k = 0; k < tmpArray.length; k++) {
      tmpArrayCashTotal = tmpArrayCashTotal + parseInt(tmpArray[k].CashTotal);
      tmpArrayBankTotal = tmpArrayBankTotal + parseInt(tmpArray[k].BankTotal);
      tmpArrayMemTotal = tmpArrayMemTotal + parseInt(tmpArray[k].Total);
      gCashTotal = gCashTotal + parseInt(tmpArray[k].CashTotal);
      gBankTotal = gBankTotal + parseInt(tmpArray[k].BankTotal);
    }
    tmpArrayDiffTotal = tmpArrayCashTotal + tmpArrayBankTotal;
    tmpArrayDiffTotal = tmpArrayDiffTotal - tmpArrayMemTotal;
    gDiffTotal = gDiffTotal + tmpArrayDiffTotal;
    var tmpMemberArray = [];
    tmpMemberArray = this.allMembersArrayData;
    tmpMemberArray = tmpMemberArray.filter(function (item) {
      return item.memberID == tmpArray[0].MemberID;
    });
    var memberCentre = tmpMemberArray[0].mainCentre.substr(0, 3);
    if (tmpMemberArray[0].childCentre != "") {
      memberCentre = memberCentre + "/" + tmpMemberArray[0].childCentre.substr(0, 3);
    }
    if (tmpMemberArray[0].subCentre != "") {
      memberCentre = memberCentre + "/" + tmpMemberArray[0].subCentre.substr(0, 3);
    }

    var memberObj1 = {
      SrNo: iCount,
      MemberID: tmpArray[0].MemberID,
      MemberName: tmpArray[0].MemberName,
      Total: memWiseTot,
      CashTotal: tmpArrayCashTotal,
      BankTotal: tmpArrayBankTotal,
      DiffTotal: tmpArrayDiffTotal,
      Gender: tmpMemberArray[0].gender,
      Status: tmpMemberArray[0].status,
      Centre: memberCentre,
      Phone: tmpMemberArray[0].mobileNo,
      Address1: tmpMemberArray[0].addressL1,
      Address2: tmpMemberArray[0].addressL2,
      Address3: tmpMemberArray[0].addressL3,
      City: tmpMemberArray[0].city,
      ZipCode: tmpMemberArray[0].zipCode,
      AadhaarNo: tmpMemberArray[0].aadhaarNo,
      PANNo: tmpMemberArray[0].panNo
    }
    groupByArray.push(memberObj1);
    var grandTotalObj = {
      SrNo: "",
      MemberID: "",
      MemberName: "",
      Total: gTotal,
      CashTotal: gCashTotal,
      BankTotal: gBankTotal,
      DiffTotal: gDiffTotal,
      Gender: "",
      Status: "",
      Centre: "",
      Phone: "",
      Address1: "",
      Address2: "",
      Address3: "",
      City: "",
      ZipCode: "",
      AadhaarNo: "",
      PANNo: ""
    }
    groupByArray.push(grandTotalObj);
    this.memwiseCashBankTotal = groupByArray;
  }
  exportAsMemWiseBankCash() {
    var pipe = new DatePipe('en-US');
    this.excelService.exportAsExcelFile(this.memwiseCashBankTotal, 'MemWiseCashBankTotal' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
  }
  //MemberWise Cash Bank Total Completed

  GetLastSCMORecords(form: NgForm) {
    if (form.value.childCentreDetails == undefined) {
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
    } else {
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
    }

    if (form.value.mainCentreDetails == undefined) {
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName']
    } else {
      form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if (form.value.subCentreDetails == undefined) {
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    } else {
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    }
    var pipe = new DatePipe('en-US')
    var d = new Date();

    form.value.offerDateStart = pipe.transform(d, 'MM/dd/yyyy');
    form.value.offerDateEnd = pipe.transform(d, 'MM/dd/yyyy');
    form.value.memberID = "";
    form.value.status = "";
    form.value.report = "LASTSCMO";

    this.offerService.getOffers(form.value).subscribe((res: any) => {
      if (res.status == 1) {
        do {
          console.log(res.result);
          this.popuplateOtherOffering(res.result);

        } while (false);
      } else {
        alert(res.message);
        this.onClear();
      }
    });
  }

  popuplateOtherOffering(data) {
    var tmpArray = new Array();
    var tmpSearched = new Array();
    var pipe = new DatePipe('en-US');
    var tmpList = data;
    var rOtamagushi = 0;
    var rOkiyome = 0;
    var rGoshintai = 0;
    var rAncestors = 0;
    var rOmitama = 0;
    var rGoshugo = 0;
    var rOther = 0;
    var rCountryM = 0;
    var rCentreM = 0;
    var rScmo = 0;
    for (var i = 0; i < tmpList.length; i++) {
      var memberCentre = tmpList[i].mainCentre + "/" + tmpList[i].childCentre + "/" + tmpList[i].subCentre;
      var processing = tmpList[i].offerMainCentre + "/" + tmpList[i].offerChildCentre + "/" + tmpList[i].offerSubCentre;
      rOtamagushi = (parseInt(tmpList[i].otamagushi) > 0 ? parseInt(tmpList[i].otamagushi) : 0);
      rOkiyome = (parseInt(tmpList[i].okiyome) > 0 ? parseInt(tmpList[i].okiyome) : 0);
      rGoshintai = (parseInt(tmpList[i].goshintai) > 0 ? parseInt(tmpList[i].goshintai) : 0);
      rAncestors = (parseInt(tmpList[i].ancestors) > 0 ? parseInt(tmpList[i].ancestors) : 0);
      rOmitama = (parseInt(tmpList[i].omitama) > 0 ? parseInt(tmpList[i].omitama) : 0);
      rGoshugo = (parseInt(tmpList[i].goshugo) > 0 ? parseInt(tmpList[i].goshugo) : 0)
      rOther = (parseInt(tmpList[i].other) > 0 ? parseInt(tmpList[i].other) : 0);
      rCountryM = (parseInt(tmpList[i].countryM) > 0 ? parseInt(tmpList[i].countryM) : 0);
      rCentreM = (parseInt(tmpList[i].centreM) > 0 ? parseInt(tmpList[i].centreM) : 0);
      rScmo = (parseInt(tmpList[i].scmo) > 0 ? parseInt(tmpList[i].scmo) : 0);

      var tmp = {
        SrNo: i + 1,
        otherOfferingID: tmpList[i].otherOfferingID,
        ProcessingDate: tmpList[i].processingDate,
        OfferingDate: tmpList[i].offerDate,
        MemberName: tmpList[i].memberName,
        Actual: memberCentre,
        Processing: processing,
        SlipNo: tmpList[i].slipNo,
        Status: tmpList[i].status,
        SCMOMonth: pipe.transform(tmpList[i].forMonth, 'MMM-yyyy'),
        SCMO: rScmo,
        CountryM: rCountryM,
        CentreM: rCentreM,
        Otamagushi: rOtamagushi,
        Okiyome: rOkiyome,
        Goshintai: rGoshintai,
        Ancestors: rAncestors,
        Omitama: rOmitama,
        Goshugo: rGoshugo,
        Other: rOther,
        Total: tmpList[i].total,
      }
      tmpArray.push(tmp);
    }
    this.otherOffering = tmpArray;
  }

  exportAsXLSXDO() {
    var pipe = new DatePipe('en-US');
      var tmpArray = new Array();
      for (var i = 0; i < this.otherOffering.length; i++) {
        var tmp = {
          SrNo: this.otherOffering[i].SrNo,
          ProcessingDate: pipe.transform(this.otherOffering[i].ProcessingDate, 'dd/MM/yyyy'),
          OfferingDate: pipe.transform(this.otherOffering[i].OfferingDate, 'dd/MM/yyyy'),
          MemberName: this.otherOffering[i].MemberName,
          SlipNo: this.otherOffering[i].SlipNo,
          Status: this.otherOffering[i].Status,
          OTA: this.otherOffering[i].Otamagushi,
          OKI: this.otherOffering[i].Okiyome,
          Ghoshugo: this.otherOffering[i].Goshugo,
          SCMOMonth: pipe.transform(this.otherOffering[i].SCMOMonth, 'MMM-yyyy'),
          SCMO: this.otherOffering[i].SCMO,
          CountryM: this.otherOffering[i].CountryM,
          CentreM: this.otherOffering[i].CentreM,
          GoshApp: this.otherOffering[i].Goshintai,
          AncAlt: this.otherOffering[i].Ancestors,
          Omitama: this.otherOffering[i].Omitama,
          Others: this.otherOffering[i].Other,
          Total: this.otherOffering[i].Total
        }
        tmpArray.push(tmp);
    }
    this.excelService.exportAsExcelFile(tmpArray, 'LASTSCMORecords');
  }

  onSearch(form: NgForm) {
    this.onClear();

    var pipe = new DatePipe('en-US')
    if (form.value.offerDateStart != undefined) {
      form.value.offerDateStart = pipe.transform(form.value.offerDateStart, 'MM/dd/yyyy')
      form.value.offerDateEnd = pipe.transform(form.value.offerDateEnd, 'MM/dd/yyyy')
    }

    if (form.value.tillDate != undefined) {
      form.value.tillDate = pipe.transform(form.value.tillDate, 'MM/dd/yyyy');
    }

    if (form.value.childCentreDetails == undefined) {
      form.value.offerChildCentre = this.selectedChildCentreObj['childCentreName'];
    } else {
      form.value.offerChildCentre = form.value.childCentreDetails.childCentreName;
    }

    if (form.value.mainCentreDetails == undefined) {
      form.value.offerMainCentre = this.selectedMainCentreObj['mainCentreName']
    } else {
      form.value.offerMainCentre = form.value.mainCentreDetails.mainCentreName;
    }

    if (form.value.subCentreDetails == undefined) {
      form.value.offerSubCentre = this.selectedSubCentreObj['subCentreName'];
    } else {
      form.value.offerSubCentre = form.value.subCentreDetails.subCentreName;
    }

    if (form.value.statusDetails == undefined) {
      form.value.status = this.selectedStatusObj['statusName']
    } else {
      form.value.status = form.value.statusDetails.statusName;
    }

    if (this.formModel.report == "MONTHWISESHIDOBU") {
      var d = new Date(form.value.forMonth);
      if (d.getMonth() <= 2) {
        form.value.offerDateStart = new Date(Date.UTC(d.getFullYear()-1, 3, 1));
        form.value.offerDateEnd = new Date(Date.UTC(d.getFullYear(), 3, 0));
        this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), 3, 1));
        this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear() + 1, 3, 0));
      } else {
        form.value.offerDateStart = new Date(Date.UTC(d.getFullYear(), 3, 1));
        form.value.offerDateEnd = new Date(Date.UTC(d.getFullYear() + 1, 3, 0));
        this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), 3, 1));
        this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear() + 1, 3, 0));
      }
    }
    if (this.formModel.report == "CENTREWISESHIDOBU") {
      var d = new Date(form.value.forMonth);
      form.value.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
      form.value.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
      this.formModel.offerDateStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
      this.formModel.offerDateEnd = new Date(Date.UTC(d.getFullYear(), d.getMonth() + 1, 0));
    }

    this.offerService.getOffers(form.value).subscribe((res: any) => {
      if (res.status == 1) {
        do {
          if (this.formModel.report == "MONTHWISESHIDOBU") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.populateSHIDOBUMonthWise(res.result);
          } else if (this.formModel.report == "CENTREWISESHIDOBU") {
            this.paymentOpt = form.value.paymentOptionDetails.type;
            this.populateSHIDOBUCentreWise(res.result);
          } else if (this.formModel.report == "MWCBT") {
            this.popuplateMemberWiseCashBankTotal(res.result);
          }
        } while (false);
      } else {
        alert(res.message);
        this.onClear();
      }
    });
  }

  onClear() {
    do {
      if (this.formModel.report == "MONTHWISESHIDOBU") {
        this.monthwiseShidobu = [];
      } else if (this.formModel.report == "CENTREWISESHIDOBU") {
        this.centrewiseShidobu = [];
      } else if (this.formModel.report == "MWCBT") {
        this.memwiseCashBankTotal = [];
      }
    } while (false);
  }
  exportAsXLSXSHIDOBUMonthWise() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "MONTHWISESHIDOBU") {
      var tmpArray = new Array();
      //OfferingDate: pipe.transform(this.datewiseOffering[i].OfferingDate, 'dd/MM/yyyy'),
      for (var i = 0; i < this.monthwiseShidobu.length; i++) {
        var tmp = {
          Centre: this.monthwiseShidobu[i].Centre,
          Month_Year: this.monthwiseShidobu[i].OfferingDate,
          OtamagushiNo: this.monthwiseShidobu[i].Otamagushi_cnt,
          OtamagushiAmt: this.monthwiseShidobu[i].Otamagushi,
          PurificationNo: this.monthwiseShidobu[i].Okiyome_cnt,
          PurificationAmt: this.monthwiseShidobu[i].Okiyome,
          GoshintaiNo: this.monthwiseShidobu[i].Goshugo_cnt,
          GoshintaiAmt: this.monthwiseShidobu[i].Goshugo,
          AncestorsAltarNo: this.monthwiseShidobu[i].Ancestors_cnt,
          AncestorsAltarAmt: this.monthwiseShidobu[i].Ancestors,
          PrimaryCourseNo: "",
          PrimaryCourseAmt: "",
          OmitanaNo: this.monthwiseShidobu[i].Omitama_cnt,
          OmitanaAmt: this.monthwiseShidobu[i].Omitama,
          DivineProtectionNo: this.monthwiseShidobu[i].Goshintai_cnt,
          DivineProtectionAmt: this.monthwiseShidobu[i].Goshintai,
          SpiritualCordMaintenanceNo: this.monthwiseShidobu[i].SCMO_cnt,
          SpiritualCordMaintenanceAmt: this.monthwiseShidobu[i].SCMO,
          ReviewersNo: "",
          ReviewersAmt: "",
          OtherNo: this.monthwiseShidobu[i].Other_cnt,
          OtherAMT: this.monthwiseShidobu[i].Other,
          Total: this.monthwiseShidobu[i].Total,
          CountryNo: this.monthwiseShidobu[i].CountryM_cnt,
          CountryAMT: this.monthwiseShidobu[i].CountryM,
          CentreNo: this.monthwiseShidobu[i].CentreM_cnt,
          CentreAMT: this.monthwiseShidobu[i].CentreM,
          SuzaNo: "",
          SuzaAmt: "",
          Total_: this.monthwiseShidobu[i].Total_1,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'M-wise_Sum' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAsXLSXSHIDOBUCentreWise() {
    var pipe = new DatePipe('en-US');
    if (this.formModel.report == "CENTREWISESHIDOBU") {
      var tmpArray = new Array();
      //OfferingDate: pipe.transform(this.datewiseOffering[i].OfferingDate, 'dd/MM/yyyy'),
      for (var i = 0; i < this.centrewiseShidobu.length; i++) {
        var tmp = {
          Centre: this.centrewiseShidobu[i].Centre,
          Month_Year: this.centrewiseShidobu[i].OfferingDate,
          OtamagushiNo: this.centrewiseShidobu[i].Otamagushi_cnt,
          OtamagushiAmt: this.centrewiseShidobu[i].Otamagushi,
          PurificationNo: this.centrewiseShidobu[i].Okiyome_cnt,
          PurificationAmt: this.centrewiseShidobu[i].Okiyome,
          GoshintaiNo: this.centrewiseShidobu[i].Goshugo_cnt,
          GoshintaiAmt: this.centrewiseShidobu[i].Goshugo,
          AncestorsAltarNo: this.centrewiseShidobu[i].Ancestors_cnt,
          AncestorsAltarAmt: this.centrewiseShidobu[i].Ancestors,
          PrimaryCourseNo: "",
          PrimaryCourseAmt: "",
          OmitanaNo: this.centrewiseShidobu[i].Omitama_cnt,
          OmitanaAmt: this.centrewiseShidobu[i].Omitama,
          DivineProtectionNo: this.centrewiseShidobu[i].Goshintai_cnt,
          DivineProtectionAmt: this.centrewiseShidobu[i].Goshintai,
          SpiritualCordMaintenanceNo: this.centrewiseShidobu[i].SCMO_cnt,
          SpiritualCordMaintenanceAmt: this.centrewiseShidobu[i].SCMO,
          ReviewersNo: "",
          ReviewersAmt: "",
          OtherNo: this.centrewiseShidobu[i].Other_cnt,
          OtherAMT: this.centrewiseShidobu[i].Other,
          Total: this.centrewiseShidobu[i].Total,
          CountryNo: this.centrewiseShidobu[i].CountryM_cnt,
          CountryAMT: this.centrewiseShidobu[i].CountryM,
          CentreNo: this.centrewiseShidobu[i].CentreM_cnt,
          CentreAMT: this.centrewiseShidobu[i].CentreM,
          SuzaNo: "",
          SuzaAmt: "",
          Total_: this.centrewiseShidobu[i].Total_1,
        }
        tmpArray.push(tmp);
      }
      this.excelService.exportAsExcelFile(tmpArray, 'Ch Cen-wise Sum' + "_" + pipe.transform(this.formModel.offerDateStart, 'dd/MM/yyyy') + "_" + pipe.transform(this.formModel.offerDateEnd, 'dd/MM/yyyy'));
    }
  }
  exportAllMasterData() {
    this.formModel.displayMessage = "Exporting data in progress...";
    var pipe = new DatePipe('en-US');
    var d = new Date();
    var tmpArray = new Array();
    var i = 0;
    for (i = 0; i < this.glbmainCentreDetails.length; i++) {
      var tmp = {
        mainCentreID: this.glbmainCentreDetails[i].mainCentreID,
        MainCentre: this.glbmainCentreDetails[i].mainCentreName
      }
      tmpArray.push(tmp);
    }
    this.excelService.exportAsExcelFile(tmpArray, 'MainCentre' + "_" + pipe.transform(d, 'dd/MM/yyyy'));
    var tmpArray2 = new Array();
    i = 0;
    for (i = 0; i < this.glbchildCentreDetails.length; i++) {
      var tmp1 = {
        mainCentreID: this.glbchildCentreDetails[i].mainCentreID,
        MainCentre: this.glbchildCentreDetails[i].mainCentreName,
        childCentreID: this.glbchildCentreDetails[i].childCentreID,
        childCentreName: this.glbchildCentreDetails[i].childCentreName
      }
      tmpArray2.push(tmp1);
    }
    this.excelService.exportAsExcelFile(tmpArray2, 'ChildCentre' + "_" + pipe.transform(d, 'dd/MM/yyyy'));

    var tmpArray3 = new Array();
    i = 0;
    for (i = 0; i < this.glbsubCentreDetails.length; i++) {
      var tmp2 = {
        mainCentreID: this.glbsubCentreDetails[i].mainCentreID,
        MainCentre: this.glbsubCentreDetails[i].mainCentreName,
        childCentreID: this.glbsubCentreDetails[i].childCentreID,
        childCentreName: this.glbsubCentreDetails[i].childCentreName,
        subCentreID: this.glbsubCentreDetails[i].subCentreID,
        subCentreName: this.glbsubCentreDetails[i].subCentreName
      }
      tmpArray3.push(tmp2);
    }
    this.excelService.exportAsExcelFile(tmpArray3, 'SubCentre' + "_" + pipe.transform(d, 'dd/MM/yyyy'));

    this.statusDetails = this.dataSharingService.getStatusData();
    var tmpArray4 = new Array();
    i = 0;
    for (i = 0; i < this.statusDetails.length; i++) {
      var tmp3 = {
        statusID: this.statusDetails[i].statusID,
        statusName: this.statusDetails[i].statusName
      }
      tmpArray4.push(tmp3);
    }
    this.excelService.exportAsExcelFile(tmpArray4, 'Status' + "_" + pipe.transform(d, 'dd/MM/yyyy'));

    //this.excelService.exportAsExcelFileMultiple(tmpArray, tmpArray2,tmpArray3,tmpArray4, 'AllMasters' + "_" + pipe.transform(d, 'dd/MM/yyyy'));

    /* Export to CSV adds double quotes
    this.excelService.exportAsCsv(tmpArray, "mainCentre" + "_" + pipe.transform(d, 'dd/MM/yyyy') + ".csv");
    this.excelService.exportAsCsv(tmpArray2, "childCentre" + "_" + pipe.transform(d, 'dd/MM/yyyy') + ".csv");
    this.excelService.exportAsCsv(tmpArray3, "subCentre" + "_" + pipe.transform(d, 'dd/MM/yyyy') + ".csv");
    this.excelService.exportAsCsv(tmpArray4, "status" + "_" + pipe.transform(d, 'dd/MM/yyyy') + ".csv");
    */
    this.formModel.displayMessage = "Exporting data completed.";
  }
  exportAllMemberData1() {
    this.formModel.displayMessage = "Exporting data in progress...";
    var pipe = new DatePipe('en-US');
    var d = new Date(); 
    var tmpArray2 = [];
    var tmpArray = new Array();
    this.offerService.getAllMembersListing().subscribe((res: any) => {
      if (res != null && res.status == 1 && res.result != null) {
        tmpArray2 = res.result;
        for (var i = 0; i < tmpArray2.length; i++) {
          var tmp3 = {
            AadhaarNo: tmpArray2[i].aadhaarNo,
            AddressL1: tmpArray2[i].addressL1,
            AddressL2: tmpArray2[i].addressL2,
            AddressL3: tmpArray2[i].addressL3,
            AdvKDate: (tmpArray2[i].advKDate == null ? '' : pipe.transform(tmpArray2[i].advKDate, 'yyyy/MM/dd')),
            AdvKNo: tmpArray2[i].advKNo,
            AdvKPlace: tmpArray2[i].advKPlace,
            Akyn: tmpArray2[i].akyn,
            AlternateNo: tmpArray2[i].alternateNo,
            BirthDate: (tmpArray2[i].birthDate == null ? '' : pipe.transform(tmpArray2[i].birthDate, 'yyyy/MM/dd')),
            CentreM: tmpArray2[i].centreM,
            ChildCentre: tmpArray2[i].childCentre,
            City: tmpArray2[i].city,
            Comment: tmpArray2[i].comment,
            Country: tmpArray2[i].country,
            CountryM: tmpArray2[i].countryM,
            FirstName: tmpArray2[i].firstName,
            ForMonth: tmpArray2[i].forMonth,
            Gender: tmpArray2[i].gender,
            GroupLeader: tmpArray2[i].groupLeader,
            GroupUser: tmpArray2[i].groupUser,
            ID: tmpArray2[i].id,
            Ikyn: tmpArray2[i].ikyn,
            InAndOut: tmpArray2[i].inAndOut,
            IntKDate: (tmpArray2[i].intKDate == null ? '' : pipe.transform(tmpArray2[i].intKDate, 'yyyy/MM/dd')),
            IntKNo: tmpArray2[i].intKNo,
            IntKPlace: tmpArray2[i].intKPlace,
            IntroducerName: tmpArray2[i].introducerName,
            IsAdultYN: tmpArray2[i].isAdultYN,
            LastName: tmpArray2[i].lastName,
            MahikariTai: tmpArray2[i].mahikariTai,
            MainCentre: tmpArray2[i].mainCentre,
            MemberID: tmpArray2[i].memberID,
            MiddleName: tmpArray2[i].middleName,
            MobileNo: tmpArray2[i].mobileNo,
            Name: tmpArray2[i].name,
            PanNo: tmpArray2[i].panNo,
            Pkyn: tmpArray2[i].pkyn,
            PreFirstName: tmpArray2[i].preFirstName,
            PreLastName: tmpArray2[i].preLastName,
            PreMiddleName: tmpArray2[i].preMiddleName,
            PreviousName: tmpArray2[i].previousName,
            PriKDate: (tmpArray2[i].priKDate == null ? '' : pipe.transform(tmpArray2[i].priKDate, 'yyyy/MM/dd')),
            PriKID: tmpArray2[i].priKID,
            PriKLecturer: tmpArray2[i].priKLecturer,
            PriKNo: tmpArray2[i].priKNo,
            PriKPlace: tmpArray2[i].priKPlace,
            Remark1: tmpArray2[i].remark1,
            Remark2: tmpArray2[i].remark2,
            Remark3: tmpArray2[i].remark3,
            Scmo: tmpArray2[i].scmo,
            State: tmpArray2[i].state,
            Status: tmpArray2[i].status,
            StatusDate: (tmpArray2[i].statusDate == null ? '' : pipe.transform(tmpArray2[i].statusDate, 'yyyy/MM/dd')),
            SubCentre: tmpArray2[i].subCentre,
            ZipCode: tmpArray2[i].zipCode
          }
          tmpArray.push(tmp3);
        }
        this.excelService.exportAsExcelFile(tmpArray, 'Member' + "_" + pipe.transform(d, 'dd/MM/yyyy'));
        this.formModel.displayMessage = "Exporting data completed...";
      }

    })
  }
  exportAllMemberData() {
    this.formModel.displayMessage = "Exporting data in progress...";
    var pipe = new DatePipe('en-US');
    var d = new Date();
    var tmpAllMemberData = this.dataSharingService.getAllMembersListing();
    this.excelService.exportAsExcelFile(tmpAllMemberData, 'Member' + "_" + pipe.transform(d, 'dd/MM/yyyy'));
    this.formModel.displayMessage = "Exporting data completed...";
    /*
    var tmpArray2 = [];
    this.offerService.getAllMembersListing().subscribe((res: any) => {
      if (res != null && res.status == 1 && res.result != null) {
        tmpArray2 = res.result;
        this.excelService.exportAsExcelFile(tmpArray2, 'Member' + "_" + pipe.transform(d, 'dd/MM/yyyy'));
        this.formModel.displayMessage = "Exporting data completed...";
      }
    })
    */
  }
  exportAllOfferingData1() {
    this.formModel.displayMessage = "Exporting data in progress...";
    this.offerService.getAllOtherOfferingData().subscribe((res: any) => {
      if (res != null && res.status == 1 && res.result != null) {
        var pipe = new DatePipe('en-US');
        var d = new Date(); 
        var tmpArray2 = [];
        var tmpArray = new Array();
        tmpArray2 = res.result;
        console.log(tmpArray2[0]);
        for (var i = 0; i < tmpArray2.length;i++ ) {
          var tmp3 = {
            aadhaarNo: tmpArray2[i].aadhaarNo,
            addUser: tmpArray2[i].addUser,
            addUserDate: (tmpArray2[i].addUserDate == null ? '' : pipe.transform(tmpArray2[i].addUserDate, 'yyyy/MM/dd')),
            addUserTime: tmpArray2[i].addUserTime,
            ancestors: tmpArray2[i].ancestors,
            bankBranchName: tmpArray2[i].bankBranchName,
            bankName: tmpArray2[i].bankName,
            centreM: tmpArray2[i].centreM,
            chequeDate: (tmpArray2[i].chequeDate == null ? '' : pipe.transform(tmpArray2[i].chequeDate, 'yyyy/MM/dd')),
            chequeNo: tmpArray2[i].chequeNo,
            childCentre: tmpArray2[i].childCentre,
            countryM: tmpArray2[i].countryM,
            forMonth: tmpArray2[i].forMonth,
            gender: tmpArray2[i].gender,
            goshintai: tmpArray2[i].goshintai,
            goshugo: tmpArray2[i].goshugo,
            groupLeader: tmpArray2[i].groupLeader,
            groupUser: tmpArray2[i].groupUser,
            isAdultYN: tmpArray2[i].isAdultYN,
            isMember: tmpArray2[i].isMember,
            mainCentre: tmpArray2[i].mainCentre,
            memberID: tmpArray2[i].memberID,
            memberName: tmpArray2[i].memberName,
            offerChildCentre: tmpArray2[i].offerChildCentre,
            offerDate: (tmpArray2[i].offerDate == null ? '' : pipe.transform(tmpArray2[i].offerDate, 'yyyy/MM/dd')),
            offerMainCentre: tmpArray2[i].offerMainCentre,
            offerSubCentre: tmpArray2[i].offerSubCentre,
            offeringFor: tmpArray2[i].offeringFor,
            okiyome: tmpArray2[i].okiyome,
            omitama: tmpArray2[i].omitama,
            otamagushi: tmpArray2[i].otamagushi,
            other: tmpArray2[i].other,
            otherOfferingID: tmpArray2[i].otherOfferingID,
            panNo: tmpArray2[i].panNo,
            processingDate: (tmpArray2[i].processingDate == null ? '' : pipe.transform(tmpArray2[i].processingDate, 'yyyy/MM/dd')),
            remarks: tmpArray2[i].remarks,
            scmo: tmpArray2[i].scmo,
            slipNo: tmpArray2[i].slipNo,
            status: tmpArray2[i].status,
            subCentre: tmpArray2[i].subCentre,
            total: tmpArray2[i].total, 
            updateUser: tmpArray2[i].updateUser,
            updateUserDate: (tmpArray2[i].updateUserDate == null ? '' : pipe.transform(tmpArray2[i].updateUserDate, 'yyyy/MM/dd')),
            updateUserTime: tmpArray2[i].updateUserTime
          }
          tmpArray.push(tmp3);
        }
        this.excelService.exportAsExcelFile(tmpArray, 'OtherOffering' + "_" + pipe.transform(d, 'dd/MM/yyyy'));
        //this.excelService.exportAsCsv(tmpArray, "OtherOffering" + "_" + pipe.transform(d, 'dd/MM/yyyy') + ".csv");
        this.formModel.displayMessage = "Exporting data completed...";
      }
    })

  }
  exportAllOfferingData() {
    do {

      if (this.dataSharingService.bOfferListingDataDownloaded == false) {
        alert("Downloading Offering data is still in-progess... Please try later ");
        this.offerService.getAllOtherOfferingData().subscribe((res: any) => {
          if (res.status == 1) {
            console.log("Data downloaded");
            this.dataSharingService.bOfferListingDataDownloaded = true;
            this.dataSharingService.setOfferListingData(res.result);
          }
        })
        break;
      }
      this.formModel.displayMessage = "Exporting data in progress...";
      var pipe = new DatePipe('en-US');
      var d = new Date();
      this.excelService.exportAsCsv(this.dataSharingService.getOfferListingData(), 'OtherOffering' + "_" + pipe.transform(d, 'dd/MM/yyyy'));
      this.formModel.displayMessage = "Exporting data completed...";
    } while (false);

  }

  addNewUser() {
    this.formModel.inEditMode = 'Y';
    this.formModel.loginID = "";
    this.formModel.password = "";
    this.formModel.contactNo = "";
    this.formModel.email = "";
    this.formModel.roleType = "";
  }
  deleteUser(roleID, roleType) {
    if (roleType == 'Adm') {
      alert("Super User cannot be deleted");
      return;
    }
    if (!confirm("Delete User ?")) {
      return;
    }

    this.offerService.deleteUser(roleID).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setAllUsersData(res.result);
        this.allUserDetails = res.result;
      }
    })

  }
  EditUser(roleID) {
    this.formModel.inEditMode = "Y";
    this.authService.GetUserInfoById(roleID).subscribe((res: any) => {
      if (res.status = 1) {
        console.log(res.result);
        this.formModel.loginID = res.result['loginID'];
        this.formModel.roleID = res.result['roleID'];
        // this.formModel.roleName=res.result['roleName'];
        this.formModel.email = res.result['email'];
        this.formModel.contactNo = res.result['contactNo'];
        this.formModel.password = res.result['password'];
      }
    })
  }
  userModel: any = {
    RoleID: "",
    Email: "",
    ContactNo: "",
    Password: "",
  }
  UpdateUser(form: NgForm) {
    if (form.value.password == "" || form.value.password == null || form.value.password == undefined) {
      alert("Enter Password");
      return;
    }
    this.userModel.RoleID = form.value.roleID;
    this.userModel.Email = form.value.email;
    this.userModel.ContactNo = form.value.contactNo;
    this.userModel.Password = form.value.password;

    this.authService.UpdateUser(this.userModel).subscribe((res: any) => {
      if (res.status = 1) {
        if (this.formModel.loggedinUserRole == 'Adm') {
          this.authService.getAllUserDetails().subscribe((res1: any) => { //this is used for User Management Screen
            if (res1 != null) {
              if (res1.status == 1) {
                this.dataSharingService.setAllUsersData(res1.result);
              }
            }
          })
          this.allUserDetails = this.dataSharingService.getAllUsersData();
          this.formModel.inEditMode = 'N';
        } else {
          this.authService.GetUserInfoById(this.formModel.loginUserRoleID).subscribe((res: any) => {
            if (res.status = 1) {
              console.log(res.result);
              this.formModel.loginID = res.result['loginID'];
              this.formModel.roleID = res.result['roleID'];
              // this.formModel.roleName=res.result['roleName'];
              this.formModel.email = res.result['email'];
              this.formModel.contactNo = res.result['contactNo'];
              this.formModel.password = res.result['password'];
            }
          })
          this.formModel.inEditMode = 'N';
        }

      }
      else {
        alert(res.message);
        this.formModel.inEditMode = 'N';
      }
    });
  }
  onSubmit(form: NgForm) {
    if (form.value.loginID == "" || form.value.loginID == null || form.value.loginID == undefined) {
      alert("Enter Login Name");
      return;
    }
    if (form.value.password == "" || form.value.password == null || form.value.password == undefined) {
      alert("Enter Password");
      return;
    }
    if (form.value.roleType == "" || form.value.roleType == null || form.value.roleType == undefined) {
      alert("Select Role for the User");
      return;
    }

    var dataGood = false;


    var obj = {};
    switch (form.value.roleType) {
      case 'SCU': {
        if (form.value.subCentreDetails == null) {
          alert('Select Child Centre');
          break;
        }
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: form.value.subCentreDetails.subCentreName,
          subCentreID: form.value.subCentreDetails.subCentreID,
          childCentreID: form.value.childCentreDetails.childCentreID,
          childCentreName: form.value.childCentreDetails.childCentreName,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName,
          roleName: 'Sub Centre User'
        };
        dataGood = true;
        break;
      }
      case 'CCU': {
        if (form.value.childCentreDetails == null) {
          alert('Select Child Centre');
          break;
        }
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: '',
          subCentreID: 0,
          childCentreID: form.value.childCentreDetails.childCentreID,
          childCentreName: form.value.childCentreDetails.childCentreName,
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName,
          roleName: 'Child Centre User'
        };
        dataGood = true;
        break;
      }
      case 'MCU': {
        if (form.value.mainCentreDetails == null) {
          alert('Select Main Centre');
          break;
        }
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: '',
          subCentreID: 0,
          childCentreID: 0,
          childCentreName: '',
          mainCentreID: form.value.mainCentreDetails.mainCentreID,
          mainCentreName: form.value.mainCentreDetails.mainCentreName,
          roleName: 'Main Centre User'
        };
        dataGood = true;
        break;
      }
      case 'Adm': {
        dataGood = true;
        obj = {
          loginID: form.value.loginID,
          email: form.value.email,
          password: form.value.password,
          contactNo: form.value.contactNo,
          roleType: form.value.roleType,
          subCentreName: '',
          subCentreID: 0,
          childCentreID: 0,
          childCentreName: '',
          mainCentreID: 0,
          mainCentreName: '',
          roleName: 'Super User'
        };
        break;
      }
      default:
        break;
    }
    if (dataGood == false) {
      return;
    }
    this.offerService.saveUserData(obj).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setAllUsersData(res.result);
        this.allUserDetails = res.result;
        this.formModel.inEditMode = 'N';
      }
    })

  }

  SetCutOffDate() {
    var pipe = new DatePipe('en-US');
    var cutDate = "";
    console.log(this.formModel.cuttOffDate);
    cutDate = pipe.transform(this.formModel.cuttOffDate, 'MM/dd/yyyy');
    var obj = {
      sysVarDataID: 0,
      cuttOffDate: cutDate
    };

    this.offerService.saveSysVarData(obj).subscribe((res: any) => {
      if (res.status = 1) {
        this.dataSharingService.setSysVarData(res.result);
        alert("Cut Off Date updated successfully");
      }
    })
  }

  openNav() {
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }
  closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }
   
  uploadFile(files) {
    if (files.length === 0)
      return;
    this.fileNameMebo = "";
    this.fileNameMain = "";
    this.fileNameChild = "";
    this.fileNameSub = "";
    this.fileNameStatus = "";
    this.fileNameOffering = "";
    this.fileNameMember = "";
    this.meboUploadMessage = "";
    this.meboUploadDetails = [];
    this.mainCentreUploadDetails = [];
    this.childCentreUploadDetails = [];
    this.subCentreUploadDetails = [];
    this.statusUploadDetails = [];
    this.membersUploadDetails = [];
    this.offeringsUploadDetails = [];

    const formData = new FormData();
    let fileToUpload = <File>files[0];
    let flName = fileToUpload.name;;
    formData.append('file', fileToUpload, fileToUpload.name);
    this.meboUploadErrors = [];
    switch (this.fileTypeToUpload){
      case 'Main': {
        this.fileNameMain = flName;
        this.meboUploadMessage = "";
        this.offerService.uploadMainCentre(formData).subscribe((res: any) => {
          if (res.valid == false) {
            alert(res.message);
            return;
          }
          this.meboUploadErrors = res.errors;
          this.meboUploadMessage = res.message;
          this.mainCentreUploadDetails = res.data;
        })
        break;
      }
      case 'Child': {
        this.fileNameChild = flName;
        this.meboUploadMessage = "";
        this.offerService.uploadChildCentre(formData).subscribe((res: any) => {
          if (res.valid == false) {
            alert(res.message);
            return;
          }
          this.meboUploadErrors = res.errors;
          this.meboUploadMessage = res.message;
          this.childCentreUploadDetails = res.data;
        })
        break;
      }
      case 'Sub': {
        this.fileNameSub = flName;
        this.meboUploadMessage = "";
        this.offerService.uploadSubCentre(formData).subscribe((res: any) => {
          if (res.valid == false) {
            alert(res.message);
            return;
          }
          this.meboUploadErrors = res.errors;
          this.meboUploadMessage = res.message;
          this.subCentreUploadDetails = res.data;
        })
        break;
      }
      case 'Status': {
        this.fileNameStatus = flName;
        this.meboUploadMessage = "";
        this.offerService.uploadStatus(formData).subscribe((res: any) => {
          if (res.valid == false) {
            alert(res.message);
            return;
          }
          this.meboUploadErrors = res.errors;
          this.meboUploadMessage = res.message;
          this.statusUploadDetails = res.data;
        })
        break;
      }
      case 'Offering': {
        this.fileNameOffering = flName;
        this.meboUploadMessage = "";
        this.offerService.uploadOffering(formData).subscribe((res: any) => {
          if (res.valid == false) {
            alert(res.message);
            return;
          }
          this.meboUploadErrors = res.errors;
          this.meboUploadMessage = res.message;
          this.offeringsUploadDetails = res.data;
          console.log(this.offeringsUploadDetails[0]);
        })
        break;
      }
      case 'Member': {
        this.fileNameMember = flName;
        this.meboUploadMessage = "";
        this.offerService.uploadMember(formData).subscribe((res: any) => {
          if (res.valid == false) {
            alert(res.message);
            return;
          }
          this.meboUploadErrors = res.errors;
          this.meboUploadMessage = res.message;
          this.membersUploadDetails = res.data;
        })
        break;
      }
      case 'Mebo': {
        this.fileNameMebo = flName;

        this.offerService.uploadMeboCSV(formData).subscribe((res: any) => {
          if (res.valid == false) {
            alert(res.message);
            return;
          }
          this.meboUploadErrors = res.errors;
          this.meboUploadDetails = res.data;
          this.meboUploadMessage = res.message;
        })
        break;
      }
      default:
        break;
    }

  }

}
