import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { OfferService } from "../../services/offer.service";
import { GlobalDataSharingService } from "../../services/globaldatasharing.service";
import { NgForm } from '@angular/forms';
import { ConnectionService } from 'ng-connection-service';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mebo',
  templateUrl: './mebo.component.html',
  styleUrls: ['./mebo.component.css']
})
export class MeboComponent implements OnInit {
 
  formModel: any = {
    loggedinUserRole: "",
    mainCentreName: "",
    mainCentreID: "",
    childCentreName: "",
    childCentreID: "",
    subCentreName:"",
    subCentreID: "",
    manageMebo: "",
    message: "",
    progress: "",
    showQueryMOption: "",
    showQueryIOption: "",
    showQueryAOption: "",
    showQueryHOption: "",
    showQueryCOption: "",
    inEditMode: "",
    gender: "",
    isAdultYN: "",
    memberID: "",
    name:""
  };
  totalRecords = 0;
  ErrMessage = "";
  loggedInUserData = [];
  mainCentreDetails = [];
  childCentreDetails = [];
  subCentreDetails = [];
  mainCentreDetailsEditMode = [];
  childCentreDetailsEditMode = [];
  subCentreDetailsEditMode = [];
  glbmainCentreDetails = [];
  glbchildCentreDetails = [];
  glbsubCentreDetails = [];
  statusDetails = [];
  statusDetailsEditMode = [];
  kenshuDetails = [];
  genderDetails = [];
  panadhardetails=[];
  ageDetails = [];
  kenshuYearDetails = [];
  sortOrderByDetails = [];
  queryMDetails = [];
  querySDetails = [];
  genderList = [];
  isAdultList=[];
  panadharList=[];

  queryMSearchCriteria = "";
  fileNameMebo = "";
  meboUploadErrors = [];
  meboUploadDetails = [];
  meboUploadMessage = "";
  selectedMainCentreObj = {};
  selectedChildCentreObj = {};
  selectedSubCentreObj = {};
  mainCentreObjEditMode = {};
  childCentreObjEditMode = {};
  subCentreObjEditMode = {};
  statusObjEditMode = {};

  allTextChildCentreObj = {};
  allTextSubCentreObj = {};
  selectedStatusObj = {};
  selectedKenshuObj = {};
  selectedGenderObj = {};
  selectedAgeObj = {};
  selectKenshuYearObj = {};
  selectedPanObj = {};
  selectedSortOrder = {};
  isConnected = true;
  status = 'ONLINE';
 // public isOnline: boolean = navigator.onLine;

  @Output() public onUploadFinished = new EventEmitter();

  constructor(private router: Router, private excelService: ExcelService,private dataSharingService: GlobalDataSharingService, private http: HttpClient, private offerService: OfferService,
    private spinner: NgxSpinnerService, private connectionService: ConnectionService)
  {
    this.connectionService.monitor().subscribe(isConnected => {
      console.log("a");
      this.isConnected = isConnected;
      console.log(this.isConnected);
      if (this.isConnected) {
        console.log(23);
        this.status = "ONLINE";
        console.log(this.status);
      }
      else {
        console.log(12);
        this.status = "OFFLINE";
        console.log(this.status);
      }
    })
  }


  ngOnInit() {
     
  //  this.GetMembersForQueryM()
  
    this.formModel.manageMebo = "M";
    this.formModel.showQueryMOption = "N";
    this.formModel.showQueryIOption = "N";
    this.formModel.showQueryAOption = "N";
    this.formModel.showQueryHOption = "N";
    this.formModel.showQueryCOption = "N";
    this.formModel.inEditMode = "N";
    this.formModel.isAdultYN = "";
    this.formModel.gender = "";
    this.formModel.memberID = "";
    this.formModel.name = "";

    this.genderList = [{ name: "Male", value: "M" }, { name: "Female", value: "F" }];
    this.isAdultList = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }];
    this.panadharList=[{ name: "Filled", value: "F" }, { name: "Blank", value: "B" }]

    this.formModel.loggedinUserRole = localStorage.getItem("loggedinUserRole");
    this.loggedInUserData = this.dataSharingService.getLoggedInUserData();
    this.formModel.mainCentreName = this.loggedInUserData[0].mainCentreName;
    this.formModel.childCentreName = this.loggedInUserData[0].childCentreName;
    this.formModel.subCentreName = this.loggedInUserData[0].subCentreName;
    this.formModel.mainCentreID = this.loggedInUserData[0].mainCentreID;
    this.formModel.childCentreID = this.loggedInUserData[0].childCentreID;
    this.formModel.subCentreID = this.loggedInUserData[0].subCentreID;
    this.glbchildCentreDetails = this.dataSharingService.getChildCentreData();
    this.glbsubCentreDetails = this.dataSharingService.getSubCentreData();
    this.glbmainCentreDetails = this.dataSharingService.getMainCentreData();
   
    this.selectedMainCentreObj = {
      mainCentreID: 0,
      mainCentreName: "All"
    };
    this.selectedChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextChildCentreObj = {
      childCentreID: 0,
      childCentreName: "All",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.allTextSubCentreObj = {
      subCentreID: 0,
      subCentreName: "All",
      childCentreID: 0,
      childCentreName: "",
      mainCentreID: 0,
      mainCentreName: ""
    };
    this.selectedStatusObj = {
      statusID: 0,
      statusName: "All"
    };
    this.selectedKenshuObj = {
      kenshuID: 0,
      kenshuType: "All"
    };
    this.selectedGenderObj = {
      genderID: "A",
      genderType: "All"
    };

    this.selectedPanObj = {
      ID: "A",
      Type: "All"
    };

    this.selectedAgeObj = {
      ageID: "A",
      ageType:"All"
    }

    this.selectKenshuYearObj = {
      kenshuYear: "A",
      kenshuYearType: "All"
    };

    this.selectedSortOrder = {
      id: "no",
      type: "No"
    };

    this.initializeTabs(1);
    this.queryMDetails = [];
    var tmpQueryMData = this.dataSharingService.getQueryMDetailsData();
    if (tmpQueryMData.queryMSearchCriteria != "") {
      this.totalRecords = tmpQueryMData.totalRecords;
      this.queryMSearchCriteria = tmpQueryMData.queryMSearchCriteria;
      this.queryMDetails = tmpQueryMData.queryMDetails;
    }

   // this.GetMembersForQueryM(this.formModel)
  }

  initializeTabs(tab) {
    var tmpArry = [];
    var i = 0;
    tmpArry = this.dataSharingService.getStatusData();
    this.statusDetails = [];
    this.statusDetails.push(this.selectedStatusObj);
    for (i = 0; i < tmpArry.length; i++) {
      this.statusDetails.push(tmpArry[i]);
    }
    this.kenshuDetails = [];
    tmpArry = [];
    this.kenshuDetails.push(this.selectedKenshuObj);
    tmpArry =  [
      {
        kenshuID: 1,
        kenshuType: "Primary",
      },
      {
        kenshuID: 2,
        kenshuType: "Intermediate",
      },
      {
        kenshuID: 3,
        kenshuType: "Advanced",
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.kenshuDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.genderDetails = [];
    this.genderDetails.push(this.selectedGenderObj);
    tmpArry = [
      {
        genderID: "M",
        genderType: "Male"
      },
      {
        genderID: "F",
        genderType: "Female"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.genderDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.ageDetails = [];
    this.ageDetails.push(this.selectedAgeObj);
    tmpArry = [
      {
        ageID: "Y",
        ageType: "Youth Age <= 35"
      },
      {
        ageID: "E",
        ageType: "Adult Age > 35"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.ageDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.kenshuYearDetails = [];
    this.kenshuYearDetails.push(this.selectKenshuYearObj);
    tmpArry = [
      {
        kenshuYear: "B",
        kenshuYearType: "Before 2000"
      },
      {
        kenshuYear: "C",
        kenshuYearType: "2001 To 2010"
      },
      {
        kenshuYear: "D",
        kenshuYearType: "After 2010"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.kenshuYearDetails.push(tmpArry[i]);
    }

    tmpArry = [];
    this.panadhardetails = [];
    this.panadhardetails.push(this.selectedPanObj);
    tmpArry = [
      {
        ID: "PB",
        Type: "Pan Blank"
      },
      {
        ID: "PF",
        Type: "Pan Filled"
      },
      {
        ID: "AB",
        Type: "Aadar Blank"
      },
      {
        ID: "AF",
        Type: "Aadar Filled"
      },
      {
        ID: "BB",
        Type: "Both Blank"
      },
      {
        ID: "BF",
        Type: "Both Filled"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.panadhardetails.push(tmpArry[i]);
    }

    this.selectedSortOrder = {
      id: "nm",
      type: "Name"
    };

    tmpArry = [];
    this.sortOrderByDetails = [];
    this.sortOrderByDetails.push(this.selectedSortOrder);
    tmpArry = [
      {
        id: "no",
        type: "No"
      },
      {
        id: "st",
        type: "Status"
      },
      {
        id: "ct",
        type: "Centre"
      },
      {
        id: "sa",
        type: "SCMO Month(Asc)"
      },
      {
        id: "sd",
        type: "SCMO Month(Des)"
      },      
      {
        id: "cy",
        type: "City"
      },
      {
        id: "zp",
        type: "Zip Code"
      }
    ];
    for (i = 0; i < tmpArry.length; i++) {
      this.sortOrderByDetails.push(tmpArry[i]);
    }


    switch (this.formModel.loggedinUserRole) {
      case 'Adm': {
        this.mainCentreDetails.push(this.selectedMainCentreObj);
        for (i = 0; i < this.glbmainCentreDetails.length;i++) {
          this.mainCentreDetails.push(this.glbmainCentreDetails[i]);
        }
        this.childCentreDetails.push(this.selectedChildCentreObj);
        this.subCentreDetails.push(this.selectedSubCentreObj);
        break;
      }
      case 'MCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        this.onChangeMainCentre(this.selectedMainCentreObj);
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'CCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        this.onChangeChildCentre(this.selectedChildCentreObj);
        break;
      }
      case 'SCU': {
        for (i = 0; i < this.glbmainCentreDetails.length; i++) {
          if (this.formModel.mainCentreID == this.glbmainCentreDetails[i].mainCentreID) {
            this.selectedMainCentreObj = this.glbmainCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.formModel.childCentreID == this.glbchildCentreDetails[i].childCentreID) {
            this.selectedChildCentreObj = this.glbchildCentreDetails[i];
            break;
          }
        }
        for (i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.formModel.subCentreID == this.glbsubCentreDetails[i].subCentreID) {
            this.selectedSubCentreObj = this.glbsubCentreDetails[i];
            break;
          }
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  onChangeMainCentre(mainCentre) {
    do {
      if (this.formModel.inEditMode == "N") {
        this.childCentreDetails = [];
        this.childCentreDetails.push(this.allTextChildCentreObj);
        this.selectedChildCentreObj = this.allTextChildCentreObj;

        for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
            this.childCentreDetails.push(this.glbchildCentreDetails[i]);
          }
        }
      } else {
        this.childCentreDetailsEditMode = [];
        for (var i = 0; i < this.glbchildCentreDetails.length; i++) {
          if (this.glbchildCentreDetails[i].mainCentreID == mainCentre.mainCentreID) {
            this.childCentreDetailsEditMode.push(this.glbchildCentreDetails[i]);
          }
        }
      }
    } while (false);

  }

  onChangeChildCentre(childCentre) {
    do {
      if (this.formModel.inEditMode == "N") {
        this.subCentreDetails = [];
        this.subCentreDetails.push(this.allTextSubCentreObj);
        this.selectedSubCentreObj = this.allTextSubCentreObj;
        for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
            this.subCentreDetails.push(this.glbsubCentreDetails[i]);
          }
        }
      } else {
        this.subCentreDetailsEditMode = [];
        for (var i = 0; i < this.glbsubCentreDetails.length; i++) {
          if (this.glbsubCentreDetails[i].childCentreID == childCentre.childCentreID) {
            this.subCentreDetailsEditMode.push(this.glbsubCentreDetails[i]);
          }
        }
      }
    } while (false);
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
    }, 5000);
  }

  GetMembersForQueryS(form: NgForm) {
    //this.showSpinner();
    this.queryMSearchCriteria = "Processing Records....";
    this.populateQuerySResult(form.value);
  }

  populateQuerySResult(searchCriteria) {
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();
    var iRowTotal = 0;

    var iGTot = 0;
    var iGActive = 0;
    var iGAstral = 0;
    var iGCheck = 0;
    var iGDormantLt = 0;
    var iGDormantGt = 0;
    var iGORet = 0;
    var iGTrfOut = 0;

    var iAGActive = 0;
    var iAGAstral = 0;
    var iAGCheck = 0;
    var iAGDormantLt = 0;
    var iAGDormantGt = 0;
    var iAGORet = 0;
    var iAGTrfOut = 0;

    var iIGActive = 0;
    var iIGAstral = 0;
    var iIGCheck = 0;
    var iIGDormantLt = 0;
    var iIGDormantGt = 0;
    var iIGORet = 0;
    var iIGTrfOut = 0;

    var iPGActive = 0;
    var iPGAstral = 0;
    var iPGCheck = 0;
    var iPGDormantLt = 0;
    var iPGDormantGt = 0;
    var iPGORet = 0;
    var iPGTrfOut = 0;

    var iGP = 0;
    var iGI = 0;
    var iGA = 0;



    var iActive = 0;
    var iAstral = 0;
    var iCheck = 0;
    var iDormantLt = 0;
    var iDormantGt = 0;
    var iORet = 0;
    var iTrfOut = 0;

    var iARActive = 0;
    var iARAstral = 0;
    var iARCheck = 0;
    var iARDormantLt = 0;
    var iARDormantGt = 0;
    var iARORet = 0;
    var iARTrfOut = 0;

    var iIRActive = 0;
    var iIRAstral = 0;
    var iIRCheck = 0;
    var iIRDormantLt = 0;
    var iIRDormantGt = 0;
    var iIRORet = 0;
    var iIRTrfOut = 0;

    var iPRActive = 0;
    var iPRAstral = 0;
    var iPRCheck = 0;
    var iPRDormantLt = 0;
    var iPRDormantGt = 0;
    var iPRORet = 0;
    var iPRTrfOut = 0;


    do {
      if (searchCriteria.mainCentreDetails.mainCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.mainCentre = item.mainCentre.trim();
        return item.mainCentre == searchCriteria.mainCentreDetails.mainCentreName;
      });
    } while (false);
    do {
      if (searchCriteria.childCentreDetails.childCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.childCentre = item.childCentre.trim();
        return item.childCentre == searchCriteria.childCentreDetails.childCentreName;
      });
    } while (false);
    do {
      if (searchCriteria.subCentreDetails.subCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.subCentre = item.subCentre.trim();
        return item.subCentre == searchCriteria.subCentreDetails.subCentreName;
      });
    } while (false);
    tmpArray.sort(function (a, b) {
      var nameA = a.mainCentre + "/" + a.childCentre  + "/" + a.subCentre;
      var nameB = b.mainCentre + "/" + b.childCentre  + "/" + b.subCentre;
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })


    iGTot = 0;
    iRowTotal = 0;
    var mainCentre = tmpArray[0].mainCentre;
    var childCentre = tmpArray[0].childCentre;
    var subCentre = tmpArray[0].subCentre;

    var iP = 0;
    var iI = 0;
    var iA = 0;
    var sortedArray = new Array();
    var iSrNo = 0;
    for (var i = 0; i < tmpArray.length; i++) {
      var nm = tmpArray[i].name;
      var vistorFnd = -1;
      vistorFnd = nm.indexOf("VISITOR");
      if (vistorFnd >= 0) {
        continue;
      }
      if (mainCentre == tmpArray[i].mainCentre &&
        childCentre == tmpArray[i].childCentre &&
        subCentre == tmpArray[i].subCentre) {
        iRowTotal = iRowTotal + 1;
        if (tmpArray[i].status == 'Active') {
          iActive = iActive + 1;
        }
        if (tmpArray[i].status == 'Astral B') {
          iAstral = iAstral + 1;
        }
        if (tmpArray[i].status == 'Check') {
          iCheck = iCheck + 1;
        }
        if (tmpArray[i].status == 'Dormant <5') {
          iDormantLt = iDormantLt + 1;
        }
        if (tmpArray[i].status == 'Dormant >5') {
          iDormantGt = iDormantGt + 1;
        }
        if (tmpArray[i].status == 'O Ret') {
          iORet = iORet + 1;
        }
        if (tmpArray[i].status == 'Trf Out') {
          iTrfOut = iTrfOut + 1;
        }
        do {
          if (tmpArray[i].akyn == 'Y') {
            iA = iA + 1;
            if (tmpArray[i].status == 'Active') {
              iARActive = iARActive + 1;
            }
            if (tmpArray[i].status == 'Astral B') {
              iARAstral = iARAstral + 1;
            }
            if (tmpArray[i].status == 'Check') {
              iARCheck = iARCheck + 1;
            }
            if (tmpArray[i].status == 'Dormant <5') {
              iARDormantLt = iARDormantLt + 1;
            }
            if (tmpArray[i].status == 'Dormant >5') {
              iARDormantGt = iARDormantGt + 1;
            }
            if (tmpArray[i].status == 'O Ret') {
              iARORet = iARORet + 1;
            }
            if (tmpArray[i].status == 'Trf Out') {
              iARTrfOut = iARTrfOut + 1;
            }

            break;
          }
          if (tmpArray[i].ikyn == 'Y') {
            iI = iI + 1;
            if (tmpArray[i].status == 'Active') {
              iIRActive = iIRActive + 1;
            }
            if (tmpArray[i].status == 'Astral B') {
              iIRAstral = iIRAstral + 1;
            }
            if (tmpArray[i].status == 'Check') {
              iIRCheck = iIRCheck + 1;
            }
            if (tmpArray[i].status == 'Dormant <5') {
              iIRDormantLt = iIRDormantLt + 1;
            }
            if (tmpArray[i].status == 'Dormant >5') {
              iIRDormantGt = iIRDormantGt + 1;
            }
            if (tmpArray[i].status == 'O Ret') {
              iIRORet = iIRORet + 1;
            }
            if (tmpArray[i].status == 'Trf Out') {
              iIRTrfOut = iIRTrfOut + 1;
            }
            break;
          }
          if (tmpArray[i].pkyn == 'Y') {
            if (tmpArray[i].status == 'Active') {
              iPRActive = iPRActive + 1;
            }
            if (tmpArray[i].status == 'Astral B') {
              iPRAstral = iPRAstral + 1;
            }
            if (tmpArray[i].status == 'Check') {
              iPRCheck = iPRCheck + 1;
            }
            if (tmpArray[i].status == 'Dormant <5') {
              iPRDormantLt = iPRDormantLt + 1;
            }
            if (tmpArray[i].status == 'Dormant >5') {
              iPRDormantGt = iPRDormantGt + 1;
            }
            if (tmpArray[i].status == 'O Ret') {
              iPRORet = iPRORet + 1;
            }
            if (tmpArray[i].status == 'Trf Out') {
              iPRTrfOut = iPRTrfOut + 1;
            }
            iP = iP + 1;
            break;
          }
        } while (false);
      } else {

        var tmp = {
          SrNo: iSrNo ,
          MainCentre: mainCentre,
          ChildCentre: childCentre,
          SubCentre: subCentre,
          Ken: '+',
          ShowP: "",
          ShowI: "",
          ShowA: "",
          KenTitle:"",
          Total: iRowTotal,
          Active: iActive,
          Astral: iAstral,
          Check: iCheck,
          DormantLt: iDormantLt,
          DormantGt: iDormantGt,
          ORet: iORet,
          TrfOut: iTrfOut
        }
        sortedArray.push(tmp);
        var tmp3 = {
          SrNo: iSrNo,
          MainCentre: "",
          ChildCentre: "",
          SubCentre: "N",
          Ken: '',
          ShowP: "",
          ShowI: "",
          ShowA: "",
          KenTitle: "A",
          Total: iA,
          Active: iARActive,
          Astral: iARAstral,
          Check: iARCheck,
          DormantLt: iARDormantLt,
          DormantGt: iARDormantGt,
          ORet: iARORet,
          TrfOut: iARTrfOut
        }
        sortedArray.push(tmp3);

        var tmp2 = {
          SrNo: iSrNo,
          MainCentre: "",
          ChildCentre: "",
          SubCentre: "N",
          Ken: '',
          ShowP: "",
          ShowI: "",
          ShowA: "",
          KenTitle: "I",
          Total: iI,
          Active: iIRActive,
          Astral: iIRAstral,
          Check: iIRCheck,
          DormantLt: iIRDormantLt,
          DormantGt: iIRDormantGt,
          ORet: iIRORet,
          TrfOut: iIRTrfOut
        }
        sortedArray.push(tmp2);

        var tmp1 = {
          SrNo: iSrNo,
          MainCentre: "",
          ChildCentre: "",
          SubCentre: "N",
          Ken: '',
          ShowP: "",
          ShowI: "",
          ShowA: "",
          KenTitle: "P",
          Total: iP ,
          Active: iPRActive,
          Astral: iPRAstral,
          Check: iPRCheck,
          DormantLt: iPRDormantLt,
          DormantGt: iPRDormantGt,
          ORet: iPRORet,
          TrfOut: iPRTrfOut
        }
        sortedArray.push(tmp1);

        iGTot = iRowTotal + iGTot;
        iGActive = iGActive + iActive;
        iGAstral = iGAstral + iAstral ;
        iGCheck = iGCheck + iCheck;
        iGDormantLt = iGDormantLt + iDormantLt ;
        iGDormantGt = iGDormantGt + iDormantGt;
        iGORet = iGORet + iORet;
        iGTrfOut = iGTrfOut + iTrfOut;

        iAGActive = iAGActive + iARActive;
        iAGAstral = iAGAstral + iARAstral;
        iAGCheck = iAGCheck + iARCheck;
        iAGDormantLt = iAGDormantLt + iARDormantLt;
        iAGDormantGt = iAGDormantGt + iARDormantGt;
        iAGORet = iAGORet + iARORet;
        iAGTrfOut = iAGTrfOut + iARTrfOut;

        iIGActive = iIGActive + iIRActive;
        iIGAstral = iIGAstral + iIRAstral;
        iIGCheck = iIGCheck + iIRCheck;
        iIGDormantLt = iIGDormantLt + iIRDormantLt;
        iIGDormantGt = iIGDormantGt + iIRDormantGt;
        iIGORet = iIGORet + iIRORet;
        iIGTrfOut = iIGTrfOut + iIRTrfOut;

        iPGActive = iPGActive + iPRActive;
        iPGAstral = iPGAstral + iPRAstral;
        iPGCheck = iPGCheck + iPRCheck ;
        iPGDormantLt = iPGDormantLt + iPRDormantLt;
        iPGDormantGt = iPGDormantGt + iPRDormantGt;
        iPGORet = iPGORet + iPRORet ;
        iPGTrfOut = iPGTrfOut + iPRTrfOut ;

        iGP = iGP + iP;
        iGI = iGI + iI;
        iGA = iGA + iA ;


        
        mainCentre = tmpArray[i].mainCentre;
        childCentre = tmpArray[i].childCentre;
        subCentre = tmpArray[i].subCentre;
        iP = 0;
        iI = 0;
        iA = 0;
        iActive = 0;
        iAstral = 0;
        iCheck = 0;
        iDormantLt = 0;
        iDormantGt = 0;
        iORet = 0;
        iTrfOut = 0;
        iRowTotal = 0;

        iARActive = 0;
        iARAstral = 0;
        iARCheck = 0;
        iARDormantLt = 0;
        iARDormantGt = 0;
        iARORet = 0;
        iARTrfOut = 0;

        iIRActive = 0;
        iIRAstral = 0;
        iIRCheck = 0;
        iIRDormantLt = 0;
        iIRDormantGt = 0;
        iIRORet = 0;
        iIRTrfOut = 0;

        iPRActive = 0;
        iPRAstral = 0;
        iPRCheck = 0;
        iPRDormantLt = 0;
        iPRDormantGt = 0;
        iPRORet = 0;
        iPRTrfOut = 0;
        iSrNo = iSrNo + 1;

        iRowTotal = iRowTotal + 1;
        if (tmpArray[i].status == 'Active') {
          iActive = iActive + 1;
        }
        if (tmpArray[i].status == 'Astral B') {
          iAstral = iAstral + 1;
        }
        if (tmpArray[i].status == 'Check') {
          iCheck = iCheck + 1;
        }
        if (tmpArray[i].status == 'Dormant <5') {
          iDormantLt = iDormantLt + 1;
        }
        if (tmpArray[i].status == 'Dormant >5') {
          iDormantGt = iDormantGt + 1;
        }
        if (tmpArray[i].status == 'O Ret') {
          iORet = iORet + 1;
        }
        if (tmpArray[i].status == 'Trf Out') {
          iTrfOut = iTrfOut + 1;
        }
        do {
          if (tmpArray[i].akyn == 'Y') {
            iA = iA + 1;
            if (tmpArray[i].status == 'Active') {
              iARActive = iARActive + 1;
            }
            if (tmpArray[i].status == 'Astral B') {
              iARAstral = iARAstral + 1;
            }
            if (tmpArray[i].status == 'Check') {
              iARCheck = iARCheck + 1;
            }
            if (tmpArray[i].status == 'Dormant <5') {
              iARDormantLt = iARDormantLt + 1;
            }
            if (tmpArray[i].status == 'Dormant >5') {
              iARDormantGt = iARDormantGt + 1;
            }
            if (tmpArray[i].status == 'O Ret') {
              iARORet = iARORet + 1;
            }
            if (tmpArray[i].status == 'Trf Out') {
              iARTrfOut = iARTrfOut + 1;
            }

            break;
          }
          if (tmpArray[i].ikyn == 'Y') {
            iI = iI + 1;
            if (tmpArray[i].status == 'Active') {
              iIRActive = iIRActive + 1;
            }
            if (tmpArray[i].status == 'Astral B') {
              iIRAstral = iIRAstral + 1;
            }
            if (tmpArray[i].status == 'Check') {
              iIRCheck = iIRCheck + 1;
            }
            if (tmpArray[i].status == 'Dormant <5') {
              iIRDormantLt = iIRDormantLt + 1;
            }
            if (tmpArray[i].status == 'Dormant >5') {
              iIRDormantGt = iIRDormantGt + 1;
            }
            if (tmpArray[i].status == 'O Ret') {
              iIRORet = iIRORet + 1;
            }
            if (tmpArray[i].status == 'Trf Out') {
              iIRTrfOut = iIRTrfOut + 1;
            }
            break;
          }
          if (tmpArray[i].pkyn == 'Y') {
            if (tmpArray[i].status == 'Active') {
              iPRActive = iPRActive + 1;
            }
            if (tmpArray[i].status == 'Astral B') {
              iPRAstral = iPRAstral + 1;
            }
            if (tmpArray[i].status == 'Check') {
              iPRCheck = iPRCheck + 1;
            }
            if (tmpArray[i].status == 'Dormant <5') {
              iPRDormantLt = iPRDormantLt + 1;
            }
            if (tmpArray[i].status == 'Dormant >5') {
              iPRDormantGt = iPRDormantGt + 1;
            }
            if (tmpArray[i].status == 'O Ret') {
              iPRORet = iPRORet + 1;
            }
            if (tmpArray[i].status == 'Trf Out') {
              iPRTrfOut = iPRTrfOut + 1;
            }
            iP = iP + 1;
            break;
          }
        } while (false);


      }

    }

    var tmpLast = {
      SrNo: iSrNo,
      MainCentre: mainCentre,
      ChildCentre: childCentre,
      SubCentre: subCentre,
      Ken: '+',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "",
      Total: iRowTotal,
      Active: iActive,
      Astral: iAstral,
      Check: iCheck,
      DormantLt: iDormantLt,
      DormantGt: iDormantGt,
      ORet: iORet,
      TrfOut: iTrfOut
    }
    sortedArray.push(tmpLast);
    var tmp3Last = {
      SrNo: iSrNo,
      MainCentre: "",
      ChildCentre: "",
      SubCentre: "N",
      Ken: '',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "A",
      Total: iA,
      Active: iARActive,
      Astral: iARAstral,
      Check: iARCheck,
      DormantLt: iARDormantLt,
      DormantGt: iARDormantGt,
      ORet: iARORet,
      TrfOut: iARTrfOut
    }
    sortedArray.push(tmp3Last);

    var tmp2Last = {
      SrNo: iSrNo,
      MainCentre: "",
      ChildCentre: "",
      SubCentre: "N",
      Ken: '',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "I",
      Total: iI ,
      Active: iIRActive,
      Astral: iIRAstral,
      Check: iIRCheck,
      DormantLt: iIRDormantLt,
      DormantGt: iIRDormantGt,
      ORet: iIRORet,
      TrfOut: iIRTrfOut
    }
    sortedArray.push(tmp2Last);
    var tmp1Last = {
      SrNo: iSrNo,
      MainCentre: "",
      ChildCentre: "",
      SubCentre: "N",
      Ken: '',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "P",
      Total: iP,
      Active: iPRActive,
      Astral: iPRAstral,
      Check: iPRCheck,
      DormantLt: iPRDormantLt,
      DormantGt: iPRDormantGt,
      ORet: iPRORet,
      TrfOut: iPRTrfOut
    }
    sortedArray.push(tmp1Last);

    iGTot = iRowTotal + iGTot;
    iGActive = iGActive + iActive;
    iGAstral = iGAstral + iAstral;
    iGCheck = iGCheck + iCheck;
    iGDormantLt = iGDormantLt + iDormantLt;
    iGDormantGt = iGDormantGt + iDormantGt;
    iGORet = iGORet + iORet;
    iGTrfOut = iGTrfOut + iTrfOut;

    iAGActive = iAGActive + iARActive;
    iAGAstral = iAGAstral + iARAstral;
    iAGCheck = iAGCheck + iARCheck;
    iAGDormantLt = iAGDormantLt + iARDormantLt;
    iAGDormantGt = iAGDormantGt + iARDormantGt;
    iAGORet = iAGORet + iARORet;
    iAGTrfOut = iAGTrfOut + iARTrfOut;

    iIGActive = iIGActive + iIRActive;
    iIGAstral = iIGAstral + iIRAstral;
    iIGCheck = iIGCheck + iIRCheck;
    iIGDormantLt = iIGDormantLt + iIRDormantLt;
    iIGDormantGt = iIGDormantGt + iIRDormantGt;
    iIGORet = iIGORet + iIRORet;
    iIGTrfOut = iIGTrfOut + iIRTrfOut;

    iPGActive = iPGActive + iPRActive;
    iPGAstral = iPGAstral + iPRAstral;
    iPGCheck = iPGCheck + iPRCheck;
    iPGDormantLt = iPGDormantLt + iPRDormantLt;
    iPGDormantGt = iPGDormantGt + iPRDormantGt;
    iPGORet = iPGORet + iPRORet;
    iPGTrfOut = iPGTrfOut + iPRTrfOut;

    iGP = iGP + iP;
    iGI = iGI + iI;
    iGA = iGA + iA;

//Prakash
    iSrNo = iSrNo + 1;
    var tmpGrand = {
      SrNo: iSrNo,
      MainCentre: "Total",
      ChildCentre: "",
      SubCentre: "",
      Ken: '+',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "",
      Total: iGTot,
      Active: iGActive,
      Astral: iGAstral,
      Check: iGCheck,
      DormantLt: iGDormantLt,
      DormantGt: iGDormantGt,
      ORet: iGORet,
      TrfOut: iGTrfOut
    }
    sortedArray.push(tmpGrand);

    var tmpAtmpGrand = {
      SrNo: iSrNo,
      MainCentre: "",
      ChildCentre: "",
      SubCentre: "N",
      Ken: '',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "A",
      Total: iGA,
      Active: iAGActive,
      Astral: iAGAstral,
      Check: iAGCheck,
      DormantLt: iAGDormantLt,
      DormantGt: iAGDormantGt,
      ORet: iAGORet,
      TrfOut: iAGTrfOut
    }
    sortedArray.push(tmpAtmpGrand);

    var tmpItmpGrand = {
      SrNo: iSrNo,
      MainCentre: "",
      ChildCentre: "",
      SubCentre: "N",
      Ken: '',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "I",
      Total: iGI,
      Active: iIGActive,
      Astral: iIGAstral,
      Check: iIGCheck,
      DormantLt: iIGDormantLt,
      DormantGt: iIGDormantGt,
      ORet: iIGORet,
      TrfOut: iIGTrfOut
    }
    sortedArray.push(tmpItmpGrand);

    var tmpPtmpGrand = {
      SrNo: iSrNo,
      MainCentre: "",
      ChildCentre: "",
      SubCentre: "N",
      Ken: '',
      ShowP: "",
      ShowI: "",
      ShowA: "",
      KenTitle: "P",
      Total: iGP,
      Active: iPGActive,
      Astral: iPGAstral,
      Check: iPGCheck,
      DormantLt: iPGDormantLt,
      DormantGt: iPGDormantGt,
      ORet: iPGORet,
      TrfOut: iPGTrfOut
    }
    sortedArray.push(tmpPtmpGrand);
    this.querySDetails = [];
    this.querySDetails = sortedArray;
  }

  showNextThree(iSrNo) {
    for ( var i= 0 ; i < this.querySDetails.length;i++) {
      if (this.querySDetails[i].SrNo == iSrNo) {
        this.querySDetails[i].Ken = "-";
        this.querySDetails[i + 1].SubCentre = "";
        this.querySDetails[i + 1].ShowA= "A";
        this.querySDetails[i + 2].SubCentre = "";
        this.querySDetails[i + 2].ShowI = "I";
        this.querySDetails[i + 3].SubCentre = "";
        this.querySDetails[i + 3].ShowP= "P";
        break;

      }
    }
  }

  HideNextThree(iSrNo) {
    for (var i = 0; i < this.querySDetails.length; i++) {
      if (this.querySDetails[i].SrNo == iSrNo) {
        this.querySDetails[i].Ken = "+";
        this.querySDetails[i + 1].SubCentre = "N";
        this.querySDetails[i + 1].ShowA= "";
        this.querySDetails[i + 2].SubCentre = "N";
        this.querySDetails[i + 2].ShowI = "";
        this.querySDetails[i + 3].SubCentre = "N";
        this.querySDetails[i + 3].ShowP  = "";
        break;

      }
    }
  }



  GetMembersForQueryM(form: NgForm) {
    //this.showSpinner();
    this.queryMSearchCriteria = "Processing Records....";
    this.populateQueryMResult(form.value);
  }


  populateQueryMResult(searchCriteria) {
    var tmpArray = [];
    tmpArray = this.dataSharingService.getAllMembersListing();

    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu : " + searchCriteria.kenshuDetails.kenshuType;
      if (searchCriteria.kenshuDetails.kenshuType == 'Primary') {
        break;
      }
      if (searchCriteria.kenshuDetails.kenshuType == 'Intermediate') {
        tmpArray = tmpArray.filter(function (item) {
          return item.ikyn == 'Y';
        });
      }
      if (searchCriteria.kenshuDetails.kenshuType == 'Advanced') {
        tmpArray = tmpArray.filter(function (item) {
          return item.akyn == 'Y';
        });
      }
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu ID: " + searchCriteria.kenshuID;
      if (searchCriteria.kenshuID == null || searchCriteria.kenshuID == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.priKID == searchCriteria.kenshuID;
      });
    } while (false)

    do {
      if (searchCriteria.memberName == null || searchCriteria.memberName == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.name.toLowerCase().includes(searchCriteria.memberName.toLowerCase());
      });
    } while (false);
    do {
      if (searchCriteria.introName == null || searchCriteria.introName == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.introducerName.toLowerCase().includes(searchCriteria.introName.toLowerCase());
      });
    } while (false);

    do {
      if (searchCriteria.remark1 == null || searchCriteria.remark1 == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return (item.remark1 && item.remark1.toLowerCase().includes(searchCriteria.remark1.toLowerCase()));
      });
    } while (false);

    do {
      if (searchCriteria.remark2 == null || searchCriteria.remark2 == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return (item.remark2 &&  item.remark2.toLowerCase().includes(searchCriteria.remark2.toLowerCase()));
      });
    } while (false);

    do {
      if (searchCriteria.remark3 == null || searchCriteria.remark3 == "") {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return (item.remark3 && item.remark3.toLowerCase().includes(searchCriteria.remark3.toLowerCase()));
      });
    } while (false);

    do {
      this.queryMSearchCriteria = "Search Criteria Main Centre : " + searchCriteria.mainCentreDetails.mainCentreName;
      if (searchCriteria.mainCentreDetails.mainCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.mainCentre = item.mainCentre.trim();
        return item.mainCentre == searchCriteria.mainCentreDetails.mainCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria +  ", Child Centre : " + searchCriteria.childCentreDetails.childCentreName;
      if (searchCriteria.childCentreDetails.childCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        item.childCentre = item.childCentre.trim();
        return item.childCentre == searchCriteria.childCentreDetails.childCentreName;
      });
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Sub Centre :" + searchCriteria.subCentreDetails.subCentreName;
      if (searchCriteria.subCentreDetails.subCentreName == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        //console.log(item.subCentre +"---"+searchCriteria.subCentreDetails.subCentreName+"--");
        item.subCentre = item.subCentre.trim();
        return item.subCentre == searchCriteria.subCentreDetails.subCentreName;
      });
      //console.log(tmpArray);
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Status : " + searchCriteria.statusDetails.statusName; 
      if (searchCriteria.statusDetails.statusName== 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.status == searchCriteria.statusDetails.statusName;
      });
    } while (false);

    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Gender : " + searchCriteria.genderDetails.genderType;
      if (searchCriteria.genderDetails.genderType == 'All') {
        break;
      }
      tmpArray = tmpArray.filter(function (item) {
        return item.gender == searchCriteria.genderDetails.genderID;
      });
    } while (false);


    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Kenshu Year : " + searchCriteria.kenshuYearDetails.kenshuYearType;
      if (searchCriteria.kenshuYearDetails.kenshuYear == 'A') {
        break;
      }
      var kenshuArray = [];
      for (var i = 0; i < tmpArray.length; i++) {
        var priLevelDate = tmpArray[i].priKDate;
        if (priLevelDate == null || priLevelDate == "") {
          continue;
        }
        var prilevel = priLevelDate.split("-");
        var pri = prilevel[0];
        console.log(pri);
        if (searchCriteria.kenshuYearDetails.kenshuYear == 'B' && pri <= 2000) {
          kenshuArray.push(tmpArray[i]);
        }
        if (searchCriteria.kenshuYearDetails.kenshuYear== 'C' && (pri >= 2001 && pri <= 2010)) {
          kenshuArray.push(tmpArray[i]);
        }
        if (searchCriteria.kenshuYearDetails.kenshuYear == 'D' && pri > 2010) {
          kenshuArray.push(tmpArray[i]);
        }
      }
      tmpArray = kenshuArray;
    } while (false);
    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Age : " + searchCriteria.ageDetails.ageType;
      if (searchCriteria.ageDetails.ageID == 'A') {
        break;
      }
      var dummyArray = [];
      var yy = new Date().getFullYear();
      var bError = false;
      for (var i = 0; i < tmpArray.length; i++) {
        var birthDate = tmpArray[i].birthDate;
        if (birthDate == null || birthDate == "") {
          bError = true;
          this.ErrMessage = this.ErrMessage + tmpArray[i].memberId + ","
          continue;
        }
        var dbDArr = birthDate.split("-");
        var bdyy = dbDArr[0];
        var years = yy - bdyy;
        if (searchCriteria.ageDetails.ageID == 'Y' && years <= 35) {
          dummyArray.push(tmpArray[i]);
        }
        if (searchCriteria.ageDetails.ageID == 'E' && years > 35) {
          dummyArray.push(tmpArray[i]);
        }
      }
      tmpArray = dummyArray;
    } while (false);


    do {
      //console.log(searchCriteria.sortOrderByDetails.type+"   searchCriteria.sortOrderByDetails.type");
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", Order By : " + searchCriteria.sortOrderByDetails.type;
      /*
      if (searchCriteria.sortOrderByDetails.id == "no") {
        tmpArray.sort(function (a, b) {
          return a.id - b.id
        })
        break;
      }*/
      if (searchCriteria.sortOrderByDetails.id == "zp") {
        var tmpBlankArray = [];
        var tmpFilledArray = [];
        var i = 0;
        for (i = 0; i < tmpArray.length; i++) {
          var obj = tmpArray[i];
          if (obj.zipCode == "" || obj.zipCode == null) {
            tmpBlankArray.push(obj);
          } else {
            tmpFilledArray.push(obj);
          }
        }
        tmpFilledArray.sort(function (a, b) {
          var nameA = a.zipCode.toLowerCase(), nameB = b.zipCode.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })

        i = 0;
        for (i = 0; i < tmpBlankArray.length; i++) {
          tmpFilledArray.push(tmpBlankArray[i]);
        }
        tmpArray = tmpFilledArray;

        break;        
      }

      if (searchCriteria.sortOrderByDetails.id == "cy") {
        var tmpBlankArray = [];
        var tmpFilledArray = [];
        var i = 0;
        for (i = 0; i < tmpArray.length; i++) {
          var obj = tmpArray[i];
          if (obj.city == "" || obj.city == null) {
            tmpBlankArray.push(obj);
          } else {
            tmpFilledArray.push(obj);
          }
        }
        tmpFilledArray.sort(function (a, b) {
          var nameA = a.city.toLowerCase(), nameB = b.city.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })

        i = 0;
        for (i = 0; i < tmpBlankArray.length; i++) {
          tmpFilledArray.push(tmpBlankArray[i]);
        }
        tmpArray = tmpFilledArray;
        break;        
      }

      if (searchCriteria.sortOrderByDetails.id == "sa") {
        var tmpBlankArray = [];
        var tmpFilledArray = [];
        var i = 0; 
        for (i = 0; i < tmpArray.length; i++) {
          var obj = tmpArray[i];
          if (obj.forMonth == "" || obj.forMonth == null) {
            tmpBlankArray.push(obj);
          } else {
            tmpFilledArray.push(obj);
          }
        }
        tmpFilledArray = this.sortData(tmpFilledArray, "A");
        i = 0; 
        for (i = 0; i < tmpBlankArray.length; i++) {
          tmpFilledArray.push(tmpBlankArray[i]);
        }
        tmpArray = tmpFilledArray;
        break;
      }

      if (searchCriteria.sortOrderByDetails.id == "sd") {
        var tmpBlankArray = [];
        var tmpFilledArray = [];
        var i = 0;
        for (i = 0; i < tmpArray.length; i++) {
          var obj = tmpArray[i];
          if (obj.forMonth == "" || obj.forMonth == null) {
            tmpBlankArray.push(obj);
          } else {
            tmpFilledArray.push(obj);
          }
        }
        tmpFilledArray = this.sortData(tmpFilledArray, "D");
        i = 0;
        for (i = 0; i < tmpBlankArray.length; i++) {
          tmpFilledArray.push(tmpBlankArray[i]);
        }
        tmpArray = tmpFilledArray;
        break;
      }

      if (searchCriteria.sortOrderByDetails.id == "no") {
        tmpArray.sort(function (a, b) {
          var nameA = a.memberID.toLowerCase(), nameB = b.memberID.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "nm") {
        tmpArray.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "st") {
        tmpArray.sort(function (a, b) {
          var nameA = a.status, nameB = b.status
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
      if (searchCriteria.sortOrderByDetails.id == "ct") {
        tmpArray.sort(function (a, b) {

          var nameA = a.mainCentre.substr(0,3) +"/"+a.childCentre.substr(0,3)+"/"+a.subCentre.substr(0,3);
          var nameB = b.mainCentre.substr(0,3) +"/"+b.childCentre.substr(0,3)+"/"+b.subCentre.substr(0,3);
         // console.log(nameA);
         // console.log(nameB);
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
        break;
      }
    } while (false);

    do {
      this.queryMSearchCriteria = this.queryMSearchCriteria + ", PAN/Aadhar : " + searchCriteria.panadhardetails.Type;
      if (searchCriteria.panadhardetails.Type == 'All') {
        break;
      }
      if (searchCriteria.panadhardetails.ID == 'PB') {
        console.log("Pan Blank");
        var panArray = [];
        for (var i = 0; i < tmpArray.length; i++) {
          if ((tmpArray[i].panNo == "" || tmpArray[i].panNo == null)) {
            panArray.push(tmpArray[i]);
          }
        }
        tmpArray = panArray;
        break;
      }
      if (searchCriteria.panadhardetails.ID == 'PF') {
        console.log("Pan Filled");
        var panArray = [];
        for (var i = 0; i < tmpArray.length; i++) {
          if ((tmpArray[i].panNo != "" && tmpArray[i].panNo != null)) {
            panArray.push(tmpArray[i]);
          }
        }
        tmpArray = panArray;
        break;
      }
      if (searchCriteria.panadhardetails.ID == 'AB') {
        console.log("Aadhar Blank");
        var panArray = [];
        for (var i = 0; i < tmpArray.length; i++) {
          if ((tmpArray[i].aadhaarNo == "" || tmpArray[i].aadhaarNo == null)) {
            panArray.push(tmpArray[i]);
          }
        }
        tmpArray = panArray;
        break;
      }      
      if (searchCriteria.panadhardetails.ID == 'AF') {
        console.log("Aadhar Filled");
        var panArray = [];
        for (var i = 0; i < tmpArray.length; i++) {
          if ((tmpArray[i].aadhaarNo != "" && tmpArray[i].aadhaarNo != null)) {
            panArray.push(tmpArray[i]);
          }
        }
        tmpArray = panArray;
        break;
      }
      if (searchCriteria.panadhardetails.ID == 'BB') {
        console.log("Both Blank");
        var panArray = [];
        for (var i = 0; i < tmpArray.length; i++) {
          if ((tmpArray[i].panNo == "" || tmpArray[i].panNo == null) && (tmpArray[i].aadhaarNo == "" || tmpArray[i].aadhaarNo == null)) {
            panArray.push(tmpArray[i]);
          }
        }
        tmpArray = panArray;
        break;
      }
      if(searchCriteria.panadhardetails.ID == 'BF'){
        console.log("Both Filled");
        var panArray=[];
        for (var i = 0; i < tmpArray.length; i++) {
            if((tmpArray[i].panNo!="" && tmpArray[i].panNo!=null) && (tmpArray[i].aadhaarNo!="" && tmpArray[i].aadhaarNo!=null)){
              panArray.push(tmpArray[i]);
            }
        }
        tmpArray=panArray;
        break;
      }
    } while (false);
    this.queryMDetails = [];
    this.queryMDetails = tmpArray;
    console.log(this.queryMDetails[0]);
    this.totalRecords = tmpArray.length;
    if (this.ErrMessage != "") {
      this.ErrMessage = "Invalid DOB for Members ( " + this.ErrMessage.substr(0, this.ErrMessage.length - 1) + " )";
    }
    var tmpObj = {
      totalRecords: this.totalRecords,
      queryMSearchCriteria: this.queryMSearchCriteria,
      queryMDetails: this.queryMDetails
    };
    this.dataSharingService.setQueryMDetailsData(tmpObj);
    /*
     setTimeout(() => {
      this.spinner.hide();
    }, 5000);
    */

  }

  sortData(tmpList, order) {
    if (order == "A") {
      return tmpList.sort((a, b) => {
        return <any>new Date(a.forMonth).getTime() - <any>new Date(b.forMonth).getTime();
      });

    } else {
      return tmpList.sort((a, b) => {
        return <any>new Date(b.forMonth).getTime() - <any>new Date(a.forMonth).getTime();
      });

    }
  }


  uploadFile(files) {
    if (files.length === 0)
      return;
    const formData = new FormData();
    let fileToUpload = <File>files[0];
    this.fileNameMebo = fileToUpload.name;
    formData.append('file', fileToUpload, fileToUpload.name);
    this.meboUploadErrors = [];
    this.meboUploadDetails = [];
    this.meboUploadMessage = "";
    
    this.offerService.uploadMeboCSV(formData).subscribe((res: any) => {
      if (res.valid == false) {
        alert(res.message);
        return;
      }
      this.meboUploadErrors = res.errors;
      this.meboUploadDetails = res.data;
      this.meboUploadMessage = res.message;
    })
  }

  showHideQueryOptions(queryOption) {
    do {
      if (queryOption == 'M') {
        if (this.formModel.showQueryMOption == "Y") {
          this.formModel.showQueryMOption = "N";
        } else {
          this.formModel.showQueryMOption = "Y";
        }
        break;
      }
      if (queryOption == 'A') {
        if (this.formModel.showQueryAOption == "Y") {
          this.formModel.showQueryAOption = "N";
        } else {
          this.formModel.showQueryAOption = "Y";
        }
        break;
      }
      if (queryOption == 'H') {
        if (this.formModel.showQueryHOption == "Y") {
          this.formModel.showQueryHOption = "N";
        } else {
          this.formModel.showQueryHOption = "Y";
        }
        break;
      }
      if (queryOption == 'C') {
        if (this.formModel.showQueryCOption == "Y") {
          this.formModel.showQueryCOption = "N";
        } else {
          this.formModel.showQueryCOption = "Y";
        }
        break;
      }
      if (queryOption == 'I') {
        if (this.formModel.showQueryIOption == "Y") {
          this.formModel.showQueryIOption = "N";
        } else {
          this.formModel.showQueryIOption = "Y";
        }
        break;
      }
    } while (false);
  }

  editMember(){
    this.router.navigateByUrl('offer/editmebo');
  }

  exportToExcelMeboData() {
    var pipe = new DatePipe('en-US');
    var tmpArray = new Array();
    for (var i = 0; i < this.queryMDetails.length; i++) {
      var centre = this.queryMDetails[i].mainCentre.substr(0, 3) + "/" + this.queryMDetails[i].childCentre.substr(0, 3) + "/" + this.queryMDetails[i].subCentre.substr(0, 3);
      var tmp = {
        SrNo: i + 1,
        MemberID: this.queryMDetails[i].memberID,
        MemberName: this.queryMDetails[i].name,
        Gender: this.queryMDetails[i].gender,
        DOB: pipe.transform(this.queryMDetails[i].birthDate, 'dd/MM/yyyy'),
        Primary: pipe.transform(this.queryMDetails[i].priKDate, 'dd/MM/yyyy'),
        Intermediate: pipe.transform(this.queryMDetails[i].intKDate, 'dd/MM/yyyy'),
        Advance: pipe.transform(this.queryMDetails[i].advKDate, 'dd/MM/yyyy'),
        InNOut: this.queryMDetails[i].inAndOut,
        Status: this.queryMDetails[i].status,
        Centre: centre,
        IntroducerName: this.queryMDetails[i].introducerName,
        Gr: this.queryMDetails[i].remark1,
        SCMOMonth: pipe.transform(this.queryMDetails[i].forMonth, 'MMM-yyyy'),
        Remark1: this.queryMDetails[i].remark2,
        Remark2: this.queryMDetails[i].remark3
      }
      tmpArray.push(tmp);
    }

    this.excelService.exportAsExcelFile(tmpArray, 'MeboDetails' + "_" + pipe.transform(new Date(), 'dd/MM/yyyy'));
  }

  exportToExcelQueryS() {
    var pipe = new DatePipe('en-US');
    var tmpArray = new Array();
    for (var i = 0; i < this.querySDetails.length; i++) {
      var tmp = {
        MainCentre: this.querySDetails[i].MainCentre,
        ChildCentre: this.querySDetails[i].ChildCentre,
        SubCentre: ((this.querySDetails[i].SubCentre == "N") ? '' : this.querySDetails[i].SubCentre ),
        Ken: this.querySDetails[i].KenTitle,
        Total: this.querySDetails[i].Total,
        Active: this.querySDetails[i].Active,
        AstralB: this.querySDetails[i].Astral,
        Check: this.querySDetails[i].Check,
        DormantLt: this.querySDetails[i].DormantLt,
        DormantGt: this.querySDetails[i].DormantGt,
        ORet: this.querySDetails[i].ORet,
        TrfOut: this.querySDetails[i].TrfOut,
      }
      tmpArray.push(tmp);
    }

    this.excelService.exportAsExcelFile(tmpArray, 'QuerySDetails' + "_" + pipe.transform(new Date(), 'dd/MM/yyyy'));
  }


}
